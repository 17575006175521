import React from 'react'
import { 
  BooleanInput, Create, DateInput, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator } from 'react-admin'
import { VehicleTypeDescriptionItemChoices } from 'Constants/VehicleTypes';

const VehicleTypeTitle = ({ record }) => {
  return (
    <span>
      Create New VehicleType
    </span>
  )
}

export const VehicleTypeCreate = props => (
  <Create title={<VehicleTypeTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="type" />
      <TextInput source="category" />
      <TextInput source="refModel" />
      <ArrayInput source="description">
        <SimpleFormIterator>
          <SelectInput source="key" choices={VehicleTypeDescriptionItemChoices} optionText="name" optionValue="value"/>
          <TextInput source="value" />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="commercial" />
      <NumberInput source="maxPax" />
      <NumberInput source="maxBaggage" />
      <TextInput source="imageUrl" />
    </SimpleForm>
  </Create>
)

export default VehicleTypeCreate;
import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../../Components/Common/Modal/Modal";
import Editor from "../../../Components/Common/Editor/Editor";

const OperatingCityFormSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, "Too Short!").required(),
  visibility: Yup.boolean().required(),
  images: Yup.object().shape({
    web: Yup.object().shape({
      cover: Yup.string().trim().url(),
      thumbnailLarge: Yup.string().trim().url(),
      thumbnailSmall: Yup.string().trim().url(),
    }),
    mobile: Yup.object().shape({
      cover: Yup.string().trim().url(),
      thumbnailLarge: Yup.string().trim().url(),
      thumbnailSmall: Yup.string().trim().url(),
    }),
  }),
  imagesCredits: Yup.object().shape({
    cover: Yup.string().trim(),
    thumbnailLarge: Yup.string().trim(),
    thumbnailSmall: Yup.string().trim(),
  }),
  latitude: Yup.number(),
  longitude: Yup.number(),
  country: Yup.string().required().matches(new RegExp("^[0-9a-fA-F]{24}$")),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    inputContainer: {
      width: "45%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
  };
});

const OpertaingCityModal = ({
  operatingCityDetails,
  showModal,
  closeModal,
  refreshList,
  countries,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [imageCreditType, setImageCreditType] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: OperatingCityFormSchema,
    initialValues: {
      name: "",
      visibility: true,
      images: {
        web: {
          cover: "",
          thumbnailLarge: "",
          thumbnailSmall: "",
        },
        mobile: {
          cover: "",
          thumbnailLarge: "",
          thumbnailSmall: "",
        },
      },
      imagesCredits: {
        cover: "",
        thumbnailLarge: "",
        thumbnailSmall: "",
      },
      latitude: 0,
      longitude: 0,
      ...operatingCityDetails,
      country:
        operatingCityDetails &&
        operatingCityDetails.country &&
        operatingCityDetails.country.id,
    },
    onSubmit: (data) => {
      if (operatingCityDetails) {
        editCity(data);
      } else {
        addCity(data);
      }
    },
  });

  const addCity = (data) => {
    let regionId = countries.find(
      (country) => country.id === data.country
    ).region;

    data.region = regionId;

    dataProvider
      .create("operating-city", { data })
      .then(() => {
        closeModal();
        refreshList();
        notify("Operating city created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editCity = (data) => {
    delete data.cities;
    delete data.slug;

    let regionId = countries.find(
      (country) => country.id === data.country
    ).region;

    data.region = regionId;

    dataProvider
      .update("operating-city", {
        id: operatingCityDetails.slug,
        data,
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Operating city updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!operatingCityDetails && countries && countries.length > 0) {
      setFieldValue("country", countries[0].id);
    }
  }, [countries, operatingCityDetails]);

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        {!imageCreditType ? (
          <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
            <Typography variant="h5">{`${
              operatingCityDetails ? "Edit" : "Create"
            } Operating City`}</Typography>
            <form className={classes.formContainer} onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="space-between">
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Country Name
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.country}
                    onChange={handleChange("country")}
                    onBlur={handleBlur("country")}
                    label="Country Name"
                    inputProps={{
                      name: "country",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="City Name"
                  error={"name" in errors && "name" in touched}
                  helperText={
                    "name" in errors && "name" in touched && errors.name
                  }
                  variant="outlined"
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.name}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "cover" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "cover" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "cover" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "cover" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Cover Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.cover")}
                  onBlur={handleBlur("images.web.cover")}
                  value={values.images.web.cover}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("cover")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.cover ||
                        "<p>Cover Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Small Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailSmall")}
                  onBlur={handleBlur("images.web.thumbnailSmall")}
                  value={values.images.web.thumbnailSmall}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("thumbnailSmall")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailSmall ||
                        "<p>Thumbnail Small Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Large Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailLarge")}
                  onBlur={handleBlur("images.web.thumbnailLarge")}
                  value={values.images.web.thumbnailLarge}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("thumbnailLarge")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailLarge ||
                        "<p>Thumbnail Large Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  label="Latitude"
                  variant="outlined"
                  onChange={handleChange("latitude")}
                  onBlur={handleBlur("latitude")}
                  value={values.latitude}
                  className={classes.inputContainer}
                />
                <TextField
                  label="Longitude"
                  variant="outlined"
                  onChange={handleChange("longitude")}
                  onBlur={handleBlur("longitude")}
                  value={values.longitude}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Visibility
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.visibility}
                    onChange={handleChange("visibility")}
                    onBlur={handleBlur("visibility")}
                    label="Visibility"
                    inputProps={{
                      name: "visibility",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value={true}>On</option>
                    <option value={false}>Off</option>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  {operatingCityDetails ? "Discard" : "Cancel"}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {operatingCityDetails ? "Save" : "Submit"}
                </Button>
              </Box>
            </form>
          </Box>
        ) : (
          <Box>
            <Box display="flex" bgcolor="white" justifyContent="space-between">
              <Button
                onClick={() => {
                  setTemplateData(values.imagesCredits[imageCreditType] || "");
                  setImageCreditType(null);
                }}
                variant="text"
                color="primary"
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  setFieldValue(
                    `imagesCredits.${imageCreditType}`,
                    templateData
                  );
                  setImageCreditType(null);
                }}
                style={{ borderRadius: 0 }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
            <Editor
              onChange={(e) => setTemplateData(e.editor.getData())}
              style={{ borderWidth: 0 }}
              config={{
                height: "400px",
                width: "100%",
                resize_enabled: false,
              }}
              data={templateData || values.imagesCredits[imageCreditType]}
            />
          </Box>
        )}
      </Fragment>
    </Modal>
  );
};

export default OpertaingCityModal;

import React from 'react'
import PropTypes from 'prop-types'
import Button from 'Components/Common/RaOverrides/Button';

export default function ResetFiltersActionButton(props) {
  return (
      <Button {...props} onClick={() => props.setFilters(props.defaultObj)} label={'Reset Filters'} />
  );
}

ResetFiltersActionButton.propTypes = {
  defaultObj: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
}

ResetFiltersActionButton.defaultProps = {
  defaultObj: {},
}

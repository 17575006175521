import React, { useState, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../../Components/Common/Modal/Modal";
import Editor from "../../../Components/Common/Editor/Editor";

const SightseeingFormSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, "Too Short!").required(),
  about: Yup.string().trim(),
  images: Yup.object().shape({
    web: Yup.object().shape({
      cover: Yup.string().trim().url(),
      thumbnailLarge: Yup.string().trim().url(),
      thumbnailSmall: Yup.string().trim().url(),
    }),
    mobile: Yup.object().shape({
      cover: Yup.string().trim().url(),
      thumbnailLarge: Yup.string().trim().url(),
      thumbnailSmall: Yup.string().trim().url(),
    }),
  }),
  imagesCredits: Yup.object().shape({
    cover: Yup.string().trim(),
    thumbnailLarge: Yup.string().trim(),
    thumbnailSmall: Yup.string().trim(),
  }),
  nearByDetails: Yup.object().shape({
    about: Yup.string().trim(),
    images: Yup.object().shape({
      web: Yup.object().shape({
        cover: Yup.string().trim().url(),
        thumbnailLarge: Yup.string().trim().url(),
        thumbnailSmall: Yup.string().trim().url(),
      }),
      mobile: Yup.object().shape({
        cover: Yup.string().trim().url(),
        thumbnailLarge: Yup.string().trim().url(),
        thumbnailSmall: Yup.string().trim().url(),
      }),
    }),
    imagesCredits: Yup.object().shape({
      cover: Yup.string().trim(),
      thumbnailLarge: Yup.string().trim(),
      thumbnailSmall: Yup.string().trim(),
    }),
  }),
  latitude: Yup.number(),
  longitude: Yup.number(),
  city: Yup.string().required().matches(new RegExp("^[0-9a-fA-F]{24}$")),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    inputContainer: {
      width: "45%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
  };
});

const SightseeingModal = ({
  sightseeingDetails,
  showModal,
  closeModal,
  refreshList,
  cities,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [imageCreditType, setImageCreditType] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: SightseeingFormSchema,
    initialValues: {
      name: "",
      images: {
        web: {
          cover: "",
          thumbnailLarge: "",
          thumbnailSmall: "",
        },
        mobile: {
          cover: "",
          thumbnailLarge: "",
          thumbnailSmall: "",
        },
      },
      imagesCredits: {
        cover: "",
        thumbnailLarge: "",
        thumbnailSmall: "",
      },
      nearByDetails: {
        about: "",
        images: {
          web: {
            cover: "",
            thumbnailLarge: "",
            thumbnailSmall: "",
          },
          mobile: {
            cover: "",
            thumbnailLarge: "",
            thumbnailSmall: "",
          },
        },
        imagesCredits: {
          cover: "",
          thumbnailLarge: "",
          thumbnailSmall: "",
        },
      },
      latitude: 0,
      longitude: 0,
      ...sightseeingDetails,
      city: sightseeingDetails
        ? sightseeingDetails.city && sightseeingDetails.city.id
        : cities && cities.length > 0 && cities[0].id,
    },
    onSubmit: (data) => {
      if (sightseeingDetails) {
        editSightseeing(data);
      } else {
        addSightseeing(data);
      }
    },
  });

  const addSightseeing = (data) => {
    let countryId = cities.find((city) => city.id === data.city).country.id;
    data.country = countryId;

    dataProvider
      .create("operating-city/sightseeings", { data })
      .then(() => {
        closeModal();
        refreshList();
        notify("Sightseeing created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editSightseeing = (data) => {
    delete data.slug;

    let countryId = cities.find((city) => city.id === data.city).country.id;
    data.country = countryId;

    dataProvider
      .update("operating-city/sightseeings", {
        id: sightseeingDetails.slug,
        data,
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Sightseeing updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        {!imageCreditType ? (
          <Box width={800} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
            <Typography variant="h5">{`${
              sightseeingDetails ? "Edit" : "Create"
            } Sightseeing`}</Typography>
            <form className={classes.formContainer} onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="space-between">
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    City Name
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.city}
                    onChange={handleChange("city")}
                    onBlur={handleBlur("city")}
                    label="City Name"
                    inputProps={{
                      name: "city",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Sightseeing Place Name"
                  error={"name" in errors && "name" in touched}
                  helperText={
                    "name" in errors && "name" in touched && errors.name
                  }
                  variant="outlined"
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.name}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  label="Latitude"
                  variant="outlined"
                  onChange={handleChange("latitude")}
                  onBlur={handleBlur("latitude")}
                  value={values.latitude}
                  className={classes.inputContainer}
                />
                <TextField
                  label="Longitude"
                  variant="outlined"
                  onChange={handleChange("longitude")}
                  onBlur={handleBlur("longitude")}
                  value={values.longitude}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box width="45%">
                  <Typography variant="h6">Used in Places List</Typography>
                </Box>
                <Box width="45%">
                  <Typography variant="h6">
                    Used in NearBy Places List
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  label="Description"
                  variant="outlined"
                  onChange={handleChange("about")}
                  onBlur={handleBlur("about")}
                  value={values.about}
                  className={classes.inputContainer}
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  onChange={handleChange("nearByDetails.about")}
                  onBlur={handleBlur("nearByDetails.about")}
                  value={values.nearByDetails.about}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "cover" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "cover" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "cover" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "cover" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Cover Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.cover")}
                  onBlur={handleBlur("images.web.cover")}
                  value={values.images.web.cover}
                  className={classes.inputContainer}
                />
                <TextField
                  error={
                    "nearByDetails" in errors &&
                    "images" in errors.nearByDetails &&
                    "web" in errors.nearByDetails.images &&
                    "cover" in errors.nearByDetails.images.web &&
                    "nearByDetails" in touched &&
                    "images" in touched.nearByDetails &&
                    "web" in touched.nearByDetails.images &&
                    "cover" in touched.nearByDetails.images.web
                  }
                  helperText={
                    "nearByDetails" in errors &&
                    "images" in errors.nearByDetails &&
                    "web" in errors.nearByDetails.images &&
                    "cover" in errors.nearByDetails.images.web &&
                    "nearByDetails" in touched &&
                    "images" in touched.nearByDetails &&
                    "web" in touched.nearByDetails.images &&
                    "cover" in touched.nearByDetails.images.web &&
                    "Invalid url"
                  }
                  label="Web Cover Image URL"
                  variant="outlined"
                  onChange={handleChange("nearByDetails.images.web.cover")}
                  onBlur={handleBlur("nearByDetails.images.web.cover")}
                  value={values.nearByDetails.images.web.cover}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  onClick={() => {
                    setTemplateData(values.imagesCredits?.cover || "");
                    setImageCreditType("imagesCredits.cover");
                  }}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.cover ||
                        "<p>Cover Image Credits</p>",
                    }}
                  />
                </Button>
                <Button
                  onClick={() => {
                    setTemplateData(
                      values.nearByDetails?.imagesCredits?.cover || ""
                    );
                    setImageCreditType("nearByDetails.imagesCredits.cover");
                  }}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        (values.nearByDetails.imagesCredits &&
                          values.nearByDetails.imagesCredits.cover) ||
                        "<p>NearBy Cover Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Small Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailSmall")}
                  onBlur={handleBlur("images.web.thumbnailSmall")}
                  value={values.images.web.thumbnailSmall}
                  className={classes.inputContainer}
                />
                <TextField
                  error={
                    "nearByDetails" in errors &&
                    "images" in errors.nearByDetails &&
                    "web" in errors.nearByDetails.images &&
                    "thumbnailSmall" in errors.nearByDetails.images.web &&
                    "nearByDetails" in touched &&
                    "images" in touched.nearByDetails &&
                    "web" in touched.nearByDetails.images &&
                    "thumbnailSmall" in touched.nearByDetails.images.web
                  }
                  helperText={
                    "nearByDetails" in errors &&
                    "images" in errors.nearByDetails &&
                    "web" in errors.nearByDetails.images &&
                    "thumbnailSmall" in errors.nearByDetails.images.web &&
                    "nearByDetails" in touched &&
                    "images" in touched.nearByDetails &&
                    "web" in touched.nearByDetails.images &&
                    "thumbnailSmall" in touched.nearByDetails.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Small Image URL"
                  variant="outlined"
                  onChange={handleChange(
                    "nearByDetails.images.web.thumbnailSmall"
                  )}
                  onBlur={handleBlur("nearByDetails.images.web.thumbnailSmall")}
                  value={values.nearByDetails.images.web.thumbnailSmall}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  onClick={() => {
                    setTemplateData(values.imagesCredits?.thumbnailSmall || "");
                    setImageCreditType("imagesCredits.thumbnailSmall");
                  }}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailSmall ||
                        "<p>Thumbnail Small Image Credits</p>",
                    }}
                  />
                </Button>
                <Button
                  onClick={() => {
                    setTemplateData(
                      values.nearByDetails?.imagesCredits?.thumbnailSmall || ""
                    );
                    setImageCreditType(
                      "nearByDetails.imagesCredits.thumbnailSmall"
                    );
                  }}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        (values.nearByDetails.imagesCredits &&
                          values.nearByDetails.imagesCredits.thumbnailSmall) ||
                        "<p>NearBy Thumbnail Small Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Large Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailLarge")}
                  onBlur={handleBlur("images.web.thumbnailLarge")}
                  value={values.images.web.thumbnailLarge}
                  className={classes.inputContainer}
                />
                <TextField
                  error={
                    "nearByDetails" in errors &&
                    "images" in errors.nearByDetails &&
                    "web" in errors.nearByDetails.images &&
                    "thumbnailLarge" in errors.nearByDetails.images.web &&
                    "nearByDetails" in touched &&
                    "images" in touched.nearByDetails &&
                    "web" in touched.nearByDetails.images &&
                    "thumbnailLarge" in touched.nearByDetails.images.web
                  }
                  helperText={
                    "nearByDetails" in errors &&
                    "images" in errors.nearByDetails &&
                    "web" in errors.nearByDetails.images &&
                    "thumbnailLarge" in errors.nearByDetails.images.web &&
                    "nearByDetails" in touched &&
                    "images" in touched.nearByDetails &&
                    "web" in touched.nearByDetails.images &&
                    "thumbnailLarge" in touched.nearByDetails.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Large Image URL"
                  variant="outlined"
                  onChange={handleChange(
                    "nearByDetails.images.web.thumbnailLarge"
                  )}
                  onBlur={handleBlur("nearByDetails.images.web.thumbnailLarge")}
                  value={values.nearByDetails.images.web.thumbnailLarge}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  onClick={() => {
                    setTemplateData(values.imagesCredits?.thumbnailLarge || "");
                    setImageCreditType("imagesCredits.thumbnailLarge");
                  }}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailLarge ||
                        "<p>Thumbnail Large Image Credits</p>",
                    }}
                  />
                </Button>
                <Button
                  onClick={() => {
                    setTemplateData(
                      values.nearByDetails?.imagesCredits?.thumbnailLarge || ""
                    );
                    setImageCreditType(
                      "nearByDetails.imagesCredits.thumbnailLarge"
                    );
                  }}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        (values.nearByDetails.imagesCredits &&
                          values.nearByDetails.imagesCredits.thumbnailLarge) ||
                        "<p>NearBy Thumbnail Large Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>

              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  {sightseeingDetails ? "Discard" : "cancel"}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {sightseeingDetails ? "Save" : "Submit"}
                </Button>
              </Box>
            </form>
          </Box>
        ) : (
          <Box>
            <Box display="flex" bgcolor="white" justifyContent="space-between">
              <Button
                onClick={() => {
                  setTemplateData(eval("values." + imageCreditType) || "");
                  setImageCreditType(null);
                }}
                variant="text"
                color="primary"
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  setFieldValue(imageCreditType, templateData);
                  setImageCreditType(null);
                }}
                style={{ borderRadius: 0 }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
            <Editor
              onChange={(e) => setTemplateData(e.editor.getData())}
              style={{ borderWidth: 0 }}
              config={{
                height: "400px",
                width: "100%",
                resize_enabled: false,
              }}
              data={
                templateData ||
                eval(`values.${imageCreditType}`.replaceAll(".", "?.")) ||
                ""
              }
            />
          </Box>
        )}
      </Fragment>
    </Modal>
  );
};

export default SightseeingModal;

import React from "react";
import { Route } from "react-router-dom";
import OperatingCityDetails from "Resources/OperatingAreas/OperatingCity/Details";
import AirportDetails from "Resources/OperatingAreas/OperatingCity/Tabs/Airports/Details";
import IntercityRouteDetails from "Resources/OperatingAreas/OperatingCity/Tabs/IntercityRoutes/Details";

export default [
  <Route
    exact
    path="/operating-city/:citySlug"
    component={OperatingCityDetails}
  />,
  <Route
    exact
    path="/operating-city/:citySlug/airports/:airportSlug"
    component={AirportDetails}
  />,
  <Route
    exact
    path="/operating-city/:citySlug/intercity-routes/:intercityRouteSlug"
    component={IntercityRouteDetails}
  />,
];

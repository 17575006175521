import React from 'react';
import { Link } from 'react-router-dom';
import PrintIcon from '@material-ui/icons/Print';
import { withStyles } from '@material-ui/core/styles';
import { Button, useDataProvider, Loading, Error } from 'react-admin';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const PrintBookingButton = ({ classes, record, showNotification }) => {
  const dataProvider = useDataProvider();
 
  return (
    <Button
      component={Link}
      onClick={() => {
          // console.log('print', dataProvider);
          dataProvider.getOne('printVoucher', { id: record.id })
            .then((res) => {
              showNotification('Printed Successfully!')
            })
            .catch(error => {
              showNotification('Something went wrong!')
            })
      }}
      label="Print DMC Booking"
      title="Print DMC Booking"
    >
      <PrintIcon />
    </Button>
  );
}

export default connect(
  undefined,
  { showNotification }
)(withStyles(styles)(PrintBookingButton));

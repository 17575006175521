export const removeFalsyValues = function(obj) {
  for (const i of Object.keys(obj)) {
    if (!obj[i]) {
      // console.log(obj);
      delete obj[i];
      // console.log(obj);
    }
  }
  // console.log(obj);
  return obj;
};

export const createOrUpdateObjectWithKeySameAsValue = (list, obj = {}) => {
  list.forEach(i => {
    obj[i] = i;
  });
  return obj;
};

export const parseUrl = url => {
  return url.split("/")[3];
};

export default {
  removeFalsyValues,
  createOrUpdateObjectWithKeySameAsValue,
  parseUrl
};

import React from 'react';
import CustomUrlField from './CustomUrlField';
import { makeStyles } from '@material-ui/core/styles';
import FileViewer from 'react-file-viewer';
import Button from '../RaOverrides/Button';
import Modal from '../Mui/Modal';
import { startCase } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    margin: theme.spacing(1),
  },
  viewButton: {
    // paddingTop: theme.spacing(0.5),
    // paddingBottom: theme.spacing(0.5),
    paddingTop: `0px !important`,
    paddingBottom: `0px !important`,
    minHeight: `30px !important`,
    marginLeft: `0px !important`,
  },
}))

export const DocumentOrImageFileField = ({ record = {}, source, displayName='Document', useFileViewer }) => {
  let url = record[source] || '';
  let isImage = url && ['.jpg', '.jpeg', '.png', '.webp'].some(item => url.toLowerCase().endsWith(item));
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => setIsOpen(false);
  const handleModalButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  }

  return (
    <>
    {
      (useFileViewer && url) ? (
        <>
          <Button className={classes.viewButton} variant="contained" onClick={handleModalButtonClick} label="View" />
          <Modal 
            title={`${startCase(source)}`} 
            content={(
              <FileViewer
                fileType={url.split('.').slice(-1)[0].toLowerCase()}
                filePath={url}
              />
            )}
            isOpen={isOpen}
            handleClose={handleClose}
            actions={
              <>
                <CustomUrlField record={record} source={source} displayName={'Download'} ></CustomUrlField>
              </>
            }
          />
        </>
      ) : isImage ? (
        <img className={classes.root} height="100" width="100" src={record[source]} />
      ) : (
        <CustomUrlField record={record} source={source} displayName={displayName} ></CustomUrlField>
      )
    }
    </>
  )
  
}

DocumentOrImageFileField.defaultProps = {
    addLabel: true,
    useFileViewer: true,
};

export default DocumentOrImageFileField;
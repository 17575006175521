import React from "react";
import { useRedirect } from "react-admin";
import queryString from "query-string";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  makeStyles
} from "@material-ui/core";
import Templates from "./Tabs/Templates/Templates";
import Airports from "./Tabs/Airports/List";
import Places from "./Tabs/Places/List";
import NearByPlaces from "./Tabs/NearByPlaces/List";
import IntercityRoutes from "./Tabs/IntercityRoutes/List";
import Questions from "./Tabs/Faqs/List";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const OperatingCityDetails = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  let tabId = 0;
  const handleChange = (event, newValue) => {
    redirect(`${props.location.pathname}?tid=${newValue}`);
  };

  if (props.location.search) {
    let params = queryString.parse(props.location.search);
    tabId = Number(params.tid);
  }

  return (
    <Box>
      <Box mt={2} mb={3} display="flex" justifyContent="space-between">
        <Typography variant="h4">
          {props.match.params.citySlug.toUpperCase()}
        </Typography>
      </Box>
      <Paper className={classes.root}>
        <Tabs
          value={tabId}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Templates" />
          <Tab label="Airports" />
          <Tab label="Places" />
          <Tab label="Nearby Places" />
          <Tab label="Intercity Routes" />
          <Tab label="Faqs" />
        </Tabs>
      </Paper>
      {tabId === 0 && <Templates citySlug={props.match.params.citySlug} />}
      {tabId === 1 && <Airports citySlug={props.match.params.citySlug} />}
      {tabId === 2 && <Places citySlug={props.match.params.citySlug} />}
      {tabId === 3 && <NearByPlaces citySlug={props.match.params.citySlug} />}
      {tabId === 4 && (
        <IntercityRoutes citySlug={props.match.params.citySlug} />
      )}
      {tabId === 5 && <Questions citySlug={props.match.params.citySlug} />}
    </Box>
  );
};

export default OperatingCityDetails;

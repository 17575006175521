import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider, useRedirect } from "react-admin";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CreateEditIntercityRoute from "./CreateEditIntercityRoute";

const IntercityRouteList = (props) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [cityDetails, setCityDetails] = useState([]);
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const getCityDetails = () => {
    dataProvider
      .getOne("operating-city", { id: props.citySlug })
      .then((res) => {
        setCityDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getList = useCallback(() => {
    dataProvider
      .getList(`operating-city/${props.citySlug}/intercity-routes`)
      .then((res) => {
        setResponse({ data: res.data, loading: false, error: null });
      })
      .catch((err) => {
        setResponse({ data: null, loading: false, error: err.message });
      });
  }, []);

  useEffect(() => {
    getCityDetails();
    getList();
  }, []);

  return (
    <Box>
      <Box mt={3} mb={3} display="flex" justifyContent="flex-end">
        <CreateEditIntercityRoute
          cityDetails={cityDetails}
          refreshList={getList}
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {data && !error && !loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Source</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(data).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Button
                      onClick={() =>
                        redirect(
                          `/operating-city/${props.citySlug}/intercity-routes/${row.slug}`
                        )
                      }
                      variant="text"
                      color="primary"
                    >
                      {row.sourceCity ? row.sourceCity.toUpperCase() : "N/A"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        redirect(
                          `/operating-city/${props.citySlug}/intercity-routes/${row.slug}`
                        )
                      }
                      variant="text"
                      color="primary"
                    >
                      {row.destinationCity
                        ? row.destinationCity.toUpperCase()
                        : "N/A"}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <CreateEditIntercityRoute
                      cityDetails={cityDetails}
                      refreshList={getList}
                      intercityRouteDetails={row}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default IntercityRouteList;

import React from "react";
import { useInput, required } from "react-admin";
import { useForm } from "react-final-form";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from "react-google-places-autocomplete";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import capitalize from "@material-ui/core/utils/capitalize";
import createStyles from "@material-ui/core/styles/createStyles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: "absolute",
      zIndex: 1000,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border}`,
      marginTop: theme.spacing(0.1),
      borderRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: "inherit",
      marginTop: -20,
      border:"none"
    },
    suggestion: {
      padding: theme.spacing(2),
      "&:hover": {
        backgroundColor: theme.palette.background.dark
      }
    },
    suggestionActive: {
      backgroundColor: theme.palette.background.default
    },
    label: {
      transform: "translate(-8px, -14px) scale(.75)",
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      paddingLeft: 6,
      paddingRight: 6
    },
    inputField: {
      width:"50%",
      marginTop: 15,
      marginBottom: 15,
    }
  })
);
const LocaleInput = props => {
  const form = useForm();
  const {
    input: { name, onChange, value },
    meta: { touched, error },
    isRequired
  } = useInput(props);
  const { label, variant } = props;
  const classes = useStyles();
  return (
    <GooglePlacesAutocomplete
      // apiKey="AIzaSyCdgNacS0Z-r2oi90zPgHJxHN7N-_8iav4"
      onSelect={({ description, ...rest }) => {
        geocodeByPlaceId(rest.place_id)
          .then(results => {
            return {
              address_components: results[0].address_components,
              res: getLatLng(results[0])
            };
          })
          .then( async ({ address_components, res }) => {
            const state = name.split("_")[0];
            const { lat, lng } = await res;
            form.change(`${state}_country`, address_components[address_components.length -1].short_name);
            // get city => locality and if locality is not there administrative area 1
            let city;
            address_components.some( areaObj => {
              if( areaObj.types[0] === 'locality') {
                city = areaObj.long_name;
                return true;
              }
              if( areaObj.types[0] === 'administrative_area_level_1')
              city = areaObj.long_name;
            })
            form.change(`${state}_city`, city);
            form.change(`${state}_lat`, lat);
            form.change(`${state}_lng`, lng);
          })
          .catch(err => console.error(err));
        return onChange(description);
      }}
      autocompletionRequest={{
        // componentRestrictions: {
        //   country: ["ie"]
        // }
        // types: ['address']
      }}
      idPrefix={name}
      initialValue={value}
      name={name}
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          label={label}
          variant={variant}
          error={!!(touched && error)}
          helperText={touched && error}
          size="small"
          className={classes.inputField}
        />
      )}
      renderSuggestions={(active, suggestions, onSelectSuggestion) => (
        <Paper variant="outlined" className={classes.container}>
          <List>
            {suggestions.map(suggestion => (
              <ListItem
                key={suggestion.place_id}
                button
                onClick={event => onSelectSuggestion(suggestion, event)}
                style={{ borderRadius: 0 }}
              >
                <ListItemText primary={suggestion.description} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    />
  );
};
export default ({ source, label, variant }) => (
  <LocaleInput
    label={label || capitalize(source.split("_").join(" "))}
    source={`${source}`}
    variant={variant}
    required={required()}
  />
);
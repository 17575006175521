import React from 'react';
import { ChipField } from 'react-admin';

export const SimpleArrayField = ({ record = {}, source, max, FieldComponent, fieldComponentProps }) => {
  let arr = (record[source] && 
      record[source] instanceof Array && 
      record[source]) || [];

  return (
    <span>
    {
      arr.slice(0, max).map((item, index) => (<FieldComponent key={item} record={{item}} source={'item'} {...fieldComponentProps} />))
    }
    {
      arr.length > max && '...'
    }
    </span>
  )
};

SimpleArrayField.defaultProps = {
    addLabel: true,
    max: 3,
    FieldComponent: ChipField,
    fieldComponentProps: {},
};

export default SimpleArrayField;
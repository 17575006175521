import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider } from "react-admin";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CreateEditOperatingRegion from "./CreateEditOperatingRegion";

const OperatingRegionList = (props) => {
  const dataProvider = useDataProvider();
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const getList = useCallback(() => {
    dataProvider
      .getList("operating-region")
      .then((res) => {
        setResponse({
          data: Object.values(res.data),
          loading: false,
          error: null,
        });
      })
      .catch((err) => {
        setResponse({ data: null, loading: false, error: err.message });
      });
  }, []);

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box>
      <Box mt={2} mb={5} display="flex" justifyContent="space-between">
        <Typography variant="h4">Operating Regions</Typography>
        <CreateEditOperatingRegion refreshList={getList} />
      </Box>
      {loading && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {data && !error && !loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Operating Region Name</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name.toUpperCase()}</TableCell>
                  <TableCell align="center">
                    <CreateEditOperatingRegion
                      refreshList={getList}
                      operatingRegionDetails={row}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default OperatingRegionList;

import React from "react";
import {
  BooleanInput,
  DateInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField
} from "react-admin";
import { parse } from 'query-string';
import { PaymentStateChoices } from 'Constants/Payments';

const today = new Date();

const PaymentStateCreate = props => {
    const { paymentId } = parse(props.location.search);
  
    const redirect = paymentId ? `/payments/${paymentId}/show` : false;

    // console.log(paymentId, redirect);

    return (
    <Create {...props} title={`Add New Payment State for Payment Id ${paymentId}`}>
      <SimpleForm
        defaultValue={{ paymentId }}
        redirect={redirect}
      >
        <ReferenceInput disabled source="paymentId" reference="payments"><SelectInput optionText="id" /></ReferenceInput>
        <SelectInput source="state" choices={PaymentStateChoices}/>
    </SimpleForm>
    </Create>
    );
}

export default PaymentStateCreate;

import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import CKEditor from "ckeditor4-react";

import { useStyles } from "./List.styles";

CKEditor.editorUrl = "https://cdn.ckeditor.com/4.15.0/full/ckeditor.js";

const WebTemplate = `<p><span style="font-size:16px">Nestled in the heart of Italian Peninsula, Rome is the capital of the country and one of the most populated cities in Italy. From its rich architecture to rare artefacts, this city is known to possess a history like none other. The pic</span><span style="font-size:16px">turesque atmosphere, the cold wind blowing and the serene atmosphere is what makes Rome a must visit place for all t</span><span style="font-size:16px">he travel fanatics out there.</span></p>

<div style="float:right; padding-left:20px">
<div><img alt="" src="https://cdn.rydeu.com/rome-civitavecchia-italy_1800x1000+1.png" style="height:225px; width:391px" /></div>

<div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>

<div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
</div>

<div><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city adsasd wholeheartedly welcomes travellers from a wide array of interests, from awe inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></div>

<p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city wholeheartedly welcomes travellers from a wide arra</span><span style="font-size:16px">y of interests, from awe</span>&nbsp;&nbsp;<span style="font-size:16px">inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>

<p><span style="font-size:16px">The Roman Empire has been an icon when it comes to historical importance. The Roman Empire had cut the ribbons when Augustus Caesar announced to be the first emperor of Rome in 31BC and ended with the fall of Constantinople in 1453CE. Known to be the centre of the Roman Empire, Rome attracts the majority of the crowd due to its rich history and politics.</span><br />
&nbsp;</p>

<div style="float:left; padding-right:20px">
<div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/Hotel-Eden-La-Terrazza-scaled+1.png" style="height:225px; width:391px" /></span></div>

<div><em><span style="font-size:10px">Image by VIA DEI MONTI PARIOLI</span></em></div>

<p>&nbsp;</p>
</div>

<p><span style="font-size:16px">Referred to as &lsquo;An Eternal City&rsquo; in the beginning of the history, Rome is seen to be a dream for music lovers due to the Parco Della Musica which is known to be one of the largest music venues in the world. Not only music, Rome has also set itself in the most wanted places to visit in the world and is the third m</span><span style="font-size:16px">ost visited places in the entire Europe. This place has experienced arts from different periods and became a home for some of the most renowned artists such as Borromini, Bernini, Carracci and Cortona.</span></p>

<p><span style="font-size:16px">Embracing the ancient art, Rome has now decided to use its innovation to give birth to contemporary art to keep enhancing their love for art and architecture, National Museum of the 21st Century Arts is a live example of that. The Italian cuisine has somehow managed to be a king of all cuisines, from the Carciofi alla romana to the Spaghetti alla carbonara- Delicious spaghetti topped with bacon, smokey eggs and pecorino is what makes it mouth watering.&nbsp;</span></p>

<p><span style="font-size:16px">Rome has a series of reasons to attract you and slip you into its warm arms to rejuvenate your soul with its authentic art, awe-inspiring architecture and mouth-watering food.</span></p>


`;

const MobileTemplate = `<p><span style="font-size:16px">Nestled in the heart of Italian Peninsula, Rome is the capital of the country and one of the most populated cities in Italy. From its rich architecture to rare artefacts, this city is known to possess a history like none other. The pic</span><span style="font-size:16px">turesque atmosphere, the cold wind blowing and the serene atmosphere is what makes Rome a must visit place for all t</span><span style="font-size:16px">he travel fanatics out there.</span></p>

<div>
<div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/rome-civitavecchia-italy_1800x1000+1.png" style="height:225px; width:391px" /></span></div>

<div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>

<div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
</div>

<p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city adsasd wholeheartedly welcomes travellers from a wide array of interests, from awe inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>

<p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city wholeheartedly welcomes travellers from a wide arra</span><span style="font-size:16px">y of interests, from awe</span>&nbsp;&nbsp;<span style="font-size:16px">inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>

<p><span style="font-size:16px">The Roman Empire has been an icon when it comes to historical importance. The Roman Empire had cut the ribbons when Augustus Caesar announced to be the first emperor of Rome in 31BC and ended with the fall of Constantinople in 1453CE. Known to be the centre of the Roman Empire, Rome attracts the majority of the crowd due to its rich history and politics.</span><br />
&nbsp;</p>

<div>
<div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/Hotel-Eden-La-Terrazza-scaled+1.png" style="height:225px; width:391px" /></span></div>

<div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>

<div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
</div>

<p><span style="font-size:16px">Referred to as &lsquo;An Eternal City&rsquo; in the beginning of the history, Rome is seen to be a dream for music lovers due to the Parco Della Musica which is known to be one of the largest music venues in the world. Not only music, Rome has also set itself in the most wanted places to visit in the world and is the third m</span><span style="font-size:16px">ost visited places in the entire Europe. This place has experienced arts from different periods and became a home for some of the most renowned artists such as Borromini, Bernini, Carracci and Cortona.</span></p>

<p><span style="font-size:16px">Embracing the ancient art, Rome has now decided to use its innovation to give birth to contemporary art to keep enhancing their love for art and architecture, National Museum of the 21st Century Arts is a live example of that. The Italian cuisine has somehow managed to be a king of all cuisines, from the Carciofi alla romana to the Spaghetti alla carbonara- Delicious spaghetti topped with bacon, smokey eggs and pecorino is what makes it mouth watering.&nbsp;</span></p>

<p><span style="font-size:16px">Rome has a series of reasons to attract you and slip you into its warm arms to rejuvenate your soul with its authentic art, awe-inspiring architecture and mouth-watering food.</span></p>


`;

const TemplateList = (props) => {
  const classes = useStyles();
  const [platform, setPlatform] = useState("web");

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Template Creation</h2>
        <Box>
          <Button
            className={classes.button}
            onClick={() => setPlatform("web")}
            variant={platform === "web" ? "outlined" : "text"}
            color={platform === "web" ? "primary" : "inherit"}
          >
            Web
          </Button>
          <Button
            className={classes.button}
            onClick={() => setPlatform("mobile")}
            variant={platform === "mobile" ? "outlined" : "text"}
            color={platform === "mobile" ? "primary" : "inherit"}
          >
            Mobile
          </Button>
        </Box>
      </Box>
      <Box width={platform === "mobile" ? 500 : "100%"}>
        <CKEditor
          config={{
            height: "70vh",
            width: "100%",
          }}
          data={platform === "mobile" ? MobileTemplate : WebTemplate}
        />
      </Box>
    </Box>
  );
};

export default TemplateList;

import React from "react";
import {
  SimpleShowLayout,
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  NumberField,
  DateField,
  Show,
  EmailField,
  ReferenceField,
  BooleanField,
} from "react-admin";
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const AccountShow = props => {
  return (
    <>
      
      <Show {...props}>
        <SimpleShowLayout>
          {/* <TextField source="id" /> */}
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="companyName" />
          <TextField source="businessEmail" />
          <TextField source="mobileNo" />
          <TextField source="officePhone" />
          <TextField source="termsAndCondition" />
          <TextField source="drivingLicence" />
          <TextField source="age" />
          <TextField source="occupation" />
          <TextField source="businessType" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ReferenceField source="organizationId" reference="organizations">
            <TextField source="companyName" />
          </ReferenceField>
      </SimpleShowLayout>
      </Show>
    </>
  )
};

export default AccountShow;

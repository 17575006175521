import React from 'react'
import List from './List';
import Show from './Show';
import Edit from './Edit';
import Create from './Create';
import { ShowGuesser, EditGuesser, ListGuesser } from 'react-admin';

export default {
  create: Create,
  // list: List,
  show: Show,
  // edit: Edit,
}
import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  useGetList,
  useNotify,
  useDataProvider,
  Datagrid,
  Filter,
  List,
  TextField,
  ReferenceField,
  SelectInput,
} from "react-admin";
import { API_HOSTNAME as apiUrl } from "Constants";
import Button from "Components/Common/RaOverrides/Button";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CurrencyField from "Components/Common/Fields/CurrencyField";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import TopToolbar from "Components/Common/RaOverrides/TopToolbar";
import ResetFiltersActionButton from "Components/Common/ResetFiltersActionButton";
import CustomPagination from "Components/Common/CustomPagination";
import { getItem } from "Util/storage";

const PricingFilter = (props) => {
  const notify = useNotify();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    getServiceLocations()
      .then(async (response) => {
        if (response.data.rows) {
          const countries = {};
          const cities = {};
          for (let i = 0; i < response.data.rows.length; i++) {
            const item = response.data.rows[i];
            countries[item.country] = countries[item.country] || {
              id: item.country,
              name: item.country,
            };

            cities[item.city] = cities[item.city] || {
              id: item.city,
              name: item.city,
            };
          }

          setCountries(Object.values(countries));

          setCities(Object.values(cities));
        }
      })
      .catch((error) => {
        notify(error);
      });
  }, []);

  return (
    <Filter {...props}>
      <AutocompleteInput
        label="Country"
        source="country"
        choices={countries}
        alwaysOn
        emptyText="All"
      />
      <AutocompleteInput
        label="City"
        source="city"
        choices={cities}
        alwaysOn
        emptyText="All"
      />
      <ReferenceInput
        label="Vehicle Type"
        source="vehicleType"
        reference="vehicleTypes"
        alwaysOn
        emptyText="All"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

const CreatePricingButton = ({ data, loading }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={{
        pathname: "/pricings/create",
        state: { data },
      }}
      label="Pricing"
      title="Create Pricing"
      disabled={loading}
    >
      <AddIcon />
    </Button>
  );
};

const PricingListActions = withRouter(
  ({ basePath, data, vehicleTypes, vehicleTypeLoading, resource, ...rest }) => {
    return (
      <TopToolbar>
        <ResetFiltersActionButton
          {...rest}
          defaultObj={{
            q: null,
            pricingGroup: null,
            vehicleType: null,
            pricingType: null,
          }}
        />
        <CreatePricingButton loading={vehicleTypeLoading} data={vehicleTypes} />
      </TopToolbar>
    );
  }
);

const PricingBulkActionButtons = (props) => <></>;

const getServiceLocations = async () => {
  const defaultOptions = {
    headers: {
      authorization: `Bearer ${getItem("token")}`,
    },
  };
  return await fetch(`${apiUrl}/admin/cities`, defaultOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const PricingList = (props) => {
  const dataProvider = useDataProvider();
  const {
    loading: vehicleTypeLoading,
    data: vehicleTypes,
    error,
  } = useGetList(
    "vehicleTypes",
    { page: 1, perPage: 100 },
    { field: "id", order: "asc" },
    {}
  );

  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Pricings",
      url: "/pricings",
    },
  ];
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <List
        {...props}
        empty={
          <PricingListActions
            vehicleTypes={vehicleTypes}
            vehicleTypeLoading={vehicleTypeLoading}
          />
        }
        pagination={<CustomPagination />}
        title="Pricing List"
        filters={<PricingFilter />}
        actions={
          <PricingListActions
            vehicleTypes={vehicleTypes}
            vehicleTypeLoading={vehicleTypeLoading}
          />
        }
        bulkActionButtons={false}
      >
        <Datagrid rowClick="show" isRowSelectable={(record) => record}>
          <TextField source="country" />
          <TextField source="city" />
          <ReferenceField source="vehicleTypeId" reference="vehicleTypes">
            <TextField source="name" />
          </ReferenceField>

          <TextField source="isDisable" label="Disabled" />

          <CurrencyField
            textAlign={"right"}
            showCurrencySymbol={false}
            label="Amount Airport Fees"
            source="amountCentsAirportFees"
          />

          <CurrencyField
            textAlign={"right"}
            showCurrencySymbol={false}
            label="Amount Per Hour"
            source="amountCentsPerHour"
          />

          <CurrencyField
            textAlign={"right"}
            showCurrencySymbol={false}
            label="Amount Per KM"
            source="amountCentsPerKm"
          />

          <CurrencyField
            textAlign={"right"}
            showCurrencySymbol={false}
            label="Base Amount"
            source="baseAmountCents"
          />

          <TextField source="baseKms" label="Base Kms" />
        </Datagrid>
      </List>
    </>
  );
};
export default PricingList;

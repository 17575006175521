import React, {useState, useEffect} from 'react'
import moment from "moment-timezone";
import { 
  BooleanInput, FunctionField,
  DateField, SelectField,
  TextField, SelectInput,
  Datagrid, ReferenceField,
  Filter, List, TextInput,
  ExportButton
 } from 'react-admin'
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import ResetFiltersActionButton from 'Components/Common/ResetFiltersActionButton';
import Button from 'Components/Common/RaOverrides/Button';
import { TransferOfferStatusChoices } from 'Constants/TransferOffers';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';
import { API_HOSTNAME as apiUrl, BooleanInputChoices } from 'Constants';
import CustomPagination from 'Components/Common/CustomPagination';

const TransferOfferTitle = ({ record }) => {
  return (
    <span>
      Transfer Offer : {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

const TransferOfferFilter = props => {
  const [ countryChoices, setCountryChoices ] = useState([]);
  const tripChoices = [{id: 'YES', name: 'Yes'},{ id: 'NO', name:"No" }];

  useEffect( ()=>{
    countriesFetch().then( async response => {
      response.data.rows = await response.data.rows.map( countryObj => {
        return { id:  countryObj.country.toUpperCase(), name:  countryObj.country.toUpperCase()};
      })
      setCountryChoices([ ...response.data.rows ]);
    })
  }, []);
  return (
  <Filter {...props}>
    <TextInput label="Search City" source="city" alwaysOn />
    <SelectInput label="Country" source="country" choices={countryChoices} alwaysOn emptyText="All"/>
    <SelectInput label="Return Trip" source="returnTrip" choices={BooleanInputChoices} alwaysOn emptyText="All"/>
  </Filter>
  );
};

async function countriesFetch () {
  return await fetch(`${apiUrl}/countryList?country=true`)
   .then( response => response.json() )
   .then( data => data)
 }

const TransferOfferAction = props => {
  const { className, ...rest } = props || {};
  return (
    <div style={{ display: 'flex', alignSelf: 'center', }}>
      
      <ResetFiltersActionButton 
        {...rest}
        defaultObj={{
          q: null,
          // add other filter to reset here          
        }} 
      />
      <Button customComponent={ExportButton} label="Export" {...rest} />
    </div>
  )
}

export const TransferOfferList = ({ history, ...props }) => {
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Transfer Offers',
      url: '/transferOffers',
    },
  ];

  const handleFieldClick = (id, basePath) => {
    history.push({
      pathname: `${basePath}/${id}/show`,
      search: "",
      state: { prevResource: "transferOfferList", transferOfferId: ''}
    });
  };

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <List {...props} pagination={<CustomPagination />} bulkActionButtons={false} title="TransferOffer List" filters={<TransferOfferFilter />} actions={<TransferOfferAction />}>
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField
              label="Pickup Location"
              source="transfer.pickupLocation.formattedAddress"
            />
            <TextField
              label="Drop Location"
              source="transfer.dropLocation.formattedAddress"
            />
            <FunctionField
              component="pre"
              label="Return Trip"
              render={(record) => record.transfer.returnDateTime == null ? 
                `NO`
                : `YES`
              }
            />
            {/* <ReferenceField link={false} source="pickupLocationId" reference="locations">
              <TextField source="formattedAddress" />
            </ReferenceField>
            <ReferenceField link={false} source="dropLocationId" reference="locations">
              <TextField source="formattedAddress" />
            </ReferenceField> */}
            <TextField label="TotalAmount" source="totalAmount" />
            <TextField label="Amount Cents Per Hour" source="amountCentsPerHour" />
            <TextField label="Distance" source="transfer.numberOfKms" />
            {/* <DateField source="commissionAmount" />
            <SelectField source="currentState" choices={TransferOfferStatusChoices} /> */}
            <FunctionField
              component="pre"
              label="Offered Vehicle"
              render={(record) => record.vehicle ? 
                `${record.vehicle.brand} ${record.vehicle.model} , ${record.vehicle.yearOfManufacture}`
                : '---'
              }
            />
            {/* onClick={(event) => handleFieldClick( event.target.textContent, props.basePath )} */}
            <ReferenceField onClick={(event, props) => {
                event.stopPropagation();
                handleFieldClick( event.target.innerHTML, 'Vendors' );
                }}
                source="vendorId" reference="vendors" link={false}>
              <TextField source="id" style={{ color: "#01579B"}}/>
            </ReferenceField>
          </Datagrid>
        </List>
      </>
)};
export default TransferOfferList;


import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import OpertaingCountryModal from "./OperatingCountryModal";

const CreateEditOperatingCountry = ({
  refreshList,
  operatingCountryDetails,
  regions,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant={operatingCountryDetails ? "text" : "contained"}
        color="primary"
      >
        {operatingCountryDetails ? "Edit" : "Create Operating Country"}
      </Button>
      <OpertaingCountryModal
        regions={regions}
        operatingCountryDetails={operatingCountryDetails}
        refreshList={refreshList}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

export default CreateEditOperatingCountry;

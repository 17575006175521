import React from "react";
import {
  BooleanInput, Create, NumberInput,
  SimpleForm, TextInput, ReferenceInput,
  SelectInput, TextField
} from "react-admin";
import { parse } from 'query-string';
import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';

import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const today = new Date();

const VoucherCreate = props => {
    const { dmcBookingId, orderNumber, bookingId } = parse(props.location.search);
  
    let redirect = dmcBookingId ? `/dmcBookings/${dmcBookingId}/show/vouchers` : false;

    // if(!dmcBookingId) {
    //   if(bookingId && !orderNumber) props.history.push(`/bookings/${bookingId}/show/vouchers`);
    //   if(!bookingId || !orderNumber) props.history.push('/');
    //   redirect = bookingId ? `/bookings/${bookingId}/show/vouchers` : false;
    // }

    if(!dmcBookingId) {
      if(bookingId) redirect = bookingId ? `/bookings/${bookingId}/show/vouchers` : false;
      // props.history.push('/');
      // props.history.push(`/bookings/${bookingId}/show/vouchers`);
    }


    // console.log(bookingId, dmcBookingId, redirect);

    const bookingNumber = bookingId;

    const breadcrumbLinks = [
      {
        name: 'Home',
        url: '/',
      },
      {
        name: 'Bookings',
        url: '/bookings',
      },
      {
        name: `${bookingNumber}`,
        url: `/bookings/${bookingId}/show`,
      },
      {
        name: 'Vouchers',
        url: `/bookings/${bookingId}/show/vouchers`,
      },
      {
        name: `Create`,
        url: `${props.location.pathname}${props.location.search}`,
      },
    ]

    return (
      <>
        <Breadcrumbs links={breadcrumbLinks} />
        <Create {...props}>
          <SimpleForm
              defaultValue={{ 
                dmcBookingId, 
                bookingId, 
                // orderNo: orderNumber,
              }}
              redirect={redirect}
          >
          <TextInput source="supportName" />
          {/* <TextInput source="driverName" /> */}
          {/* <NumberInput source="driverContactNumber" /> */}
          {/* <TextInput source="meetingPoint" /> */}
          <TextInput source="supportContact" /> 
          {/* <TextInput source="orderNo" /> */}
          {/* <TextInput source="email" /> */}
          {/* <NumberInput source="noOfPax" /> */}
          {/* <NumberInput source="noOfBaggage" /> */}
          {/* <TextInput source="vehicleInfo" /> */}
          {/* <TextInput source="pickupAddress" /> */}
          {/* <TextInput source="dropAddress" /> */}
          {/* <TextInput source="flightNo" /> */}
          {/* <TextInput source="pickupSign" /> */}
          {/* <TextInput source="distance" /> */}
          {/* <TextInput source="customerName" /> */}
          {/* <DateTimeInput source="travelDateTime" /> */}
          {/* <TextInput disabled source={'orderNo'} label={'Order Number'}/> */}
          {
            bookingId && (
              <ReferenceInput source="bookingId" reference="bookings">
                <SelectInput disabled optionText="bookingNumber" />
              </ReferenceInput>
            )
          }
          {
            dmcBookingId && (
              <ReferenceInput source="dmcBookingId" reference="dmcBookings">
                <SelectInput optionText="id" />
              </ReferenceInput>
            )
          }
          </SimpleForm>
      </Create>
    </>
    );
}

export default VoucherCreate;

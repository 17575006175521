import React, { useState, Fragment, useEffect } from "react";
import {
  useNotify,
  useRedirect,
  SimpleForm,
  useQuery,
  ListContextProvider,
  Datagrid,
  TextField as ReactTextField,
  FunctionField,
  Loading,
  Pagination,
  useDataProvider,
  BooleanField,
  DateField,
} from "react-admin";
import httpClient from "Providers/Data/HttpClient";
import keyBy from "lodash/keyBy";
import Modal from "./Common/Modal/Modal";
import * as Yup from "yup";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import { API_HOSTNAME as apiUrl } from "Constants";
import { useFormik } from "formik";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AuctionTransferSchema = Yup.object().shape({
  vendorId: Yup.string(),
  transferId: Yup.string(),
  transferOfferId: Yup.string(),
  existingOfferId: Yup.string(),
  bookingId: Yup.string(),
});

const AuctionReassignModal = ({ showModal, closeModal, bookingRecord }) => {
  const [selectedOffer, setSelectedOffer] = useState(null);
  const dataProvider = useDataProvider();
  const classes = useStyles();
  // console.log("THis is Booking");
  // console.log(bookingRecord.id);

  const removeNullVals = (obj) => {
    return Object.keys(obj)
      .filter((k) => obj[k] != "")
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  };
  const notify = useNotify();
  const redirectTo = useRedirect();
  //style for accepted offer
  const handleOfferClick = (id) => {
    setSelectedOffer(id);
  };
  const acceptedOfferStyle = (record, index) => ({
    background:
      record.id == selectedOffer
        ? "linear-gradient(to right, #afa, white 5%)"
        : "white",
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: AuctionTransferSchema,

    initialValues: {},
    onSubmit: (data) => {
      let reassignData;
      if (!selectedOffer) {
        notify("Please select an offer to reassign !");
        return;
      }
      reassignData = {
        transferOfferId: selectedOffer,
        vendorId: data.vendorId,
        bookingId: bookingRecord?.id,
        existingOfferId: bookingRecord?.transfers[0]?.transferOffers[0]?.id,
        transferId: bookingRecord?.transfers[0]?.id,
      };
      dataProvider
        .create(`auction/reassign`, { data: removeNullVals(reassignData) })
        .then((res) => {
          notify("Vendor reassigned successfully! ");
          redirectTo(`/bookings`);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
  });

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        <Box width={800} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
          <Typography className={classes.heading} variant="h4">
            Auction Reassign
          </Typography>
          <SimpleForm toolbar={null}>
            <div className={classes.formContainer}>
              <>
                <Typography className={classes.subHeading} variant="h6">
                  Select Transfer Offer
                </Typography>
                <AuctionTransferOfferList
                  previousOfferId={
                    bookingRecord?.transfers &&
                    bookingRecord?.transfers[0]?.transferOffers &&
                    bookingRecord?.transfers[0]?.transferOffers[0]?.id
                  }
                  acceptedOfferStyle={acceptedOfferStyle.bind(this)}
                  handleOfferClick={handleOfferClick.bind(this)}
                  transferId={
                    bookingRecord?.transfers && bookingRecord?.transfers[0]?.id
                  }
                />
              </>
              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  Close
                </Button>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {"Reassign"}
                </Button>
              </Box>
            </div>
          </SimpleForm>
        </Box>
      </Fragment>
    </Modal>
  );
};

export const AuctionTransferOfferList = (props) => {
  console.log(props);
  const [page, setPage] = useState(1);
  const perPage = 50;

  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "transferOffers",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: {
        transferId: props.transferId,
        showOffers: "all",
      },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR: {error}</p>;
  }
  return (
    <>
      {/* {console.log(data)} */}
      <ListContextProvider
        value={{
          data: keyBy(data, "id"),
          ids: data
            .map(({ id }) => id)
            .filter((id) => id !== props.previousOfferId),
          total,
          page,
          perPage,
          setPage,
          currentSort: { field: "id", order: "ASC" },
          basePath: "/transferOffers",
          resource: "transferOffers",
          selectedIds: [],
        }}
      >
        <Datagrid
          rowClick={(offerId) => props.handleOfferClick(offerId)}
          rowStyle={props.acceptedOfferStyle}
        >
          <ReactTextField source="vendorId" />
          <FunctionField
            label="Offered Vehicle"
            render={(record) =>
              record.vehicleId
                ? `${record.vehicle.brand} ${record.vehicle.model} , ${record.vehicle.yearOfManufacture}`
                : `${record.vehicleType.name} ${record.vehicleType.refModel}`
            }
          />
          <ReactTextField label="Total Amount" source="totalAmount" />
          <ReactTextField
            label="Amount Cents Per Hour"
            source="amountCentsPerHour"
          />
          <BooleanField
            label="Limited Offer"
            source="limitedOffer"
          ></BooleanField>
          <DateField
            label="Limited Offer Due Date"
            source="limitedOfferDueDate"
          />
          <DateField label="Offer Creation Date" source="createdAt" />
          {/* <ReactTextField label="Comment" source="comment" /> */}
        </Datagrid>
        <Pagination />
      </ListContextProvider>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    heading: {
      textAlign: "center",
      letterSpacing: "1.5px",
      fontWeight: "600",
    },
    radioGroup: {
      justifyContent: "space-around",
    },
    errorMessage: {
      marginTop: 10,
      fontSize: 14,
      color: "#8B0000",
    },
    radioContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: "1.5rem",
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "column",
      width: "45%",
      marginTop: "1.5rem",
      justifyContent: "center",
    },
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width: "100%",
    },
    inputContainer: {
      // width: "45%",
      width: "95%",
      margin: spacing(2, 0),
    },
    checkIcon: {
      color: "green",
      fontSize: 32,
    },
    closeIcon: {
      color: "#8b0000",
      fontSize: 32,
    },
    suggestionsContainer: {
      zIndex: 2100,
    },
    autoInput: {
      width: "47%",
    },
    subHeading: {
      letterSpacing: "1.5px",
      marginTop: "2rem",
      fontWeight: "500",
      marginBottom: "0.5rem",
      // color: '#949494'
    },
    fieldColumn: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginTop: "1.5rem",
    },
    offerContainer: {
      display: "flex",
      flexDirection: "column",
    },
    offerContainerActive: {
      display: "flex",
      flexDirection: "column",
      marginTop: "-1.4rem",
    },
    ancillaryContainerActive: {
      marginTop: "-1.2rem",
    },
    ancillaryContainer: {
      marginTop: "0.1rem",
    },
    infoContainer: {
      background: "#E8F2FC",
      padding: "1rem",
      display: "flex",
    },
    infoHead: {
      width: "60%",
      fontWeight: "600",
      fontSize: "14px",
      textAlign: "left",
      letterSpacing: "1.5px",
    },
    infoValue: {
      width: "40%",
      textAlign: "center",
      color: "#004A85",
      fontWeight: "600",
    },
    infoTotalValue: {
      width: "40%",
      textAlign: "center",
      color: "#33B30B",
      fontWeight: "600",
    },
    priceContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginTop: "1.5rem",
      justifyContent: "center",
      borderRight: "1px solid #d4d4d5",
    },
  };
});

export default AuctionReassignModal;

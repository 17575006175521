import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import OpertaingRegionModal from "./OperatingRegionModal";

const CreateEditOperatingRegion = ({ refreshList, operatingRegionDetails }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant={operatingRegionDetails ? "text" : "contained"}
        color="primary"
      >
        {operatingRegionDetails ? "Edit" : "Create Operating Region"}
      </Button>
      <OpertaingRegionModal
        operatingRegionDetails={operatingRegionDetails}
        refreshList={refreshList}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

export default CreateEditOperatingRegion;

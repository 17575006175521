import { objToNameIdArray } from "Util/Constants";

export const BookingStatuses = {
  PENDING: "1",
  CONFIRMED: "2",
  INPROGRESS: "3",
  COMPLETED: "4",
  CANCELLED: "5",
  EXPIRED: "6",
};

export const AuctionStatuses = {
  ONGOING: "1",
  COMPLETED: "2",
  CANCELLED: "3",
  EXPIRED: "4",
};

export const BookingTypes = {
  // AIRPORT_TRANSFER: "airport_transfer",
  CITY_TRANSFER: "city_transfer",
  // HOURLY: "hourly_transfer"
};

export const BookingActivity = {
  PENDING: "pending",
  INPROGRESS: "inProgress",
  COMPLETED: "completed",
};

export const BookingActivityType = {
  PROSPECTION: "prospection",
  PROCUREMENT: "procurement",
  TRIPCOORDINATOR: "tripCoordinator",
};

const cancelReasonText = [
  "As requested, your booking has been successfully cancelled. \r\n\r\nA full refund request has also been raised for the booking amount and will be processed within 2 business days.",
  "As requested, your booking has been successfully cancelled. \r\n\r\nThis booking is cancelled within 24 hours of travel. As per our cancellation policy, there will be no refund of booking amount.",
  "We regret to inform you that the supplier has cancelled the booking due to Non-availability of vehicle. Efforts to find an alternate supplier were not successful.\r\n\r\nA full refund request has been raised for the booking amount and will be processed within 2 business days. We apologise for any inconvenience that this may have caused.",
  "We regret to inform you that the supplier has quoted a wrong price for this trip by mistake. For this reason the supplier has now cancelled the booking.\r\n\r\nA full refund request has been raised for the booking amount and will be processed within 2 business days. \r\nWe apologise for any inconvenience that this may have caused.",
  "We regret to inform you that the supplier has cancelled the booking due to Vehicle breakdown. Efforts to find an alternate supplier were not successful.\r\n\r\nA full refund request has been raised for the booking amount and it will be processed within 2 business days. \r\nWe apologise for any inconvenience that this may have caused.",
];

export const BookingCancelReasons = {
  "On customer request (Outside 24 hours - Full refund)": cancelReasonText[0],
  "On customer request (within 24 hours - No refund)": cancelReasonText[1],
  "On Supplier request (No Availability - Full refund)": cancelReasonText[2],
  "On Supplier request (Wrong offer price - Full refund)": cancelReasonText[3],
  "On Supplier request (Vehicle breakdown - Full refund)": cancelReasonText[4],
};

export const BookingActivityTypeChoices = objToNameIdArray(BookingActivityType);
export const BookingActivityChoices = objToNameIdArray(BookingActivity);
export const BookingStatusChoices = objToNameIdArray(BookingStatuses);
export const BookingTypeChoices = objToNameIdArray(BookingTypes);
export const BookingCancelReasonsChoices =
  objToNameIdArray(BookingCancelReasons);

export default {
  BookingStatuses,
  BookingStatusChoices,
  BookingTypes,
  BookingTypeChoices,
};

import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider, useRedirect, useNotify } from "react-admin";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CreateEditOperatingCity from "./CreateEditOperatingCity";
import AlertDialog from "Components/Common/AlertDialog/AlertDialog";
const OperatingCityList = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [cityId, setCity] = useState(null);
  const [baseCityId, setBaseCity] = useState(null);
  const [country, setCountryCode] = useState(null);
  const [alert, setAlert] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const getList = useCallback((cityId, baseCityId, country) => {
    dataProvider
      .getList("cities", { filter: { cityId, baseCityId, country } })
      .then((res) => {
        setResponse({
          data: Object.values(res.data),
          loading: false,
          error: null,
        });
      })
      .catch((err) => {
        setResponse({ data: null, loading: false, error: err.message });
      });
  }, []);

  const handleAlertOpen = (selectedCity) => {
    setSelectedCity(selectedCity);
    setAlert(true);
  };

  const handleAlertClose = () => {
    setAlert(false);
  };
  const deleteCity = (cityId) => {
    dataProvider
      .delete("cities", { id: cityId })
      .then(() => {
        getList();
        notify("City deleted successfully");
        setAlert(false);
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const deleteAlias = (data) => {
    dataProvider
      .update("cities", {
        id: data.id,
        data: {
          isEmailOptional: data.isEmailOptional,
          distance: data.distance,
          minimumLeadHours: data.minimumLeadHours,
          bookingPreference: data.bookingPreference,
          deleteAlias: true,
        },
      })
      .then(() => {
        window.location.reload();
        notify("Alias deleted successfully");
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  useEffect(() => {
    getList(cityId, baseCityId, country);
  }, [cityId, baseCityId, country]);

  return (
    <Box>
      <AlertDialog
        buttonComponent={null}
        isOpen={alert}
        handleClose={handleAlertClose}
        handleOk={() => deleteCity(selectedCity)}
        handleCancel={handleAlertClose}
      />
      <Box mt={2} mb={5} display="flex" justifyContent="space-between">
        <Typography variant="h4">Cities</Typography>
        <CreateEditOperatingCity cityList={data} refreshList={getList} />
      </Box>
      {loading && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {data && !error && !loading && (
        <TableContainer component={Paper}>
          <Box
            pt="10px"
            mb="20px"
            ml="10px"
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            <Box ml="2px">
              <TextField
                id="standard-basic"
                label="Country"
                variant="outlined"
                onChange={(event) => setCountryCode(event.target.value)}
                value={country ?? ""}
              />
            </Box>

            <Autocomplete
              id="controlled-demo"
              getOptionLabel={(option) =>
                `${option.city || "N/A"}-${option.country || "N/A"}`
              }
              options={
                data ? data.map((city) => ({ ...city, value: city.id })) : []
              }
              onChange={(event, newValue) => {
                setCity(newValue?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="City" variant="outlined" />
              )}
              style={{ width: "35%" }}
            />

            <Autocomplete
              id="controlled-demo"
              style={{ width: "35%" }}
              getOptionLabel={(option) =>
                `${option.city || "N/A"}-${option.country || "N/A"}`
              }
              options={
                data
                  ? data
                      .filter(
                        (city) =>
                          city.id === city.baseCityId ||
                          city.baseCityId === null
                      )
                      .map((city) => ({ ...city, value: city.id }))
                  : []
              }
              onChange={(event, newValue) => {
                setBaseCity(newValue?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Base city" variant="outlined" />
              )}
            />
          </Box>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>City Name</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Distance (Kms)</TableCell>
                <TableCell>Minimum Lead Hours</TableCell>
                <TableCell>Booking Preference</TableCell>
                <TableCell>Is Email Optional?</TableCell>
                <TableCell>Base City</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.city.toUpperCase()}</TableCell>
                  <TableCell>{row.country.toUpperCase()}</TableCell>
                  <TableCell>{row.distance || 0}</TableCell>
                  <TableCell>{row.minimumLeadHours || 0}</TableCell>
                  <TableCell>{row.bookingPreference || "BOTH"}</TableCell>
                  <TableCell>{row.isEmailOptional ? "On" : "Off"}</TableCell>
                  <TableCell>
                    {row.baseCity?.city.toUpperCase() || "--"}
                  </TableCell>

                  <TableCell align="center">
                    <CreateEditOperatingCity
                      cityList={data}
                      cityDetails={row}
                      refreshList={getList}
                    />
                    <Button
                      onClick={() => {
                        handleAlertOpen(row.id);
                      }}
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 10 }}
                    >
                      Delete
                    </Button>

                    {row.baseCityId && row.id !== row.baseCityId && (
                      <Button
                        onClick={() => {
                          deleteAlias(row);
                        }}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 10 }}
                      >
                        Delete alias
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default OperatingCityList;

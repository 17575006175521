import React from 'react'
import { 
  BooleanField, NumberField, SelectField,
  DateField, SingleFieldList, ChipField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout, Button, EmailField,
  // TopToolbar, 
  EditButton, 
  ReferenceManyField, Filter, TextInput,
 } from 'react-admin'

import LongDateField from 'Components/Common/Fields/LongDateField';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import SimpleArrayField from 'Components/Common/Fields/SimpleArrayField';

import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';
import List from 'Components/Common/RaOverrides/List';

const DriverTitle = ({ record }) => {
  return (
    <span>
      Driver: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

const DriverFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Name" source="q" alwaysOn />
  </Filter>
)

export const DriverList = props => (
  <List {...props} title="Driver List" pagination={false} filters={<DriverFilter />}>
    <Datagrid rowClick="show">
      {/* <TextField source="id" /> */}
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <EmailField source="email" />
      <TextField source="drivingLicense" />
      <SimpleArrayField source="languageProficiency" />
      {/* <TextField source="drivingLicenseDocuments" /> */}
      <BooleanField source="documentVerified" />
      <DateField source="createdAt" />
      {/* <DateField source="updatedAt" /> */}
      <ReferenceField source="vendorId" reference="vendors"><TextField source="id" /></ReferenceField>
    </Datagrid>
  </List>
)
export default DriverList;


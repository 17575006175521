import React from 'react'
import { BooleanInput, DateInput, Edit, NumberInput, SimpleForm, TextInput, ReferenceField, ReferenceInput, SelectInput, } from 'react-admin'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import { parse } from 'query-string';

const CreditProfileTitle = ({ record }) => {
  return (
    <span>
      CreditProfile: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}
export const CreditProfileEdit = props => {
  const [record, setRecord] = React.useState({});
  const [orgRecord, setOrgRecord] = React.useState({});
  const redirect = orgRecord ? `/organizations/${orgRecord.id}/show` : false;
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Organizations',
      url: '/organizations',
    },
    {
      name: `${orgRecord.companyName}`,
      url: `/organizations/${orgRecord.id}/show`,
    },
    {
      name: `Credit Profile`,
      url: `/creditProfiles/${record.id}/show`,
    },
    {
      name: 'Edit',
      url: `/creditProfiles/${record.id}`,
    }
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<CreditProfileTitle />} {...props}>
        <SimpleForm redirect={"show"}>
          <RecordFetcher setRecord={setRecord} />
          <ReferenceField addLabel="false" source="organizationId" reference="organizations">
            <RecordFetcher setRecord={setOrgRecord}/>
          </ReferenceField>
          {/* <TextInput source="id" /> */}
          <NumberInput source="creditLimit" format={v => v/100} parse={v => v*100}/>
          <NumberInput source="walletBalance" format={v => v/100} parse={v => v*100}/>
          {/* <DateInput source="createdAt" /> */}
          {/* <DateInput source="updatedAt" /> */}
          <ReferenceInput disabled source="organizationId" reference="organizations">
            <SelectInput optionText="id" />
          </ReferenceInput>
       </SimpleForm>
      </Edit>
    </>
  )
}

export default CreditProfileEdit;
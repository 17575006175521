import React from "react";
import {
  SimpleShowLayout,
  SelectField,
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  NumberField,
  DateField,
  Show,
  EmailField,
  ReferenceField,
  BooleanField,
} from "react-admin";
import FullNameField from 'Components/Common/Fields/FullNameField';
import { PaymentStateChoices } from 'Constants/Payments';

const PaymentStateShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="paymentId" reference="payments">
        <TextField source="id" />
      </ReferenceField>
      <SelectField source="state" choices={PaymentStateChoices}/>
  </SimpleShowLayout>
  </Show>
);

export default PaymentStateShow;

import React from 'react';
import { TextField } from 'react-admin';

export const FullNameField = ({ record = {}, ...rest }) => <TextField source="fullName" record={{fullName: `${record.firstName} ${record.lastName}`}} {...rest} />;

FullNameField.defaultProps = {
    addLabel: true,
    label: 'Full Name',
};

export default FullNameField;
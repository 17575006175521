import { objToNameIdArray } from 'Util/Constants';

export const UserStatuses = {
  USER: '1',
  AGENT: '2',
  ADMIN: '3',
  VENDOR: '4',
  EMPLOYEE: '5',
  OPS_ADMIN: '6',
};

export const UserStatusChoices = objToNameIdArray(UserStatuses);
export const UserStatusChoicesIntegers = UserStatusChoices.map(item => ({ ...item, id: parseInt(item.id), }));

export default {
  UserStatuses,
  UserStatusChoices,
  UserStatusChoicesIntegers,
}

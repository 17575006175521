import React, { useState } from 'react';
import { ChipField } from 'react-admin';
import ImgsViewer from 'react-images-viewer'
import DocumentOrImageFileField from './DocumentOrImageFileField';

export const ImagesArrayField = ({ record = {}, source, max }) => {
  let arr = (record[source] && 
      record[source] instanceof Array && 
      record[source]) || [];
  
  const [imgs, setImgs] = useState([]);
  const [currImgIndex, setCurrImgIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    let imageUrls = (
      record[source] && 
      record[source] instanceof Array && 
      record[source]
    ) || [];
    
    if(!imageUrls) imageUrls = [];
    imageUrls = imageUrls.map(item => ({ src: item }));
    setImgs(imageUrls);
  }, [record]);

  const handleItemClick = (index) => {
    setCurrImgIndex(index);
    setIsOpen(true);
  }

  return (
    <span>
    {
      arr.slice(0, max).map((item, index) => (
        <span key={item} onClick={() => handleItemClick(index)}>
          <DocumentOrImageFileField record={{item}} source={'item'} height={50} width={50} useFileViewer={false} />
        </span>
      ))
    }
    {
      arr.length > max && '...'
    }
    <ImgsViewer
      imgs={imgs}
      currImg={currImgIndex}
      isOpen={isOpen}
      onClickPrev={() => setCurrImgIndex(currImgIndex - 1)}
      onClickNext={() => setCurrImgIndex(currImgIndex + 1)}
      onClose={() => setIsOpen(false)}
      closeBtnTitle="Close"
      leftArrowTitle="View Previous Image"
      rightArrowTitle="View Next Image"
      width={900}
      backdropCloseable
    />
    </span>
  )
};

ImagesArrayField.defaultProps = {
    addLabel: true,
    max: 3,
};

export default ImagesArrayField;
import React from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField
} from "react-admin";

import { UserStatusChoices } from 'Constants/Users';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const UserEdit = props => {
  const [record, setRecord] = React.useState({});

  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Users',
      url: '/users',
    },
    {
      name: `${record.email}`,
      url: `/users/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/users/${record.id}`,
    }
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit {...props}>
        <SimpleForm>
          <RecordFetcher setRecord={setRecord} />
          {/* <TextInput disabled source="id" /> */}
          <TextInput source="email" />
          <SelectInput source="userType" choices={UserStatusChoices}/>
          {/* <NumberInput source="userType" /> */}
          <TextInput source="mobileNo" />
          <BooleanInput source="verified" />
          {/* <DateInput disabled source="createdAt" /> */}
          {/* <DateInput disabled source="updatedAt" /> */}
          <ReferenceInput disabled source="accountId" reference="accounts"><SelectInput optionText={({firstName, lastName}) => (`${firstName} ${lastName}`)} /></ReferenceInput>
          {/* <TextInput disabled source="account.id" /> */}
      </SimpleForm>
      </Edit>
    </>  
  )
}

export default UserEdit;

import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Modal from "../../../Components/Common/Modal/Modal";

const OperatingCountryFormSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, "Too Short!").required(),
  countryCode: Yup.string().trim().min(2, "Too Short!").max(2).required(),
  images: Yup.object().shape({
    web: Yup.string().trim().url(),
    mobile: Yup.string().trim().url()
  }),
  content: Yup.string().trim(),
  latitude: Yup.number(),
  longitude: Yup.number(),
  region: Yup.string().required().matches(new RegExp("^[0-9a-fA-F]{24}$"))
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2)
    },
    inputContainer: {
      width: "100%",
      margin: spacing(2, 0)
    }
  };
});

const OpertaingCountryModal = ({
  operatingCountryDetails,
  showModal,
  closeModal,
  refreshList,
  regions
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const addCountry = (values) => {
    dataProvider
      .create("operating-country", { data: values })
      .then(() => {
        closeModal();
        refreshList();
        notify("Operating country created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };
  const editCountry = (values) => {
    delete values.cities;
    dataProvider
      .update("operating-country", {
        id: operatingCountryDetails.id,
        data: values
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Operating country updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={300} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">{`${
          operatingCountryDetails ? "Edit" : "Create"
        } Operating Country`}</Typography>
        <Formik
          validationSchema={OperatingCountryFormSchema}
          initialValues={{
            name: "",
            images: {
              web: "",
              mobile: ""
            },
            content: "",
            latitude: 0,
            longitude: 0,
            region: regions && regions.length > 0 && regions[0].id,
            ...operatingCountryDetails
          }}
          onSubmit={(values) => {
            if (operatingCountryDetails) {
              editCountry(values);
            } else {
              addCountry(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur
          }) => (
            <Form className={classes.formContainer} onSubmit={handleSubmit}>
              <FormControl
                variant="outlined"
                className={classes.inputContainer}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Region Name
                </InputLabel>
                <Select
                  native={true}
                  value={values.region}
                  onChange={handleChange("region")}
                  onBlur={handleBlur("region")}
                  label="Region Name"
                  inputProps={{
                    name: "region",
                    id: "outlined-age-native-simple"
                  }}
                >
                  {regions.map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.name.toUpperCase()}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Country Name"
                error={"name" in errors && "name" in touched}
                helperText={
                  "name" in errors && "name" in touched && errors.name
                }
                variant="outlined"
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
                className={classes.inputContainer}
              />
              <TextField
                label="Country Code"
                error={"countryCode" in errors && "countryCode" in touched}
                helperText={
                  "countryCode" in errors &&
                  "countryCode" in touched &&
                  errors.countryCode
                }
                variant="outlined"
                onChange={handleChange("countryCode")}
                onBlur={handleBlur("countryCode")}
                value={values.countryCode}
                className={classes.inputContainer}
              />
              <TextField
                error={
                  "images" in errors &&
                  "web" in errors.images &&
                  "images" in touched &&
                  "web" in touched.images
                }
                helperText={
                  "images" in errors &&
                  "web" in errors.images &&
                  "images" in touched &&
                  "web" in touched.images &&
                  "Invalid url"
                }
                label="Web Image URL"
                variant="outlined"
                onChange={handleChange("images.web")}
                onBlur={handleBlur("images.web")}
                value={values.images.web}
                className={classes.inputContainer}
              />
              <TextField
                error={
                  "images" in errors &&
                  "mobile" in errors.images &&
                  "images" in touched &&
                  "mobile" in touched.images
                }
                helperText={
                  "images" in errors &&
                  "mobile" in errors.images &&
                  "images" in touched &&
                  "mobile" in touched.images &&
                  "Invalid url"
                }
                label="Mobile Image URL"
                variant="outlined"
                onChange={handleChange("images.mobile")}
                onBlur={handleBlur("images.mobile")}
                value={values.images.mobile}
                className={classes.inputContainer}
              />
              <TextField
                label="Latitude"
                variant="outlined"
                onChange={handleChange("latitude")}
                onBlur={handleBlur("latitude")}
                value={values.latitude}
                className={classes.inputContainer}
              />
              <TextField
                label="Longitude"
                variant="outlined"
                onChange={handleChange("longitude")}
                onBlur={handleBlur("longitude")}
                value={values.longitude}
                className={classes.inputContainer}
              />
              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  {operatingCountryDetails ? "Discard" : "cancel"}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {operatingCountryDetails ? "Save" : "Submit"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default OpertaingCountryModal;

import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Modal from "../../../Components/Common/Modal/Modal";

const OperatingRegionFormSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, "Too Short!").required(),
  images: Yup.object().shape({
    web: Yup.string().trim().url(),
    mobile: Yup.string().trim().url(),
  }),
  content: Yup.string().trim(),
  latitude: Yup.number(),
  longitude: Yup.number(),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
    },
    inputContainer: {
      width: "100%",
      margin: spacing(2, 0),
    },
  };
});

const OpertaingRegionModal = ({
  operatingRegionDetails,
  showModal,
  closeModal,
  refreshList,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const addRegion = (values) => {
    dataProvider
      .create("operating-region", { data: values })
      .then(() => {
        closeModal();
        refreshList();
        notify("Operating region created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };
  const editRegion = (values) => {
    delete values.countries;
    dataProvider
      .update("operating-region", {
        id: operatingRegionDetails.id,
        data: values,
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Operating region updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={300} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">{`${
          operatingRegionDetails ? "Edit" : "Create"
        } Operating Region`}</Typography>
        <Formik
          validationSchema={OperatingRegionFormSchema}
          initialValues={{
            name: "",
            images: {
              web: "",
              mobile: "",
            },
            content: "",
            latitude: 0,
            longitude: 0,
            ...operatingRegionDetails,
          }}
          onSubmit={(values) => {
            if (operatingRegionDetails) {
              editRegion(values);
            } else {
              addRegion(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form className={classes.formContainer} onSubmit={handleSubmit}>
              <TextField
                label="Region Name"
                error={"name" in errors && "name" in touched}
                helperText={
                  "name" in errors && "name" in touched && errors.name
                }
                variant="outlined"
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
                className={classes.inputContainer}
              />
              <TextField
                error={
                  "images" in errors &&
                  "web" in errors.images &&
                  "images" in touched &&
                  "web" in touched.images
                }
                helperText={
                  "images" in errors &&
                  "web" in errors.images &&
                  "images" in touched &&
                  "web" in touched.images &&
                  "Invalid url"
                }
                label="Web Image URL"
                variant="outlined"
                onChange={handleChange("images.web")}
                onBlur={handleBlur("images.web")}
                value={values.images.web}
                className={classes.inputContainer}
              />
              <TextField
                error={
                  "images" in errors &&
                  "mobile" in errors.images &&
                  "images" in touched &&
                  "mobile" in touched.images
                }
                helperText={
                  "images" in errors &&
                  "mobile" in errors.images &&
                  "images" in touched &&
                  "mobile" in touched.images &&
                  "Invalid url"
                }
                label="Mobile Image URL"
                variant="outlined"
                onChange={handleChange("images.mobile")}
                onBlur={handleBlur("images.mobile")}
                value={values.images.mobile}
                className={classes.inputContainer}
              />
              <TextField
                label="Latitude"
                variant="outlined"
                onChange={handleChange("latitude")}
                onBlur={handleBlur("latitude")}
                value={values.latitude}
                className={classes.inputContainer}
              />
              <TextField
                label="Longitude"
                variant="outlined"
                onChange={handleChange("longitude")}
                onBlur={handleBlur("longitude")}
                value={values.longitude}
                className={classes.inputContainer}
              />
              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  {operatingRegionDetails ? "Discard" : "cancel"}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {operatingRegionDetails ? "Save" : "Submit"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default OpertaingRegionModal;

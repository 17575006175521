import React from "react";
import { SimpleForm, useNotify} from "react-admin";

import { TextField, FormControl, InputLabel, Select, Button as MaterialButton} from "@material-ui/core";
import { useFormik } from "formik";

import { parse } from "query-string";
import { BookingStatusChoices, BookingCancelReasonsChoices, BookingCancelReasons } from "Constants/Bookings";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import httpClient from '../../Providers/Data/HttpClient';

import { useHistory } from "react-router-dom";

const BookingStateCreate = (props) => {
  const notify = useNotify();
  const history = useHistory();
  const { bookingId, bookingNumber } = parse(props.location.search);

  const redirect = bookingId
    ? `/bookings/${bookingId}/show/bookingStates`
    : false;


  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Bookings",
      url: "/bookings",
    },
    {
      name: `${bookingNumber}`,
      url: `/bookings/${bookingId}/show`,
    },
    {
      name: "Booking States",
      url: `/bookings/${bookingId}/show/bookingStates`,
    },
    {
      name: `Create`,
      url: `${props.location.pathname}${props.location.search}`,
    },
  ];

  const setCancelReason = (e) => {
    const { value } = e.target;
    setFieldValue("cancelReasonOptions", value);
    setFieldValue("cancelReason", value);
  }

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      bookingId: bookingId,
      bookingNumber: bookingNumber,
      bookingState: "1",
      cancelReason: BookingCancelReasons["On customer request (Outside 24 hours - Full refund)"],
      cancelReasonOptions: BookingCancelReasons["On customer request (Outside 24 hours - Full refund)"]
    },
    onSubmit: (data) => {
      
      const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/bookingStates`;
      let payload = {
        state: data.bookingState,
        bookingId: data.bookingId,
        bookingNumber: data.bookingNumber
      }

      if(data.bookingState === "5") {
        payload["cancelReason"] = data.cancelReason;
      }

      httpClient(url, {
        method: 'POST',
        body: JSON.stringify(payload)
      }).then((res) => {
        notify("booking state added successfully! ");
        history.replace(redirect);
        
      }).catch((error) => {
        console.log(error);
        notify("Something went wrong!");
      });

    }
  });

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />

      <SimpleForm toolbar={null} >
        <FormControl label="bookingId">
          <InputLabel htmlFor="bookingId"> Booking id</InputLabel>
          <Select native value={bookingId} inputProps={{ name: "bookingId", id: "bookingId" }} disabled>
            <option value={bookingId}>{bookingId}</option>
          </Select>
        </FormControl>
        <br></br>
        <FormControl label="bookingNumber">
          <InputLabel htmlFor="bookingNumber"> Booking Number</InputLabel>
          <Select native value={bookingNumber} inputProps={{ name: "bookingNumber", id: "bookingNumber" }} disabled>
            <option value={bookingNumber}>{bookingNumber}</option>
          </Select>
        </FormControl>
        <br></br>
        <FormControl label="bookingState">
          <InputLabel htmlFor="bookingState"> State</InputLabel>
          <Select native defaultValue={"1"} inputProps={{ name: "bookingState", id: "bookingState" }} onChange={handleChange} >
              {BookingStatusChoices.map((choice, index) => (
                <option key={index} value={choice.id}>
                  {`${choice.name}`}
                </option>
              ))}
          </Select>
        </FormControl>
        <br></br>
        {
          values?.bookingState === "5" && 
          <>
            <FormControl label="cancelReasonOptions">
              <InputLabel htmlFor="cancelReasonOptions"> Reasons</InputLabel>
              <Select native inputProps={{ name: "cancelReasonOptions", id: "cancelReasonOptions" }} onChange={setCancelReason} >
                  {BookingCancelReasonsChoices.map((choice, index) => (
                    <option key={index} value={choice.id}>
                      {`${choice.name}`}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <br></br>
            <FormControl fullWidth={true}>
              <TextField  multiline={true} label="cancel Reason" onChange={handleChange("cancelReason")} value={values.cancelReason} fullWidth={true}/>
            </FormControl>
          </>
        }
        <br></br>
        <MaterialButton onClick={handleSubmit} type="submit" variant="contained" color="primary">{"Save"}</MaterialButton>
      </SimpleForm>
    </>
  );
};

export default BookingStateCreate;

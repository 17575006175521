export default {
  airportDetails: {
    about: {
      web: `<div style="text-align:justify"><img alt="geneva airport" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_genevaAiportCover.jpg" style="width:100%" /></div>

      <div style="text-align:justify"><span style="font-size:12px"><span style="color:#999999"><span style="font-family:Arial">Geneva Airport&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></span><em><span style="font-size:10px"><span style="color:#999999">Image by</span> </span></em><span style="font-size:10px"><a href="https://www.flickr.com/photos/134758269@N08/" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial"><span style="background-color:#fefefe">Jiv.Talking</span></span></span></a></span></div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Resting calmly in the heart of Swiss territory and running along the Swiss-French border, Geneva Airport lies at a distance of just 4 km from the city&rsquo;s centre. A good destination for vacation is one which is easily accessible. The proximity between Geneva airport and city as well as their good connectivity via different forms of commute makes it one of the best places to spend a holiday in. <strong>Transport from Geneva Airport</strong> is quick and completely hassle-free. The facilities of buses, trams, trains, and private taxis make <strong>Geneva airport transfers </strong>easy and comfortable. The airport is also flooded by passengers throughout the year since Geneva is the second most populous city in Switzerland. Therefore, you will hardly find a note of incompetence in working at Geneva Airport.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">The airport acts as the hub of Swiss International Airlines and easyJet Switzerland. It facilitates a number of flights to European metropolitan as well as great leisure destinations. It also serves long-haul flights to North America, Middle East, and even China.</span></span></p>
      
      <div style="float:left; padding-right:20px">
      <div style="text-align:justify"><span style="font-size:16px"><img alt="geneva airport" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_genevaAiportThumbnail1.jpg" style="width:100%" /></span></div>
      
      <div style="text-align:justify"><span style="font-size:12px"><span style="color:#999999"><span style="font-family:Arial">Geneva Airport&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span></span><em><span style="font-size:10px"><span style="color:#999999">Image by</span> </span></em><span style="font-size:10px"><a href="https://www.flickr.com/photos/eguidetravel/" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial">eGuide Travel</span></span></a></span></div>
      
      <div style="text-align:justify">&nbsp;</div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">The airport&rsquo;s terminal 1 is known as the Main Terminal (M) which is divided into five piers, which are, A, B, C, D, and F. The Piers A, B, C and D are situated in the Swiss side of Terminal 1. The passengers travelling from these gates check in at the main check-in hall as well as use the central security check above the check in hall. </span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Pier A stands right in front of the main shopping area and facilitates destinations in the Schengen area. Pier B consists of two non-Schengen circular satellite buildings which are reached from the shopping area through a walkway built underground, which also serves as passport control. Pier C is also non-Schengen,&nbsp; and is situated to the right side of Pier A and houses long haul flights. Pier D consists of one big circular satellite and one bus gate building, this is divided between Schengen and non-Schengen passengers on different floors.</span></span></p>
      `,
      mobile: `<div style="text-align:justify"><span style="font-size:16px"><img alt="geneva airport" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_genevaAiportCoverMobile.jpg" style="width:100%" /></span></div>

      <div>
      <div style="text-align:justify"><span style="color:#999999"><span style="font-size:12px">Geneva Airport&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;</span></span><span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/eguidetravel/"><span style="color:#3498db">eGuide Travel</span></a></span></div>
      </div>
      
      <div>
      <div>
      <p style="text-align:justify"><span style="font-size:16px">Resting calmly in the heart of Swiss territory and running along the Swiss-French border, Geneva Airport lies at a distance of just 4 km from the city&rsquo;s centre. A good destination for vacation is one which is easily accessible. The proximity between Geneva airport and city as well as their good connectivity via different forms of commute makes it one of the best places to spend a holiday in. <strong>Transport from Geneva Airport</strong> is quick and completely hassle-free. The facilities of buses, trams, trains, and private taxis make <strong>Geneva airport transfers </strong>easy and comfortable. The airport is also flooded by passengers throughout the year since Geneva is the second most populous city in Switzerland. Therefore, you will hardly find a note of incompetence in working at Geneva Airport.</span></p>
      </div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px">The airport acts as the hub of Swiss International Airlines and easyJet Switzerland. It facilitates a number of flights to European metropolitan as well as great leisure destinations. It also serves long-haul flights to North America, Middle East, and even China.</span></p>
      
      <div>
      <div style="text-align:justify"><span style="font-size:16px"><img alt="geneva airport" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_genevaAiportThumbnail1.jpg" style="width:100%" /></span></div>
      
      <div>
      <div style="text-align:justify"><span style="color:#999999"><span style="font-size:12px">Geneva Airport&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;</span></span><span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/eguidetravel/"><span style="color:#3498db">eGuide Travel</span></a></span></div>
      
      <div style="text-align:justify">&nbsp;</div>
      
      <p style="text-align:justify"><span style="font-size:16px">The airport&rsquo;s terminal 1 is known as the Main Terminal (M) which is divided into five piers, which are, A, B, C, D, and F. The Piers A, B, C and D are situated in the Swiss side of Terminal 1. The passengers travelling from these gates check in at the main check-in hall as well as use the central security check above the check in hall.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px">Pier A stands right in front of the main shopping area and facilitates destinations in the Schengen area. Pier B consists of two non-Schengen circular satellite buildings which are reached from the shopping area through a walkway built underground, which also serves as passport control. Pier C is also non-Schengen,&nbsp; and is situated to the right side of Pier A and houses long haul flights. Pier D consists of one big circular satellite and one bus gate building, this is divided between Schengen and non-Schengen passengers on different floors.</span></p>
      </div>
      </div>`,
    },
    bookAirportTransfer: {
      web: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial"><strong>Rydeu </strong>serves you the best of both worlds, that is, convenience and comfort. Our services facilitate all our clients the opportunity to book with us before your journey has even begun. Pre-book your ride with Rydeu just to sit back and relax. Now stop with the incessant stressing over your travelling time, way, navigation, and commute services when with us. That&rsquo;s right if you know the date and time for you to travel then we are at your service! This option saves you a lot of time and energy. So, you do not have to stress about finding a ride because of last-minute bookings.&nbsp;</span></span></p>

      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Rydeu offers you a diversity of options from which you can choose your fancied means of commuting. The range we provide is critical of your choice and needs, as we try to make both ends meet. We always consider your inclination towards a ride according to your mood and budget so we cover the whole spectrum of options. Our topnotch provisions help you find the booking that suits your style and statement. Rydeu promises to cover all the prospects out there- from high-end incentive rides to the affordable and worthwhile ones. Our transfers from Geneva Airport make sure you have no problem navigating your journey. With our customer-friendly services and easy cancellation policy, we make your needs our top priority. So, if you have your travel dates intact then book your rides already!</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Our assistance helps you find the most adequate Geneva Airport transfers to/from the city of Sion. You&rsquo;re just a tap away from reinvigorating your spirits at the town. Yes, from bookings to execution we include everything in our extraordinary packages and make your dreams come true. So, enjoy the smooth, swift and safe airport transfers with Rydeu.</span></span></p>`,
      mobile: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial"><strong>Rydeu </strong>serves you the best of both worlds, that is, convenience and comfort. Our services facilitate all our clients the opportunity to book with us before your journey has even begun. Pre-book your ride with Rydeu just to sit back and relax. Now stop with the incessant stressing over your travelling time, way, navigation, and commute services when with us. That&rsquo;s right if you know the date and time for you to travel then we are at your service! This option saves you a lot of time and energy. So, you do not have to stress about finding a ride because of last-minute bookings.&nbsp;</span></span></p>

      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Rydeu offers you a diversity of options from which you can choose your fancied means of commuting. The range we provide is critical of your choice and needs, as we try to make both ends meet. We always consider your inclination towards a ride according to your mood and budget so we cover the whole spectrum of options. Our topnotch provisions help you find the booking that suits your style and statement. Rydeu promises to cover all the prospects out there- from high-end incentive rides to the affordable and worthwhile ones. Our transfers from Geneva Airport make sure you have no problem navigating your journey. With our customer-friendly services and easy cancellation policy, we make your needs our top priority. So, if you have your travel dates intact then book your rides already!</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Our assistance helps you find the most adequate Geneva Airport transfers to/from the city of Sion. You&rsquo;re just a tap away from reinvigorating your spirits at the town. Yes, from bookings to execution we include everything in our extraordinary packages and make your dreams come true. So, enjoy the smooth, swift and safe airport transfers with Rydeu.</span></span></p>`,
    },
  },
};

import React from 'react'
import { 
  Datagrid, Filter, TextField, 
  TextInput, BooleanField, DateField, 
  SingleFieldList, ChipField,
  ArrayField, NumberField, 
  ReferenceInput,AutocompleteInput,
  SelectInput, ExportButton,
} from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import List from 'Components/Common/RaOverrides/List';
import SimpleArrayField from 'Components/Common/Fields/SimpleArrayField';
import { ServiceTypeChoices, BusinessTypeChoices } from 'Constants/Vendors';
import Button from 'Components/Common/RaOverrides/Button';
import ResetFiltersActionButton from 'Components/Common/ResetFiltersActionButton';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_HOSTNAME as apiUrl } from 'Constants';
import CustomPagination from 'Components/Common/CustomPagination';
const VendorTitle = ({ record }) => {
  return (
    <span>
      Vendor: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}
const VendorFilter = ( props ) => {
  const [ countryChoices, setCountryChoices ] = useState([]);
  useEffect( ()=>{
    countriesFetch().then( async response => {
      response.data.rows = await response.data.rows.map( countryObj => {
        return { id:  countryObj.country.toUpperCase(), name:  countryObj.country.toUpperCase()};
      })
      setCountryChoices([ ...response.data.rows ]);
    })
  }, [])
  return (
    <Filter { ...props }>
    <TextInput label="Search Email, City..." source="q" alwaysOn />
    <SelectInput label="Country" source="country" choices={countryChoices} alwaysOn emptyText="All"/>
    <SelectInput label="Service Type" source="serviceType" choices={ServiceTypeChoices} alwaysOn emptyText="All"/>
    <SelectInput label="Business Type" source="businessType" choices={BusinessTypeChoices} alwaysOn emptyText="All"/>
  </Filter>
  )
}

async function countriesFetch () {
 return await fetch(`${apiUrl}/countryList?country=true`)
  .then( response => response.json() )
  .then( data => data)
}

const VendorActions = props => {
  // console.log("Vendor Actions Props", props);
  const { className, ...rest } = props || {};
  return (
    <div style={{ display: 'flex', alignSelf: 'center', }}>
      
      <ResetFiltersActionButton 
        {...rest}
        defaultObj={{
          q: null,
          businessType: null,
          serviceType: null,
          country: null
        }} 
      />
      <Button customComponent={ExportButton} label="Export" {...rest} />
    </div>
  )
}

export const VendorList = props => {
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <List {...props} title="Vendor List" pagination={<CustomPagination />} filters={<VendorFilter />} actions={<VendorActions />}>
        <Datagrid rowClick="show">
            {/* <TextField source="id" /> */}
            {/* <TextField source="firstName" />
            <TextField source="lastName" /> */}
            <TextField label="E-mail" source="user.email" />
            {/* <TextField label="E-mail" source="businessEmail" /> */}
            <TextField source="mobileNo" />
            <TextField source="businessType" />
            <SimpleArrayField source="serviceType" />
            {/* <TextField source="language" /> */}
            {/* <TextField source="description" /> */}
            <TextField source="companyName" />
            {/* <TextField source="website" /> */}
            {/* <TextField source="registrationAddress" /> */}
            {/* <TextField source="registrationNo" /> */}
            {/* <TextField source="vatNo" /> */}
            {/* <ReferenceField source="taxId" reference="taxes"><TextField source="id" /></ReferenceField> */}
            {/* <TextField source="termAndConditions" /> */}
            <BooleanField source="verificationState" />
            {/* <TextField source="iban" /> */}
            {/* <ReferenceField source="paypalId" reference="paypals"><TextField source="id" /></ReferenceField> */}
            {/* <TextField source="paypalEmail" /> */}
            {/* <TextField source="accountNumber" /> */}
            {/* <TextField source="ifsc" /> */}
            {/* <TextField source="swift" /> */}
            {/* <TextField source="accountHolderName" /> */}
            {/* <TextField source="accountHolderNameIndia" /> */}
            {/* <TextField source="bankName" /> */}
            {/* <TextField source="region" /> */}
            {/* <DocumentOrImageFileField source="organizationRegistrationDocument" title="File" target="_blank" /> */}
            {/* <DocumentOrImageFileField source="passengerLicense" title="File" target="_blank" /> */}
            {/* <DocumentOrImageFileField source="passengerInsurance" title="File" target="_blank" /> */}
            <BooleanField source="organizationRegistrationDocumentVerified" />
            <BooleanField source="passengerLicenseVerified" />
            <BooleanField source="passengerInsuranceVerified" />
            <DateField source="createdAt" />
            {/* <DateField source="updatedAt" /> */}
            {/* <TextField source="vendorLocations" /> */}
            {/* <ArrayField source="vendorLocations">
              <SingleFieldList>
                <ChipField source="id" />
              </SingleFieldList>
            </ArrayField> */}
            {/* <ArrayField source="drivers">
              <SingleFieldList>
                <ChipField source="id" />
              </SingleFieldList>
            </ArrayField> */}
            {/* <ArrayField source="vehicles">
              <SingleFieldList>
                <ChipField source="id" />
              </SingleFieldList>
            </ArrayField> */}
        </Datagrid>
      </List>
    </>
  )
}
export default VendorList;


import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Tabs,
  Tab,
  IconButton
} from "@material-ui/core";
import { CameraAltOutlined } from "@material-ui/icons";
import { useDataProvider, useNotify } from "react-admin";
import Axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../../../../Components/Common/Modal/Modal";
import Editor from "../../../../../Components/Common/Editor/Editor";

const QuestionFormSchema = Yup.object().shape({
  question: Yup.string().trim().required(),
  type: Yup.string().trim().required(),
  city: Yup.string().required().matches(new RegExp("^[0-9a-fA-F]{24}$"))
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto"
    },
    inputContainer: {
      width: "100%",
      margin: spacing(2, 0)
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2)
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start"
    }
  };
});

const AirportModal = ({
  questionDetails,
  showModal,
  closeModal,
  refreshList,
  cityDetails
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [imageCreditType, setImageCreditType] = useState(null);
  const [tabId, setTabId] = useState(0);
  const [templateData, setTemplateData] = useState("");
  const [faqFile, setFaqFile] = useState(null);
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue
  } = useFormik({
    validationSchema: QuestionFormSchema,
    initialValues: {
      question: "",
      type: "city",
      ...questionDetails,
      city: ""
    },
    onSubmit: (data) => {
      console.log(data);
      if (questionDetails) {
        editQuestion(data);
      } else {
        addQuestion(data);
      }
    }
  });

  useEffect(() => {
    if (cityDetails) {
      setFieldValue("city", cityDetails.id);
    }
  }, [cityDetails]);

  const addQuestion = (data) => {
    dataProvider
      .create(`operating-city/${cityDetails.slug}/questions`, {
        data
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Question created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const addBulkQuestion = () => {
    const url =
      process.env.REACT_APP_API_HOSTNAME +
      `/admin/operating-city/${cityDetails.slug}/questions/bulk`;
    const formData = new FormData();
    formData.append("file", faqFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    Axios.post(url, formData, config)
      .then(() => {
        closeModal();
        refreshList();
        notify("Question created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editQuestion = (data) => {
    dataProvider
      .update(`operating-city/${cityDetails.slug}/questions`, {
        id: questionDetails.slug,
        data: {
          question: data.question,
          city: questionDetails.city.id,
          questionNumber: questionDetails.questionNumber,
          type: data.type
        }
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Question Details updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabId(newValue);
  };

  const bulkSubmitHandler = () => {
    if (faqFile) {
      addBulkQuestion();
    } else {
      notify(`Please select the file.`);
    }
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">{`${
          questionDetails ? "Edit" : "Create"
        } Question`}</Typography>

        <Paper className={classes.root}>
          <Tabs
            value={tabId}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Single" />
            <Tab label="Bulk" />
          </Tabs>
        </Paper>

        {tabId === 0 && (
          <form className={classes.formContainer} onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between">
              <FormControl
                variant="outlined"
                className={classes.inputContainer}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Type
                </InputLabel>
                <Select
                  native={true}
                  value={values.type}
                  onChange={handleChange("type")}
                  onBlur={handleBlur("type")}
                  label="Type"
                  inputProps={{
                    name: "type",
                    id: "outlined-age-native-simple"
                  }}
                >
                  <option value={"city"}>City</option>
                  <option value={"airport_transfer"}>Airport Transfer</option>
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                label="Question"
                multiline={true}
                rows={3}
                variant="outlined"
                onChange={handleChange("question")}
                onBlur={handleBlur("question")}
                value={values.question}
                className={classes.inputContainer}
              />
            </Box>
            <Box display="flex" mt={2} justifyContent="space-between">
              <Button onClick={closeModal} variant="text" color="primary">
                {questionDetails ? "Discard" : "cancel"}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {questionDetails ? "Save" : "Submit"}
              </Button>
            </Box>
          </form>
        )}

        {tabId === 1 && (
          <form className={classes.formContainer} onSubmit={bulkSubmitHandler}>
            <Box
              style={{
                border: "1px solid #DBDBDB",
                height: 200,
                width: "100%",
                outline: "none",
                borderRadius: 16,
                resize: "none",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <input
                type="file"
                onChange={(e) => {
                  setFaqFile(e.target.files[0]);
                  // this.handleSelectBanner(e, setFieldValue, setFieldError);
                }}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  zIndex: 2,
                  cursor: "pointer",
                  opacity: 0
                }}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />

              <Typography>
                {faqFile ? faqFile.name : "Click here to select the file"}
              </Typography>
            </Box>

            <Box display="flex" mt={2} justifyContent="space-between">
              <Button onClick={closeModal} variant="text" color="primary">
                {questionDetails ? "Discard" : "cancel"}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {questionDetails ? "Save" : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default AirportModal;

import simpleRestProvider from "ra-data-simple-rest";
import { API_HOSTNAME as apiUrl } from "Constants";
import httpClient from "./HttpClient";
import { stringify } from "query-string";
import { createOrUpdateObjectWithKeySameAsValue as listToObject } from "Util/helper";
import { pickBy, identity, isNumber } from "lodash";
// export default simpleRestProvider(apiUrl, httpClient);
// const apiUrl = 'https://my.api.com/';
// const httpClient = fetchUtils.fetchJson;
import { removeFalsyValuesDeep, removeKeysDeep } from "Util/base";

const METHODS = listToObject([
  "GET_LIST",
  "GET_ONE",
  "GET_MANY",
  "GET_MANY_REFS",
  "UPDATE",
  "UPDATE_MANY",
  "CREATE",
  "DELETE",
  "DELETE_MANY",
]);

const mapResourceToEndpoint = (resource, params) => {
  switch (resource) {
    case "dropLocations":
      return "locations";
    case "pickupLocations":
      return "locations";
    case "dmcBooking":
      return resource;
    case "drivers":
      return "admin/drivers";
    default:
      // for general case
      return `admin/${resource}`;
  }
};

const logAndNext = (data) => {
  // console.log('In logAndNext', data);
  return data;
};

export default {
  onlyList: (resource) => {
    const url = `${apiUrl}/${mapResourceToEndpoint(resource)}`;
    return httpClient(url).then((res) => JSON.parse(res.body));
  },
  getList: (resource, params = {}) => {
    const { page, perPage } = params.pagination || {};
    const { field, order } = params.sort || {};
    // console.log(`Get ${resource}, Sort by ${field} ${order}`);
    let query;
    query = {
      sort: JSON.stringify([field, order]),
      offset:
        resource == "vehicles" ||
        resource == "drivers" ||
        resource == "ancillary"
          ? null
          : (page - 1) * perPage,
      limit:
        resource == "vehicles" || resource == "drivers"
          ? null
          : perPage <= 10
          ? 25
          : perPage,
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    if (isNaN(query.offset)) {
      delete query.offset;
    }

    const url = `${apiUrl}/${mapResourceToEndpoint(
      resource,
      params,
      METHODS.GET_LIST
    )}?${stringify(query)}`;
    console.log(url);
    return httpClient(url)
      .then(({ headers, json }) => ({
        data: json.data.rows || [],
        total: json.data.count || 0,
      }))
      .then(logAndNext);
  },
  getOne: (resource, params) => {
    //    console.log('Get One', resource, params)
    return httpClient(
      `${apiUrl}/${mapResourceToEndpoint(resource, params, METHODS.GET_ONE)}/${
        params.id
      }`
    ).then(({ json }) => ({
      data: json.data,
    }));
  },

  getMany: (resource, params) => {
    // console.log('Get Many', resource)

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    const url = `${apiUrl}/${mapResourceToEndpoint(
      resource,
      params,
      METHODS.GET_MANY
    )}?${stringify(query)}`;
    return httpClient(url)
      .then(({ json }) => ({
        data: json.data.rows || [],
        total: json.data.count || 0,
      }))
      .then(logAndNext);
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      // offset: (page - 1) * perPage,
      // limit: perPage,
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    };
    if (typeof params.target == "object") {
      query.filter = JSON.stringify({
        ...params.filter,
        ...params.target,
      });
    } else {
      query.filter = JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      });
    }

    const url = `${apiUrl}/${mapResourceToEndpoint(
      resource,
      params,
      METHODS.GET_MANY_REFS
    )}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data.rows || [],
      total: json.data.count || 0,
      // total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: (resource, params) => {
    if (resource == "vendors") {
      const dataToSend = {
        id: params.data.id,
        businessEmail: params.data.businessEmail,
        mobileNo: params.data.mobileNo,
        businessType: params.data.businessType,
        verificationState: params.data.verificationState,
        organizationRegistrationDocumentVerified:
          params.data.organizationRegistrationDocumentVerified,
        passengerLicenseVerified: params.data.passengerLicenseVerified,
        passengerInsuranceVerified: params.data.passengerInsuranceVerified,
      };
      params.data = { ...dataToSend };
    } else if (resource == "transfers") {
      const dataToSend = {
        transferType: params.data.transferType,
        startDateTime: params.data.startDateTime,
        returnDateTime: params.data.returnDateTime,
        numberOfKms: params.data.numerOfKmsUpdated
          ? parseFloat(
              params.data.numerOfKmsUpdated.replaceAll(",", "").split(" ")[0]
            )
          : null,
        numberOfHours: params.data.numberOfHours,
        totalAdultSeats: params.data.totalAdultSeats,
        totalChildSeats: params.data.totalChildSeats,
        baggageCount: params.data.baggageCount,
        customerComment: params.data.customerComment,
        passengerContactNumber: params.data.passengerContactNumber,
        passengerName: params.data.passengerName,
        flightNumber: params.data.flightNumber,
      };
      if (
        params.data.pickupLocation &&
        params.data.pickupLocation.formattedAddress_lat
      ) {
        dataToSend.pickupLocation = {
          city: params.data.pickupLocation.formattedAddress_city,
          country: params.data.pickupLocation.formattedAddress_country,
          formattedAddress: params.data.pickupLocation.formattedAddress,
          lat: params.data.pickupLocation.formattedAddress_lat,
          lng: params.data.pickupLocation.formattedAddress_lng,
        };
      }
      if (
        params.data.dropLocation &&
        params.data.dropLocation.formattedAddress_lat
      ) {
        dataToSend.dropLocation = {
          city: params.data.dropLocation.formattedAddress_city,
          country: params.data.dropLocation.formattedAddress_country,
          formattedAddress: params.data.dropLocation.formattedAddress,
          lat: params.data.dropLocation.formattedAddress_lat,
          lng: params.data.dropLocation.formattedAddress_lng,
        };
      }
      params.data = { ...dataToSend };
    }

    return httpClient(
      `${apiUrl}/${mapResourceToEndpoint(resource, params, METHODS.UPDATE)}/${
        params.id
      }`,
      {
        method: "PUT",
        body: JSON.stringify(
          removeKeysDeep(
            removeFalsyValuesDeep(params.data),
            "id",
            "createdAt",
            "updatedAt"
          )
        ),
      }
    ).then(({ json }) => ({ data: { ...json.data, id: params.id } }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(
      `${apiUrl}/${mapResourceToEndpoint(
        resource,
        params,
        METHODS.UPDATE_MANY
      )}?${stringify(query)}`,
      {
        method: "PUT",
        body: JSON.stringify(params.data),
      }
    ).then(({ json }) => ({ data: json.data }));
  },

  create: (resource, params) =>
    httpClient(
      `${apiUrl}/${mapResourceToEndpoint(resource, params, METHODS.CREATE)}`,
      {
        method: "POST",
        body: JSON.stringify(params.data),
      }
    ).then(({ json }) => ({
      data: { ...params.data, id: json.data.id },
    })),

  deleteAuction: (resource, params) =>
    httpClient(
      `${apiUrl}/${mapResourceToEndpoint(resource, params, METHODS.DELETE)}`,
      {
        method: "DELETE",
      }
    ).then(({ json }) => ({ data: json.data })),

  delete: (resource, params) =>
    httpClient(
      `${apiUrl}/${mapResourceToEndpoint(resource, params, METHODS.DELETE)}/${
        params.id
      }`,
      {
        method: "DELETE",
      }
    ).then(({ json }) => ({ data: json.data })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(
      `${apiUrl}/${mapResourceToEndpoint(
        resource,
        params,
        METHODS.DELETE_MANY
      )}?${stringify(query)}`,
      {
        method: "DELETE",
        body: JSON.stringify(params.data),
      }
    ).then(({ json }) => ({ data: json.data }));
  },
  getCustomWithQueryParams: (resource, queryParams) => {
    const query = { ...queryParams };
    return httpClient(
      `${apiUrl}/${mapResourceToEndpoint(
        resource,
        {},
        METHODS.GET_ONE
      )}?${stringify(query)}`,
      {
        method: "GET",
      }
    ).then(({ json }) => ({ data: json.data }));
  },
};

import React from 'react'
import { BooleanField, NumberField, Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin'
import LongDateField from '../../Components/Common/Fields/LongDateField'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import CurrencyField from 'Components/Common/Fields/CurrencyField';

const CreditProfileTitle = ({ record }) => {
  return (
    <span>
      CreditProfile: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const CreditProfileShow = props => {
  const [record, setRecord] = React.useState({});
  const [orgRecord, setOrgRecord] = React.useState({});
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Organizations',
      url: '/organizations',
    },
    {
      name: `${orgRecord.companyName}`,
      url: `/organizations/${orgRecord.id}/show`,
    },
    {
      name: `Credit Profile`,
      url: `/creditProfiles/${record.id}/show`,
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show {...props}>
        <SimpleShowLayout>
          <RecordFetcher setRecord={setRecord} />
          <CurrencyField source="creditLimit" />
          <CurrencyField source="walletBalance" />
          <ReferenceField source="organizationId" reference="organizations">
            <RecordFetcher setRecord={setOrgRecord}>
              <TextField source="companyName" />
            </RecordFetcher>
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
    </>
  )
}

export default CreditProfileShow;
import React from 'react'
import { BooleanInput, Create, DateInput, NumberInput, SimpleForm, TextInput } from 'react-admin'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

export const VendorCreate = props => {
  const [record, setRecord] = React.useState({});
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
    {
      name: `Create`,
      url: `/vendors/create`,
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Create {...props}>
        <RecordFetcher setRecord={setRecord} />
      </Create>
    </>
  )
}

export default VendorCreate;
import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider } from "react-admin";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CreateEditSightseeing from "./CreateEditSightseeing";

const SightseeingList = (props) => {
  const dataProvider = useDataProvider();
  const [cities, setCities] = useState([]);
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const getCitiesList = () => {
    dataProvider
      .getList("operating-city")
      .then((res) => {
        setCities(Object.values(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getList = useCallback(() => {
    dataProvider
      .getList("operating-city/sightseeings")
      .then((res) => {
        setResponse({ data: res.data, loading: false, error: null });
      })
      .catch((err) => {
        setResponse({ data: null, loading: false, error: err.message });
      });
  }, []);

  useEffect(() => {
    getCitiesList();
    getList();
  }, []);

  return (
    <Box>
      <Box mt={2} mb={5} display="flex" justifyContent="space-between">
        <Typography variant="h4">Sightseeings</Typography>
        <CreateEditSightseeing cities={cities} refreshList={getList} />
      </Box>
      {loading && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {data && !error && !loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sightseeing Place Name</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(data).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name.toUpperCase()}</TableCell>
                  <TableCell align="center">
                    <CreateEditSightseeing
                      cities={cities}
                      refreshList={getList}
                      sightseeingDetails={row}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default SightseeingList;

import React from 'react'
import { 
  BooleanInput, Edit, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator, FormDataConsumer, ReferenceField,
} from 'react-admin';
import DateFnsUtils from '@date-io/date-fns';

import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
import { OrderStates } from 'Constants/OrderStates'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const OrderTitle = ({ record }) => {
  return (
    <span>
      Order: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const OrderEdit = props => {
  
  const [record, setRecord] = React.useState({});
  const [bookingRecord, setBookingRecord] = React.useState({});

  const { bookingId } = props.record || {};
  const redirect = record.bookingId ? `/bookings/${record.bookingId}/show/orders` : false;
  // console.log("Order Edit Props", props)
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Bookings',
      url: '/bookings',
    },
    {
      name: `${bookingRecord.bookingNumber}`,
      url: `/bookings/${bookingRecord.id}/show`,
    },
    {
      name: 'Orders',
      url: `/bookings/${bookingRecord.id}/show/orders`,
    },
    {
      name: `${record.orderNumber}`,
      url: `/orders/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/orders/${record.id}`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<OrderTitle />} {...props}>
        <SimpleForm
          redirect={redirect}
        >
          <RecordFetcher setRecord={setRecord} />
          <ReferenceField addLabel={false} link="show" source="bookingId" reference="bookings">
            <RecordFetcher setRecord={setBookingRecord} />  
          </ReferenceField>
          <TextInput source="termAndConditions" />
          <TextInput disabled source="orderNumber" />
          <DateTimeInput label="Start Date Time" source="startDateTime" />
          <DateTimeInput label="End Date Time" source="endDateTime" />
          <TextInput source="additionalRequest" />
          <NumberInput source="childSeats" />
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.currentState === OrderStates.PENDING &&
                  <NumberInput label="Total Amount" source="totalAmountCents" format={v => v/100} parse={v => v*100} {...rest} />
            }
        </FormDataConsumer>
          <NumberInput label="Total Amount" source="totalAmountCents" format={v => v/100} parse={v => v*100} />
          <TextInput source="travellerName" />
          <TextInput source="travellerPhoneNumber" />
          <TextInput disabled source="currency" />
          <TextInput source="travellerEmail" />
          {/* <DateInput source="currentState" /> */}
          <ReferenceInput source="bookingId" reference="bookings">
            <SelectInput disabled optionText="bookingNumber" />
          </ReferenceInput>
          {/* <ReferenceInput source="billingAddressId" reference="billingAddresses">
            <SelectInput optionText="id" />
          </ReferenceInput> */}
          {/* <ReferenceInput source="pickupLocationId" reference="locations">
            <SelectInput optionText="formattedAddress" />
          </ReferenceInput> */}
          {/* <ReferenceInput source="dropLocationId" reference="locations">
            <SelectInput optionText="formattedAddress" />
          </ReferenceInput> */}
          <ReferenceInput source="vehicleTypeId" reference="vehicleTypes">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  )
}

export default OrderEdit;
export const vehicleTypeImg = (type) => {
  switch (type) {
  case 'business-van':
    return 'vehicle_types/business-van.png'
  case 'cadillac':
    return 'vehicle_types/cadillac-escalade.png';
  case 'miniVan':
    return 'vehicle_types/v-class-van.png';
  case 'standard':
    return 'vehicle_types/s-class.png';
  case 'premium':
    return 'vehicle_types/e-class-sedan.png';
  default:
    return 'vehicle_types/s-class.png';
  }
};

export const removeFalsyValuesDeep = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeFalsyValuesDeep(obj[key]);
    else if (obj[key] === null || obj[key] === undefined) delete obj[key];
  });
  // console.log("$$$", obj);
  return obj;
};

export const removeKeysDeep = (obj, ...removeKeys) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeKeysDeep(obj[key], ...removeKeys);
    else if (removeKeys.includes(key)) delete obj[key];
  });
  // console.log("$$$", obj);
  return obj;
};

export default {};

import React, { Fragment, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";

const DeletePlace = ({ refreshList, place, citySlug }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletePlace = () => {
    dataProvider
      .delete(`operating-city/${citySlug}/places`, {
        id: place.id,
        previousData: place,
      })
      .then((res) => {
        handleClose();
        refreshList();
        notify("Place deleted successfully");
      })
      .catch((error) => {
        handleClose();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Fragment>
      <Button color="primary" onClick={handleClickOpen}>
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this place?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={deletePlace}
            variant="contained"
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DeletePlace;

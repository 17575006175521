import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider, useRedirect } from "react-admin";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CreateEditPricing from "./CreateEditPricing";

const IntercityRoutePricingList = (props) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [isPricingCreated, setIsPricingCreated] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const getList = useCallback(() => {
    let filter = {
      sourceCity: props.intercityRouteDetails.sourceCity,
      destinationCity: props.intercityRouteDetails.destinationCity,
    };
    dataProvider
      .getList(`interCityPricings`, {
        filter,
      })
      .then((res) => {
        let data = {};
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            data[res.data[i].vehicleTypeId] =
              data[res.data[i].vehicleTypeId] || {};
            data[res.data[i].vehicleTypeId].id = res.data[i].id;
            data[res.data[i].vehicleTypeId].vehicleType =
              res.data[i].vehicleType;
            data[res.data[i].vehicleTypeId][res.data[i].pricingGroup] =
              data[res.data[i].vehicleTypeId][res.data[i].pricingGroup] || {};
            data[res.data[i].vehicleTypeId][res.data[i].pricingGroup].price =
              res.data[i].priceInCents / 100;
          }
          setIsPricingCreated(true);
        }

        setResponse({
          data: data,
          loading: false,
          error: null,
        });
      })
      .catch((err) => {
        setResponse({ data: null, loading: false, error: err.message });
      });
  }, [props.intercityRouteDetails]);

  const getVehicleTypes = useCallback(() => {
    dataProvider
      .getList(`vehicleTypes`)
      .then((res) => {
        setVehicleTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getVehicleTypes();
    getList();
  }, []);

  return (
    <Box>
      <Box mt={3} mb={3} display="flex" justifyContent="flex-end">
        <CreateEditPricing
          intercityRouteDetails={props.intercityRouteDetails}
          vehicleTypes={vehicleTypes}
          pricings={data}
          isPricingCreated={isPricingCreated}
          refreshList={getList}
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {data && !error && !loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Vehicle Type</TableCell>
                <TableCell>B2C Pricing</TableCell>
                <TableCell>B2B Pricing</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(data).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.hasOwnProperty("vehicleType")
                      ? row.vehicleType.name
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {row.hasOwnProperty("b2c") ? row["b2c"].price : "N/A"}
                  </TableCell>
                  <TableCell>
                    {row.hasOwnProperty("b2b") ? row["b2b"].price : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default IntercityRoutePricingList;

import React from 'react';
import PropTypes from 'prop-types'
import { List as RaList } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(theme=>({
  root: {
    
  }
}));

const List = ({className, ...props}) => {
  const classes = useStyles();
  return <RaList {...props} className={classnames(className, classes.root)} />
}

List.propTypes = {

}

List.defaultProps = {
  bulkActionButtons: false,
  sort: { 
    field: 'createdAt', 
    order: 'DESC', 
  },
}

export default List;
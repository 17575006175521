import React from 'react'
import { BooleanInput, Create, DateInput, NumberInput, SimpleForm, TextInput } from 'react-admin'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

export const OrganizationCreate = props => {
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Organizations',
      url: '/organizations',
    },
    {
      name: `Create`,
      url: `/organizations/create`,
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="companyName" />
          <TextInput source="domainName" />
          <DateInput source="contractStartDate" />
          <DateInput source="contractEndDate" />
          <TextInput source="registrationNo" />
          <TextInput source="vatNo" />
          {/* <ReferenceInput source="taxId" reference="taxes"><SelectInput optionText="id" /></ReferenceInput> */}
          <BooleanInput source="termAndConditions" />
          <BooleanInput source="active" />
          <BooleanInput source="blocked" />
          {/* <BooleanInput source="allowInvoicing" /> */}
          {/* <NumberInput source="discountPercentage" /> */}
          <TextInput type="email" source="email" />
          <TextInput source="mobileNo" />
          <TextInput source="gstnNo" />
          <TextInput source="country" />
          <TextInput source="currency" />
          <NumberInput source="invoiceDueDate" label={"Invoice Due Date (Days)"}/>

          <TextInput source="address.buildingNumber" />
          <TextInput source="address.streetNumber" />
          <TextInput source="address.area" />
          <TextInput source="address.city" />
          <NumberInput source="address.pinCode" />
          <TextInput source="address.country" />
          <TextInput source="address.type" />
        </SimpleForm>
      </Create>
    </>
  )
}

export default OrganizationCreate;
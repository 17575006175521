import React from "react";
import {
  BooleanInput, DateInput, Edit,
  NumberInput, SimpleForm, TextInput,
  ReferenceInput, SelectInput, TextField,
  ReferenceField, Toolbar, 
} from "react-admin";
import { parse } from 'query-string';

import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import UpdateRecordButton from './components/UpdateRecordButton';

const VoucherTitle = ({ record }) => {
  return (
    <span>
      Voucher: {record ? `${record.name || record.id}` : ''}
    </span>
  )
}

const VoucherEditToolbar = props => (
    <Toolbar {...props} >
        <UpdateRecordButton />
    </Toolbar>
);

//this is a hack
const OmitSomeValuesAndPassTheRestToSimpleForm = ({ children, ...props }) => {
  
  // console.log("OmitSomeValuesAndPassTheRestToSimpleForm Props", props);

  props.record.voucherDetails = undefined;

  if(children instanceof Object ) {
    if(children instanceof Array) {
      return children.map(child => React.cloneElement(child, {...props, ...(child.props)}));
    }
    // console.log("child Props", children);
    return React.cloneElement(children, {...props, ...(children.props)})
  }
  return null;
}

const VoucherEdit = props => {

  const [record, setRecord] = React.useState({});
  const [bookingRecord, setBookingRecord] = React.useState({});

  let redirect = `/bookings/${bookingRecord.id}/show/vouchers`;
  // console.log("Voucher Edit Props", props)

  React.useEffect(() => {
    // console.log("Redirect changed to", redirect);
  }, [redirect])
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Bookings',
      url: '/bookings',
    },
    {
      name: `${bookingRecord.bookingNumber}`,
      url: `/bookings/${bookingRecord.id}/show`,
    },
    {
      name: 'Vouchers',
      url: `/bookings/${bookingRecord.id}/show/vouchers`,
    },
    {
      name: `${record.id}`,
      url: `/vouchers/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/vouchers/${record.id}`,
    },
  ]

  return (
    <>      
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<VoucherTitle />} {...props}>
        <OmitSomeValuesAndPassTheRestToSimpleForm>        
          <SimpleForm redirect={redirect}>
            <RecordFetcher setRecord={setRecord} />
            <ReferenceField addLabel={false} link="show" source="bookingId" reference="bookings">
              <RecordFetcher setRecord={setBookingRecord} />  
            </ReferenceField>
            <TextInput source="id" />
            <TextInput source="driverName" />
            <TextInput source="driverContactNumber" />
            <TextInput source="receiverName" />
            <TextInput source="receiverEmail" />
            <TextInput source="receiverPhoneNumber" />
            <TextInput source="supportName" />
            <TextInput source="supportPhoneNumber" />
            <TextInput source="createdBy" />
            <TextInput source="voucherUrl" />
            <TextInput source="voucherNumber" />
            {/* <DateInput source="createdAt" /> */}
            {/* <DateInput source="updatedAt" /> */}
            {/* <TextInput source="deletedAt" /> */}
            <ReferenceInput source="bookingId" reference="bookings"><SelectInput optionText="bookingNumber" /></ReferenceInput>
            {/* <ReferenceInput source="dmcBookingId" reference="dmcBookings"><SelectInput optionText="id" /></ReferenceInput> */}
          </SimpleForm>
        </OmitSomeValuesAndPassTheRestToSimpleForm>
      </Edit>
    </>
  )
}
export default VoucherEdit;

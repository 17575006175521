import React from "react";
import {
  BooleanInput,
  DateInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField
} from "react-admin";
import { parse } from 'query-string';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const today = new Date();

const AccountCreate = props => {
    const { dmcBookingId } = parse(props.location.search);
  
    const redirect = dmcBookingId ? `/dmcBooking/${dmcBookingId}/show/Accounts` : false;

    // console.log(dmcBookingId, redirect);
    return (
      <div>Not Implemented Yet</div>
    )
    return (
    <Create {...props}>
      <SimpleForm>

      </SimpleForm>
    </Create>
    );
}

export default AccountCreate;

import React, { Fragment, useState, useEffect } from "react";
import httpClient from "../../Providers/Data/HttpClient";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
  Button as MaterialButton,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  DateInput,
  AutocompleteInput,
  SelectField,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ReferenceInput,
  SelectInput,
  Datagrid,
  Filter,
  TextField,
  TextInput,
  ChipField,
  ExportButton,
  useLocale,
  SelectArrayInput,
  useDataProvider,
  useNotify,
  SimpleForm,
} from "react-admin";
import moment from "moment-timezone";
import {
  BookingTypeChoices,
  BookingStatuses,
  BookingActivityChoices,
} from "Constants/Bookings";
import { CrmTypeChoices, CrmStatuses } from "Constants/Crm";
import Button from "Components/Common/RaOverrides/Button";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import ResetFiltersActionButton from "Components/Common/ResetFiltersActionButton";
import List from "Components/Common/RaOverrides/List";
import { API_HOSTNAME as apiUrl } from "Constants";
import CustomPagination from "Components/Common/CustomPagination";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Chat from "@material-ui/icons/Chat";
import Modal from "../../Components/Common/Modal/Modal";
import { useFormik } from "formik";
import { capitalize, lowerCase } from "lodash";
import { CommunicationStatusTypeChoices } from "Constants/CommunicatedToCustomer";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import CopyIcon from "@material-ui/icons/FileCopy";

const BookingTitle = ({ record }) => {
  return <span>Booking: {record ? `${record.name}` : ""}</span>;
};

const BookingFilter = (props) => {
  // console.log("Booking Filter Props", props);
  const [countryChoices, setCountryChoices] = useState([]);
  const [campaignChoices, setCampaignChoices] = useState([]);
  const [adminUserChoices, setAdminUsers] = useState([]);
  useEffect(() => {
    countriesFetch().then(async (response) => {
      response.data.rows = await response.data.rows.map((countryObj) => {
        return {
          id: countryObj.country.toUpperCase(),
          name: countryObj.country.toUpperCase(),
        };
      });
      setCountryChoices([...response.data.rows]);
    });

    fetchAdminUsers().then(async (response) => {
      response.data.rows = await response.data.rows.map((userObj) => {
        return {
          id: userObj.id,
          name: `${userObj.account?.firstName ?? ""} ${
            userObj.account?.lastName ?? ""
          } - ${userObj.email}`,
        };
      });
      setAdminUsers([...response.data.rows]);
    });

    fetchMarketingAttributes().then(async (response) => {
      response.data.rows = await response.data.rows.map((marketingObj) => {
        return {
          id: marketingObj.campaign,
          name: marketingObj.campaign.toUpperCase(),
        };
      });
      setCampaignChoices([...response.data.rows]);
    });
  }, []);

  return (
    <div>
      <Filter {...props}>
        {/* <TextInput label="Search Name" source="q" alwaysOn /> */}
        <ReferenceInput
          label="Booking Number"
          source="bookingId"
          reference="bookingRequests"
          alwaysOn
        >
          <AutocompleteInput optionText="bookingNumber" />
        </ReferenceInput>
        <TextInput label="Search Email, City..." source="q" alwaysOn />
        <SelectInput
          label="Country"
          source="country"
          choices={countryChoices}
          alwaysOn
          emptyText="All"
        />
        <DateInput
          source="from"
          label="Start Time"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />
        <DateInput
          source="to"
          label="End Time"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />
        <DateInput
          source="createdAtStartTime"
          label="CreatetAt Start"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />
        <DateInput
          source="createdAtEndTime"
          label="CreatedAt End"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />

        <SelectArrayInput
          alwaysOn
          label="Booking Status"
          source="bookingStatuses"
          choices={[
            { id: BookingStatuses.PENDING, name: "Pending", selected: true },
            { id: BookingStatuses.CANCELLED, name: "Cancelled" },
            { id: BookingStatuses.EXPIRED, name: "Expired" },
          ]}
          initialValue={[BookingStatuses.PENDING, BookingStatuses.CANCELLED]}
        />
        <SelectInput
          label="Crm State"
          source="crmState"
          choices={CrmTypeChoices.map((choice) => ({
            id: `${choice.id}`,
            name: choice.name,
          }))}
          alwaysOn
          emptyText="All"
        />

        <SelectArrayInput
          alwaysOn
          allowEmpty
          label="Campaign"
          source="campaign"
          className={props.classes.campaignFormControl}
          choices={campaignChoices}
        />

        <SelectInput
          alwaysOn
          allowEmpty
          label="Prospection Owner"
          source="prospectionOwner"
          choices={adminUserChoices}
          emptyText="All"
        />

        <SelectInput
          label="Prospection State"
          source="prospectionState"
          choices={BookingActivityChoices.map((choice) => ({
            id: `${choice.id}`,
            name: choice.name,
          }))}
          alwaysOn
          emptyText="All"
        />
      </Filter>
    </div>
  );
};

async function fetchAdminUsers() {
  return await httpClient(`${apiUrl}/admin/users?filter={"userType":"6"}&limit=${1000}&offset=${0}`)
    .then((response) => JSON.parse(response.body))
    .then((data) => data);
}

async function countriesFetch() {
  return await fetch(`${apiUrl}/countryList?country=true`)
    .then((response) => response.json())
    .then((data) => data);
}
async function fetchMarketingAttributes() {
  return await fetch(`${apiUrl}/marketingAttributes?campaign=true`)
    .then((response) => response.json())
    .then((data) => data);
}

const BookingActions = (props) => {
  // console.log("Booking Actions Props", props);
  const { className, ...rest } = props || {};
  return (
    <div style={{ display: "flex", alignSelf: "center" }}>
      <ResetFiltersActionButton
        {...rest}
        defaultObj={{
          bookingId: null,
          organizationId: null,
          bookingState: null,
          paymentState: null,
        }}
      />
      <Button customComponent={ExportButton} label="Export" {...rest} />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formControl: {
      marginTop: "1rem",
      width: "50%",
    },
    campaignFormControl: {
      minWidth: "10em",
    },
    heading: {
      textAlign: "center",
      letterSpacing: "1.5px",
      fontWeight: "600",
    },
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width: "100%",
    },
    communicationRadioContainer: {
      width: "100%",
      paddingLeft: "6em",
    },
    contactNumber: {
      marginTop: "5%",
      color: "#33512F",
      fontWeight: "700",
      fontSize: "0.9rem",
    },
    returnDateTime: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: "5%",
    },
    startDateTime: {
      display: "flex",
      alignItems: "flex-start",
    },
    pickupLocation: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    dropLocation: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: "5%",
      justifyContent: "space-between",
    },
  };
});

export const BookingList = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [crmModal, setCrmModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showType, setShowType] = useState(null);

  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Booking Requests",
      url: "/bookingRequests",
    },
  ];

  const locale = useLocale();

  const requestRowStyle = (record, index) => {
    if (record?.currentState == BookingStatuses.CANCELLED) {
      return {
        background: "linear-gradient(to right, #ff6161, white 5%)",
      };
    } else if (record?.currentState == BookingStatuses.EXPIRED) {
      return {
        background: "linear-gradient(to right, #7c7c7c, white 5%)",
      };
    } else if (record?.crm?.currentState == CrmStatuses.BADREQUEST) {
      return {
        background: "linear-gradient(to right, #F570AD, white 5%)",
      };
    } else if (
      record?.crm?.currentState == CrmStatuses.WAITINGPAYMENTCONFIRMATION ||
      record?.crm?.currentState == CrmStatuses.PROSPECTS
    ) {
      return {
        background: "linear-gradient(to right, #6FCB64, white 5%)",
      };
    } else if (
      record?.crm?.currentState == CrmStatuses.QUOTEREQUESTED ||
      record?.crm?.currentState == CrmStatuses.LEAD ||
      record?.crm?.currentState == CrmStatuses.FOLLOWUP ||
      record?.crm?.currentState == CrmStatuses.RESPONSENEEDED ||
      record?.crm?.currentState == CrmStatuses.WAITINGBETTEROFFERS
    ) {
      return {
        background: "linear-gradient(to right, #FFEF78, white 5%)",
      };
    } else if (record?.crm?.currentState == CrmStatuses.DUPLICATE) {
      return {
        background: "linear-gradient(to right, #93B5C6, white 5%)",
      };
    } else if (record?.crm?.currentState == CrmStatuses.PROPOSALRECEIVED) {
      return {
        background: "#ffffff",
      };
    }
    //  else if( record.user || ( record.transfers[0] && record.transfers[0].passengerContactNumber)) {
    //   return {
    //     background: "linear-gradient(to right, rgba(255,250,205,1), white 5% )"
    //   }
    // }
    else {
      return {
        background: "linear-gradient(to right, #3E57B2, white 5%)",
      };
    }
  };

  const handleModalEdit = (event, type, record) => {
    event.stopPropagation();
    setCurrentRecord(record);
    setShowType(type);
    setCrmModal(true);
    if (type === "communicationWithCustomer") {
      console.log("if");
    } else {
      console.log("else");
    }
  };

  const CrmUpdateModal = ({
    showModal,
    closeModal,
    currentRecord,
    showType,
  }) => {
    const { values, handleSubmit, handleChange } = useFormik({
      enableReinitialize: true,
      initialValues: {
        crmState: currentRecord?.crm?.currentState || null,
        communication: currentRecord?.crm?.communicatedToCustomer
          ? [...currentRecord.crm.communicatedToCustomer]
          : [],
      },
      onSubmit: (data) => {
        const crmData = {};

        if (showType === "crmState") crmData.currentState = data.crmState;
        else crmData.communicatedToCustomer = data.communication;

        if (currentRecord?.crm?.id) {
          dataProvider
            .update(`crm`, { id: currentRecord.crm.id, data: crmData })
            .then((res) => {
              notify("Crm updated successfully! ");
              window.location.reload();
            })
            .catch((error) => {
              console.log(error);
              notify("Something went wrong!");
            });
        }
      },
    });

    return (
      <Modal showModal={showModal} closeModal={closeModal}>
        <Fragment>
          <Box width={800} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
            <Typography className={classes.heading} variant="h4">
              {showType === "crmState"
                ? "Update CRM"
                : "Update Customer Communication"}
            </Typography>
            <SimpleForm toolbar={null}>
              <div className={classes.formContainer}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  {showType === "crmState" ? (
                    <FormControl
                      label="Crm State"
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="select-state-id">
                        Crm State
                      </InputLabel>
                      <Select
                        native
                        value={values?.crmState}
                        onChange={handleChange}
                        onClick={(e) => e.stopPropagation()}
                        label="Crm State"
                        inputProps={{
                          name: "crmState",
                          id: "select-crm-state-id",
                        }}
                      >
                        {CrmTypeChoices &&
                          CrmTypeChoices.map((choice, index) => (
                            <option key={index} value={choice.id}>
                              {`${choice.name}`}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl
                      component="fieldset"
                      className={classes.communicationRadioContainer}
                    >
                      <FormGroup row>
                        {CommunicationStatusTypeChoices &&
                          CommunicationStatusTypeChoices.map(
                            (communicationType, index) => (
                              <FormControlLabel
                                key={communicationType.id}
                                control={
                                  <Checkbox
                                    style={
                                      index > 2 ? { color: "#ff5d59" } : {}
                                    }
                                    checked={
                                      values.communication &&
                                      values.communication.length &&
                                      values.communication.includes(
                                        communicationType.id
                                      )
                                    }
                                    onChange={handleChange}
                                    name="communication"
                                    value={lowerCase(communicationType.name)}
                                  />
                                }
                                label={capitalize(communicationType.name)}
                              />
                            )
                          )}
                      </FormGroup>
                    </FormControl>
                  )}
                </Box>

                <Box display="flex" mt={2} justifyContent="space-between">
                  <MaterialButton
                    onClick={closeModal}
                    variant="text"
                    color="primary"
                  >
                    {"Close"}
                  </MaterialButton>
                  <MaterialButton
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {"Update"}
                  </MaterialButton>
                </Box>
              </div>
            </SimpleForm>
          </Box>
        </Fragment>
      </Modal>
    );
  };

  const handleAddressCopy = (e, id, type) => {
    e.stopPropagation();
    let address;
    if (type == "pickup") {
      address = document.getElementById("pickup-" + id);
    } else {
      address = document.getElementById("drop-" + id);
    }
    const textArea = document.createElement("textarea");
    textArea.value = address.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  return (
    <>
      <CrmUpdateModal
        showModal={crmModal}
        closeModal={() => setCrmModal(false)}
        currentRecord={currentRecord}
        showType={showType}
      />
      <Breadcrumbs links={breadcrumbLinks} />
      <List
        {...props}
        title="Booking List"
        filters={<BookingFilter classes={classes} />}
        actions={<BookingActions />}
        pagination={<CustomPagination />}
      >
        <Datagrid rowClick="show" rowStyle={requestRowStyle}>
          <FunctionField
            label="Booking Number"
            render={(record) => {
              const d = new Date(record.createdAt);
              return `${record.bookingNumber}
             ${d.getUTCDate() < 10 ? "0" + d.getUTCDate() : d.getUTCDate()}/${
                d.getUTCMonth() + 1 < 10
                  ? "0" + (d.getUTCMonth() + 1)
                  : d.getUTCMonth() + 1
              }/${d.getUTCFullYear()},
              ${
                d.getUTCHours() < 10 ? "0" + d.getUTCHours() : d.getUTCHours()
              }:${
                d.getUTCMinutes() < 10
                  ? "0" + d.getUTCMinutes()
                  : d.getUTCMinutes()
              }
              `;
            }}
          />
          <FunctionField
            label="Travel Date/Time"
            render={(record) => (
              <>
                <div className={classes.startDateTime}>
                  <WatchLaterIcon
                    style={{ fontSize: 18, marginRight: "2px" }}
                  />
                  {moment
                    .tz(
                      record.transfers[0].startDateTime,
                      record.transfers[0].timezone || moment.tz.guess()
                    )
                    .format("DD/MM/YYYY,HH:mm")}
                </div>
                {record.transfers[0].returnDateTime && (
                  <div className={classes.returnDateTime}>
                    <WatchLaterIcon
                      style={{ fontSize: 18, marginRight: "2px" }}
                    />
                    {moment
                      .tz(
                        record.transfers[0].returnDateTime,
                        record.transfers[0].timezone || moment.tz.guess()
                      )
                      .format("DD/MM/YYYY,HH:mm")}
                  </div>
                )}
              </>
            )}
          />
          <FunctionField
            label="Travel Pickup/Drop"
            render={(record) => (
              <>
                <div className={classes.pickupLocation}>
                  <div style={{ display: "flex" }}>
                    <LocationOnIcon style={{ fontSize: 18 }} />
                    <span id={`pickup-${record.id}`}>
                      {record.transfers[0]?.pickupLocation?.formattedAddress ||
                        ""}
                    </span>
                  </div>
                  {/* <CopyIcon style={{ fontSize: 14 }} onClick={(e) => handleAddressCopy(e, record.id, 'pickup')} /> */}
                </div>
                {record.transfers[0].dropLocation && (
                  <div className={classes.dropLocation}>
                    <div style={{ display: "flex" }}>
                      <LocationOnIcon style={{ fontSize: 18 }} />
                      <span id={`drop-${record.id}`}>
                        {record.transfers[0].dropLocation.formattedAddress}
                      </span>
                    </div>
                    {/* <CopyIcon style={{ fontSize: 14 }} onClick={(e) => handleAddressCopy(e, record.id, 'drop')} /> */}
                  </div>
                )}
              </>
            )}
          />
          <TextField label="Distance" source="transfers[0].numberOfKms" />
          <FunctionField
            label="Passengers / BaggageCount"
            render={(record) =>
              `${record.transfers[0].totalAdultSeats},${record.transfers[0].totalChildSeats} / ${record.transfers[0].baggageCount}`
            }
          />
          <FunctionField
            label="Customer Contact"
            render={(record) => (
              <>
                <div>{record?.user?.email}</div>
                <div className={classes.contactNumber}>
                  {record.transfers[0]?.passengerContactNumber ||
                    record.user?.mobileNo ||
                    record.user?.account?.mobileNo ||
                    ""}
                </div>
                {record &&
                  record.transfers &&
                  record.transfers[0].customerComment && <Chat />}
              </>
            )}
          />
          <TextField label="Supplier Offers" source="transfers[0].supplierOfferCount" />
          {/* <TextField label="Email" source="user.email" /> */}
          <FunctionField
            label="Communication With Customer"
            render={(record) =>
              record && record.crm && record.crm.communicatedToCustomer ? (
                <div>
                  {record.crm.communicatedToCustomer.map((communication, i) => (
                    <span
                      style={
                        communication === "voicemail" ||
                        communication === "nowhatsapp" ||
                        communication === "wrongnumber"
                          ? { color: "#ff5d59" }
                          : {}
                      }
                    >
                      {communication}
                      {i === record.crm.communicatedToCustomer.length - 1
                        ? ""
                        : ", "}
                    </span>
                  ))}
                  <span>
                    <IconButton
                      aria-label="edit"
                      onClick={(e) =>
                        handleModalEdit(e, "communicationWithCustomer", record)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </div>
              ) : (
                <div>
                  <IconButton
                    aria-label="edit"
                    onClick={(e) =>
                      handleModalEdit(e, "communicationWithCustomer", record)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              )
            }
          />
          <FunctionField
            label="Crm State"
            render={(record) => {
              if (record && record.crm && record.crm.currentState) {
                return (
                  <div>
                    {CrmTypeChoices &&
                      CrmTypeChoices.filter(
                        (choice) => choice.id == record.crm.currentState
                      )[0]?.name}
                    <span>
                      <IconButton
                        aria-label="edit"
                        onClick={(e) => handleModalEdit(e, "crmState", record)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </div>
                );
              }
            }}
          />
          <FunctionField
            label="Prospector"
            render={(record) => {
              const bookingHistories = record.bookingHistories ?? {}; 
              return (
                <div>
                  <div>
                    {`${bookingHistories?.user?.account && Object.keys(bookingHistories?.user?.account).length > 0 ? `${bookingHistories?.user?.account?.firstName } ${bookingHistories?.user?.account?.lastName}` :  'N/A'}`}
                  </div>
                  <br />
                  <div>
                    {`${bookingHistories?.activityState ?? ""}`.toUpperCase()}
                  </div>
                  <br />
                  <div>
                    {bookingHistories.updatedAt
                      ? moment
                          .tz(bookingHistories.updatedAt, moment.tz.guess())
                          .format("DD/MM/YYYY, HH:mm")
                      : ""}
                  </div>
                </div>
              );
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default BookingList;

import React from 'react'
import { BooleanInput, DateInput, Edit, NumberInput, SimpleForm, TextInput, ReferenceInput, SelectInput, ArrayInput, SimpleFormIterator } from 'react-admin'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const VendorTitle = ({ record }) => {
  return (
    <span>
      Vendor: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}
export const VendorEdit = props => {
  const [record, setRecord] = React.useState({});
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
    {
      name: `${record.id}`,
      url: `/vendors/${record.id}/show`,
    },
    {
      name: 'Edit',
      url: `/vendors/${record.id}`,
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<VendorTitle />} {...props}>
        <SimpleForm>
          <RecordFetcher setRecord={setRecord} />
          <TextInput disabled source="id" />
          {/* <TextInput source="firstName" /> */}
          {/* <TextInput source="lastName" /> */}
          <TextInput source="businessEmail" />
          <TextInput source="mobileNo" />
          <TextInput source="businessType" />
          {/* <TextInput source="serviceType" /> */}
          {/* <TextInput source="language" /> */}
          {/* <TextInput source="description" /> */}
          {/* <TextInput source="companyName" /> */}
          {/* <TextInput source="website" /> */}
          {/* <TextInput source="registrationAddress" /> */}
          {/* <TextInput source="registrationNo" /> */}
          {/* <TextInput source="vatNo" /> */}
          {/* <TextInput source="taxId" /> */}
          {/* <TextInput source="termAndConditions" /> */}
          <BooleanInput source="verificationState" />
          {/* <TextInput source="iban" /> */}
          {/* <TextInput source="paypalId" /> */}
          {/* <TextInput source="paypalEmail" /> */}
          {/* <TextInput source="accountNumber" /> */}
          {/* <TextInput source="ifsc" /> */}
          {/* <TextInput source="swift" /> */}
          {/* <TextInput source="accountHolderName" /> */}
          {/* <TextInput source="accountHolderNameIndia" /> */}
          {/* <TextInput source="bankName" /> */}
          {/* <TextInput source="region" /> */}
          {/* <TextInput source="organizationRegistrationDocument" /> */}
          {/* <TextInput source="passengerLicense" /> */}
          {/* <TextInput source="passengerInsurance" /> */}
          <BooleanInput source="organizationRegistrationDocumentVerified" />
          <BooleanInput source="passengerLicenseVerified" />
          <BooleanInput source="passengerInsuranceVerified" />
          {/* <DateInput source="createdAt" /> */}
          {/* <DateInput source="updatedAt" />           */}
        </SimpleForm>
      </Edit>
    </>
  )
}

export default VendorEdit;
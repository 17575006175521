import React from 'react';
import PropTypes from 'prop-types'
import { TopToolbar as RaTopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(theme=>({
  root: {
    
  }
}));

const TopToolbar = ({className, ...props}) => {
  const classes = useStyles();
  return <RaTopToolbar {...props} className={classnames(className, classes.root)} />
}

TopToolbar.propTypes = {

}

TopToolbar.defaultProps = {

}

export default TopToolbar;

import React from 'react'
import { 
  BooleanField, NumberField, Show, 
  SimpleShowLayout, TextField, DateField, 
  SingleFieldList, ChipField, SelectField, 
  ArrayField, ReferenceField, Tab, TabbedShowLayout,
  Datagrid, ReferenceManyField, EmailField
} from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import SimpleArrayField from 'Components/Common/Fields/SimpleArrayField'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import FullNameField from 'Components/Common/Fields/FullNameField';
import DocumentOrImageFileField from 'Components/Common/Fields/DocumentOrImageFileField';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: theme.typography.h6.fontSize,
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const VendorTitle = ({ record }) => {
  return (
    <span>
      Vendor: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

// const BankDetails = (props) => {

//   const { record = {}, ...rest } = props || {};

//   return (
//     <>
//       <TextField source="region" {...props} />
//       
//       {
//         record.region === 'EU' ? (
//           <>
//             <TextField source="accountHolderName" {...props} />
//             <TextField source="iban" {...props} />
//             <TextField source="swift" {...props} />            
//           </>
//         ) : (
//           <>
{/*            <TextField source="bankName" {...props} /> */}
//             <TextField source="accountHolderNameIndia" {...props} />
//             <TextField source="accountNumber" {...props} />
//             <TextField source="ifsc" {...props} />
//           </>
//         )
//       }
//     </>
//   )
// }

export const VendorShow = ({ history, ...props }) => {
  const [record, setRecord] = React.useState({});
  const classes = useStyles();
  let breadcrumbLinks;
  if( history.location.state && history.location.state.prevResource == 'transferOfferList' ) {
    breadcrumbLinks = [
      {
        name: 'Home',
        url: '/',
      },
      {
        name: `Transfer Offers`,
        url: `/transferOffers`,
      },
      {
        name: 'Vendors',
        url: '/vendors',
      },
      {
        name: `${record.id}`,
        url: `/vendors/${record.id}/show`,
      },
   ];
    
  } 
  else {
    breadcrumbLinks = [
      {
        name: 'Home',
        url: '/',
      },
      {
        name: 'Vendors',
        url: '/vendors',
      },
      {
        name: `${record.id}`,
        url: `/vendors/${record.id}/show`,
      },
    ];
  }

  const handleRowClick = (id, basePath, record) => {
    // console.log(id, basePath, record);
    history.push(`${basePath}/${id}/show`)
  }

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show {...props}>
        
        <TabbedShowLayout>
          <Tab label="Profile">
            <RecordFetcher setRecord={setRecord} />        
            <TextField source="id" />
            <FullNameField />
            <TextField label="E-mail" source="user.email" />
            {/* <TextField source="businessEmail" /> */}
            <TextField source="mobileNo" />

            <TextField source="businessType" />
            <SimpleArrayField source="serviceType" />
                        
            <TextField source="language" />
            <TextField source="description" />
            
            <TextField source="termAndConditions" />
            <TextField source="verificationState" />

            <DocumentOrImageFileField source="organizationRegistrationDocument" />
            <DocumentOrImageFileField source="passengerLicense" />
            <DocumentOrImageFileField source="passengerInsurance" />

            <BooleanField source="organizationRegistrationDocumentVerified" />
            <BooleanField source="passengerLicenseVerified" />
            <BooleanField source="passengerInsuranceVerified" />
               
            <LongDateField source="createdAt" />
            {/* <LongDateField source="updatedAt" /> */}

            {/* <TextField source="vendorLocations" /> */}
            {/* <ArrayField source="vendorLocations">
              <SingleFieldList>
                <ChipField source="id" />
              </SingleFieldList>
            </ArrayField> */}
            

            {/* <ReferenceField source="organizationId" reference="organizations">
              <TextField source="companyName" />
            </ReferenceField> */}

            <ReferenceField link="show" source="user.id" reference="users">
              <TextField source="email" />
            </ReferenceField>      

          </Tab>
          
          <Tab label="Company Info" path="company-info">
            <RecordFetcher setRecord={setRecord} />
            <TextField source="companyName" />
            <TextField source="website" />
            <TextField source="registrationAddress" />
            <TextField source="registrationNo" />
            <TextField source="vatNo" />
            <TextField source="taxId" />
          </Tab>

          <Tab label="Payment Info" path="payment-info">
            <RecordFetcher setRecord={setRecord} />
            
            {/* Paypal Details */}
            <div className={classnames(classes.bold, classes.header)}>Paypal Details</div>
            <TextField source="paypalId" />
            <TextField source="paypalEmail" />

            {/* Bank Details */}

            <div className={classnames(classes.bold, classes.header)}>Bank Details</div>

            <div className={classes.bold}>India</div>
            {/* <TextField source="region" /> */}
            <TextField source="bankName" />
            <TextField source="accountHolderNameIndia" />
            <TextField source="accountNumber" />
            <TextField source="ifsc" />

            <div className={classes.bold}>Europe</div>
            <TextField source="accountHolderName" />
            <TextField source="iban" />
            <TextField source="swift" />

          </Tab>

          <Tab label="Drivers" path="drivers">
            <RecordFetcher setRecord={setRecord} />
            {/* <CreateBookingStateButton /> */}
            <ReferenceManyField reference="drivers" target="vendorId" addLabel={false} sort={{ field: 'createdAt', order: 'DESC' }}>
              <Datagrid rowClick={handleRowClick}>
                <FullNameField />
                <TextField source="phone" />
                <EmailField source="email" />
                <TextField source="drivingLicense" />
                <SimpleArrayField source="languageProficiency" max={5}/>
                {/* <TextField source="drivingLicenseDocuments" /> */}
                <BooleanField source="documentVerified" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                {/* <ReferenceField source="vendorId" reference="vendors"><TextField source="id" /></ReferenceField> */}
              </Datagrid>
            </ReferenceManyField>
          </Tab>

          <Tab label="Vehicles" path="vehicles">
            <RecordFetcher setRecord={setRecord} />
            {/* <CreateBookingStateButton /> */}
            <ReferenceManyField reference="vehicles" target="vendorId" addLabel={false} sort={{ field: 'createdAt', order: 'DESC' }}>
              <Datagrid rowClick={handleRowClick}>
                {/* <TextField source="id" /> */}
                {/* <TextField source="brand" /> */}
                {/* <TextField source="vehicleModel" /> */}
                <TextField label="Brand (deprecated)" source="brand" />
                <TextField label="Model (deprecated)" source="vehicleModel" />
                <ReferenceField link="show" source="vehicleModelId" reference="vehicleModels"><TextField source="model" /></ReferenceField>
                <DateField source="yearOfManufacture" />
                <NumberField source="maxPax" />
                <NumberField source="maxBaggage" />
                <TextField source="color" />
                <TextField source="licensePlate" />
                <TextField source="category" />
                <TextField source="type" />
                <DateField source="createdAt" />
                {/* <DateField source="updatedAt" /> */}
                {/* <ReferenceField source="vendorId" reference="vendors"><TextField source="id" /></ReferenceField> */}
                <ReferenceField source="vehicleTypeId" reference="vehicleTypes"><TextField source="name" /></ReferenceField>
              </Datagrid>
            </ReferenceManyField>
          </Tab>

          <Tab label="Locations" path="locations">
            <RecordFetcher setRecord={setRecord} />
            {/* <CreateBookingStateButton /> */}
            <ReferenceManyField reference="vendorLocations" target="vendorId" addLabel={false} sort={{ field: 'createdAt', order: 'DESC' }}>
              <Datagrid>
                {/* <TextField source="id" /> */}
                <TextField source="city" />
                <TextField source="country" />
                <DateField source="createdAt" />
                {/* <DateField source="updatedAt" /> */}
                {/* <ReferenceField source="vendorId" reference="vendors"><TextField source="id" /></ReferenceField> */}
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          
        </TabbedShowLayout>
      </Show>
    </>
  )
}

export default VendorShow;
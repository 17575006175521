import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import PlaceModal from "./PlaceModal";

const CreatePlace = ({
  refreshList,
  sightseeings,
  nearByPlaces,
  places,
  citySlug,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant="contained"
        color="primary"
      >
        Add Places
      </Button>
      <PlaceModal
        nearByPlaces={nearByPlaces}
        sightseeings={sightseeings}
        places={places}
        refreshList={refreshList}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        citySlug={citySlug}
      />
    </Fragment>
  );
};

export default CreatePlace;

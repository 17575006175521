import React from 'react';
import { SelectField, ChipField } from 'react-admin';

export const ChipSelectField = (props) => {

  const { record, source, choices, } = props || {};
  let choice = record[source];
  let value = choices.filter(ch => ch.id == choice );
  value = value.length && value[0];
  value = value && value.name;
  // console.log(value);

  record[source] = value;

  return <ChipField record={record} {...props} />
};

ChipSelectField.defaultProps = {
    addLabel: true,
};

export default ChipSelectField;
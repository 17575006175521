import React from "react";
import { Admin, Resource } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import OrganizationIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import PublicIcon from "@material-ui/icons/Public";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import AccessibleIcon from "@material-ui/icons/Accessible";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import PaymentIcon from "@material-ui/icons/Payment";
import AccessTime from "@material-ui/icons/AccessTime";
import AccountBalanceWalletSharp from "@material-ui/icons/AccountBalanceWalletSharp";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LocalTaxiIcon from "@material-ui/icons/LocalTaxi";
import LocalOffer from "@material-ui/icons/LocalOffer";
import InsertDriveFileRoundedIcon from "@material-ui/icons/InsertDriveFileRounded";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { FaHandshake } from "react-icons/fa";
import Dashboard from "Components/Dashboard";
import AuthProvider from "Providers/Auth";
import DataProvider from "Providers/Data";
import LoginPage from "Layout/LoginPage";
import LogoutButton from "Layout/LogoutButton";
import Routes from "Routes";
import AppTheme from "AppTheme";

import Organizations from "Resources/Organizations";
import Deals from "Resources/Deals";
import Transfers from "Resources/Transfers";
import DmcBookings from "Resources/DmcBookings";
import Vouchers from "Resources/Vouchers";
import VehicleTypes from "Resources/VehicleTypes";
import Locations from "Resources/Locations";
import Users from "Resources/Users";
import Accounts from "Resources/Accounts";
import Auction from "Resources/Auction";
import DealOffers from "Resources/DealOffers";
import TransferOffers from "Resources/TransferOffers";
import DealStates from "Resources/DealStates";
import Bookings from "Resources/Bookings";
import BookingRequests from "Resources/BookingRequests";
import BookingStates from "Resources/BookingStates";
import Orders from "Resources/Orders";
import OrderStates from "Resources/OrderStates";
import Payments from "Resources/Payments";
import PaymentStates from "Resources/PaymentStates";
import Pricings from "Resources/Pricings";
import CreditProfiles from "Resources/CreditProfiles";
import Vendors from "Resources/Vendors";
import Drivers from "Resources/Drivers";
import Vehicles from "Resources/Vehicles";
import VendorLocations from "Resources/VendorLocations";
import VehicleBrands from "Resources/VehicleBrands";
import VehicleModels from "Resources/VehicleModels";
import Templates from "Resources/Templates";
import OperatingRegions from "Resources/OperatingAreas/OperatingRegion";
import OperatingCountries from "Resources/OperatingAreas/OperatingCountry";
import OperatingCities from "Resources/OperatingAreas/OperatingCity";
import Sightseeing from "Resources/OperatingAreas/Sightseeing";
import Tickets from "Resources/Tickets";
import Surges from "Resources/Surges";
import Cities from "Resources/Cities";
import UpcomingBookings from "Resources/UpcomingBookings";
import EmailTemplates from "Resources/EmailTemplates";
import BookingHistory from "Resources/BookingHistory";

// const dataProvider = jsonServerProvider('http://jsonplaceholder.typicode.com');
export const App = () => (
  <>
    <Admin
      initialState={{ admin: { ui: { sidebarOpen: false } } }}
      theme={AppTheme}
      loginPage={LoginPage}
      logoutButton={LogoutButton}
      dataProvider={DataProvider}
      dashboard={Dashboard}
      authProvider={AuthProvider}
      customRoutes={Routes}
    >
      <Resource
        name="bookingRequests"
        {...BookingRequests}
        icon={ListAltIcon}
      />
      <Resource
        name="upcomingBookings"
        options={{ label: "UpcomingBookings" }}
        {...UpcomingBookings}
        icon={AccessTime}
      />

      <Resource
        name="emailTemplates"
        options={{ label: "EmailTemplates" }}
        {...EmailTemplates}
        icon={ListAltIcon}
      />
      <Resource name="bookings" {...Bookings} icon={SpeakerNotesIcon} />

      <Resource name="auction" {...Auction} icon={AccountBalanceWalletSharp} />

      <Resource name="bookingHistory" {...BookingHistory} />

      <Resource name="tickets" {...Tickets} icon={AssignmentIndIcon} />
      <Resource name="vendors" {...Vendors} icon={SupervisedUserCircleIcon} />
      <Resource name="pricings" {...Pricings} icon={EuroSymbolIcon} />

      <Resource name="bookingStates" {...BookingStates} />

      <Resource name="payments" {...Payments} />

      <Resource name="paymentStates" {...PaymentStates} />

      <Resource name="orders" {...Orders} />
      <Resource name="orderStates" {...OrderStates} />

      {/* <Resource
      name="deals"
      options={{ label: 'Deals' }}
      {...Deals}
      icon={() => <FaHandshake size={24} />}
    /> */}
      <Resource
        name="dealOffers"
        options={{ label: "Deal Offers" }}
        {...DealOffers}
        icon={() => <FaHandshake size={24} />}
      />
      <Resource name="transferOffers" {...TransferOffers} icon={LocalOffer} />
      <Resource
        name="dealStates"
        options={{ label: "Deal States" }}
        {...DealStates}
        icon={() => <FaHandshake size={24} />}
      />
      <Resource name="transfers" {...Transfers} />
      {/* <Resource
      name="dmcBooking"
      options={{ label: 'DMC Bookings' }}
      icon={EmojiTransportationIcon}
      {...DmcBookings}
    /> */}
      <Resource name="vouchers" options={{ label: "Vouchers" }} {...Vouchers} />
      <Resource name="vehicleTypes" {...VehicleTypes} />
      <Resource name="locations" {...Locations} />
      <Resource
        name="organizations"
        options={{ label: "Organizations" }}
        {...Organizations}
        icon={OrganizationIcon}
      />
      <Resource name="users" {...Users} icon={GroupIcon} />
      <Resource name="accounts" {...Accounts} icon={AccountBoxIcon} />
      <Resource
        name="creditProfiles"
        options={{ label: "Credit Profiles" }}
        {...CreditProfiles}
      />

      {/* <Divider/> */}

      <Resource name="drivers" {...Drivers} icon={AccessibleIcon} />
      <Resource name="vehicles" {...Vehicles} icon={DirectionsCarIcon} />
      <Resource
        name="vendorLocations"
        {...VendorLocations}
        icon={PersonPinCircleIcon}
      />
      <Resource
        name="vehicleBrands"
        options={{ label: "Vehicle Brands" }}
        {...VehicleBrands}
        icon={AirportShuttleIcon}
      />
      <Resource
        name="vehicleModels"
        options={{ label: "Vehicle Models" }}
        {...VehicleModels}
        icon={LocalTaxiIcon}
      />

      {/**      
        <Resource
          name="templates"
          options={{ label: "Templates" }}
          {...Templates}
          icon={InsertDriveFileRoundedIcon}
        />
      **/}

      <Resource
        name="operating-region"
        options={{ label: "Operating Regions" }}
        {...OperatingRegions}
        icon={LocationOnIcon}
      />

      <Resource
        name="operating-country"
        options={{ label: "Operating Countries" }}
        {...OperatingCountries}
        icon={PublicIcon}
      />

      <Resource
        name="operating-city"
        options={{ label: "Operating Cities" }}
        {...OperatingCities}
        icon={LocationCityIcon}
      />

      <Resource
        name="sightseeing"
        options={{ label: "Sightseeings" }}
        {...Sightseeing}
        icon={EmojiTransportationIcon}
      />

      <Resource
        name="surges"
        options={{ label: "Surges" }}
        {...Surges}
        icon={LocationCityIcon}
      />

      <Resource
        name="cities"
        options={{ label: "Cities" }}
        {...Cities}
        icon={LocationCityIcon}
      />
    </Admin>
  </>
);

export default App;

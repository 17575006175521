import React from "react";
import {
  FunctionField,
  DateInput,
  AutocompleteInput,
  ArrayField,
  SingleFieldList,
  ReferenceInput,
  SelectInput,
  Datagrid,
  Filter,
  TextField,
  TextInput,
  ChipField,
  ExportButton,
  SelectArrayInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import moment from "moment-timezone";
import {
  BookingStatusChoices,
  BookingTypeChoices,
  BookingStatuses,
  AuctionStatuses,
} from "Constants/Bookings";
import {TransferOfferStatusChoices} from "Constants/TransferOffers";
import { PaymentStateChoices } from "Constants/Payments";
import Button from "Components/Common/RaOverrides/Button";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import ResetFiltersActionButton from "Components/Common/ResetFiltersActionButton";
import List from "Components/Common/RaOverrides/List";
import { useEffect } from "react";
import { useState } from "react";
import { API_HOSTNAME as apiUrl } from "Constants";
import CustomPagination from "Components/Common/CustomPagination";
import CheckCircle from "@material-ui/icons/CheckCircle";

const BookingTitle = ({ record }) => {
  return <span>Booking: {record ? `${record.name}` : ""}</span>;
};

async function countriesFetch() {
  return await fetch(`${apiUrl}/countryList?country=true`)
    .then((response) => response.json())
    .then((data) => data);
}

async function fetchMarketingAttributes() {
  return await fetch(`${apiUrl}/marketingAttributes?campaign=true`)
    .then((response) => response.json())
    .then((data) => data);
}

const BookingFilter = (props) => {
  // console.log("Booking Filter Props", props);
  const [countryChoices, setCountryChoices] = useState([]);
  const [campaignChoices, setCampaignChoices] = useState([]);

  useEffect(() => {
    countriesFetch().then(async (response) => {
      response.data.rows = await response.data.rows.map((countryObj) => {
        return {
          id: countryObj.country.toUpperCase(),
          name: countryObj.country.toUpperCase(),
        };
      });
      setCountryChoices([...response.data.rows]);
    });
    fetchMarketingAttributes().then(async (response) => {
      response.data.rows = await response.data.rows.map((marketingObj) => {
        return {
          id: marketingObj.campaign,
          name: marketingObj.campaign.toUpperCase(),
        };
      });
      setCampaignChoices([...response.data.rows]);
    });
  }, []);
  return (
    <div>
      <Filter {...props}>
        {/* <TextInput label="Search Name" source="q" alwaysOn /> */}
        <ReferenceInput
          label="Booking Number"
          source="bookingId"
          reference="bookings"
          alwaysOn
        >
          <AutocompleteInput optionText="bookingNumber" />
        </ReferenceInput>
        <TextInput label="Search Email, City..." source="q" alwaysOn />
        <TextInput label="Vendor Email" source="vendorEmail" alwaysOn />
        <SelectInput
          label="Vendor Offer Status"
          source="vendorOfferState"
          choices={TransferOfferStatusChoices.filter(
            (status) => !(status.name == "EXPIRED" || status.name == "PENDING")
          )}
          alwaysOn
          emptyText="All"
        />

        <SelectInput
          label="Country"
          source="country"
          choices={countryChoices}
          alwaysOn
          emptyText="All"
        />

        <SelectInput
          label="Booking Status"
          source="bookingState"
          choices={BookingStatusChoices.filter(
            (status) => !(status.name == "EXPIRED" || status.name == "PENDING")
          )}
          alwaysOn
          emptyText="All"
        />
        <SelectInput
          label="Payment Status"
          source="paymentState"
          choices={PaymentStateChoices}
          alwaysOn
          emptyText="All"
        />
        <ReferenceInput
          label="Organization"
          source="organizationId"
          reference="organizations"
          alwaysOn
        >
          <AutocompleteInput optionText="companyName" />
        </ReferenceInput>
        {/* <SelectInput
          label="Transfer Type"
          source="transferType"
          choices={BookingTypeChoices}
          alwaysOn
          emptyText="All"
        /> */}
        <DateInput
          source="from"
          label="Start Time"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />
        <DateInput
          source="to"
          label="End Time"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />
        <DateInput
          source="createdAtStartTime"
          label="CreatetAt Start"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />
        <DateInput
          source="createdAtEndTime"
          label="CreatedAt End"
          alwaysOn
          options={{
            mode: "portrait",
            locale: "pl",
          }}
        />
        <SelectArrayInput
          alwaysOn
          allowEmpty
          label="Campaign"
          source="campaign"
          className={props.classes.campaignFormControl}
          choices={campaignChoices}
        />
      </Filter>
    </div>
  );
};

const BookingActions = (props) => {
  // console.log("Booking Actions Props", props);
  const { className, ...rest } = props || {};
  return (
    <div style={{ display: "flex", alignSelf: "center" }}>
      <ResetFiltersActionButton
        {...rest}
        defaultObj={{
          bookingId: null,
          organizationId: null,
          bookingState: null,
          paymentState: null,
        }}
      />
      <Button customComponent={ExportButton} label="Export" {...rest} />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    campaignFormControl: {
      minWidth: "10em",
    },
  };
});

const BookingList = (props) => {
  const classes = useStyles();
  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Bookings",
      url: "/bookings",
    },
  ];

  const bookingRowStyle = (record, index) => {
    if (
      record.currentState == BookingStatuses.CANCELLED &&
      record?.crm?.currentState == "11"
    ) {
      return {
        background:
          "linear-gradient(90deg, #ff6161 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 90%, #FFBF00 100%)",
      };
    } else if (
      parseInt(record?.transfers[0]?.reservationAmountCents) > 0 &&
      record?.crm?.currentState == "11"
    ) {
      return {
        background:
          "linear-gradient(90deg, #ee33d7 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 90%, #FFBF00 100%)",
      };
    } else if (record.currentState == BookingStatuses.CANCELLED) {
      return {
        background: "linear-gradient(to right, #ff6161, white 5%)",
      };
    } else if (parseInt(record?.transfers[0]?.reservationAmountCents) > 0) {
      return {
        background: "linear-gradient(to right, #ee33d7, white 5%)",
      };
    } else if (record?.crm?.currentState == "11") {
      return {
        background: "linear-gradient(to right, #FFBF00, white 5%)",
      };
    } else {
      return {
        background: "white",
      };
    }
  };

  console.log(props);

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />

      <List
        {...props}
        title="Booking List"
        filters={<BookingFilter classes={classes} />}
        actions={<BookingActions />}
        pagination={<CustomPagination />}
      >
        <Datagrid rowClick="show" rowStyle={bookingRowStyle}>
          {/* <FunctionField  label="Booking Number" render={record => `${record.bookingNumber}
        CreatedAt ${(new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: '2-digit',day: '2-digit'}).format( new Date( record.createdAt ) ) )}` } />
          <DateField label = "Start Date Time" showTime source="transfers[0].startDateTime" />
          <DateField label = "Return Date Time" showTime source="transfers[0].returnDateTime" /> */}
          <FunctionField
            label="Booking Number"
            render={(record) => {
              const d = new Date(record.createdAt);
              console.log(record);
              return (
                <>
                  <div>{record.bookingNumber}</div>
                  <div>
                    {`${
                      d.getUTCDate() < 10
                        ? "0" + d.getUTCDate()
                        : d.getUTCDate()
                    }/${
                      d.getUTCMonth() + 1 < 10
                        ? "0" + (d.getUTCMonth() + 1)
                        : d.getUTCMonth() + 1
                    }/${d.getUTCFullYear()},
                    ${
                      d.getUTCHours() < 10
                        ? "0" + d.getUTCHours()
                        : d.getUTCHours()
                    }:${
                      d.getUTCMinutes() < 10
                        ? "0" + d.getUTCMinutes()
                        : d.getUTCMinutes()
                    }
                  `}
                  </div>
                </>
              );
            }}
          />
          {/* <DateField label = "Start Date Time" parse={dateParser} showTime source="transfers[0].startDateTime" /> */}
          <FunctionField
            label="Start Date Time"
            render={(record) => {
              if (
                record.transfers.length > 0 &&
                record.transfers[0].startDateTime
              ) {
                return moment
                  .tz(
                    record.transfers[0].startDateTime,
                    record.transfers[0].timezone || moment.tz.guess()
                  )
                  .format("DD/MM/YYYY, HH:mm");
              } else return "N/A";
            }}
          />
          <FunctionField
            label="Return Date Time"
            render={(record) => {
              if (
                record.transfers.length > 0 &&
                record.transfers[0].returnDateTime
              ) {
                return moment
                  .tz(
                    record.transfers[0].returnDateTime,
                    record.transfers[0].timezone || moment.tz.guess()
                  )
                  .format("DD/MM/YYYY, HH:mm");
              } else return "N/A";
            }}
          />
          <TextField
            label="Pickup Location"
            source="transfers[0].pickupLocation.formattedAddress"
          />
          <TextField
            label="Drop Location"
            source="transfers[0].dropLocation.formattedAddress"
          />
          <FunctionField
            label="Passengers / BaggageCount"
            render={(record) =>
              record && record.transfers && record.transfers[0]
                ? `${record.transfers[0].totalAdultSeats},${record.transfers[0].totalChildSeats} / ${record.transfers[0].baggageCount}`
                : null
            }
          />
          <ArrayField label="vehicleTypes" source="transfers[0].vehicleTypes">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField label="Booking Type" source="bookingType" />
          <TextField label="Offered By" source="offeredBy" />
          <TextField label="Booking By" source="user.email" />
          {/* <FunctionField label="Offered By" render = { record => record.transfers && record.transfers[0] &&  record.transfers[0].vendorId  == 'c0987b66-15be-48fb-aa7f-8b22236abbd0' ? 'Rydeu' : 'Supplier' } /> */}
          <FunctionField
            label="Driver Assigned"
            render={(record) =>
              record.transfers &&
              record.transfers[0] &&
              record.transfers[0].forwardTripDriverDetails && Object.keys(record.transfers[0].forwardTripDriverDetails).length > 0 && (
                <CheckCircle style={{ fill: "green" }} />
              )
            }
          />
          <FunctionField
            label="In Auction"
            render={(record) =>
              record?.auctions?.length > 0 && (
                <>
                  {record?.auctions[0].currentState ===
                  AuctionStatuses.COMPLETED ? (
                    <CheckCircle style={{ fill: "green" }} />
                  ) : (
                    <CheckCircle style={{ fill: "gray" }} />
                  )}
                </>
              )
            }
          />
        </Datagrid>
      </List>
    </>
  );
};

export default BookingList;

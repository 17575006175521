import React, { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

import Editor from "../../../../../../../Components/Common/Editor/Editor";
import DefaultTemplates from "./DefaultTemplates";

const TemplatesOptions = [
  {
    id: 1,
    label: "About Intercity Route",
    value: "about",
  },
];

const PlatformOptions = [
  {
    id: 1,
    label: "Web",
    value: "web",
  },
  {
    id: 2,
    label: "Mobile",
    value: "mobile",
  },
];

const Templates = (props) => {
  const notify = useNotify();
  const [template, setTemplate] = useState(JSON.stringify(TemplatesOptions[0]));
  const [templateData, setTemplateData] = useState("");
  const [platform, setPlatform] = useState(PlatformOptions[0].value);
  const dataProvider = useDataProvider();
  const [
    {
      intercityRouteDetails,
      intercityRouteDetailsLoading,
      intercityRouteDetailsError,
    },
    setIntercityRouteDetails,
  ] = useState({
    intercityRouteDetails: null,
    intercityRouteDetailsLoading: true,
    intercityRouteDetailsError: "",
  });

  const updateTemplate = (
    currentTemplateType = template,
    platformType = platform
  ) => {
    let selectedTemplate = JSON.parse(currentTemplateType);
    if (
      intercityRouteDetails &&
      intercityRouteDetails[selectedTemplate.value] &&
      intercityRouteDetails[selectedTemplate.value][platformType]
    ) {
      setTemplateData(
        intercityRouteDetails[selectedTemplate.value][platformType]
      );
    } else {
      setTemplateData(
        DefaultTemplates.intercityRouteDetails[selectedTemplate.value][
          platformType
        ]
      );
    }
  };

  useEffect(() => {
    const clearTimer = setTimeout(() => {
      updateTemplate();
    }, 3000);
    return () => {
      clearTimeout(clearTimer);
    };
  }, [intercityRouteDetails]);

  const getIntercityRouteDetails = () => {
    dataProvider
      .getOne(`operating-city/${props.citySlug}/intercity-routes`, {
        id: props.intercityRouteSlug,
      })
      .then((res) => {
        setIntercityRouteDetails({
          intercityRouteDetails: res.data,
          intercityRouteDetailsLoading: false,
          intercityRouteDetailsError: "",
        });
      })
      .catch((err) => {
        setIntercityRouteDetails({
          intercityRouteDetails: null,
          intercityRouteDetailsLoading: false,
          intercityRouteDetailsError: err.message,
        });
      });
  };

  const saveIntercityRouteTemplate = () => {
    let selectedTemplate = JSON.parse(template),
      data = {
        [selectedTemplate.value]: {
          [platform]: templateData,
        },
        sourceCity: intercityRouteDetails.sourceCity,
        destinationCity: intercityRouteDetails.destinationCity,
        sourceCountry: intercityRouteDetails.sourceCountry,
        destinationCountry: intercityRouteDetails.destinationCountry,
        city: intercityRouteDetails.city.id,
        duration: intercityRouteDetails.duration,
        distance: intercityRouteDetails.distance,
        lowestPrice: intercityRouteDetails.lowestPrice,
        isPricingActive: intercityRouteDetails.isPricingActive
      };

    if (typeof intercityRouteDetails[selectedTemplate.value] !== "string") {
      data[selectedTemplate.value] = {
        ...intercityRouteDetails[selectedTemplate.value],
        ...data[selectedTemplate.value],
      };
    }

    dataProvider
      .update(`operating-city/${props.citySlug}/intercity-routes`, {
        id: props.intercityRouteSlug,
        data,
      })
      .then((res) => {
        notify("Template Saved Successfully");
        getIntercityRouteDetails();
      })
      .catch((err) => {
        notify(`Error: ${err.message}`);
      });
  };

  useEffect(() => {
    getIntercityRouteDetails();
  }, []);

  const handleTemplateType = (e) => {
    setTemplate(e.target.value);
    updateTemplate(e.target.value, platform);
  };

  const handlePlatformType = (e) => {
    setPlatform(e.target.value);
    updateTemplate(template, e.target.value);
  };

  const handleResetTemplate = () => {
    updateTemplate(template, platform);
  };

  if (intercityRouteDetailsLoading) {
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  if (
    intercityRouteDetailsError &&
    !intercityRouteDetailsLoading &&
    !intercityRouteDetails
  ) {
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6">{intercityRouteDetailsError}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box
        mt={3}
        mb={3}
        width="100%"
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" width="50%" justifyContent="space-between">
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Template Type
            </InputLabel>
            <Select
              native={true}
              value={template}
              onChange={handleTemplateType}
              label="Template Type"
              inputProps={{
                name: "template",
                id: "outlined-age-native-simple",
              }}
            >
              {TemplatesOptions.map((item) => (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Platform Type
            </InputLabel>
            <Select
              native={true}
              value={platform}
              onChange={handlePlatformType}
              label="Platform Type"
              inputProps={{
                name: "platform",
                id: "outlined-age-native-simple",
              }}
            >
              {PlatformOptions.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width="30%" display="flex" justifyContent="space-between">
          <Button
            onClick={() => handleResetTemplate()}
            variant="contained"
            color="default"
          >
            Reset Template
          </Button>
          <Button
            onClick={() => saveIntercityRouteTemplate()}
            variant="contained"
            color="primary"
          >
            Save Template
          </Button>
        </Box>
      </Box>
      <Box width={platform === "mobile" ? 500 : 1024}>
        <Editor
          config={{
            height: "70vh",
            width: "100%",
          }}
          onChange={(e) => setTemplateData(e.editor.getData())}
          data={templateData}
        />
      </Box>
    </Box>
  );
};

export default Templates;

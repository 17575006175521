import React, { useState, useEffect } from "react";
import { useDataProvider, useRedirect } from "react-admin";
import queryString from "query-string";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import Templates from "./Tabs/Templates/Templates";
import Pricings from "./Tabs/Pricings/List";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const IntercityRouteDetails = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [intercityRouteDetails, setIntercityRouteDetails] = useState({});
  let tabId = 0;
  const handleChange = (event, newValue) => {
    redirect(`${props.location.pathname}?tid=${newValue}`);
  };

  if (props.location.search) {
    let params = queryString.parse(props.location.search);
    tabId = Number(params.tid);
  }

  const getInterCityRouteDetails = () => {
    dataProvider
      .getOne(
        `operating-city/${props.match.params.citySlug}/intercity-routes`,
        { id: props.match.params.intercityRouteSlug }
      )
      .then((res) => {
        setIntercityRouteDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInterCityRouteDetails();
  }, []);

  return (
    <Box>
      <Box mt={2} mb={3} display="flex" justifyContent="space-between">
        <Typography variant="h4">
          {props.match.params.intercityRouteSlug.toUpperCase()}
        </Typography>
      </Box>
      <Paper className={classes.root}>
        <Tabs
          value={tabId}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Templates" />
          <Tab label="Pricings" />
        </Tabs>
      </Paper>
      {tabId === 0 && (
        <Templates
          intercityRouteSlug={props.match.params.intercityRouteSlug}
          citySlug={props.match.params.citySlug}
        />
      )}
      {tabId === 1 && (
        <Pricings
          intercityRouteDetails={intercityRouteDetails}
          intercityRouteSlug={props.match.params.intercityRouteSlug}
          citySlug={props.match.params.citySlug}
        />
      )}
    </Box>
  );
};

export default IntercityRouteDetails;

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { NavLink } from "react-router-dom";
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  link: {
    root: {
      padding: theme.spacing(1),
    },
  }
}))

function CustomBreadcrumbs(props) {
  const classes = useStyles();
  return (
    <div>
      <Breadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {
          props.links.map((item, index, arr) => (            
            <MuiLink classes={{ root: classes.link.root }} key={item.name} color={(index+1 === arr.length) ? "primary":"inherit"} to={item.url} component={NavLink}>
              {item.name}
            </MuiLink>
          ))
        }
      </Breadcrumbs>      
    </div>
  )
}

CustomBreadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired
}

export default CustomBreadcrumbs


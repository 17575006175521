import React from 'react'
import { 
  BooleanField, NumberField, Show, 
  SimpleShowLayout, TextField, ReferenceField, 
  ArrayField, Datagrid, EmailField,
  EditButton,
} from 'react-admin'
import LongDateField from '../../Components/Common/Fields/LongDateField'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import CustomUrlField from 'Components/Common/Fields/CustomUrlField';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';
import Button from 'Components/Common/RaOverrides/Button';
import { withRouter } from "react-router";

const VoucherTitle = ({ record }) => {
  return (
    <span>
      Voucher: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

const VoucherShowActions = withRouter(({ basePath, data, resource, ...rest }) => {
  // console.log("BookingShowActions", basePath, resource, rest);
  return (
    <TopToolbar>
      <Button variant="contained" customComponent={EditButton} basePath={basePath} record={data} />
    </TopToolbar>
  )
});

export const VoucherShow = props => {
  const [record, setRecord] = React.useState({});
  const [bookingRecord, setBookingRecord] = React.useState({});

  const { bookingId } = props.record || {};
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Bookings',
      url: '/bookings',
    },
    {
      name: `${bookingRecord.bookingNumber}`,
      url: `/bookings/${bookingRecord.id}/show`,
    },
    {
      name: 'Vouchers',
      url: `/bookings/${bookingRecord.id}/show/vouchers`,
    },
    {
      name: `${record.id}`,
      url: `/vouchers/${record.id}/show`,
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show actions={<VoucherShowActions />} {...props}>
        <SimpleShowLayout>
          <RecordFetcher setRecord={setRecord} />
          <ReferenceField link="show" source="bookingId" reference="bookings">
            {/* <TextField source="bookingNumber" /> */}
            <RecordFetcher setRecord={setBookingRecord}>
              <TextField source="bookingNumber" />
            </RecordFetcher>
          </ReferenceField>
          <TextField source="id" />
          <TextField source="driverName" />
          <TextField source="driverContactNumber" />
          <TextField source="receiverName" />
          <TextField source="receiverEmail" />
          <TextField source="receiverPhoneNumber" />
          <TextField source="supportName" />
          <TextField source="supportPhoneNumber" />
          <TextField source="createdBy" />
          <CustomUrlField displayName="Pdf Link" source="voucherUrl" />
          <TextField source="voucherNumber" />
          <LongDateField source="createdAt" />
          {/* <DateField source="updatedAt" /> */}
          {/* <TextField source="deletedAt" /> */}
          <ReferenceField source="bookingId" reference="bookings">
            <TextField source="id" />
          </ReferenceField>
          <ReferenceField source="dmcBookingId" reference="dmcBookings">
            <TextField source="id" />
          </ReferenceField>

          <ArrayField source="voucherDetails">
            <Datagrid><TextField source="orderNumber" />
              {/* <ReferenceField source="bookingId" reference="bookings">
                <TextField source="id" />
              </ReferenceField> */}
              <TextField source="supportName" />
              <TextField source="supportPhoneNumber" />
              <TextField source="pickupAddress" />
              <TextField source="dropAddress" />
              <TextField source="vehicleInfo" />
              <NumberField source="noOfBaggage" />
              <NumberField source="noOfPax" />
              <EmailField source="email" />
              <TextField source="flightNo" />
              <TextField source="customerName" />
              <LongDateField source="travelDateTime" />
              <TextField source="distance" />
              <NumberField source="day" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Show>
    </>
  )
}

export default VoucherShow;
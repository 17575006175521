import React from 'react'
import { BooleanField, NumberField, Show, SimpleShowLayout, TextField, ReferenceField, ReferenceManyField, SingleFieldList, ChipField } from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import RecordFetcher from 'Components/Common/RecordFetcher';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import Breadcrumbs from 'Components/Common/Breadcrumbs';

const OrganizationTitle = ({ record }) => {
  return (
    <span>
      Organization: {record ? `${record.companyName}` : ''}
    </span>
  )
}

export const OrganizationShow = props => {
  
  const [record, setRecord] = React.useState({});

  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Organizations',
      url: '/organizations',
    },
    {
      name: `${record.companyName}`,
      url: `/organizations/${record.id}/show`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show {...props}>
        <SimpleShowLayout>
          <RecordFetcher setRecord={setRecord} />
          {/* <TextField source="id" /> */}
          <TextField source="companyName" />
          {/* <TextField source="firstName" /> */}
          {/* <TextField source="lastName" /> */}
          <TextField source="domainName" />
          <TextField source="contractStartDate" />
          <TextField source="contractEndDate" />
          <LongDateField source="registrationNo" />
          <TextField source="vatNo" />
          {/* <ReferenceField source="taxId" reference="taxes"><TextField source="id" /></ReferenceField> */}
          <TextField source="termAndConditions" />
          <TextField source="active" />
          <TextField source="blocked" />
          <BooleanField source="allowInvoicing" />
          <NumberField source="discountPercentage" />
          <TextField source="email" />
          <TextField source="mobileNo" />
          <TextField source="gstnNo" />
          <TextField source="country" />
          <TextField source="currency" />
          <TextField source="createdAt" />
          {/* <TextField source="updatedAt" /> */}
          <ReferenceManyField label={'Credit Profile'} reference="creditProfiles" target="organizationId">
            {/* <RecordFetcher setRecord={() => {}}> */}
              <SingleFieldList linkType={'show'}>
                {/* <TextField record={{ linkText: "Credit Profile"}} source="linkText"/> */}
                <ChipField source="id" />
              </SingleFieldList>
            {/* </RecordFetcher> */}
          </ReferenceManyField>
        </SimpleShowLayout>
      </Show>
    </>
  )
}

export default OrganizationShow;
import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  Checkbox
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from "yup";
import { useFormik } from "formik";
import { SimpleForm, useDataProvider, useNotify, useRedirect } from "react-admin";
import Modal from "../Components/Common/Modal/Modal";
import keyBy from 'lodash/keyBy'
import { useQuery, Datagrid, TextField as ReactTextField, Pagination, Loading, ListContextProvider,
  FunctionField, BooleanField, DateField
} from 'react-admin';
import { CrmTypeChoices } from "Constants/Crm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OfferSchema = Yup.object().shape({
  crmState: Yup.string().trim(),
});

const CrmModal = ({
  showModal,
  closeModal,
  refreshList,
  bookingRecord
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue
  } = useFormik({
    enableReinitialize:true,
    validationSchema: OfferSchema,
    initialValues: {
      crmState: bookingRecord?.crm?.currentState,
    },
    onSubmit: (data) => {
    // update crm here
    const crmData = {
        currentState: data.crmState
    }

    if( bookingRecord?.crm?.id ) {
        dataProvider
        .update(`crm`, { id: bookingRecord.crm.id, data: removeNullVals(crmData) })
        .then((res) => {
            notify("Crm updated successfully! ");
            // closeModal();
            redirectTo(`/bookingRequests`);
        })
        .catch((error) => {
            console.log(error);
            notify("Something went wrong!");
        });
    }
    },
  });

  const removeNullVals = ( obj => {
    return Object.keys(obj)
      .filter((k) => obj[k] != "")
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  });
  
  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
          <Box width={800} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
                <Typography className={classes.heading} variant="h4">Update CRM</Typography>
                <SimpleForm toolbar={null}>
                   <div className={classes.formContainer}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                            <div className={classes.fieldContainer}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="select-state-id">Crm State</InputLabel>
                                    <Select
                                        native
                                        value={values?.crmState}
                                        onChange={handleChange}
                                        label="Crm State"
                                        inputProps={{
                                            name: 'crmState',
                                            id: 'select-crm-state-id',
                                        }}
                                    >
                                    { CrmTypeChoices.map( ( choice, index ) => (
                                        <option key={index} value={choice.id}>
                                        {`${choice.name}`}
                                        </option>
                                    ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Box>
                        
                        <Box display="flex" mt={2} justifyContent="space-between">
                            <Button onClick={closeModal} variant="text" color="primary">
                            {"Close"}
                            </Button>
                            <Button onClick={handleSubmit} type="submit" variant="contained" color="primary">
                            {"Add State"}
                            </Button>
                        </Box>
                    </div>
                </SimpleForm>
          </Box>
      </Fragment>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    heading: {
      textAlign: "center",
      letterSpacing: '1.5px',
      fontWeight: "600"
    },
    radioGroup: {
      justifyContent:"space-around"
    },
    errorMessage: {
      marginTop: 10,
      fontSize: 14,
      color: "#8B0000"
    },
    radioContainer: {
      display: "flex",
      flexDirection: "column",
      width:"100%",
      marginTop: '1.5rem'
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "column",
      width:"45%",
      marginTop: '1.5rem',
      justifyContent: "center"
    },
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width:"100%"
    },
    inputContainer: {
      // width: "45%",
      width:"95%",
      margin: spacing(2, 0),
    },
    checkIcon: {
        color: "green",
        fontSize: 32
    },
    closeIcon: {
        color: "#8b0000",
        fontSize: 32
    },
    suggestionsContainer: {
        zIndex: 2100,
    },
    autoInput: {
        width: "47%"
    },
    subHeading: {
      letterSpacing: '1.5px',
      marginTop: '2rem',
      fontWeight:"500",
      marginBottom: '0.5rem',
      // color: '#949494'
    },
    fieldColumn: {
      display: "flex",
      flexDirection: "column",
      width: '50%',
      marginTop: '1.5rem'
    },
    offerContainer: {
      display:"flex",
      flexDirection:"column",
    },
    offerContainerActive: {
      display:"flex",
      flexDirection:"column",
      marginTop: "-1.4rem"
    },
    ancillaryContainerActive: {
      marginTop: "-1.2rem"
    },
    ancillaryContainer: {
      marginTop: "0.1rem"
    },
    infoContainer: {
      background: "#E8F2FC",
      padding: "1rem",
      display:"flex",
    },
    infoHead: {
      width: "60%",
      fontWeight: "600",
      fontSize: "14px",
      textAlign:"left",
      letterSpacing: "1.5px"
    },
    infoValue: {
      width: "40%",
      textAlign:"center",
      color: "#004A85",
      fontWeight: "600"
    },
    infoTotalValue: {
      width: "40%",
      textAlign:"center",
      color: "#33B30B",
      fontWeight: "600"
    },
    priceContainer: {
      display: "flex",
      flexDirection: "column",
      width:"50%",
      marginTop: '1.5rem',
      justifyContent: "center",
      borderRight: "1px solid #d4d4d5"
    }
  };
});

export default CrmModal;

import { objToNameIdArray } from "Util/Constants";

export const PaymentModes = {
  CARD: "card",
  CASH: "cash",
  INVOICE: "invoice",
  WALLET: "wallet",
  NETBANKING: "netbanking",
};

export const AdminPaymentModes = {
  CASH: "cash",
  PAYPAL: "paypal",
  STRIPE_INVOICE: "stripe_invoice",
  BANK_TRANSFER: "bank_transfer",
};

export const PaymentStates = {
  PENDING: "pending",
  SUCCESS: "succeeded",
  FAILED: "failed",
};

export const PaymentMethods = {
  PREPAID: "prepaid",
  POSTPAID: "postpaid",
  INVOICE: "invoice",
};

export const BookingPaymentType = {
  NORMAL_BOOKING: "normal_booking",
  RESERVE_AND_PAY: "reserve_and_pay",
  RESERVE_AND_PAY_CUSTOM: "reserve_and_pay_custom",
};

export const Currencies = {
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
};

export const PaymentModeChoices = objToNameIdArray(PaymentModes);
export const PaymentStateChoices = objToNameIdArray(PaymentStates);
export const PaymentMethodChoices = objToNameIdArray(PaymentMethods);
export const AdminPaymentModesChoices = objToNameIdArray(AdminPaymentModes);
export const BookingPaymentTypeChoices = objToNameIdArray(BookingPaymentType);
export const CurrenciesChoices = objToNameIdArray(Currencies);
export default {
  PaymentModes,
  PaymentModeChoices,
  PaymentStates,
  PaymentStateChoices,
  PaymentMethods,
  PaymentMethodChoices,
  BookingPaymentTypeChoices,
  BookingPaymentType,
  CurrenciesChoices,
};

import React from 'react';
import PropTypes from 'prop-types';

const RecordFetcher = ({children, ...props}) => {
  React.useEffect(() => {
    if(props.record) props.setRecord(props.record);
  })

  // React.useEffect(() => {
    // console.log("Record Fetcher", typeof children);
    // console.log("Record Fetcher", children);
  // })
  // return null;
  if(children instanceof Object ) {
    if(children instanceof Array) {
      return children.map(child => React.cloneElement(child, {...props}));
    }
    return React.cloneElement(children, {...props})
  }
  return null;
}

RecordFetcher.propTypes = {
  setRecord: PropTypes.func.isRequired,
}

RecordFetcher.defaultProps = {

}

export default RecordFetcher;
import React from 'react'
import { 
  BooleanInput, Create, DateInput, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator } from 'react-admin'
const DealOfferTitle = ({ record }) => {
  return (
    <span>
      Create New DealOffer
    </span>
  )
}

export const DealOfferCreate = props => (
  <div>Feature Unavailable</div>
)

export default DealOfferCreate;
import React from 'react';

const styles = {
  display: 'inline-block',
  textAlign: 'right',
  width: '100%',
}

export const CurrencyField = ({ record = {}, source, showCurrencySymbol, textAlign }) => <span style={textAlign === 'right' ? styles : {}}>{showCurrencySymbol && record.currency && record.currency.toString().toUpperCase()} {record[source]/100}</span>;

CurrencyField.defaultProps = {
    addLabel: true,
    showCurrencySymbol: true,
    textAlign: 'left',
};

export default CurrencyField;
import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import orange from "@material-ui/core/colors/orange";
import lightBlue from "@material-ui/core/colors/lightBlue";

// const ColorPrimaryAlpha = (alpha = 1.0) => `rgba(248, 134, 52, ${alpha <= 1.0 && alpha >= 0.0 ? alpha : 1.0 })`;
// const ColorPrimaryLightAlpha = (alpha = 1.0) => `rgba(250, 168, 86, ${alpha <= 1.0 && alpha >= 0.0 ? alpha : 1.0 })`;
// const ColorPrimaryAccentAlpha = (alpha = 1.0) => `rgba(254, 95, 77, ${alpha <= 1.0 && alpha >= 0.0 ? alpha : 1.0 })`;

// const ColorPrimary = '#f88634';
// const ColorPrimaryLight = '#faa856';
// const ColorPrimaryAccent = '#fe604e';

const ColorPrimaryAlpha = (alpha = 1.0) =>
  `rgba(248, 134, 52, ${alpha <= 1.0 && alpha >= 0.0 ? alpha : 1.0})`;
const ColorPrimaryLightAlpha = (alpha = 1.0) =>
  `rgba(250, 168, 86, ${alpha <= 1.0 && alpha >= 0.0 ? alpha : 1.0})`;
const ColorPrimaryAccentAlpha = (alpha = 1.0) =>
  `rgba(254, 95, 77, ${alpha <= 1.0 && alpha >= 0.0 ? alpha : 1.0})`;

const ColorPrimary = "#f88634";
const ColorPrimaryLight = "#faa856";
const ColorPrimaryAccent = "#fe604e";
const mainColor = {
  ...lightBlue,
  main: lightBlue[900],
};
const AppTheme = createMuiTheme({
  palette: {
    primary: mainColor, //{ main: ColorPrimaryAccent },
    secondary: mainColor, //{ main: ColorPrimaryAccent },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 12,
  },
  overrides: {
    MuiAppBar: {
      root: {
        color: "white",
      },
      colorDefault: {},
      colorPrimary: {},
      colorSecondary: {},
    },
    MuiDrawer: {
      // paper: {
      root: {
        background: mainColor[100], //ColorPrimaryLightAlpha(0.2),
        color: mainColor[900], //'rgba(0,0,0,0.9)',
        borderRight: "3px solid",
        minHeight: "100vh",
        marginRight: "10px",

        RaMenuItemLink: {
          root: {
            color: "white",
          },
        },
      },
      // },
    },
    MuiFilledInput: {
      root: {
        background: "transparent !important",
        transition: "none",
      },
    },
    MuiButton: {
      //   root: {
      //     color: Color,
      //   },
      //   textPrimary: {
      //     color: Color,
      //   },
      // containedPrimary: {
      //   // color: Color,
      //   '&:hover': {
      //     border: `1px solid ${mainColor[900]}`,
      //     backgroundColor: 'transparent',
      //     color: mainColor[900],
      //   },
      // },
    },
  },
});

export default AppTheme;

import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  FunctionField,
  TextField,
  NumberField,
} from "react-admin";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentFnsUtils from "@date-io/moment";
import moment from "moment-timezone";
import { TransferTypes, TransferTypeChoices } from "Constants/Transfers";
import { BookingStatuses } from "Constants/Bookings";
import LocationInput from "Components/Common/Fields/LocationInput";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { useForm } from "react-final-form";

const google = window.google;

const useStyles = makeStyles((theme) =>
  createStyles({
    distanceContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      marginBottom: 15,
      marginTop: 10,
    },
    distanceLabel: {
      fontSize: 12,
      color: "#767676",
    },
    distanceValue: {
      marginTop: 10,
      fontSize: 16,
    },
  })
);

const TransferTitle = ({ record }) => {
  return <span>Transfer: {record ? `${record.id}` : ""}</span>;
};

const TransferEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const DistanceShow = ({ value }) => {
  const classes = useStyles();
  const form = useForm();
  form.change("numerOfKmsUpdated", value);
  return (
    <div className={classes.distanceContainer}>
      <label className={classes.distanceLabel}>Distance (number of kms)</label>
      <div className={classes.distanceValue}>{value || "---"}</div>
    </div>
  );
};

const validateDistance = (coordinates) => {
  return new Promise((resolve, reject) => {
    if (window.google) {
      const matrix = new google.maps.DistanceMatrixService();
      matrix.getDistanceMatrix(
        {
          origins: [
            new google.maps.LatLng(
              coordinates.startLocationLatLng.lat,
              coordinates.startLocationLatLng.lng
            ),
          ],
          destinations: [
            new google.maps.LatLng(
              coordinates.endLocationLatLng.lat,
              coordinates.endLocationLatLng.lng
            ),
          ],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          let estimatedMeters = 0;
          let estimatedKms = 0;
          if (status === "OK") {
            if (response.rows[0].elements[0].distance) {
              estimatedMeters = response.rows[0].elements[0].distance.value;
              estimatedKms = response.rows[0].elements[0].distance.text;
            } else estimatedMeters = 9999999999999;
          }
          resolve({ estimatedMeters, estimatedKms });
        }
      );
    }
  });
};

const DatePicker = ({ source, value, timezone, label }) => {
  moment.tz.setDefault(timezone);
  const [selectedDate, setSelectedDate] = useState(value);
  const form = useForm();

  const handleDateChange = (date) => {
    form.change(source, date);
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentFnsUtils}>
      <DateTimePicker
        style={{ marginLeft: 5, marginBottom: 5, width: "250px" }}
        format="MMM D, YYYY, hh:mm A"
        label={label}
        inputVariant="standard"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export const TransferEdit = (props) => {
  const [travelDistance, setTravelDistance] = useState(null);

  return (
    <Edit title={<TransferTitle />} {...props}>
      <SimpleForm toolbar={<TransferEditToolbar />} redirect="show">
        {/* <TextInput source="title" /> */}
        {/* <DateTimeInput disabled={true} source="startDateTime" />
        <DateTimeInput disabled={true} source="returnDateTime" /> */}
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <DatePicker
                label="Start Date Time"
                source="startDateTime"
                value={formData.startDateTime}
                timezone={formData.timezone}
              />
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData.returnDateTime && (
                <DatePicker
                  label="Return Date Time"
                  source="returnDateTime"
                  value={formData.returnDateTime}
                  timezone={formData.timezone}
                />
              )
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData?.booking?.currentState === BookingStatuses.CONFIRMED)
              return (
                <LocationInput
                  label="Pickup Location"
                  source="pickupLocation.formattedAddress"
                  variant="outlined"
                />
              );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (
              formData &&
              formData.booking &&
              formData.booking.currentState === BookingStatuses.CONFIRMED &&
              formData.transferType != TransferTypes.HOURLY
            )
              return (
                <LocationInput
                  source="dropLocation.formattedAddress"
                  label="Drop Location"
                  variant="outlined"
                />
              );
          }}
        </FormDataConsumer>
        <SelectInput
          label="Transfer Type"
          source="transferType"
          choices={TransferTypeChoices}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.pickupLocation && formData.dropLocation) {
              const pickupLatLng = {
                lat:
                  formData.pickupLocation.formattedAddress_lat ||
                  formData.pickupLocation.lat,
                lng:
                  formData.pickupLocation.formattedAddress_lng ||
                  formData.pickupLocation.lng,
              };
              const dropLatLng = {
                lat:
                  formData.dropLocation.formattedAddress_lat ||
                  formData.dropLocation.lat,
                lng:
                  formData.dropLocation.formattedAddress_lng ||
                  formData.dropLocation.lng,
              };
              validateDistance({
                startLocationLatLng: pickupLatLng,
                endLocationLatLng: dropLatLng,
              }).then((res) => {
                setTravelDistance(res.estimatedKms);
              });
            } else {
              setTravelDistance(null);
            }

            return <DistanceShow value={travelDistance} />;
          }}
        </FormDataConsumer>
        <TextInput label="Passenger Name" source="passengerName" />
        <TextInput label="Passenger Contact" source="passengerContactNumber" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.transferType === TransferTypes.AIRPORT)
              return <TextInput label="Flight Number" source="flightNumber" />;
          }}
        </FormDataConsumer>
        <NumberInput
          label="Duration (number of hours)"
          source="numberOfHours"
        />
        <NumberInput source="totalAdultSeats" />
        <NumberInput source="totalChildSeats" />
        <NumberInput source="baggageCount" />
        <TextInput label="Customer Comment" source="customerComment" />
        {/* <TextInput label="Actual Amount (Cost Price)" source="actualAmountCents" format={v => v/100} parse={v => v*100} />
      <TextInput label="Target Amount" source="targetAmountCents" format={v => v/100} parse={v => v*100} />
      <TextInput label="Offered Amount (Selling Price)" source="offeredAmountCents" format={v => v/100} parse={v => v*100} />
      <TextInput source="additionalRequest" />
      <TextInput source="termAndConditions" />
      <TextInput source="city" />
      <TextInput source="country" />
      <TextInput source="currency" />      
      <NumberInput source="childSeats" />
      <NumberInput source="adultSeats" />
      <NumberInput source="sequenceNo" />
      <TextInput source="travellerName" />
      <TextInput source="travellerPhoneNumber" />
      <TextInput source="travellerEmail" />
      <TextInput source="serviceType" />
      <NumberInput source="totalhours" />
      <TextInput source="type" /> */}
      </SimpleForm>
    </Edit>
  );
};

export default TransferEdit;

import React from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField,
  ReferenceField,
} from "react-admin";

import { parse } from 'query-string';
import { PaymentModeChoices, PaymentStateChoices } from 'Constants/Payments';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const today = new Date();

const PaymentEdit = props => {
    
    const [record, setRecord] = React.useState({});
    const [bookingRecord, setBookingRecord] = React.useState({});

    const { bookingId } = props.record || {};
    const redirect = bookingId ? `/bookings/${bookingId}/show/payments` : false;

    const breadcrumbLinks = [
      {
        name: 'Home',
        url: '/',
      },
      {
        name: 'Bookings',
        url: '/bookings',
      },
      {
        name: `${bookingRecord.bookingNumber}`,
        url: `/bookings/${bookingRecord.id}/show`,
      },
      {
        name: 'Payments',
        url: `/bookings/${bookingRecord.id}/show/orders`,
      },
      {
        name: `${record.id}`,
        url: `/payments/${record.id}/show`,
      },
      {
        name: `Edit`,
        url: `/payments/${record.id}`,
      },
    ]

    return (
      <>
        <Breadcrumbs links={breadcrumbLinks} />
        <Edit {...props} title={`Edit Payment`}>
          <SimpleForm
            // defaultValue={{ bookingId }}
            redirect={redirect}
          >
            <RecordFetcher setRecord={setRecord} />
            <ReferenceField addLabel={false} link="show" source="bookingId" reference="bookings">
              <RecordFetcher setRecord={setBookingRecord} />  
            </ReferenceField>

            {/* <ReferenceInput source="chargeId" reference="charges"><SelectInput optionText="id" /></ReferenceInput> */}
            <NumberInput label="Amount" source="amountCents" format={v => v/100} parse={v => v*100} />
            <TextInput source="currency" />
            {/* <TextInput source="receiptNumber" /> */}
            {/* <TextInput source="receiptEmail" /> */}
            {/* <TextInput source="receiptUrl" /> */}
            <TextInput source="description" />
            <SelectInput source="paymentMode" choices={PaymentModeChoices}/>
            <SelectInput source="currentState" choices={PaymentStateChoices}/>

            <ReferenceInput disabled source="bookingId" reference="bookings"><SelectInput optionText="bookingNumber" /></ReferenceInput>
            <ReferenceInput disabled source="organizationId" reference="organizations"><SelectInput optionText="companyName" /></ReferenceInput>
            {/* <ReferenceInput source="userId" reference="users"><SelectInput optionText="email" /></ReferenceInput> */}

        </SimpleForm>
        </Edit>
      </>
    );
}

export default PaymentEdit;

import React, { useState, useEffect } from "react";
import CommentRounded from '@material-ui/icons/CommentRounded';
import { useLogout } from "react-admin";
import moment from "moment-timezone";
import { parse } from "query-string";
import httpClient from '../../Providers/Data/HttpClient';
import Breadcrumbs from "Components/Common/Breadcrumbs";
import {
    Box,
    Button,
    Paper,
    TableContainer,
    TablePagination,
    InputLabel,
    NativeSelect,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    CircularProgress
} from "@material-ui/core";



const BookingHistory = (props) => {

    const { bookingId, bookingNumber, referer} = parse(props.location.search);


    const activity = {
        PROSPECTION: 'prospection',
        PROCUREMENT: 'procurement',
        TRIPCOORDINATOR: 'tripCoordinator'
    };

    const activityState = {
        PENDING: "pending",
        INPROGRESS: 'inProgress',
        COMPLETED: 'completed'
    };


    const logout = useLogout();
    const [{ data, loading, error }, setResponse] = useState({ data: null, loading: true, error: null });
    const [adminUsers, setAdminUsers] = useState([]);

    const [prospectionActivity, setProspection] = useState({ activityState: '', activityType: activity.PROSPECTION, updatedAt: '', userId: '' })
    const [procurementActivity, setProcurement] = useState({ activityState: '', activityType: activity.PROCUREMENT, updatedAt: '', userId: '' })
    const [tripCoordinationActivity, setTripCoordination] = useState({ activityState: '', activityType: activity.TRIPCOORDINATOR, updatedAt: '', userId: '' })

    const[prospectionToggle, setProspectionToggle] = useState(false);
    const[procurementToggle, setProcurementToggle] = useState(false);
    const[tripCoordinationToggle, setTripCoordinationToggle] = useState(false);

    const getAdminUsers = async () => {
        try {
            const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/users?filter={"userType":"6"}&limit=${1000}&offset=${0}`
            const response = await httpClient(url).then((res) => JSON.parse(res.body));
            setAdminUsers(response.data.rows);
        } catch (err) {
            setResponse({ data: null, loading: false, error: err.message });
        }
    }


    const getList = async () => {
        try {
            const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/bookingHistory/${bookingId}`;

            const response = await httpClient(url).then((res) => JSON.parse(res.body));

            const history = response.data
            setProspection(history.filter((element) => element.activityType === activity.PROSPECTION)[0] ?? { activityState: '', activityType: activity.PROSPECTION, updatedAt: null, userId: '' });
            setProcurement(history.filter((element) => element.activityType === activity.PROCUREMENT)[0] ?? { activityState: '', activityType: activity.PROCUREMENT, updatedAt: null, userId: '' });
            setTripCoordination(history.filter((element) => element.activityType === activity.TRIPCOORDINATOR)[0] ?? { activityState: '', activityType: activity.TRIPCOORDINATOR, updatedAt: null, userId: '' })
            
            setResponse({
                data: response.data,
                loading: false,
                error: null
            });

        }
        catch (err) {
            if (err.message === "Invalid token") {
                logout();
            }
            else {
                setResponse({ data: null, loading: false, error: err.message });
            }
        }
    }

    const prospectionChangeHandler = (event) => {
        const id = event.target.id;
        const val = event.target.value;

        if(id === 'prospection'){
            setProspection({
                ...prospectionActivity,
                userId: val
            })
        }
        else if(id === 'prospectionState'){
            setProspection({
                ...prospectionActivity,
                activityState: val
            })
        }
    }

    const submitProspection = async() => {
        try{
            const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/bookingHistory/${bookingId}`;
            const payload = {
                userId: prospectionActivity.userId,
                activity: activity.PROSPECTION,
                state: prospectionActivity.activityState !== "" ?  prospectionActivity.activityState : activityState.PENDING,
            }

            setProspectionToggle(true);
            const response = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((res) => JSON.parse(res.body));

            await getList();
            setProspectionToggle(false)

        }catch(error){
            console.log(error);
        }
    }


    const procurementChangeHandler = (event) => {
        const id = event.target.id;
        const val = event.target.value;

        if(id === 'procurement'){
            setProcurement({
                ...procurementActivity,
                userId: val
            })
        }
        else if(id === 'procurementState'){
            setProcurement({
                ...procurementActivity,
                activityState: val
            })
        }
    }

    const submitProcurement = async() => {
        try{
            const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/bookingHistory/${bookingId}`;
            const payload = {
                userId: procurementActivity.userId,
                activity: activity.PROCUREMENT,
                state: procurementActivity.activityState !== "" ?  procurementActivity.activityState : activityState.PENDING,
            }
    
            setProcurementToggle(true);
            const response = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((res) => JSON.parse(res.body));
    
            await getList();
            setProcurementToggle(false)
    
        }catch(error){
            console.log(error);
        }
    }

    const tripCoordinationChangeHandler = (event) => {
        const id = event.target.id;
        const val = event.target.value;

        if(id === 'tripCoordination'){
            setTripCoordination({
                ...tripCoordinationActivity,
                userId: val
            })
        }
        else if(id === 'tripCoordinationState'){
            setTripCoordination({
                ...tripCoordinationActivity,
                activityState: val
            })
        }
    }

    const submitTripCoordination = async() => {
        try{
            const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/bookingHistory/${bookingId}`;
            const payload = {
                userId: tripCoordinationActivity.userId,
                activity: activity.TRIPCOORDINATOR,
                state: tripCoordinationActivity.activityState !== "" ?  tripCoordinationActivity.activityState : activityState.PENDING,
            }
    
            setTripCoordinationToggle(true);
            const response = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((res) => JSON.parse(res.body));
    
            await getList();
            setTripCoordinationToggle(false)
    
        }catch(error){
            console.log(error);
        }
    }




    useEffect(() => {
        getList();
        getAdminUsers();
    }, []);

    const breadcrumbLinks = [
        {
          name: "Home",
          url: "/",
        },
        {
          name: referer === 'bookings'? 'Bookings' : 'Booking Requests',
          url: `/${referer}`,
        },
        {
          name: `${bookingNumber}`,
          url: `/${referer}/${bookingId}/show`,
        },
        {
          name: "Booking History",
          url: `/${referer}/${bookingId}/show/bookingHistory`,
        },
        {
          name: `Create`,
          url: `${props.location.pathname}${props.location.search}`,
        },
      ];



    return (
        
        <>
        <Breadcrumbs links={breadcrumbLinks} />

        <br></br>
        
        <Box>
            {loading && (
                <Box
                    display="flex"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress color="primary" size={50} />
                </Box>
            )}
            {error && (
                <Box
                    display="flex"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="h6">{error}</Typography>
                </Box>
            )}
            {data && !error && !loading && (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Activity</TableCell>
                                    <TableCell>Activity Owner</TableCell>
                                    <TableCell>Activity State</TableCell>
                                    <TableCell>Last Updated</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>


                                <TableRow>
                                    <TableCell>
                                        {(prospectionActivity.activityType).toUpperCase()}
                                    </TableCell>

                                    <TableCell>

                                        <InputLabel variant="standard" htmlFor="prospection">Owner</InputLabel>
                                        <NativeSelect inputProps={{ id: 'prospection'}} onChange={prospectionChangeHandler}>
                                            <option value="" >Select</option>
                                            {
                                                adminUsers.length > 0 && adminUsers.map(admin => {
                                                    return <option value={admin.id} selected={prospectionActivity.userId === admin.id}> {admin?.account?.firstName} {admin?.account?.lastName} - {admin.email}</option>
                                                })
                                            }

                                        </NativeSelect>
                                    </TableCell>
                                    <TableCell>
                                        <InputLabel variant="standard" htmlFor="prospectionState">State</InputLabel>
                                        <NativeSelect inputProps={{ id: 'prospectionState' }} onChange={prospectionChangeHandler}>
                                            <option value={activityState.PENDING} selected={prospectionActivity.activityState === activityState.PENDING}>
                                                {(activityState.PENDING).toUpperCase()}
                                            </option>
                                            <option value={activityState.INPROGRESS} selected={prospectionActivity.activityState === activityState.INPROGRESS}>
                                                {(activityState.INPROGRESS).toUpperCase()}
                                            </option>
                                            <option value={activityState.COMPLETED} selected={prospectionActivity.activityState === activityState.COMPLETED}>
                                                {(activityState.COMPLETED).toUpperCase()}
                                            </option>
                                        </NativeSelect>

                                    </TableCell>
                                    <TableCell>
                                        {prospectionActivity.updatedAt ?? 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" onClick={submitProspection} disabled={prospectionToggle} >Submit</Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        {(procurementActivity.activityType).toUpperCase()}
                                    </TableCell>

                                    <TableCell>

                                        <InputLabel variant="standard" htmlFor="procurement">Owner</InputLabel>
                                        <NativeSelect inputProps={{ id: 'procurement' }} onChange={procurementChangeHandler}>
                                            <option value="" >Select</option>
                                            {
                                                adminUsers.length > 0 && adminUsers.map(admin => {
                                                    return <option value={admin.id} selected={procurementActivity.userId === admin.id}>{admin?.account?.firstName} {admin?.account?.lastName} - {admin.email}</option>
                                                })
                                            }

                                        </NativeSelect>
                                    </TableCell>
                                    <TableCell>
                                        <InputLabel variant="standard" htmlFor="procurementState">State</InputLabel>
                                        <NativeSelect inputProps={{ id: 'procurementState' }} onChange={procurementChangeHandler}>
                                            <option value={activityState.PENDING} selected={procurementActivity.activityState === activityState.PENDING}>
                                                {(activityState.PENDING).toUpperCase()}
                                            </option>
                                            <option value={activityState.INPROGRESS} selected={procurementActivity.activityState === activityState.INPROGRESS}>
                                                {(activityState.INPROGRESS).toUpperCase()}
                                            </option>
                                            <option value={activityState.COMPLETED} selected={procurementActivity.activityState === activityState.COMPLETED}>
                                                {(activityState.COMPLETED).toUpperCase()}
                                            </option>
                                        </NativeSelect>

                                    </TableCell>
                                    <TableCell>
                                        {procurementActivity.updatedAt ?? 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" onClick={submitProcurement} disabled={procurementToggle} >Submit</Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        {(tripCoordinationActivity.activityType).toUpperCase()}
                                    </TableCell>

                                    <TableCell>

                                        <InputLabel variant="standard" htmlFor="tripCoordination">Owner</InputLabel>
                                        <NativeSelect inputProps={{ id: 'tripCoordination' }} onChange={tripCoordinationChangeHandler}>
                                            <option value="" >Select</option>
                                            {
                                                adminUsers.length > 0 && adminUsers.map(admin => {
                                                    return <option value={admin.id} selected={tripCoordinationActivity.userId === admin.id}>{admin?.account?.firstName} {admin?.account?.lastName} - {admin.email}</option>
                                                })
                                            }

                                        </NativeSelect>
                                    </TableCell>
                                    <TableCell>
                                        <InputLabel variant="standard" htmlFor="tripCoordinationState">State</InputLabel>
                                        <NativeSelect inputProps={{ id: 'tripCoordinationState' }} onChange={tripCoordinationChangeHandler}>
                                            <option value={activityState.PENDING} selected={tripCoordinationActivity.activityState === activityState.PENDING}>
                                                {(activityState.PENDING).toUpperCase()}
                                            </option>
                                            <option value={activityState.INPROGRESS} selected={tripCoordinationActivity.activityState === activityState.INPROGRESS}>
                                                {(activityState.INPROGRESS).toUpperCase()}
                                            </option>
                                            <option value={activityState.COMPLETED} selected={tripCoordinationActivity.activityState === activityState.COMPLETED}>
                                                {(activityState.COMPLETED).toUpperCase()}
                                            </option>
                                        </NativeSelect>

                                    </TableCell>
                                    <TableCell>
                                        {tripCoordinationActivity.updatedAt ?? 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" onClick={submitTripCoordination} disabled={tripCoordinationToggle} >Submit</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
        </>
    );
};

export default BookingHistory;

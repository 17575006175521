import { objToNameIdArray } from 'Util/Constants';

export const ServiceTypes = {
  TRANSPORTATION: 'transportation',
  GUIDE: 'guide',
  RESTAURANT: 'restaurant',
  FLORIST: 'florist',
  SIGHTSEEING: 'sightseeing',
  DMC: 'dmc'
};

export const BusinessTypes = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
};

export const ServiceTypeChoices = objToNameIdArray(ServiceTypes);
export const BusinessTypeChoices = objToNameIdArray(BusinessTypes);

export default {
  ServiceTypes,
  ServiceTypeChoices,
  BusinessTypes,
  BusinessTypeChoices,
}
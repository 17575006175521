export default {
  intercityRouteDetails: {
    about: {
      web: `<div style="margin-left:auto; margin-right:auto">
      <div style="color:#484848; font-family:Lato; font-size:26px; font-style:normal; font-weight:900; letter-spacing:0.1em; line-height:31px; text-align:center">Route Summary - Rome to Milan</div>
      &nbsp;
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:159.5%; margin-left:auto; margin-right:auto; text-align:justify; width:1066px">&nbsp;</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:159.5%; margin-left:auto; margin-right:auto; text-align:justify; width:1066px">
      <p><span style="font-size:16px"><span style="font-family:Arial">Considered to be a dream city for travellers, Italy is nothing less than a miracle in itself. If you are visiting Rome but missing out in Naples, you better have a good excuse for it. Situated at a close distance from Rome, Naples is one of the most visited places after Rome for an array of travel lovers. Rydeu provides a hassle-free ride from Rome to Naples. Make sure to enjoy the scenic view on your way from the source destination to your arriving city. From luxurious rides to budget-friendly ones, we offer it all.&nbsp;</span></span></p>
      
      <p><span style="font-size:16px"><span style="font-family:Arial"><strong>Private Taxi from Rome to Naples </strong>is the best option if you want to enjoy some time alone with your family and friends without sharing the personal moment with others, making it a memorable trip. <strong>For the best transfer from Rome to Naples, </strong>make sure to explore Rydeu&rsquo;s exclusive services for our customers</span></span></p>
      
      <p>&nbsp;</p>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Distance From Rome To Naples&nbsp;- <strong>226 km&nbsp;</strong></span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Journey Time -<strong> 2-3 hours</strong></span></span></p>
      <br />
      <br />
      &nbsp;
      <div style="height:350px; margin-left:auto; margin-right:auto; width:1066px"><img src="https://cdn.rydeu.com/static/assets/content/images/europe/italy/rome/rome_cityThumbnail.jpg" style="box-shadow:rgba(0, 0, 0, 0.2) 8px 11px 20px; height:284px; position:absolute; width:251px" />
      <div style="background:#f0f0f0; float:right; height:350px; margin-left:100px; width:938px; z-index:-1">
      <div style="color:#484848; font-family:Lato; font-size:20px; font-style:normal; font-weight:900; letter-spacing:0.1em; line-height:24px; margin-left:160px; margin-right:39px; padding-bottom:10px; padding-top:32px">ROME</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:159.5%; margin-left:160px; margin-right:39px; text-align:justify"><span style="font-size:16px">Formerly Etruscan, the city was the first one to take a step towards modernity and has remained the paramount urban centre for centuries. Due to the rich history that the city possesses, it was declared as the European capital of culture and in the year 2006, it was declared as a city of music by UNESCO and was included in the Creative City Network. Bologna is known to have an amalgamation of art, history, cuisine and sightseeing to please its visitors in the best way possible. Italy is the only country which provides an array of options for every sort of visitor out there, Bologna, in general, is one of the places that have the power to sway every part of the crowd from an adventure lover to a food enthusiast. Famous for its Bolognese sauce and Italian wine and pasta, this city will satisfy your taste buds at its best.&nbsp;</span></div>
      </div>
      </div>
      <br />
      <br />
      <br />
      &nbsp;
      <div style="height:350px; margin-left:auto; margin-right:auto; width:1066px"><img src="https://cdn.rydeu.com/static/assets/content/images/europe/italy/milan/milan_cityThumbnail.jpg" style="box-shadow:rgba(0, 0, 0, 0.2) 8px 11px 20px; height:284px; position:absolute; width:251px" />
      <div style="background:#f0f0f0; float:right; height:350px; margin-left:100px; width:938px; z-index:-1">
      <div style="color:#484848; font-family:Lato; font-size:20px; font-style:normal; font-weight:900; letter-spacing:0.1em; line-height:24px; margin-left:160px; margin-right:39px; padding-bottom:10px; padding-top:32px">MILAN</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:159.5%; margin-left:160px; margin-right:39px; text-align:justify">Formerly Etruscan, the city was the first one to take a step towards modernity and has remained the paramount urban centre for centuries. Due to the rich history that the city possesses, it was declared as the European capital of culture and in the year 2006, it was declared as a city of music by UNESCO and was included in the Creative City Network. Bologna is known to have an amalgamation of art, history, cuisine and sightseeing to please its visitors in the best way possible. Italy is the only country which provides an array of options for every sort of visitor out there, Bologna, in general, is one of the places that have the power to sway every part of the crowd from an adventure lover to a food enthusiast. Famous for its Bolognese sauce and Italian wine and pasta, this city will satisfy your taste buds at its best.&nbsp;</div>
      </div>
      </div>
      <br />
      <br />
      <br />
      &nbsp;</div>
      `,
      mobile: `<div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:900; letter-spacing:0.1em; line-height:19px; text-align:left">Route Summary - Rome to Milan</div>

      <div>&nbsp;</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:1.60em; text-align:justify">Etiam purus risus, pellentesque et nunc in, tristique dapibus nisi. Nunc dolor nulla, cursus id malesuada sit amet, imperdiet vitae urna. Vestibulum convallis magna in nulla vulputate pretium. Praesent aliquet aliquam mauris, ut vulputate enim rutrum ullamcorper. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut non tempus felis, non consectetur quam. Curabitur at condimentum dui. Sed sit amet interdum est. Aenean suscipit enim vel vulputate iaculis. Donec in enim et nulla congue rhoncus sed ac lectus. In ut ante ipsum. Pellentesque mauris enim, aliquet a est id, congue euismod tellus. Maecenas lobortis, massa quis.</div>
      
      <div>&nbsp;</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:26px">Distance From Rome To Naples - <strong>226 km</strong></div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:26px">Journey Time - <strong>2-3 hours</strong></div>
      
      <p>&nbsp;</p>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:900; letter-spacing:0.1em; line-height:19px">ROME</div>
      
      <div>&nbsp;</div>
      
      <div><img src="https://cdn.rydeu.com/static/assets/content/images/europe/italy/rome/rome_sightseeingColosseum.jpg" style="width:100%" /></div>
      
      <div>&nbsp;</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:1.60em; text-align:justify">Sed cursus dapibus ornare. Ut et efficitur nisi. Mauris vel erat quam. Praesent pulvinar urna a molestie lacinia. Nulla non tellus vel libero tempor imperdiet. Nunc purus lacus, iaculis eget porttitor ac, euismod nec diam. Donec dignissim purus vitae turpis vestibulum placerat. Phasellus vitae porta metus. Integer ex velit, vestibulum a mattis in, congue at</div>
      
      <p>&nbsp;</p>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:900; letter-spacing:0.1em; line-height:19px">MILAN</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:900; letter-spacing:0.1em; line-height:19px">&nbsp;</div>
      
      <div><img src="https://cdn.rydeu.com/static/assets/content/images/europe/italy/rome/rome_sightseeingColosseum.jpg" style="width:100%" /></div>
      
      <div>&nbsp;</div>
      
      <div style="color:#484848; font-family:Lato; font-size:16px; font-style:normal; font-weight:normal; letter-spacing:0.06em; line-height:1.60em; text-align:justify">Sed cursus dapibus ornare. Ut et efficitur nisi. Mauris vel erat quam. Praesent pulvinar urna a molestie lacinia. Nulla non tellus vel libero tempor imperdiet. Nunc purus lacus, iaculis eget porttitor ac, euismod nec diam. Donec dignissim purus vitae turpis vestibulum placerat. Phasellus vitae porta metus. Integer ex velit, vestibulum a mattis in, congue at</div>
      `,
    },
  },
};

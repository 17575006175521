export const getItem = key => {
  try {
    let output = localStorage.getItem(key)
    if(!output) return '';
    if(output[0] === '{' || output[0] === '[') return JSON.parse(output);
    return output;
  } catch {
    return '';
  }
}

export const setItem = (key, val) => {
  try {
    // console.log(typeof val);
    if(typeof val === 'object') {
      val = JSON.stringify(val);
    }
    localStorage.setItem(key, val);
    return true;
  }
  catch(err) {
    return false;
  }
}

export const removeItem = key => localStorage.removeItem(key);

export const clear = () => localStorage.clear();
const asyncStorage = {
  async setItem(key, value) {
    await null;
    return Promise.resolve().then(() => localStorage.setItem(key, value));
  },
  async getItem(key) {
    await null;
    return localStorage.getItem(key);
  }
};

export default {
  getItem,
  setItem,
  removeItem,
  clear,
  asyncStorage,
};

import React from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { PricingGroupChoices } from "Constants/Pricing";
import { BookingTypeChoices } from "Constants/Bookings";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import RecordFetcher from "Components/Common/RecordFetcher";

const PricingTitle = ({ record }) => {
  return <span>Pricing: {record ? `${record.id}` : ""}</span>;
};

export const PricingEdit = (props) => {
  const [record, setRecord] = React.useState({});
  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Pricings",
      url: "/pricings",
    },
    {
      name: `${record.id}`,
      url: `/pricings/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/pricings/${record.id}`,
    },
  ];

  const commonInputProps = {};

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<PricingTitle />} {...props}>
        <SimpleForm>
          <RecordFetcher setRecord={setRecord} />

          <TextInput source="city" />
          <TextInput source="country" />

          <ReferenceInput
            label="Vehicle Type"
            source="vehicleTypeId"
            reference="vehicleTypes"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <NumberInput
            label="Airport Transfer Fees"
            source="amountCentsAirportFees"
            format={(v) => v / 100}
            parse={(v) => v * 100}
            {...commonInputProps}
          />

          <NumberInput
            label="Per Hour Amount"
            source="amountCentsPerHour"
            format={(v) => v / 100}
            parse={(v) => v * 100}
            {...commonInputProps}
          />

          <NumberInput
            label="Per KM Amount"
            source="amountCentsPerKm"
            format={(v) => v / 100}
            parse={(v) => v * 100}
            {...commonInputProps}
          />

          <NumberInput
            label="Base Amount"
            source="baseAmountCents"
            format={(v) => v / 100}
            parse={(v) => v * 100}
            {...commonInputProps}
          />

          <NumberInput
            label="Base Kms"
            source="baseKms"
            {...commonInputProps}
          />

          <BooleanInput source="isDisable" />

        </SimpleForm>
      </Edit>
    </>
  );
};

export default PricingEdit;

import React, { useState, useEffect } from "react";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import {
  UrlField,
  BooleanField,
  SelectField,
  FunctionField,
  DateField,
  SingleFieldList,
  ChipField,
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  ReferenceField,
  Tab,
  TabbedShowLayout,
  EditButton,
  ReferenceManyField,
  ShowButton,
  useRefresh,
  useDataProvider,
  useNotify,
} from "react-admin";
import {
  Box,
  TextField as MaterialTextField,
  Button as MaterialButton,
  Typography,
  TableBody, TableCell, TableRow, Table, TableHead
} from "@material-ui/core";

import ContentCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment-timezone";
import PrintIcon from "@material-ui/icons/Print";
import PersonAdd from "@material-ui/icons/PersonAdd";
import SendIcon from "@material-ui/icons/Send";
import { API_HOSTNAME as apiUrl } from "Constants";
import httpClient from "Providers/Data/HttpClient";
import LongDateField from "Components/Common/Fields/LongDateField";
import { BookingStatusChoices, BookingStatuses } from "Constants/Bookings";
import { TransferOfferStatus } from "Constants/TransferOffers";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CurrencyField from "Components/Common/Fields/CurrencyField";
import ChipSelectField from "Components/Common/Fields/ChipSelectField";
import { OrderStateStatusChoices } from "Constants/OrderStates";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import RecordFetcher from "Components/Common/RecordFetcher";
import Button from "Components/Common/RaOverrides/Button";
import { withRouter } from "react-router";
import CustomUrlField from "Components/Common/Fields/CustomUrlField";
import TopToolbar from "Components/Common/RaOverrides/TopToolbar";
import VendorReassignModal from "Components/VendorReassignModal";
import AuctionReassignModal from "Components/AuctionReassignModal";
import UserReassignModal from "Components/UserReassignModal";
import CreateAuctionModal from "Components/CreateAuctionModal";
import GenerateVendorAssignmentUrl from "Components/GenerateVendorAssignmentUrl";
import AlertDialog from "Components/Common/AlertDialog/AlertDialog";

const useStyles = makeStyles({
  trashButton: {
    position: "absolute",
    right: 10,
    cursor: "pointer",
    color: "#D50104",
  },
  templateList: {
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "scroll",
    maxHeight: "60vh",
  },
  templateContainer: {
    textAlign: "left",
    minWidth: "5%",
    maxWidth: "400px",
    width: "max-content",
    height: "auto",
    display: "inline-block",
    lineHeight: "1.5rem",
    overflowWrap: "break-word",
    background: "#BDC3C7",
    padding: "0.5rem 1rem",
    borderRadius: "0.3rem",
    marginBottom: "0.5em",
    fontSize: 14,
  },
  templateHeading: {
    marginTop: "10px",
    letterSpacing: "1px",
    fontSize: "1.2rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  commentListContainer: {
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    maxHeight: "40vh",
  },
  commentBox: {
    position: "relative",
    textAlign: "left",
    minWidth: "5%",
    maxWidth: "400px",
    width: "max-content",
    height: "auto",
    display: "inline-block",
    lineHeight: "1.5rem",
    overflowWrap: "break-word",
    background: "#BDC3C7",
    padding: "0.5rem 1rem",
    borderRadius: "0.3rem",
    marginBottom: "0.5em",
    fontSize: 14,
  },
  dateText: {
    fontSize: 10,
    textAlign: "right",
    fontWeight: "700",
  },
  emptyComments: {
    fontSize: "1.2rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh",
  },
  button: {
    width: "25%",
    alignSelf: "flex-end",
    fontSize: "14px",
  },
});

const BookingTitle = ({ record }) => {
  return <span>Booking ID: {record ? `${record.bookingNumber}` : ""}</span>;
};

const CreateBookingStateButton = ({ record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/bookingStates/create?bookingId=${record?.id ?? ""}&bookingNumber=${
        record?.bookingNumber ?? ""
      }`}
      label="Add New Booking State"
      title="Add New Booking State"
    >
      <AddIcon />
    </Button>
  );
};

const CreatePaymentButton = ({ record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/payments/create?bookingId=${record.id}&bookingNumber=${record.bookingNumber}&organizationId=${record.organizationId}`}
      label="Add New Payment"
      title="Add New Payment"
    >
      <AddIcon />
    </Button>
  );
};

// const AddVoucherForBookingButton = ({ record }) => (
//   <Button
//     variant="contained"
//     component={Link}
//     to={`/vouchers/create?bookingId=${record.id}`}
//     label="Add new voucher"
//     title="Add new voucher"
//   >
//     <AddIcon />
//   </Button>
// );

const PrintVoucherButton = ({ classes, record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  // console.log(record);
  return (
    <Button
      component={Link}
      onClick={() => {
        // console.log('print', dataProvider, record);
        dataProvider
          .getCustomWithQueryParams(`bookings/${record.id}/printVouchers`)
          .then((res) => {
            notify("Vouchers Sent Successfully!");
            // console.log("Res From Print Voucher", res)
          })
          .catch((error) => {
            notify("Something went wrong!");
          });
      }}
      label="E-mail Vouchers"
      title="Email Vouchers"
    >
      <SendIcon />
    </Button>
  );
};

const CreateVoucherForBookingButton = ({ classes, record, ...rest }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  return (
    <Button
      component={Link}
      variant="contained"
      onClick={() => {
        // console.log('generate vouchers', dataProvider, record);
        dataProvider
          .create("vouchers", { data: { bookingId: record.id } })
          .then((res) => {
            notify("Generated Vouchers Successfully!");
            // console.log(rest)
            let currRoute = rest.location.pathname;
            let matchUrl = rest.match.url;
            if (!matchUrl.endsWith("/")) matchUrl += "/";
            matchUrl += "vouchers";
            rest.history.push("/temp");
            rest.history.replace(matchUrl);
          })
          .catch((error) => {
            notify("Something went wrong!");
          });
      }}
      label="Generate Vouchers"
      title="Generate Vouchers"
    >
      <PrintIcon />
    </Button>
  );
};

const VoucherPanel = ({ id, record, resource, basePath }) => (
  <SimpleShowLayout
    record={record}
    resource={resource}
    id={id}
    basePath={basePath}
  >
    {/* <RecordFetcher setRecord={setRecord} /> */}
    {/* <ReferenceField link="show" source="bookingId" reference="bookings">
      <TextField source="bookingNumber" />
    </ReferenceField> */}
    {/* <TextField source="id" /> */}
    <TextField source="driverName" />
    <TextField source="driverContactNumber" />
    <TextField source="receiverName" />
    <TextField source="receiverEmail" />
    <TextField source="receiverPhoneNumber" />
    <TextField source="supportName" />
    <TextField source="supportPhoneNumber" />
    {/* <TextField source="createdBy" /> */}
    {/* <CustomUrlField displayName="Pdf Link" source="voucherUrl" /> */}
    {/* <TextField source="voucherNumber" /> */}
    {/* <LongDateField source="createdAt" /> */}
    {/* <DateField source="updatedAt" /> */}
    {/* <TextField source="deletedAt" /> */}
    {/* <ReferenceField source="bookingId" reference="bookings">
      <TextField source="id" />
    </ReferenceField> */}
    {/* <ReferenceField source="dmcBookingId" reference="dmcBookings">
      <TextField source="id" />
    </ReferenceField> */}

    <ArrayField source="voucherDetails">
      <Datagrid>
        <TextField source="orderNumber" />
        {/* <ReferenceField source="bookingId" reference="bookings">
          <TextField source="id" />
        </ReferenceField> */}
        <TextField source="supportName" />
        {/* <TextField source="supportPhoneNumber" /> */}
        <TextField source="pickupAddress" />
        <TextField source="dropAddress" />
        <TextField source="vehicleInfo" />
        {/* <NumberField source="noOfBaggage" /> */}
        {/* <NumberField source="noOfPax" /> */}
        {/* <EmailField source="email" /> */}
        {/* <TextField source="flightNo" /> */}
        <TextField source="customerName" />
        <LongDateField source="travelDateTime" />
        {/* <TextField source="distance" /> */}
        {/* <NumberField source="day" /> */}
      </Datagrid>
    </ArrayField>
    <Button
      variant={"contained"}
      addNegativeMargin
      customComponent={ShowButton}
      label="View Complete Details"
    />
  </SimpleShowLayout>
);

const voucherRowStyle = (record, index) => ({
  // borderLeft: record.voucherUrl ? '5px solid green' : 'white',
  background: record.voucherUrl
    ? "linear-gradient(to right, #afa, white 5%)"
    : "white",
  // backgroundColor: record.voucherUrl ? '#afa' : 'white',
});

const acceptedOfferStyle = (record, index) => {
  const style = {
    background: "white",
  };

  if (record.currentState == TransferOfferStatus.ACCEPTED) {
    style.background = "linear-gradient(to right, #afa, white 5%)";
  }

  if (
    record.currentState == TransferOfferStatus.CANCELLED ||
    record.currentState == TransferOfferStatus.EXPIRED
  ) {
    style.background = "linear-gradient(to right, #FF0000, white 5%)";
  }

  return style;
};

const BookingHistory = ({ record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/bookingHistory/create?bookingId=${record.id}&bookingNumber=${record.bookingNumber}&referer=bookings`}
      label="Booking History"
      title="Booking History"
    ></Button>
  );
};

const VendorReassignButton = ({ classes, record, showModalFunc }) => {
  return (
    <Button
      variant="contained"
      onClick={() => showModalFunc()}
      label="Reassign vendor"
      title="Reassign vendor"
    >
      <PersonAdd />
    </Button>
  );
};

const UserReassignButton = ({ classes, record, showModalFunc }) => {
  return (
    <Button
      variant="contained"
      onClick={() => showModalFunc()}
      label="Reassign user"
      title="Reassign user"
    >
      <PersonAdd />
    </Button>
  );
};

const GenerateVendorAssignmentUrlButton = ({ showModalFunc }) => {
  return (
    <Button
      variant="contained"
      onClick={() => showModalFunc()}
      label="Generate Vendor Assignment Url"
      title="Generate Vendor Assignment Url"
    >
      <PersonAdd />
    </Button>
  );
};

//add Auction General Button function here
const GenerateAuctionButton = ({ showModalFunc }) => {
  return (
    <Button
      variant="contained"
      title="Add to Auction"
      label="Add to Auction"
      onClick={() => showModalFunc()}
    >
      <PersonAdd />
    </Button>
  );
};

const ReassignAuctionModalButton = ({ showModalFunc }) => {
  return (
    <Button
      variant="contained"
      title="Reassign Auction"
      label="Reassign Auction"
      onClick={() => showModalFunc()}
    >
      <PersonAdd />
    </Button>
  );
};

const TicketButton = ({ classes, record }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleTicket = () => {
    if (record?.crm?.id) {
      const crmData = {};
      if (record.crm.currentState === "11") crmData.currentState = "3";
      else crmData.currentState = "11";

      dataProvider
        .update(`crm`, { id: record.crm.id, data: crmData })
        .then((res) => {
          notify(
            record.crm.currentState === "11"
              ? "Ticket Closed successfully !"
              : "Ticket Raised successfully !"
          );
          refresh();
        })
        .catch((error) => {
          console.log(error);
          notify("Something went wrong!");
        });
    }
  };
  return (
    <Button
      component="button"
      variant="contained"
      onClick={handleTicket}
      label={
        record?.crm?.currentState === "11" ? "Close Ticket" : "Open Ticket"
      }
      title={
        record?.crm?.currentState === "11" ? "Close Ticket" : "Open Ticket"
      }
    ></Button>
  );
};

const ShowButtons = (props) => {
  console.log(props.data);
  switch (props.slug) {
    case "show":
      return (
        <>
          {props?.data?.currentState == BookingStatuses.CONFIRMED && (
            <>
              <ReassignAuctionModalButton
                showModalFunc={props.rest.showAuctionReassignModal}
                record={props.data}
              />
              <GenerateAuctionButton
                showModalFunc={props.rest.showAuctionModal}
                record={props.data}
              />
              <GenerateVendorAssignmentUrlButton
                showModalFunc={props.rest.showGenerateVendorAssignmentUrlModal}
                record={props.data}
              />
              <VendorReassignButton
                showModalFunc={props.rest.showModalFunc}
                record={props.data}
              />
              <UserReassignButton
                showModalFunc={props.rest.showUserReassignModalFunc}
                record={props.data}
              />
              <BookingHistory record={props.data} />
            </>
          )}
          <Button
            variant="contained"
            customComponent={EditButton}
            basePath={props.basePath}
            record={props.data}
          />
        </>
      );
    case "vouchers":
      return (
        <>
          <PrintVoucherButton record={props.data} />
          <CreateVoucherForBookingButton record={props.data} {...props.rest} />
        </>
      );
    case "bookingStates":
      return <CreateBookingStateButton record={props.data} />;
    case "crm":
      return <TicketButton record={props.data} />;
    default:
      return <></>;
  }
};

const Aside = () => null;
const BookingShowActions = withRouter(
  ({ basePath, data, resource, ...rest }) => {
    // console.log("BookingShowActions", basePath, resource, rest);
    const slug = rest.location.pathname.split("/").pop();
    return (
      <TopToolbar>
        {/* <Button variant="contained" customComponent={EditButton} basePath={basePath} record={data} /> */}
        {/* <CreateBookingStateButton record={data} /> */}
        {/* <CreatePaymentButton record={data} /> */}
        {/* <PrintVoucherButton record={data} />    */}
        {/* <CreateVouchersButton record={data} /> */}
        {/* <CreateVoucherForBookingButton record={data} {...rest}/> */}
        <ShowButtons
          slug={slug}
          data={data}
          rest={rest}
          resource={resource}
          basePath={basePath}
        />
      </TopToolbar>
    );
  }
);

export const BookingShow = (props) => {
  const classes = useStyles();
  const [record, setRecord] = React.useState({});
  const [auctionsData, setAuctionsData] = useState({});
  const [emailMeta, setEmailMeta] = useState([]);
  const [showAuctionReassignModal, setAuctionReassignModal] =
    React.useState(false);
  const [showAuctionModal, setAuctionModal] = React.useState(false); //auction
  const [showReassignModal, setShowReassignModal] = React.useState(false);
  const [showUserReassignModal, setShowUserReassignModal] =
    React.useState(false);
  const [
    showGenerateVendorAssignmentUrlModal,
    setGenerateVendorAssignmentUrlModal,
  ] = React.useState(false);
  const dataProvider = useDataProvider();
  const [comment, setComment] = useState(null);
  const refresh = useRefresh();
  const notify = useNotify();

  const triggerEmail = async (emailOperation, payload) => {
  
    const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/triggerEmail/${emailOperation}`
    const response = await httpClient(url,{
        method: 'POST',
        body: JSON.stringify(payload),
    }).then((res) => JSON.parse(res.body));

    const {status} = response;
    if(status == 200){
        notify('email triggered successfully');
        getEmailMeta();
    }
    else{
        notify('something went wrong while triggering email');
    }
  }

  const checkIsBookingCompleted = () => {
    const isBookingCompleted = record?.bookingStates?.filter(({state}) => state === BookingStatuses.COMPLETED).length > 0 ? true: false;

    const triggerFeedbackMail = (e) => {
      const payload = {
        json: JSON.stringify({
          email: record.user.email,
          bookingNumber: record.bookingNumber,
          customerName: `${record.user.account.firstName ?? ""} ${record.user.account.lastName ?? ""}` 
        })
      }
      triggerEmail('customerFeedback', payload);
    }
    
    if(isBookingCompleted){
      return (
        <div>
        <br></br>
        {checkIfEmailMetaExist()}
        <br></br>
        <MaterialButton variant="contained" color="primary" onClick={triggerFeedbackMail} >
            SEND REVIEW EMAIL
        </MaterialButton>
        <br></br>
        </div>
      )
    }
  }

  const getEmailMeta = async () => {
    if(record.bookingNumber && !record.emailMeta)
    {  
      const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/emailMeta?bookingNumber=${record.bookingNumber}&operation=customerFeedback`;
      const response = await httpClient(url).then((res) => JSON.parse(res.body));
      const {status, data} = response;
      

      if(status == 200) {
        setEmailMeta(data);
      }
    }
  }

  const checkIfEmailMetaExist = () => {
    if(emailMeta.length>0){
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Triggered By</TableCell>
                <TableCell>Triggered At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { emailMeta.map(obj => {
                  return (
                    <TableRow>
                      <TableCell><b>{obj.description.triggeredBy}</b></TableCell>
                      <TableCell><b>{obj.description.triggeredAt}</b></TableCell>
                    </TableRow>
                  )
              })}
            </TableBody>
          </Table>
        )
    }
  }

  useEffect(()=>{
    getEmailMeta();
  }, [record.bookingNumber])
  

  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Bookings",
      url: "/bookings",
    },
    {
      name: `${record.bookingNumber}`,
      url: `/bookings/${record.id}/show`,
    },
  ];

  const handleRemoveComment = (commentObj) => {
    dataProvider
      .delete(`/crm/${record?.crm?.id}/comments`, {
        id: commentObj.commentId,
        previousData: commentObj,
      })
      .then(() => {
        notify("comment deleted successfully");
        refresh();
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const handleAddComment = () => {
    if (comment == null || comment == "") {
      return notify("Please add comment first !");
    }

    const data = {
      comments: {
        createdBy: "Admin",
        comment: comment,
      },
    };

    dataProvider
      .create(`/crm/${record?.crm?.id}/comments`, { data })
      .then(() => {
        notify("comment added successfully");
        refresh();
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const handleRowClick = (id, basePath, record) => {
    // console.log(id, basePath, record);
    // history.push(`${basePath}/${id}/show`)
    props.history.push({
      pathname: `${basePath}/${id}/show`,
      search: "",
      state: { prevResource: "booking" },
    });
  };

  const handleOffers = (record, type) => {
    if (record && record.transfers && record.transfers[0]) {
      return {
        transferId: record.transfers[0].id,
        offerType: type,
        resource: "bookings",
      };
    }
  };

  const handleCopyToClipboard = (templateId) => {
    var r = document.createRange();
    r.selectNode(document.getElementById(templateId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    var textarea = document.getElementById("hidden-area");
    textarea.style.display = "block";
    textarea.value = r
      .toString()
      .split("\n")
      .map((text) => text.trim(" "))
      .join("\n")
      .trim();
    textarea.select();
    document.execCommand("copy");
    textarea.style.display = "none";
  };

  const ChatMessages = ({ commentList, handleRemoveComment }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [hoverCommentId, setHoverCommentId] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);

    const handleClickOpen = (commentObj) => {
      setSelectedComment(commentObj);
      setIsDeleteModalOpen(true);
    };

    const handleClose = () => {
      setIsDeleteModalOpen(false);
    };

    const parseMsg = (comment) => {
      const obj = JSON.parse(comment);
      const keys = Object.keys(obj.before);

      const showObj = {
        pickupLocation: "Pickup Addr",
        dropLocation: "Drop Addr",
        startDateTime: "Start time",
        returnDateTime: "Return time",
        totalAdultSeats: "No. of Adults",
        totalChildSeats: "No. of Children",
        numberOfHours: "Duration",
        passengerName: "Passenger Name",
        passengerContactNumber: "Passenger Contact No.",
        baggageCount: "No. of Baggage",
        flightNumber: "Flight No.",
        customerComment: "Customer Comment",
      };

      return (
        <>
          {keys.map((key) => {
            return (
              <>
                <>
                  <b>{showObj[key]}</b> changed from {obj.before[key] ?? "null"}{" "}
                  to {obj.after[key]}
                </>
                <br></br>
              </>
            );
          })}
        </>
      );
    };

    return (
      <>
        <AlertDialog
          buttonComponent={null}
          isOpen={isDeleteModalOpen}
          handleClose={handleClose}
          handleOk={() => handleRemoveComment(selectedComment)}
          handleCancel={handleClose}
        />
        {commentList && commentList.length ? (
          <div className={classes.commentListContainer}>
            {commentList.map((commentObj) => {
              return (
                <div
                  key={commentObj.commentId}
                  className={classes.commentBox}
                  onMouseEnter={(e) => {
                    setHoverCommentId(commentObj.commentId);
                  }}
                  onMouseLeave={(e) => {
                    setHoverCommentId(null);
                  }}
                >
                  <a
                    onClick={() => handleClickOpen(commentObj)}
                    className={classes.trashButton}
                    style={
                      commentObj.commentId === hoverCommentId
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <DeleteIcon style={{ fontSize: 24 }} />
                  </a>
                  <div className={classes.comment}>
                    {commentObj.isObject
                      ? parseMsg(commentObj.comment)
                      : commentObj.comment}
                  </div>
                  <div className={classes.dateText}>
                    {moment
                      .tz(commentObj.createdAt, moment.tz.guess())
                      .format("DD/MM/YYYY, hh:mm A")}
                  </div>
                  <div className={classes.dateText}>{commentObj.createdBy}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.emptyComments}>No comments added yet !</div>
        )}
      </>
    );
  };
  return (
    <>
      {/* {console.log(record)} */}
      <AuctionReassignModal
        bookingRecord={record}
        showModal={showAuctionReassignModal}
        closeModal={() => setAuctionReassignModal(false)}
      />
      <CreateAuctionModal
        auctions={record.auctions}
        bookingRecord={record}
        showModal={showAuctionModal}
        closeModal={() => setAuctionModal(false)}
      />
      <VendorReassignModal
        bookingRecord={record}
        showModal={showReassignModal}
        closeModal={() => setShowReassignModal(false)}
      />
      <UserReassignModal
        bookingRecord={record}
        showModal={showUserReassignModal}
        closeModal={() => setShowUserReassignModal(false)}
        refresh={refresh}
      />
      <GenerateVendorAssignmentUrl
        bookingRecord={record}
        showModal={showGenerateVendorAssignmentUrlModal}
        closeModal={() => setGenerateVendorAssignmentUrlModal(false)}
        refresh={refresh}
      />
      <Breadcrumbs links={breadcrumbLinks} />
      <Show
        title={<BookingTitle />}
        actions={
          <BookingShowActions
            showModalFunc={() => setShowReassignModal(true)}
            showUserReassignModalFunc={() => setShowUserReassignModal(true)}
            showGenerateVendorAssignmentUrlModal={() =>
              setGenerateVendorAssignmentUrlModal(true)
            }
            showAuctionModal={() => setAuctionModal(true)}
            showAuctionReassignModal={() => setAuctionReassignModal(true)}
          />
        }
        aside={<Aside />}
        {...props}
      >
        <TabbedShowLayout>
          <Tab label="Booking Details">
            <BoxedShowLayout>
              <h3>Booking Details</h3>
              <RaBox
                flexWrap="wrap"
                flexGrow={4}
                display="flex"
                justifyContent="space-between"
              >
                <TextField
                  className={classes.rowField}
                  label="Booking ID"
                  source="id"
                />
                <TextField
                  className={classes.rowField}
                  label="Booking Number"
                  source="bookingNumber"
                />
                <CurrencyField label="Total Amount" source="totalAmountCents" />
                <SelectField
                  source="currentState"
                  choices={BookingStatusChoices}
                />
                <TextField
                  label="Flight Number"
                  source="transfers[0].flightNumber"
                />
                <ReferenceField source="userId" reference="users">
                  <TextField source="email" />
                </ReferenceField>
                <ReferenceField
                  label="Vendor"
                  source="transfers[0].vendorId"
                  linkType="show"
                  reference="vendors"
                >
                  <TextField source="user.email" />
                </ReferenceField>
              </RaBox>
              <RaBox
                flexWrap="wrap"
                marginTop={2}
                flexGrow={4}
                display="flex"
                justifyContent="flex-start"
              >
                <TextField label="Payment Method" source="paymentMethod" />
                <FunctionField
                  label="Payment Mode"
                  render={(record) => record?.paymentMode?.join(", ")}
                />
                <TextField label="Cancellation Reason" source="cancelReason" />
                <FunctionField
                  label="Reservation Amount"
                  render={(record) =>
                    `${
                      record.currency &&
                      record.currency.toString().toUpperCase()
                    } ${record?.transfers[0]?.reservationAmountCents / 100}`
                  }
                />
                <FunctionField
                  label="Remaining Amount"
                  render={(record) =>
                    `${
                      record.currency &&
                      record.currency.toString().toUpperCase()
                    } ${record?.dueAmountCents / 100}`
                  }
                />
                <FunctionField
                  label="Forward Trip Driver Details"
                  render={(record) =>
                    record.transfers &&
                    record.transfers[0] &&
                    record.transfers[0].forwardTripDriverDetails && (
                      <div>
                        {record.transfers[0].forwardTripDriverDetails.firstName}{" "}
                        {record.transfers[0].forwardTripDriverDetails.lastName},{" "}
                        {record.transfers[0].forwardTripDriverDetails.phone}
                      </div>
                    )
                  }
                />
                <FunctionField
                  label="Return Trip Driver Details"
                  render={(record) =>
                    record.transfers &&
                    record.transfers[0] &&
                    record.transfers[0].returnTripDriverDetails && (
                      <div>
                        {record.transfers[0].returnTripDriverDetails.firstName}{" "}
                        {record.transfers[0].returnTripDriverDetails.lastName},{" "}
                        {record.transfers[0].returnTripDriverDetails.phone}
                      </div>
                    )
                  }
                />
                <UrlField
                  className={classes.rowField}
                  label="Vendor Assignment Url"
                  source="transfers[0].vendorAssignmentUrl"
                />
              </RaBox>

              <h3>Transfers Details</h3>
              <RaBox>
                <RecordFetcher setRecord={setRecord} />
                <ReferenceManyField
                  reference="transfers"
                  target="bookingId"
                  addLabel={false}
                  sort={{ field: "createdAt", order: "DESC" }}
                >
                  <Datagrid rowClick="show">
                    <TextField label="Transfer ID" source="id" />
                    <FunctionField
                      label="Start Date Time"
                      render={(record) =>
                        moment
                          .tz(
                            record.startDateTime,
                            record.timezone || moment.tz.guess()
                          )
                          .format("DD/MM/YYYY, HH:mm")
                      }
                    />
                    <FunctionField
                      label="Return Date Time"
                      render={(record) => {
                        if (record.returnDateTime) {
                          return moment
                            .tz(
                              record.returnDateTime,
                              record.timezone || moment.tz.guess()
                            )
                            .format("DD/MM/YYYY, HH:mm");
                        } else return "N/A";
                      }}
                    />
                    <TextField
                      label="Pickup Location"
                      source="pickupLocation.formattedAddress"
                    />
                    <TextField
                      label="Drop Location"
                      source="dropLocation.formattedAddress"
                    />
                    <TextField label="Distance" source="numberOfKms" />
                    <TextField label="Duration" source="numberOfHours" />
                    <FunctionField
                      label="Passengers / BaggageCount"
                      render={(record) =>
                        `${record.totalAdultSeats},${record.totalChildSeats} / ${record.baggageCount}`
                      }
                    />
                    <TextField label="Transfer Type" source="transferType" />
                    <ArrayField label="vehicleTypes" source="vehicleTypes">
                      <SingleFieldList>
                        <ChipField source="name" />
                      </SingleFieldList>
                    </ArrayField>
                    <TextField
                      label="Customer Comments"
                      source="customerComment"
                    />
                  </Datagrid>
                </ReferenceManyField>
              </RaBox>
            </BoxedShowLayout>
          </Tab>

          <Tab label="Orders" path="orders">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceManyField
              reference="orders"
              target="bookingId"
              addLabel={false}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <Datagrid rowClick={handleRowClick}>
                {/* <Button addNegativeMargin customComponent={ShowButton} label=""/> */}
                <Button
                  addNegativeMargin
                  customComponent={EditButton}
                  label=""
                />
                {/* <AddVoucherForOrderButton /> */}
                <TextField source="orderNumber" />
                <TextField
                  label="Pickup Location"
                  source="transfer.pickupLocation.formattedAddress"
                />
                <TextField
                  label="Drop Location"
                  source="transfer.dropLocation.formattedAddress"
                />
                {/* <ReferenceField link={false} source="pickupLocationId" reference="locations"><TextField source="formattedAddress" /></ReferenceField>
                <ReferenceField link={false} source="dropLocationId" reference="locations"><TextField source="formattedAddress" /></ReferenceField> */}
                {/* <LongDateField source="startDateTime" />
                <LongDateField source="endDateTime" /> */}
                <FunctionField
                  label="Start Date Time"
                  render={(record) => {
                    const d = new Date(record.startDateTime);
                    return `${
                      d.getUTCDate() < 10
                        ? "0" + d.getUTCDate()
                        : d.getUTCDate()
                    }/${
                      d.getUTCMonth() + 1 < 10
                        ? "0" + (d.getUTCMonth() + 1)
                        : d.getUTCMonth() + 1
                    }/${d.getUTCFullYear()}, ${d.getUTCHours()}:${
                      d.getUTCMinutes() < 10
                        ? "0" + d.getUTCMinutes()
                        : d.getUTCMinutes()
                    }`;
                  }}
                />
                <FunctionField
                  label="End Date Time"
                  render={(record) => {
                    if (record.endDateTime) {
                      const d = new Date(record.endDateTime);
                      return `${
                        d.getUTCDate() < 10
                          ? "0" + d.getUTCDate()
                          : d.getUTCDate()
                      }/${
                        d.getUTCMonth() + 1 < 10
                          ? "0" + (d.getUTCMonth() + 1)
                          : d.getUTCMonth() + 1
                      }/${d.getUTCFullYear()}, ${d.getUTCHours()}:${
                        d.getUTCMinutes() < 10
                          ? "0" + d.getUTCMinutes()
                          : d.getUTCMinutes()
                      }`;
                    } else return "";
                  }}
                />
                {/* <TextField source="additionalRequest" /> */}
                {/* <NumberField source="childSeats" /> */}
                <CurrencyField label="Total Amount" source="totalAmountCents" />
                {/* <TextField source="travellerName" /> */}
                {/* <TextField source="travellerPhoneNumber" /> */}
                {/* <TextField source="currency" /> */}
                {/* <TextField source="travellerEmail" /> */}
                {/* <TextField source="termAndConditions" /> */}
                {/* <DateField source="createdAt" /> */}
                {/* <DateField source="updatedAt" /> */}
                {/* <ReferenceField source="billingAddressId" reference="billingAddresses"><TextField source="id" /></ReferenceField> */}

                <ReferenceField
                  link={"show"}
                  source="vehicleTypeId"
                  reference="vehicleTypes"
                >
                  <TextField source="name" />
                </ReferenceField>

                <ArrayField source="orderStates">
                  <SingleFieldList linkType={"show"}>
                    <ChipSelectField
                      source="state"
                      choices={OrderStateStatusChoices}
                    />
                  </SingleFieldList>
                </ArrayField>
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="Offers" path="transferOffers">
            <RecordFetcher setRecord={setRecord} />
            <h3>Rydeu Offers</h3>

            <ReferenceManyField
              reference="transferOffers"
              target={handleOffers(record, "rydeuOffers")}
              addLabel={false}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <Datagrid rowClick={handleRowClick} rowStyle={acceptedOfferStyle}>
                <ReferenceField
                  source="vendorId"
                  linkType="show"
                  reference="vendors"
                >
                  <TextField source="id" />
                </ReferenceField>
                <FunctionField
                  label="Offered Vehicle"
                  render={(record) =>
                    record.vehicleTypeId
                      ? `${record.vehicleType.name} ${record.vehicleType.refModel}`
                      : `${record.vehicle.brand} ${record.vehicle.model} , ${record.vehicle.yearOfManufacture}`
                  }
                />

                <FunctionField
                  sortBy="totalAmount"
                  label="Total Amount"
                  render={(record) => {
                    return record.transfer.reservationAmountCents > 0 ||
                      record.serviceCharges === 0
                      ? `${record?.transfer?.currency?.symbol || ""} ${
                          record.totalAmount
                        }
                    `
                      : `${record?.transfer?.currency?.symbol || ""} ${
                          record.totalAmount
                        } / ${record?.transfer?.currency?.symbol || ""} ${
                          record.totalAmount +
                          record.serviceCharges +
                          record.additionalPaymentCharges
                        }
                    `;
                  }}
                />
                <FunctionField
                  sortBy="reservationAmount"
                  label="Reservation Amount"
                  render={(record) =>
                    record.transfer.reservationAmountCents > 0 &&
                    record.serviceCharges > 0
                      ? `${record?.transfer?.currency?.symbol || ""} ${
                          record.reservationAmount
                        } / ${record?.transfer?.currency?.symbol || ""} ${
                          record.reservationAmount +
                          record.serviceCharges +
                          record.additionalPaymentCharges
                        }
                      `
                      : `${record?.transfer?.currency?.symbol || ""} ${
                          record.reservationAmount
                        }
                      `
                  }
                />
                <FunctionField
                  label="Amount Cents Per Hour"
                  render={(record) =>
                    `${record?.transfer?.currency?.symbol || ""} ${
                      record.amountCentsPerHour || 0
                    }`
                  }
                />
                <FunctionField
                  label="Booking Preference"
                  render={(record) => {
                    // console.log({ record });
                    if (record?.bookingPreference)
                      return record?.bookingPreference === "BOOK_NOW"
                        ? "PRE_PAYMENT"
                        : record?.bookingPreference;
                    else return "N/A";
                  }}
                />
                <BooleanField
                  label="Limited Offer"
                  source="limitedOffer"
                ></BooleanField>
                <DateField
                  label="Limited Offer Due Date"
                  source="limitedOfferDueDate"
                />
                <DateField label="Offer Creation Date" source="createdAt" />
                <TextField label="Comment" source="comment" />
              </Datagrid>
            </ReferenceManyField>
            <h3>Supplier Offers</h3>
            <ReferenceManyField
              reference="transferOffers"
              // target="transferId"
              target={handleOffers(record, "supplierOffers")}
              source="transfers[0].id"
              addLabel={false}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <Datagrid rowClick={handleRowClick} rowStyle={acceptedOfferStyle}>
                <ReferenceField
                  source="vendorId"
                  linkType="show"
                  reference="vendors"
                >
                  <TextField source="id" />
                </ReferenceField>
                <FunctionField
                  component="pre"
                  label="Offered Vehicle"
                  render={(record) =>
                    record.vehicle
                      ? `${record.vehicle.brand} ${record.vehicle.model} , ${record.vehicle.yearOfManufacture}`
                      : "---"
                  }
                />
                <FunctionField
                  sortBy="totalAmount"
                  label="Total Amount"
                  render={(record) =>
                    record.transfer.reservationAmountCents > 0 ||
                    record.serviceCharges === 0
                      ? `${record?.transfer?.currency?.symbol || ""} ${
                          record.totalAmount
                        }
                    `
                      : `${record?.transfer?.currency?.symbol || ""} ${
                          record.totalAmount
                        } / ${record?.transfer?.currency?.symbol || ""} ${
                          record.totalAmount +
                          record.serviceCharges +
                          record.additionalPaymentCharges
                        }
                    `
                  }
                />
                <FunctionField
                  sortBy="reservationAmount"
                  label="Reservation Amount"
                  render={(record) =>
                    record.transfer.reservationAmountCents > 0 &&
                    record.serviceCharges > 0
                      ? `${record?.transfer?.currency?.symbol || ""} ${
                          record.reservationAmount
                        } / ${record?.transfer?.currency?.symbol || ""} ${
                          record.reservationAmount +
                          record.serviceCharges +
                          record.additionalPaymentCharges
                        }
                      `
                      : `${record?.transfer?.currency?.symbol || ""} ${
                          record.reservationAmount
                        }
                      `
                  }
                />
                <FunctionField
                  label="Amount Cents Per Hour"
                  render={(record) =>
                    `${record?.transfer?.currency?.symbol || ""} ${
                      record.amountCentsPerHour || 0
                    }`
                  }
                />
                <FunctionField
                  label="Booking Preference"
                  render={(record) => {
                    // console.log({ record });
                    if (record?.bookingPreference)
                      return record?.bookingPreference === "BOOK_NOW"
                        ? "PRE_PAYMENT"
                        : record?.bookingPreference;
                    else return "N/A";
                  }}
                />
                <BooleanField
                  label="Limited Offer"
                  source="limitedOffer"
                ></BooleanField>
                <DateField
                  label="Limited Offer Due Date"
                  source="limitedOfferDueDate"
                />
                <DateField label="Offer Creation Date" source="createdAt" />
                <TextField label="Comment" source="comment" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>

          <Tab label="Booking States" path="bookingStates">
            <RecordFetcher setRecord={setRecord} />
            {/* <CreateBookingStateButton /> */}
            <ReferenceManyField
              reference="bookingStates"
              target="bookingId"
              addLabel={false}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <Datagrid>
                <SelectField source="state" choices={BookingStatusChoices} />
                <LongDateField source="createdAt" />
                <TextField label="bookingId" source="bookingId" />
                <TextField label="Created By" source="description.email" emptyText={"N/A"} />
              </Datagrid>
            </ReferenceManyField>
                  
            { checkIsBookingCompleted() }

            <br></br>
            
          </Tab>

          {/* <Tab label="Payments" path="payments">
            <RecordFetcher setRecord={setRecord} />
            <CreatePaymentButton />
            <ReferenceManyField reference="payments" target="bookingId" addLabel={false} sort={{ field: 'createdAt', order: 'DESC' }}>
              <Datagrid rowClick={handleRowClick}>
                <Button addNegativeMargin customComponent={EditButton} label=""/>
                <CurrencyField label="Amount" source="amountCents" />
                

                <TextField source="description" />
                <SelectField source="paymentMode" choices={PaymentModeChoices}/>
                <SelectField source="currentState" choices={PaymentStateChoices}/>
                
                <LongDateField source="createdAt" />
                
                <ReferenceManyField reference="paymentStates" target="paymentId" label={'Payment States'}>
                  <SingleFieldList linkType={'show'}>
                    <ChipSelectField source="state" choices={PaymentStateChoices}/>
                  </SingleFieldList>
                </ReferenceManyField>
              </Datagrid>
            </ReferenceManyField>
          </Tab> */}

          <Tab label="Vouchers" path="vouchers">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceManyField
              label="Vouchers"
              reference="vouchers"
              target="bookingId"
            >
              <Datagrid
                rowClick={false}
                rowStyle={voucherRowStyle}
                expand={<VoucherPanel basePath={"/vouchers"} />}
              >
                <Button
                  addNegativeMargin
                  customComponent={EditButton}
                  label=""
                />
                {/* <PrintVoucherButton /> */}
                <TextField source="voucherNumber" />
                <LongDateField source="createdAt" />

                <TextField source="supportName" />
                <TextField source="supportPhoneNumber" />

                <TextField source="createdBy" />
                <CustomUrlField displayName="Pdf Link" source="voucherUrl" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>

          <Tab label="Crm" path="crm">
            <RecordFetcher setRecord={setRecord} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
              style={{ marginTop: "-8px" }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                style={{ padding: "1rem", borderRight: "1px solid #D0D0D0" }}
              >
                <ChatMessages
                  handleRemoveComment={handleRemoveComment.bind(this)}
                  commentList={record && record.crm && record.crm.comments}
                />
                <MaterialTextField
                  name="comments"
                  label="Comments"
                  variant="outlined"
                  className={classes.totalInput}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  multiline
                  rows={3}
                  onChange={(e) => setComment(e.target.value)}
                />
                <MaterialButton
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<SendIcon />}
                  onClick={handleAddComment}
                >
                  Send
                </MaterialButton>
              </Box>
              <Box
                display="flex"
                mt={2.2}
                className={classes.templateList}
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
              >
                {record && record.crm && record.crm.whatsAppMessage && (
                  <div className={classes.templateContainer}>
                    <div className={classes.templateHeading}>
                      WhatsApp Template
                    </div>
                    <div
                      id="whatsapp-template"
                      className={classes.templateBody}
                      dangerouslySetInnerHTML={{
                        __html: record.crm.whatsAppMessage,
                      }}
                    />
                    <MaterialButton
                      variant="contained"
                      color="primary"
                      className={classes.clipboardButton}
                      endIcon={<ContentCopyIcon />}
                      onClick={handleCopyToClipboard.bind(
                        this,
                        "whatsapp-template"
                      )}
                    >
                      Copy
                    </MaterialButton>
                  </div>
                )}
                {record && record.crm && record.crm.offerEmailTemplate && (
                  <div className={classes.templateContainer}>
                    <div className={classes.templateHeading}>
                      Offer Email Template
                    </div>
                    <div
                      id="email-template"
                      className={classes.templateBody}
                      dangerouslySetInnerHTML={{
                        __html: record.crm.offerEmailTemplate,
                      }}
                    />
                    <MaterialButton
                      variant="contained"
                      color="primary"
                      className={classes.clipboardButton}
                      endIcon={<ContentCopyIcon />}
                      onClick={handleCopyToClipboard.bind(
                        this,
                        "email-template"
                      )}
                    >
                      Copy
                    </MaterialButton>
                  </div>
                )}
                {record && record.crm && record.crm.quoteCheckTemplate && (
                  <div className={classes.templateContainer}>
                    <div className={classes.templateHeading}>
                      Quote Template
                    </div>
                    <div
                      id="quote-template"
                      className={classes.templateBody}
                      dangerouslySetInnerHTML={{
                        __html: record.crm.quoteCheckTemplate,
                      }}
                    />
                    <MaterialButton
                      variant="contained"
                      color="primary"
                      className={classes.clipboardButton}
                      endIcon={<ContentCopyIcon />}
                      onClick={handleCopyToClipboard.bind(
                        this,
                        "quote-template"
                      )}
                    >
                      Copy
                    </MaterialButton>
                  </div>
                )}
                <textarea
                  id="hidden-area"
                  style={{ display: "none" }}
                ></textarea>
              </Box>
            </Box>
          </Tab>
          <Tab label="Marketing Details">
            <BoxedShowLayout>
              <h3>Marketing Details</h3>
              <RaBox
                flexWrap="wrap"
                flexGrow={4}
                display="flex"
                justifyContent="space-between"
              >
                <TextField
                  className={classes.rowField}
                  label="Campaign"
                  source="marketingAttribute.campaign"
                />
                <TextField
                  className={classes.rowField}
                  label="Ad Group"
                  source="marketingAttribute.adGroup"
                />
                <TextField
                  className={classes.rowField}
                  label="Source"
                  source="marketingAttribute.source"
                />
                <TextField
                  className={classes.rowField}
                  label="Keyword"
                  source="marketingAttribute.keyword"
                />
                <TextField
                  className={classes.rowField}
                  label="Ad Id"
                  source="marketingAttribute.adId"
                />
                <TextField
                  className={classes.rowField}
                  label="Device"
                  source="marketingAttribute.device"
                />
                <TextField
                  className={classes.rowField}
                  label="Ad Position"
                  source="marketingAttribute.adPosition"
                />
              </RaBox>
              <RaBox
                flexWrap="wrap"
                marginTop={2}
                flexGrow={4}
                display="flex"
                justifyContent="flex-start"
              >
                <TextField
                  className={classes.rowField}
                  label="Click Location"
                  source="marketingAttribute.clickLocation"
                />
              </RaBox>
            </BoxedShowLayout>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default BookingShow;

import React from "react";
import {
  BooleanInput,
  DateInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField
} from "react-admin";
import { parse } from 'query-string';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const today = new Date();
const UserCreate = props => {
    // const { dmcBookingId } = parse(props.location.search);
  
    // const redirect = dmcBookingId ? `/dmcBooking/${dmcBookingId}/show/Users` : false;

    // console.log(dmcBookingId, redirect);

    return (
    <Create {...props}>
      <SimpleForm>
        <TextInput type="email" source="email" />
        <TextInput type="password" source="password"/>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <NumberInput source="userType" />
        <ReferenceInput source="organizationId" reference="organizations">
          <SelectInput optionText="companyName" />
        </ReferenceInput>
    </SimpleForm>
    </Create>
    );
}

export default UserCreate;

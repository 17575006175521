import { objToNameIdArray } from "Util/Constants";

export const TransferOfferStatus = {
  PENDING: "1",
  ACCEPTED: "2",
  CANCELLED: "3",
  EXPIRED: "4",
  COMPLETED: "5"
};

export const TransferOfferStatusChoices = objToNameIdArray(TransferOfferStatus);

export default {
  TransferOfferStatus,
  TransferOfferStatusChoices
};

import React, { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

import Editor from "../../../../../Components/Common/Editor/Editor";

import DefaultTemplates from "./DefaultTemplates";

const TemplatesOptions = [
  {
    id: 1,
    label: "City Details - At a Glance",
    value: "about",
    type: "city",
  },
  {
    id: 2,
    label: "City Details - Transporatation",
    value: "transportation",
    type: "city",
  },
  {
    id: 3,
    label: "City Details - Airport Transfers",
    value: "aboutAirportTransfers",
    type: "city",
  },
  {
    id: 4,
    label: "City Details - Sightseeing Transportation",
    value: "aboutSightseeingTransfers",
    type: "city",
  },
  {
    id: 5,
    label: "Airport Transfers - About Airport Transfer",
    value: "about",
    type: "airport",
  },
  {
    id: 6,
    label: "Airport Transfers - Why Rydeu For Airport Transfer",
    value: "whyRydeu",
    type: "airport",
  },
  {
    id: 7,
    label: "Sightseeing Transfers - At a Glance",
    value: "about",
    type: "sightseeing",
  },
  {
    id: 8,
    label: "Sightseeing Transfers - Why Visit",
    value: "whyVisit",
    type: "sightseeing",
  },
  {
    id: 9,
    label: "Sightseeing Transfers - Places",
    value: "placesToVisit",
    type: "sightseeing",
  },
  {
    id: 10,
    label: "Sightseeing Transfers - NearBy Places",
    value: "nearByPlaces",
    type: "sightseeing",
  },
];

const PlatformOptions = [
  {
    id: 1,
    label: "Web",
    value: "web",
  },
  {
    id: 2,
    label: "Mobile",
    value: "mobile",
  },
];

const Templates = (props) => {
  const notify = useNotify();
  const [template, setTemplate] = useState(JSON.stringify(TemplatesOptions[0]));
  const [templateData, setTemplateData] = useState("");
  const [platform, setPlatform] = useState(PlatformOptions[0].value);
  const dataProvider = useDataProvider();
  const [
    { cityDetails, cityDetailsLoading, cityDetailsError },
    setCityDetails,
  ] = useState({
    cityDetails: null,
    cityDetailsLoading: true,
    cityDetailsError: "",
  });
  const [
    { airportDetails, airportDetailsLoading, airportDetailsError },
    setAirportDetails,
  ] = useState({
    airportDetails: null,
    airportDetailsLoading: true,
    airportDetailsError: "",
  });
  const [
    { sightseeingDetails, sightseeingDetailsLoading, sightseeingDetailsError },
    setSightseeingDetails,
  ] = useState({
    sightseeingDetails: null,
    sightseeingDetailsLoading: true,
    sightseeingDetailsError: "",
  });

  const updateTemplate = (
    currentTemplateType = template,
    platformType = platform
  ) => {
    let selectedTemplate = JSON.parse(currentTemplateType);
    switch (selectedTemplate.type) {
      case "sightseeing":
        if (
          sightseeingDetails &&
          sightseeingDetails[selectedTemplate.value] &&
          sightseeingDetails[selectedTemplate.value][platformType]
        ) {
          setTemplateData(
            sightseeingDetails[selectedTemplate.value][platformType]
          );
        } else {
          setTemplateData(
            DefaultTemplates.sightseeingDetails[selectedTemplate.value][
              platformType
            ]
          );
        }
        break;
      case "airport":
        if (
          airportDetails &&
          airportDetails[selectedTemplate.value] &&
          airportDetails[selectedTemplate.value][platformType]
        ) {
          setTemplateData(airportDetails[selectedTemplate.value][platformType]);
        } else {
          setTemplateData(
            DefaultTemplates.airportDetails[selectedTemplate.value][
              platformType
            ]
          );
        }
        break;
      case "city":
        if (
          cityDetails &&
          cityDetails[selectedTemplate.value] &&
          cityDetails[selectedTemplate.value][platformType]
        ) {
          setTemplateData(cityDetails[selectedTemplate.value][platformType]);
        } else {
          setTemplateData(
            DefaultTemplates.cityDetails[selectedTemplate.value][platformType]
          );
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const clearTimer = setTimeout(() => {
      updateTemplate();
    }, 3000);
    return () => {
      clearTimeout(clearTimer);
    };
  }, [cityDetails, airportDetails, sightseeingDetails]);

  const getCityDetails = () => {
    dataProvider
      .getOne("operating-city", { id: props.citySlug })
      .then((res) => {
        setCityDetails({
          cityDetails: res.data,
          cityDetailsLoading: false,
          cityDetailsError: "",
        });
      })
      .catch((err) => {
        setCityDetails({
          cityDetails: null,
          cityDetailsLoading: false,
          cityDetailsError: err.message,
        });
      });
  };

  const getAirportDetails = () => {
    dataProvider
      .getOne(`operating-city/${props.citySlug}`, { id: "airport-transfers" })
      .then((res) => {
        setAirportDetails({
          airportDetails: res.data,
          airportDetailsLoading: false,
          airportDetailsError: "",
        });
      })
      .catch((err) => {
        setAirportDetails({
          airportDetails: null,
          airportDetailsLoading: false,
          airportDetailsError: err.message,
        });
      });
  };

  const getSightseeingDetails = () => {
    dataProvider
      .getOne(`operating-city/${props.citySlug}`, {
        id: "sightseeing-transfers",
      })
      .then((res) => {
        setSightseeingDetails({
          sightseeingDetails: res.data,
          sightseeingDetailsLoading: false,
          sightseeingDetailsError: "",
        });
      })
      .catch((err) => {
        setSightseeingDetails({
          sightseeingDetails: null,
          sightseeingDetailsLoading: false,
          sightseeingDetailsError: err.message,
        });
      });
  };

  const saveSightseeingTemplate = () => {
    let selectedTemplate = JSON.parse(template),
      data = {
        [selectedTemplate.value]: {
          [platform]: templateData,
        },
        city: cityDetails.id,
      };

    if (sightseeingDetails) {
      if (typeof sightseeingDetails[selectedTemplate.value] !== "string") {
        data[selectedTemplate.value] = {
          ...sightseeingDetails[selectedTemplate.value],
          ...data[selectedTemplate.value],
        };
      }
      dataProvider
        .update(`operating-city/${props.citySlug}`, {
          id: "sightseeing-transfers",
          data,
        })
        .then((res) => {
          notify("Template Saved Successfully");
          getSightseeingDetails();
        })
        .catch((err) => {
          notify(`Error: ${err.message}`);
        });
    } else {
      dataProvider
        .create(`operating-city/${props.citySlug}/sightseeing-transfers`, {
          data,
        })
        .then((res) => {
          notify("Template Saved Successfully");
          getSightseeingDetails();
        })
        .catch((err) => {
          notify(`Error: ${err.message}`);
        });
    }
  };

  const saveAirportTemplate = () => {
    let selectedTemplate = JSON.parse(template),
      data = {
        [selectedTemplate.value]: {
          [platform]: templateData,
        },
        city: cityDetails.id,
      };

    if (airportDetails) {
      if (typeof airportDetails[selectedTemplate.value] !== "string") {
        data[selectedTemplate.value] = {
          ...airportDetails[selectedTemplate.value],
          ...data[selectedTemplate.value],
        };
      }

      dataProvider
        .update(`operating-city/${props.citySlug}`, {
          id: "airport-transfers",
          data,
        })
        .then((res) => {
          notify("Template Saved Successfully");
          getAirportDetails();
        })
        .catch((err) => {
          notify(`Error: ${err.message}`);
        });
    } else {
      dataProvider
        .create(`operating-city/${props.citySlug}/airport-transfers`, {
          data,
        })
        .then((res) => {
          notify("Template Saved Successfully");
          getAirportDetails();
        })
        .catch((err) => {
          notify(`Error: ${err.message}`);
        });
    }
  };

  const saveCityTemplate = () => {
    let selectedTemplate = JSON.parse(template),
      data = {
        [selectedTemplate.value]: {
          [platform]: templateData,
        },
        name: cityDetails.name,
        country: cityDetails.country.id,
        region: cityDetails.region.id,
        visibility: cityDetails.visibility
      };

    if (typeof cityDetails[selectedTemplate.value] !== "string") {
      data[selectedTemplate.value] = {
        ...cityDetails[selectedTemplate.value],
        ...data[selectedTemplate.value],
      };
    }

    dataProvider
      .update("operating-city", {
        id: props.citySlug,
        data,
      })
      .then((res) => {
        notify("Template Saved Successfully");
        getCityDetails();
      })
      .catch((err) => {
        notify(`Error: ${err.message}`);
      });
  };

  useEffect(() => {
    getCityDetails();
    getAirportDetails();
    getSightseeingDetails();
  }, []);

  const handleTemplateType = (e) => {
    setTemplate(e.target.value);
    updateTemplate(e.target.value, platform);
  };

  const handlePlatformType = (e) => {
    setPlatform(e.target.value);
    updateTemplate(template, e.target.value);
  };

  const handleResetTemplate = () => {
    updateTemplate(template, platform);
  };

  const saveTemplateHandler = () => {
    let selectedTemplate = JSON.parse(template);
    switch (selectedTemplate.type) {
      case "sightseeing":
        saveSightseeingTemplate();
        break;
      case "airport":
        saveAirportTemplate();
        break;
      case "city":
        saveCityTemplate();
        break;

      default:
        break;
    }
  };

  if (
    cityDetailsLoading ||
    airportDetailsLoading ||
    sightseeingDetailsLoading
  ) {
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  if (
    (cityDetailsError && !cityDetailsLoading && !cityDetails) ||
    (airportDetailsError && !airportDetailsLoading && !airportDetails) ||
    (sightseeingDetailsError &&
      !sightseeingDetailsLoading &&
      !sightseeingDetails)
  ) {
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6">{cityDetailsError}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box
        mt={3}
        mb={3}
        width="100%"
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" width="50%" justifyContent="space-between">
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Template Type
            </InputLabel>
            <Select
              native={true}
              value={template}
              onChange={handleTemplateType}
              label="Template Type"
              inputProps={{
                name: "template",
                id: "outlined-age-native-simple",
              }}
            >
              {TemplatesOptions.map((item) => (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Platform Type
            </InputLabel>
            <Select
              native={true}
              value={platform}
              onChange={handlePlatformType}
              label="Platform Type"
              inputProps={{
                name: "platform",
                id: "outlined-age-native-simple",
              }}
            >
              {PlatformOptions.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width="30%" display="flex" justifyContent="space-between">
          <Button
            onClick={() => handleResetTemplate()}
            variant="contained"
            color="default"
          >
            Reset Template
          </Button>
          <Button
            onClick={() => saveTemplateHandler()}
            variant="contained"
            color="primary"
          >
            Save Template
          </Button>
        </Box>
      </Box>
      <Box width={platform === "mobile" ? 500 : 1024}>
        <Editor
          config={{
            height: "70vh",
            width: "100%",
          }}
          onChange={(e) => setTemplateData(e.editor.getData())}
          data={templateData}
        />
      </Box>
    </Box>
  );
};

export default Templates;

import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../Components/Common/Modal/Modal";

const OperatingCityFormSchema = Yup.object().shape({
  city: Yup.string().trim().min(2, "Too Short!").required(),
  country: Yup.string()
    .trim()
    .min(2, "Too Short!")
    .max(2, "Should not be more than 2 characters")
    .uppercase()
    .required(),
  isEmailOptional: Yup.boolean().required(),
  distance: Yup.number().min(1).required(),
  minimumLeadHours: Yup.number().min(1).required(),
  bookingPreference: Yup.string().trim().required(),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    inputContainer: {
      width: "45%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
  };
});

const OpertaingCityModal = ({
  showModal,
  closeModal,
  refreshList,
  cityDetails,
  cityList,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setFieldValue,
  } = useFormik({
    validationSchema: OperatingCityFormSchema,
    initialValues: {
      city: "",
      country: "",
      isEmailOptional: false,
      bookingPreference: "BOTH",
      distance: 30,
      minimumLeadHours: 6,
      ...cityDetails,
    },
    onSubmit: (data) => {
      if (cityDetails) {
        editCity(data);
      } else {
        addCity(data);
      }
    },
  });

  const addCity = (data) => {
    const cityDetails = { ...data };

    if (cityDetails.baseCity) {
      cityDetails.baseCityId = cityDetails.baseCity.id;

      delete cityDetails.baseCity;
    } else {
      cityDetails.baseCityId = null;
    }

    dataProvider
      .create("cities", { data: cityDetails })
      .then(() => {
        closeModal();
        notify("Operating city created successfully");
        window.location.reload();
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editCity = (data) => {
    dataProvider
      .update("cities", {
        id: cityDetails.id,
        data: {
          isEmailOptional: data.isEmailOptional,
          distance: data.distance,
          minimumLeadHours: data.minimumLeadHours,
          bookingPreference: data.bookingPreference,
          baseCityId: data.baseCity?.id || null,
          deleteAlias: Boolean(!data.baseCity?.id),
        },
      })
      .then(() => {
        closeModalHandler();
        notify("city updated successfully");
        window.location.reload();
      })
      .catch((error) => {
        closeModalHandler();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const closeModalHandler = () => {
    handleReset();
    closeModal();
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">
          {cityDetails ? "Edit City" : "Create City"}
        </Typography>
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          {!cityDetails && (
            <Box display="flex" justifyContent="space-between">
              <TextField
                label="City Name"
                error={"city" in errors && "city" in touched}
                helperText={
                  "city" in errors && "city" in touched && errors.city
                }
                variant="outlined"
                onChange={handleChange("city")}
                onBlur={handleBlur("city")}
                value={values.city}
                className={classes.inputContainer}
              />
              <TextField
                label="Country"
                error={"country" in errors && "country" in touched}
                helperText={
                  "country" in errors && "country" in touched && errors.country
                }
                variant="outlined"
                onChange={handleChange("country")}
                onBlur={handleBlur("country")}
                value={values.country}
                className={classes.inputContainer}
              />
            </Box>
          )}

          <Box display="flex" justifyContent="space-between">
            <FormControl variant="outlined" className={classes.inputContainer}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Is Email Optional?
              </InputLabel>
              <Select
                native={true}
                value={values.isEmailOptional}
                onChange={handleChange("isEmailOptional")}
                onBlur={handleBlur("isEmailOptional")}
                label="isEmailOptional"
                inputProps={{
                  name: "Is Email Optional?",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value={true}>On</option>
                <option value={false}>Off</option>
              </Select>
            </FormControl>

            <TextField
              label="Distance"
              error={"distance" in errors && "distance" in touched}
              helperText={
                "distance" in errors && "distance" in touched && errors.distance
              }
              type="number"
              variant="outlined"
              onChange={handleChange("distance")}
              onBlur={handleBlur("distance")}
              value={values.distance}
              className={classes.inputContainer}
            />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <FormControl variant="outlined" className={classes.inputContainer}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Booking Preference
              </InputLabel>
              <Select
                native={true}
                value={values.bookingPreference}
                onChange={handleChange("bookingPreference")}
                onBlur={handleBlur("bookingPreference")}
                label="bookingPreference"
                inputProps={{
                  name: "Booking Preference",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value={"BOOK_NOW"}>Book now</option>
                <option value={"RESERVE_AND_PAY"}>Reserve and pay</option>
                <option value={"BOTH"}>Both</option>
              </Select>
            </FormControl>

            <TextField
              label="Minimum Lead Hours"
              error={
                "minimumLeadHours" in errors && "minimumLeadHours" in touched
              }
              helperText={
                "minimumLeadHours" in errors &&
                "minimumLeadHours" in touched &&
                errors.minimumLeadHours
              }
              type="number"
              variant="outlined"
              onChange={handleChange("minimumLeadHours")}
              onBlur={handleBlur("minimumLeadHours")}
              value={values.minimumLeadHours}
              className={classes.inputContainer}
            />
          </Box>

          <Autocomplete
            id="controlled-demo"
            getOptionLabel={(option) =>
              `${option.city || "N/A"}-${option.country || "N/A"}`
            }
            value={values.baseCity}
            options={
              cityList
                ? cityList
                    .filter(
                      (city) =>
                        city.id === city.baseCityId || city.baseCityId === null
                    )
                    .map((city) => ({ ...city, value: city.id }))
                : []
            }
            onChange={(event, newValue) => {
              setFieldValue("baseCity", newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Base city" variant="outlined" />
            )}
          />

          <Box display="flex" mt={2} justifyContent="space-between">
            <Button onClick={closeModalHandler} variant="text" color="primary">
              {cityDetails ? "Discard" : "Cancel"}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {cityDetails ? "Save" : "Submit"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default OpertaingCityModal;

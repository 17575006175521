import React from 'react';
import { DateField } from 'react-admin';
import { LONG_DATE_FORMAT } from 'Constants';

const LongDateField = (props) => (
  <DateField
    showTime
    {...props}
    options={LONG_DATE_FORMAT}
  />
);

LongDateField.defaultProps = {
    addLabel: true,
};

export default LongDateField;

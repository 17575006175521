import React from "react";
import {
  SelectField,
  BooleanField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
} from "react-admin";
import LongDateField from "../../Components/Common/Fields/LongDateField";
import CurrencyField from "Components/Common/Fields/CurrencyField";
import { PricingGroupChoices } from "Constants/Pricing";
import { BookingTypeChoices } from "Constants/Bookings";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import RecordFetcher from "Components/Common/RecordFetcher";

const PricingTitle = ({ record }) => {
  return (
    <span>Pricing: {record ? `${record.name || record.domainName}` : ""}</span>
  );
};

export const PricingShow = (props) => {
  const [record, setRecord] = React.useState({});
  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Pricings",
      url: "/pricings",
    },
    {
      name: `${record.id}`,
      url: `/pricings/${record.id}/show`,
    },
  ];

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show {...props}>
        <SimpleShowLayout>
          <RecordFetcher setRecord={setRecord} />
          {/* <TextField source="id" /> */}
          <TextField source="city" />
          <TextField source="country" />
          {/* <SelectField source="pricingType" choices={BookingTypeChoices} /> */}
          {/* <SelectField source="pricingGroup" choices={PricingGroupChoices} /> */}
          <ReferenceField source="vehicleTypeId" reference="vehicleTypes">
            <TextField source="name" />
          </ReferenceField>

          <BooleanField source="isDisable" />

          <CurrencyField
            label="Amount Airport Fees"
            source="amountCentsAirportFees"
          />
          <CurrencyField label="Amount Per Hour" source="amountCentsPerHour" />
          <CurrencyField label="Amount Per KM" source="amountCentsPerKm" />
          {/* <CurrencyField label="Holiday Surcharges" source="holidaySurcharges" /> */}
          {/* <CurrencyField label="Late Night Charges" source="lateNightCharges" /> */}
          <CurrencyField label="Base Amount" source="baseAmountCents" />
          <TextField source="baseKms" />
          {/* <TextField source="vatPercentage" /> */}
          {/* <TextField source="currency" /> */}
          {/* <TextField source="selfDrive" /> */}
          {/* <BooleanField source="fixedPricing" /> */}
          <LongDateField source="createdAt" />
          <LongDateField source="updatedAt" />
          {/* <LongDateField source="deletedAt" /> */}
        </SimpleShowLayout>
      </Show>
    </>
  );
};

export default PricingShow;

import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider, useRedirect, useNotify } from "react-admin";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment-timezone";
import { getItem } from "Util/storage";
import { API_HOSTNAME as apiUrl } from "Constants";
import CreateEditOperatingCity from "./CreateEditSurge";

const OperatingCityList = (props) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const getList = useCallback(() => {
    dataProvider
      .getList("surges")
      .then((res) => {
        setResponse({
          data: Object.values(res.data),
          loading: false,
          error: null,
        });
      })
      .catch((err) => {
        setResponse({ data: null, loading: false, error: err.message });
      });
  }, []);

  const deleteSurge = (surgeId) => {
    dataProvider
      .delete("surges", { id: surgeId })
      .then(() => {
        getList();
        notify("Surge deleted successfully");
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const getServiceLocations = async () => {
    const defaultOptions = {
      headers: {
        authorization: `Bearer ${getItem("token")}`,
      },
    };
    return await fetch(`${apiUrl}/admin/serviceLocations`, defaultOptions)
      .then((response) => response.json())
      .then((data) => data);
  };

  useEffect(() => {
    getServiceLocations()
      .then(async (response) => {
        console.log(response.data);
        if (response.data) {
          let countries = response.data.countryList.map((data) => ({
            id: data.country,
            name: data.country,
          }));
          setCountries([
            { id: "global", name: "global", country: "global" },
            ...countries,
          ]);
          let cities = response.data.cityList.map((data) => ({
            id: data.city,
            name: data.city,
            location: data.location,
            country: data.country,
          }));
          setCities([
            { id: "global", name: "global", country: "global" },
            ...cities,
          ]);
        }
      })
      .catch((error) => {
        notify(error);
      });
    getList();
  }, []);

  return (
    <Box>
      <Box mt={2} mb={5} display="flex" justifyContent="space-between">
        <Typography variant="h4">Surges</Typography>
        <CreateEditOperatingCity
          countries={countries}
          cities={cities}
          refreshList={getList}
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {data && !error && !loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>City</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Surge Percentage</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.city.toUpperCase()}</TableCell>
                  <TableCell>
                    {row.country ? row.country.toUpperCase() : row.country}
                  </TableCell>
                  <TableCell>{row.surgePercentage}</TableCell>
                  <TableCell>
                    {moment(row.startDate).utc().format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(row.endDate).utc().format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(row.createdAt).utc().format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    <CreateEditOperatingCity
                      countries={countries}
                      cities={cities}
                      refreshList={getList}
                      surgeDetails={row}
                    />

                    <Button
                      onClick={() => deleteSurge(row.id)}
                      variant={"text"}
                      color="primary"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default OperatingCityList;

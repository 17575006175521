import React, { useState, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

import { useDataProvider, useNotify, useRedirect } from "react-admin";
import * as Yup from "yup";
import { useFormik, ErrorMessage } from "formik";
import Modal from "../../Components/Common/Modal/Modal";
import {
  AdminPaymentModesChoices,
  BookingPaymentTypeChoices,
  BookingPaymentType,
  CurrenciesChoices,
} from "Constants/Payments";

const BookingConfirmSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(2, "First name is too short!")
    .matches(/^[aA-zZ\s]+$/, "First name must be only alphabets"),
  lastName: Yup.string()
    .trim()
    .min(2, "Last name is too short!")
    .matches(/^[aA-zZ\s]+$/, "Last name must be only alphabets"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Passenger mobile must be a number")
    .min(10, "Must be atleast 10 digits")
    .max(13, "Must be less than 13 digits"),
  flightNo: Yup.string().trim().min(4, "Flight number is too short!"),
  passengerName: Yup.string()
    .trim()
    .min(2, "Passenger name is too short!")
    .matches(/^[aA-zZ\s]+$/, "Passenger Name must be only alphabets"),
  passengerMobileNo: Yup.string()
    .matches(/^[0-9]+$/, "Passenger mobile must be a number")
    .min(10, "Must be atleast 10 digits")
    .max(13, "Must be less than 13 digits"),
  paymentMode: Yup.string().trim().required(),
  bookingType: Yup.string(),
  reservationAmount: Yup.number()
    .nullable(true)
    .when("bookingType", {
      is: (bookingType) =>
        bookingType === BookingPaymentType.RESERVE_AND_PAY_CUSTOM,
      then: Yup.number()
        .typeError("Reservation Amount must be a number !")
        .required("Reservation Amount is required !"),
    }),
  totalAmount: Yup.number()
    .nullable(true)
    .when("bookingType", {
      is: (bookingType) =>
        bookingType === BookingPaymentType.RESERVE_AND_PAY_CUSTOM,
      then: Yup.number()
        .typeError("Total Amount must be a number !")
        .required("Total Amount is required !"),
    }),
  pendingAmount: Yup.number()
    .nullable(true)
    .when("bookingType", {
      is: (bookingType) =>
        bookingType === BookingPaymentType.RESERVE_AND_PAY_CUSTOM,
      then: Yup.number()
        .typeError("Pending Amount must be a number !")
        .required("Pending Amount is required !"),
    }),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    heading: {
      textAlign: "center",
      letterSpacing: "1.5px",
      fontWeight: "600",
    },
    radioGroup: {
      justifyContent: "space-around",
    },
    radioContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: "1.5rem",
    },
    errorMessage: {
      paddingLeft: 5,
      fontSize: 14,
      color: "#8B0000",
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    subfieldContainer: {
      display: "flex",
      flexDirection: "column",
      width: "25%",
    },
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    inputContainer: {
      // width: "45%",
      width: "95%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
  };
});

const BookingConfirmModal = ({
  showModal,
  closeModal,
  refreshList,
  transferOfferRecord,
  userId,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
  } = useFormik({
    validationSchema: BookingConfirmSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      flightNo: "",
      passengerName: "",
      passengerMobileNo: "",
      bookingType: BookingPaymentTypeChoices[0].id,
      paymentMode: AdminPaymentModesChoices[0].id,
      currency:
        transferOfferRecord?.transfer?.currency?.code ||
        CurrenciesChoices[0].id,
      reservationAmount: null,
      totalAmount: null,
      pendingAmount: null,
    },
    onSubmit: (data) => {
      // call api here
      const confirmData = {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        flightNo: data.flightNo,
        passengerName: data.passengerName,
        passengerMobileNo: data.passengerMobileNo,
        paymentMode: data.paymentMode,
        userId,
        transferOfferId: transferOfferRecord.id,
        isReservation:
          data.bookingType === BookingPaymentType.RESERVE_AND_PAY ||
          data.bookingType === BookingPaymentType.RESERVE_AND_PAY_CUSTOM,
        isCustomReservation:
          data.bookingType === BookingPaymentType.RESERVE_AND_PAY_CUSTOM,
        currency: data.currency,
      };


      if (confirmData.isCustomReservation) {
        confirmData.reservationAmount = parseFloat(data.reservationAmount);
        confirmData.totalAmount = parseFloat(data.totalAmount);
        confirmData.pendingAmount = parseFloat(data.pendingAmount);
      }

      dataProvider
        .create(`confirmBooking`, { data: removeNullVals(confirmData) })
        .then((res) => {
          notify("Booking Confirmed Successfully!");
          redirectTo(`/bookingRequests`);
        })
        .catch((error) => {
          notify("Something went wrong!");
        });
    },
  });

  const removeNullVals = (obj) => {
    return Object.keys(obj)
      .filter((k) => obj[k] != "")
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        <Box width={800} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
          <Typography className={classes.heading} variant="h5">
            Booking Confirm
          </Typography>

          <form className={classes.formContainer} onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between">
              <div className={classes.fieldContainer}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  onChange={handleChange("firstName")}
                  onBlur={handleBlur("firstName")}
                  value={values.firstName}
                  className={classes.inputContainer}
                />
                {errors.firstName && touched.firstName ? (
                  <div className={classes.errorMessage}>{errors.firstName}</div>
                ) : null}
              </div>

              <div className={classes.fieldContainer}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  onChange={handleChange("lastName")}
                  onBlur={handleBlur("lastName")}
                  value={values.lastName}
                  className={classes.inputContainer}
                />
                {errors.lastName && touched.lastName ? (
                  <div className={classes.errorMessage}>{errors.lastName}</div>
                ) : null}
              </div>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <div className={classes.fieldContainer}>
                <TextField
                  label="Phone No."
                  variant="outlined"
                  name="phoneNumber"
                  onChange={handleChange("phoneNumber")}
                  onBlur={handleBlur("phoneNumber")}
                  value={values.phoneNumber}
                  className={classes.inputContainer}
                />
                {errors.phoneNumber && touched.phoneNumber ? (
                  <div className={classes.errorMessage}>
                    {errors.phoneNumber}
                  </div>
                ) : null}
              </div>

              <div className={classes.fieldContainer}>
                <TextField
                  label="Flight No."
                  variant="outlined"
                  name="flightNo"
                  onChange={handleChange("flightNo")}
                  onBlur={handleBlur("flightNo")}
                  value={values.flightNo}
                  className={classes.inputContainer}
                />
                {errors.flightNo && touched.flightNo ? (
                  <div className={classes.errorMessage}>{errors.flightNo}</div>
                ) : null}
              </div>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <div className={classes.fieldContainer}>
                <TextField
                  label="Passenger Name"
                  variant="outlined"
                  name="passengerName"
                  onChange={handleChange("passengerName")}
                  onBlur={handleBlur("passengerName")}
                  value={values.passengerName}
                  className={classes.inputContainer}
                />
                {errors.passengerName && touched.passengerName ? (
                  <div className={classes.errorMessage}>
                    {errors.passengerName}
                  </div>
                ) : null}
              </div>

              <div className={classes.fieldContainer}>
                <TextField
                  label="Passenger Contact"
                  variant="outlined"
                  name="passengerMobileNo"
                  onChange={handleChange("passengerMobileNo")}
                  onBlur={handleBlur("passengerMobileNo")}
                  value={values.passengerMobileNo}
                  className={classes.inputContainer}
                />
                {errors.passengerMobileNo && touched.passengerMobileNo ? (
                  <div className={classes.errorMessage}>
                    {errors.passengerMobileNo}
                  </div>
                ) : null}
              </div>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <div className={classes.fieldContainer}>
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Payment Mode
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.paymentMode}
                    onChange={handleChange("paymentMode")}
                    onBlur={handleBlur("paymentMode")}
                    label="Payment Mode"
                    inputProps={{
                      name: "paymentMode",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {AdminPaymentModesChoices.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.fieldContainer}>
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Booking Type
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.bookingType}
                    onChange={handleChange("bookingType")}
                    onBlur={handleBlur("bookingType")}
                    label="Booking Type"
                    inputProps={{
                      name: "bookingType",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {BookingPaymentTypeChoices.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <div className={classes.fieldContainer}>
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Currency
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.currency}
                    onChange={handleChange("currency")}
                    onBlur={handleBlur("currency")}
                    label="currency"
                    inputProps={{
                      name: "currency",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {CurrenciesChoices.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {values.bookingType ===
                BookingPaymentType.RESERVE_AND_PAY_CUSTOM && (
                <div className={classes.fieldContainer}>
                  <TextField
                    label="Total Amount"
                    variant="outlined"
                    name="totalAmount"
                    onChange={handleChange("totalAmount")}
                    onBlur={handleBlur("totalAmount")}
                    value={values.totalAmount}
                    className={classes.inputContainer}
                  />
                  {errors.totalAmount && touched.totalAmount ? (
                    <div className={classes.errorMessage}>
                      {errors.totalAmount}
                    </div>
                  ) : null}
                </div>
              )}
            </Box>

            <Box display="flex" justifyContent="space-between">
              {values.bookingType ===
                BookingPaymentType.RESERVE_AND_PAY_CUSTOM && (
                <div className={classes.fieldContainer}>
                  <TextField
                    label="Reservation Amount"
                    variant="outlined"
                    name="reservationAmount"
                    onChange={handleChange("reservationAmount")}
                    onBlur={handleBlur("reservationAmount")}
                    value={values.reservationAmount}
                    className={classes.inputContainer}
                  />
                  {errors.reservationAmount && touched.reservationAmount ? (
                    <div className={classes.errorMessage}>
                      {errors.reservationAmount}
                    </div>
                  ) : null}
                </div>
              )}

              {values.bookingType ===
                BookingPaymentType.RESERVE_AND_PAY_CUSTOM && (
                <div className={classes.fieldContainer}>
                  <TextField
                    label="Pending Amount"
                    variant="outlined"
                    name="pendingAmount"
                    onChange={handleChange("pendingAmount")}
                    onBlur={handleBlur("pendingAmount")}
                    value={values.pendingAmount}
                    className={classes.inputContainer}
                  />
                  {errors.pendingAmount && touched.pendingAmount ? (
                    <div className={classes.errorMessage}>
                      {errors.pendingAmount}
                    </div>
                  ) : null}
                </div>
              )}
            </Box>

            <Box display="flex" mt={2} justifyContent="space-between">
              <Button onClick={closeModal} variant="text" color="primary">
                {"Close"}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid}
              >
                {"Confirm"}
              </Button>
            </Box>
          </form>
        </Box>
      </Fragment>
    </Modal>
  );
};

export default BookingConfirmModal;

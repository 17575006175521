import { objToNameIdArray } from 'Util/Constants';

export const PricingGroups = {
  B2B: "b2b",
  B2C: "b2c"
}

export const PricingGroupChoices = objToNameIdArray(PricingGroups);

export default {
  PricingGroups,
  PricingGroupChoices,
}
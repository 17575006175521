import React from "react";
import CKEditor from "ckeditor4-react";

CKEditor.editorUrl = "https://cdn.ckeditor.com/4.15.0/full/ckeditor.js";

const Editor = (props) => {
  return <CKEditor {...props} />;
};

export default Editor;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    button: {
      padding: spacing(0.625, 1.875),
    },
  };
});

export { useStyles };

import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../../../../Components/Common/Modal/Modal";
import Editor from "../../../../../Components/Common/Editor/Editor";

const AirportFormSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, "Too Short!").required(),
  shortDescription: Yup.string().trim(),
  images: Yup.object().shape({
    web: Yup.object().shape({
      cover: Yup.string().trim().url(),
      thumbnailLarge: Yup.string().trim().url(),
      thumbnailSmall: Yup.string().trim().url(),
    }),
    mobile: Yup.object().shape({
      cover: Yup.string().trim().url(),
      thumbnailLarge: Yup.string().trim().url(),
      thumbnailSmall: Yup.string().trim().url(),
    }),
  }),
  imagesCredits: Yup.object().shape({
    cover: Yup.string().trim(),
    thumbnailLarge: Yup.string().trim(),
    thumbnailSmall: Yup.string().trim(),
  }),
  latitude: Yup.number(),
  longitude: Yup.number(),
  city: Yup.string().required().matches(new RegExp("^[0-9a-fA-F]{24}$")),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    inputContainer: {
      width: "45%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
  };
});

const AirportModal = ({
  airportDetails,
  showModal,
  closeModal,
  refreshList,
  cityDetails,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [imageCreditType, setImageCreditType] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: AirportFormSchema,
    initialValues: {
      name: "",
      shortDescription: "",
      images: {
        web: {
          cover: "",
          thumbnailLarge: "",
          thumbnailSmall: "",
        },
        mobile: {
          cover: "",
          thumbnailLarge: "",
          thumbnailSmall: "",
        },
      },
      imagesCredits: {
        cover: "",
        thumbnailLarge: "",
        thumbnailSmall: "",
      },
      latitude: 0,
      longitude: 0,
      ...airportDetails,
      city: "",
    },
    onSubmit: (data) => {
      console.log(data);
      if (airportDetails) {
        editAirport(data);
      } else {
        addAirport(data);
      }
    },
  });

  useEffect(() => {
    if (cityDetails) {
      setFieldValue("city", cityDetails.id);
    }
  }, [cityDetails]);

  const addAirport = (data) => {
    dataProvider
      .create(`operating-city/${cityDetails.slug}/airports`, { data })
      .then(() => {
        closeModal();
        refreshList();
        notify("Airport created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editAirport = (data) => {
    dataProvider
      .update(`operating-city/${cityDetails.slug}/airports`, {
        id: airportDetails.slug,
        data: {
          name: data.name,
          shortDescription: data.shortDescription,
          about: data.about,
          bookAirportTransfer: data.bookAirportTransfer,
          images: data.images,
          imagesCredits: data.imagesCredits,
          latitude: data.latitude,
          longitude: data.longitude,
          city: data.city,
        },
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Airport Details updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        {!imageCreditType ? (
          <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
            <Typography variant="h5">{`${
              airportDetails ? "Edit" : "Create"
            } Airport`}</Typography>
            <form className={classes.formContainer} onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  label="Airport Name"
                  error={"name" in errors && "name" in touched}
                  helperText={
                    "name" in errors && "name" in touched && errors.name
                  }
                  variant="outlined"
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.name}
                  className={classes.inputContainer}
                />
                <TextField
                  label="Short Description"
                  multiline={true}
                  rows={3}
                  variant="outlined"
                  onChange={handleChange("shortDescription")}
                  onBlur={handleBlur("shortDescription")}
                  value={values.shortDescription}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "cover" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "cover" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "cover" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "cover" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Cover Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.cover")}
                  onBlur={handleBlur("images.web.cover")}
                  value={values.images.web.cover}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("cover")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.cover ||
                        "<p>Cover Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Small Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailSmall")}
                  onBlur={handleBlur("images.web.thumbnailSmall")}
                  value={values.images.web.thumbnailSmall}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("thumbnailSmall")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailSmall ||
                        "<p>Thumbnail Small Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Large Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailLarge")}
                  onBlur={handleBlur("images.web.thumbnailLarge")}
                  value={values.images.web.thumbnailLarge}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("thumbnailLarge")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailLarge ||
                        "<p>Thumbnail Large Image Credits</p>",
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  label="Latitude"
                  variant="outlined"
                  onChange={handleChange("latitude")}
                  onBlur={handleBlur("latitude")}
                  value={values.latitude}
                  className={classes.inputContainer}
                />
                <TextField
                  label="Longitude"
                  variant="outlined"
                  onChange={handleChange("longitude")}
                  onBlur={handleBlur("longitude")}
                  value={values.longitude}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  {airportDetails ? "Discard" : "cancel"}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {airportDetails ? "Save" : "Submit"}
                </Button>
              </Box>
            </form>
          </Box>
        ) : (
          <Box>
            <Box display="flex" bgcolor="white" justifyContent="space-between">
              <Button
                onClick={() => {
                  setTemplateData(values.imagesCredits[imageCreditType] || "");
                  setImageCreditType(null);
                }}
                variant="text"
                color="primary"
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  setFieldValue(
                    `imagesCredits.${imageCreditType}`,
                    templateData
                  );
                  setImageCreditType(null);
                }}
                style={{ borderRadius: 0 }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
            <Editor
              onChange={(e) => setTemplateData(e.editor.getData())}
              style={{ borderWidth: 0 }}
              config={{
                height: "400px",
                width: "100%",
                resize_enabled: false,
              }}
              data={templateData || values.imagesCredits[imageCreditType]}
            />
          </Box>
        )}
      </Fragment>
    </Modal>
  );
};

export default AirportModal;

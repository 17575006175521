import React, { useEffect } from "react";
import {
  BooleanField,
  SelectField,
  DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  ReferenceField,
  CardActions,
  FunctionField,
} from "react-admin";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import RecordFetcher from "Components/Common/RecordFetcher";
import ImagesArrayField from "Components/Common/Fields/ImagesArrayField";
import { TransferOfferStatusChoices } from "Constants/TransferOffers";
import moment from "moment-timezone";

const TransferOfferTitle = ({ record }) => {
  return <span>TransferOffer: {record ? `${record.id}` : ""}</span>;
};
const NoneActions = (props) => <CardActions />;

export const TransferOfferShow = ({ history, ...props }) => {
  const [record, setRecord] = React.useState({});
  const bookingNumber = record.transfer
    ? record.transfer.booking
      ? record.transfer.booking.bookingNumber
      : null
    : null;
  const bookingId = record.transfer ? record.transfer.bookingId : null;
  let breadcrumbLinks;
  if (
    history.location.state &&
    history.location.state.prevResource == "bookingRequest"
  ) {
    breadcrumbLinks = [
      {
        name: "Home",
        url: "/",
      },
      {
        name: "Booking Requests",
        url: "/bookingRequests",
      },
      {
        name: `${bookingNumber}`,
        url: `/bookingRequests/${bookingId}/show`,
      },
      {
        name: `Offers`,
        url: `/bookingRequests/${bookingId}/show/transferOffers`,
      },
      {
        name: `${record.id}`,
        url: `/transferOffers/${record.id}/show`,
      },
    ];
  } else if (
    history.location.state &&
    history.location.state.prevResource == "booking"
  ) {
    breadcrumbLinks = [
      {
        name: "Home",
        url: "/",
      },
      {
        name: "Bookings",
        url: "/bookings",
      },
      {
        name: `${bookingNumber}`,
        url: `/bookings/${bookingId}/show`,
      },
      {
        name: `Offers`,
        url: `/bookings/${bookingId}/show/transferOffers`,
      },
      {
        name: `${record.id}`,
        url: `/transferOffers/${record.id}/show`,
      },
    ];
  } else {
    breadcrumbLinks = [
      {
        name: "Home",
        url: "/",
      },
      {
        name: `Transfer Offers`,
        url: `/transferOffers`,
      },
      {
        name: `${record.id}`,
        url: `/transferOffers/${record.id}/show`,
      },
    ];
  }

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <RecordFetcher setRecord={setRecord} />
      <Show {...props} actions={<NoneActions />}>
        <SimpleShowLayout>
          <TextField label="Offer ID" source="id" />
          <FunctionField
            label="One Way Price"
            render={(record) => {
              return `${record.transfer.currency.symbol || "€"} ${
                record.oneWayPrice || 0
              }`;
            }}
          />
          <FunctionField
            label="Supplier Price"
            render={(record) => {
              return `${record.currency.symbol || "€"} ${
                record.originalTotalAmount || 0
              }`;
            }}
          />
          <FunctionField
            label="Return Price"
            render={(record) => {
              return `${record.transfer.currency.symbol || "€"} ${
                record.returnPrice || 0
              }`;
            }}
          />
          <BooleanField
            label="Limited Offer"
            source="limitedOffer"
          ></BooleanField>
          <DateField
            label="Limited Offer Due Date"
            source="limitedOfferDueDate"
          />
          <DateField label="Offer Expiry Date" source="offerExpiryDate" />
          <TextField label="Comment" source="comment" />
          <FunctionField
            label="Supplier Amount"
            render={(record) => {
              const createdAt = moment(`${record.createdAt}`);
              const boundaryDate = moment("2021-05-28 00:00Z");
              const diff = createdAt.diff(boundaryDate);
              if (diff > 0)
                return `${record.transfer.currency.symbol || "€"} ${
                  record.supplierAmount || 0
                }`;

              return record.oneWayPrice + record.returnPrice;
            }}
          />
          <FunctionField
            label="Commission Amount"
            render={(record) => {
              const createdAt = moment(`${record.createdAt}`);
              const boundaryDate = moment("2021-05-28 00:00Z");
              const diff = createdAt.diff(boundaryDate);
              if (diff > 0)
                return `${record.transfer.currency.symbol || "€"} ${
                  record.commissionAmount || 0
                }`;

              return (
                record.totalAmount - record.oneWayPrice - record.returnPrice
              );
            }}
          />

          <FunctionField
            label="Total Amount"
            render={(record) => {
              return `${record.transfer.currency.symbol || "€"} ${
                record.totalAmount || 0
              }`;
            }}
          />

          <FunctionField
            label="Amount Cents Per Hour"
            render={(record) => {
              return `${record.transfer.currency.symbol || "€"} ${
                record.amountCentsPerHour || 0
              }`;
            }}
          />

          <ReferenceField
            label="Offered Vehicles"
            source="vehicleId"
            linkType={false}
            reference="vehicles"
            emptyText="N/A"
          >
            <ImagesArrayField source="uploadPics" max={4} />
          </ReferenceField>

          <ArrayField label="Offer Ancillaries" source="offerAncillaries">
            <Datagrid empty={<p>N/A</p>}>
              <TextField label="Name" source="ancillary.name" />
              <TextField label="Price" source="ancillary.price" />
              <ImageField
                source="ancillary.imageUrl"
                label="Image"
                title="ancillary image"
              />
            </Datagrid>
          </ArrayField>

          <SelectField
            source="currentState"
            choices={TransferOfferStatusChoices}
          />
          <ReferenceField source="vendorId" linkType="show" reference="vendors">
            <TextField source="id" />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
    </>
  );
};

export default TransferOfferShow;

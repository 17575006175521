import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider } from "react-admin";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CreatePlace from "./CreateNearByPlace";
import DeletePlace from "./DeleteNearByPlace";

const NearByPlacesList = (props) => {
  const dataProvider = useDataProvider();
  const [sightseeings, setSightseeings] = useState([]);
  const [places, setPlaces] = useState([]);
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const getSightseeingList = () => {
    dataProvider
      .getList("operating-city/sightseeings")
      .then((res) => {
        setSightseeings(Object.values(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPlacesList = () => {
    dataProvider
      .getList(`operating-city/${props.citySlug}/places`)
      .then((res) => {
        setPlaces(Object.values(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getList = useCallback(() => {
    dataProvider
      .getList(`operating-city/${props.citySlug}/nearby-places`)
      .then((res) => {
        setResponse({
          data: Object.values(res.data),
          loading: false,
          error: null,
        });
      })
      .catch((err) => {
        setResponse({ data: null, loading: false, error: err.message });
      });
  }, []);

  useEffect(() => {
    getPlacesList();
    getSightseeingList();
    getList();
  }, []);

  return (
    <Box>
      <Box mt={3} mb={3} display="flex" justifyContent="flex-end">
        <CreatePlace
          sightseeings={sightseeings}
          nearByPlaces={data}
          places={places}
          refreshList={getList}
          citySlug={props.citySlug}
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {data && !error && !loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>NearBy Place Name</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name.toUpperCase()}</TableCell>
                  <TableCell align="center">
                    <DeletePlace
                      place={row}
                      refreshList={getList}
                      citySlug={props.citySlug}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default NearByPlacesList;

import React, { useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  makeStyles
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../../../../Components/Common/Modal/Modal";

const IntercityRouteFormSchema = Yup.object().shape({
  sourceCity: Yup.string().trim().min(2, "Too Short!").required(),
  destinationCity: Yup.string().trim().min(2, "Too Short!").required(),
  sourceCountry: Yup.string().trim().min(2, "Too Short!").required(),
  destinationCountry: Yup.string().trim().min(2, "Too Short!").required(),
  isPricingActive: Yup.boolean().required(),
  city: Yup.string().required().matches(new RegExp("^[0-9a-fA-F]{24}$"))
});

const Hours = Array.from(Array(25).keys()).map((value) => ({
  label: value,
  value: value * 60
}));

const Minutes = Array.from(Array(12).keys()).map((value) => ({
  label: value * 5,
  value: value * 5
}));

const timeConvert = (n) => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return {
    hours: rhours,
    minutes: rminutes
  };
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto"
    },
    inputContainer: {
      width: "45%",
      margin: spacing(2, 0)
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2)
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start"
    }
  };
});

const IntercityRouteModal = ({
  intercityRouteDetails,
  showModal,
  closeModal,
  refreshList,
  cityDetails
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue
  } = useFormik({
    validationSchema: IntercityRouteFormSchema,
    initialValues: {
      sourceCity: "",
      destinationCity: "",
      sourceCountry: "",
      destinationCountry: "",
      hours: 0,
      minutes: 0,
      lowestPrice: 0,
      hitCounts: 0,
      isPricingActive: false,
      ...intercityRouteDetails,
      distance:
        intercityRouteDetails && intercityRouteDetails.distance
          ? Math.round(intercityRouteDetails.distance / 1000)
          : 0 || 0,
      hours:
        intercityRouteDetails && intercityRouteDetails.duration
          ? timeConvert(intercityRouteDetails.duration).hours * 60
          : 0 || 0,
      minutes:
        intercityRouteDetails && intercityRouteDetails.duration
          ? timeConvert(intercityRouteDetails.duration).minutes
          : 0 || 0,
      city: ""
    },
    onSubmit: (data) => {
      console.log(data);
      if (intercityRouteDetails) {
        editIntercityRoute(data);
      } else {
        addIntercityRoute(data);
      }
    }
  });

  useEffect(() => {
    if (cityDetails) {
      setFieldValue("city", cityDetails.id);
    }
  }, [cityDetails]);

  const addIntercityRoute = (data) => {
    let httpBody = { ...data };

    httpBody.duration = Number(data.hours) + Number(data.minutes);
    httpBody.distance = data.distance * 1000;
    delete httpBody.hours;
    delete httpBody.minutes;

    dataProvider
      .create(`operating-city/${cityDetails.slug}/intercity-routes`, {
        data: httpBody
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Intercity Route created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editIntercityRoute = (data) => {
    let httpBody = { ...data };

    httpBody.duration = Number(data.hours) + Number(data.minutes);
    httpBody.distance = data.distance * 1000;
    delete httpBody.hours;
    delete httpBody.minutes;
    delete httpBody.slug;
    delete httpBody.region;

    dataProvider
      .update(`operating-city/${cityDetails.slug}/intercity-routes`, {
        id: intercityRouteDetails.slug,
        data: httpBody
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Intercity Route Details updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  console.log(errors);

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">{`${
          intercityRouteDetails ? "Edit" : "Create"
        } Intercity Route`}</Typography>
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="space-between">
            <TextField
              label="Source City"
              variant="outlined"
              onChange={handleChange("sourceCity")}
              onBlur={handleBlur("sourceCity")}
              value={values.sourceCity}
              className={classes.inputContainer}
            />
            <TextField
              label="Source Country"
              variant="outlined"
              onChange={handleChange("sourceCountry")}
              onBlur={handleBlur("sourceCountry")}
              value={values.sourceCountry}
              className={classes.inputContainer}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TextField
              label="Destination City"
              variant="outlined"
              onChange={handleChange("destinationCity")}
              onBlur={handleBlur("destinationCity")}
              value={values.destinationCity}
              className={classes.inputContainer}
            />
            <TextField
              label="Destination Country"
              variant="outlined"
              onChange={handleChange("destinationCountry")}
              onBlur={handleBlur("destinationCountry")}
              value={values.destinationCountry}
              className={classes.inputContainer}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "45%" }}
            >
              <FormControl
                variant="outlined"
                className={classes.inputContainer}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Duration (in hours)
                </InputLabel>
                <Select
                  native={true}
                  value={values.hours}
                  onChange={handleChange("hours")}
                  onBlur={handleBlur("hours")}
                  label="Duration (in hours)"
                  inputProps={{
                    name: "Duration (in hours)",
                    id: "outlined-age-native-simple"
                  }}
                >
                  {Hours.map((hour, index) => (
                    <option key={index} value={hour.value}>
                      {hour.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.inputContainer}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Duration (in mins)
                </InputLabel>
                <Select
                  native={true}
                  value={values.minutes}
                  onChange={handleChange("minutes")}
                  onBlur={handleBlur("minutes")}
                  label="Duration (in mins)"
                  inputProps={{
                    name: "Duration (in mins)",
                    id: "outlined-age-native-simple"
                  }}
                >
                  {Minutes.map((minute, index) => (
                    <option key={index} value={minute.value}>
                      {minute.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              label="Distance (in kms)"
              variant="outlined"
              onChange={handleChange("distance")}
              onBlur={handleBlur("distance")}
              value={values.distance}
              className={classes.inputContainer}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TextField
              label="Price (in euros)"
              variant="outlined"
              onChange={handleChange("lowestPrice")}
              onBlur={handleBlur("lowestPrice")}
              value={values.lowestPrice}
              className={classes.inputContainer}
              type="number"
            />
            <TextField
              label="Route hits count"
              variant="outlined"
              onChange={handleChange("hitCounts")}
              onBlur={handleBlur("hitCounts")}
              value={values.hitCounts}
              className={classes.inputContainer}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <FormControl variant="outlined" className={classes.inputContainer}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Is Pricing Active?
              </InputLabel>
              <Select
                native={true}
                value={values.isPricingActive}
                onChange={handleChange("isPricingActive")}
                onBlur={handleBlur("isPricingActive")}
                label="Is Pricing Active?"
                inputProps={{
                  name: "isPricingActive",
                  id: "outlined-age-native-simple"
                }}
              >
                <option value={true}>On</option>
                <option value={false}>Off</option>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" mt={2} justifyContent="space-between">
            <Button onClick={closeModal} variant="text" color="primary">
              {intercityRouteDetails ? "Discard" : "cancel"}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {intercityRouteDetails ? "Save" : "Submit"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default IntercityRouteModal;

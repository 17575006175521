import React from 'react'
import { 
  Datagrid, Filter, Show, SimpleShowLayout, 
  TextField, DateField, SelectField, 
  TextInput, ReferenceField,
} from 'react-admin';import LongDateField from '../../Components/Common/Fields/LongDateField'
import { OrderStateStatusChoices } from 'Constants/OrderStates';

const OrderStateTitle = ({ record }) => {
  return (
    <span>
      OrderState: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const OrderStateShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <SelectField source="state" choices={OrderStateStatusChoices}/>
      <DateField source="createdAt" />
      <ReferenceField link="show" source="orderId" reference="orders">
        <TextField source="id" />
      </ReferenceField>
  </SimpleShowLayout>
  </Show>
)

export default OrderStateShow;
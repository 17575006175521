import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik, FieldArray, Field } from "formik";
import Modal from "../../../../../../../Components/Common/Modal/Modal";
import Editor from "../../../../../../../Components/Common/Editor/Editor";

const AirportFormSchema = Yup.object().shape({
  sourceCity: Yup.string().trim().min(2, "Too Short!").required(),
  sourceCountry: Yup.string().trim().min(2, "Too Short!").required(),
  destinationCity: Yup.string().trim().min(2, "Too Short!").required(),
  destinationCountry: Yup.string().trim().min(2, "Too Short!").required(),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    label: {
      width: "25%",
    },
    inputContainer: {
      width: "25%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
  };
});

const IntercityRoutePricingModal = ({
  showModal,
  closeModal,
  refreshList,
  pricings,
  vehicleTypes,
  isPricingCreated,
  intercityRouteDetails,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [imageCreditType, setImageCreditType] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      pricings,
    },
    onSubmit: (data) => {
      console.log(data);
      let pricings = Object.values(data.pricings).map((pricing) => ({
        vehicleTypeId: pricing.vehicleType.id,
        b2b: pricing.b2b.price * 100,
        b2c: pricing.b2c.price * 100,
      }));
      let httpBody = {
        sourceCity: intercityRouteDetails.sourceCity,
        sourceCountry: intercityRouteDetails.sourceCountry,
        destinationCity: intercityRouteDetails.destinationCity,
        destinationCountry: intercityRouteDetails.destinationCountry,
        data: pricings,
      };

      addPricings(httpBody);
    },
  });

  useEffect(() => {
    if (pricings && vehicleTypes) {
      let previousPricings = {};
      for (let i = 0; i < vehicleTypes.length; i++) {
        if (pricings.hasOwnProperty(vehicleTypes[i].id)) {
          previousPricings[vehicleTypes[i].id] = pricings[vehicleTypes[i].id];
          previousPricings[vehicleTypes[i].id].b2b = pricings[
            vehicleTypes[i].id
          ].b2b || { price: 0 };
          previousPricings[vehicleTypes[i].id].b2c = pricings[
            vehicleTypes[i].id
          ].b2c || { price: 0 };
        } else {
          previousPricings[vehicleTypes[i].id] =
            previousPricings[vehicleTypes[i].id] || {};
          previousPricings[vehicleTypes[i].id].id = vehicleTypes[i].id;
          previousPricings[vehicleTypes[i].id].vehicleType = {
            ...vehicleTypes[i],
          };
          previousPricings[vehicleTypes[i].id].b2b = {
            price: 0,
          };
          previousPricings[vehicleTypes[i].id].b2c = {
            price: 0,
          };
        }
      }
      setFieldValue("pricings", previousPricings);
    }
  }, [pricings, vehicleTypes]);

  const addPricings = (data) => {
    dataProvider
      .create(`interCityPricings`, { data })
      .then(() => {
        closeModal();
        refreshList();
        notify(
          `Pricings ${isPricingCreated ? "updated" : "created"} successfully`
        );
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={"50%"} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">{`${
          isPricingCreated ? "Edit" : "Create"
        } Pricings`}</Typography>
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            mt={2}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography
                className={classes.inputContainer}
                align="left"
                variant="h6"
              >
                Vehicle Type
              </Typography>
              <Typography
                className={classes.inputContainer}
                align="left"
                variant="h6"
              >
                B2C Pricing
              </Typography>
              <Typography
                className={classes.inputContainer}
                align="left"
                variant="h6"
              >
                B2B Pricing
              </Typography>
            </Box>

            {values.pricings &&
              Object.values(values.pricings).map((pricing, index) => (
                <Box key={index} display="flex" justifyContent="space-between">
                  <Typography
                    className={classes.inputContainer}
                    align="left"
                    variant="h6"
                  >
                    {pricing?.vehicleType?.name}
                  </Typography>

                  <TextField
                    label="Price"
                    variant="outlined"
                    onChange={handleChange(
                      `pricings.${pricing?.vehicleType?.id}.b2c.price`
                    )}
                    onBlur={handleBlur(
                      `pricings.${pricing?.vehicleType?.id}.b2c.price`
                    )}
                    value={values.pricings[pricing?.vehicleType?.id].b2c.price}
                    className={classes.inputContainer}
                  />
                  <TextField
                    label="Price"
                    variant="outlined"
                    onChange={handleChange(
                      `pricings.${pricing?.vehicleType?.id}.b2b.price`
                    )}
                    onBlur={handleBlur(
                      `pricings.${pricing?.vehicleType?.id}.b2b.price`
                    )}
                    value={values.pricings[pricing?.vehicleType?.id].b2b?.price}
                    className={classes.inputContainer}
                  />
                </Box>
              ))}

            <Box display="flex" justifyContent="space-between">
              <Button onClick={closeModal} variant="text" color="primary">
                cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default IntercityRoutePricingModal;

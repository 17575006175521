import React from 'react'
import { BooleanInput, DateInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const OrganizationTitle = ({ record }) => {
  return (
    <span>
      Organization: {record ? `${record.companyName}` : ''}
    </span>
  )
}

export const OrganizationEdit = props => {
  const [record, setRecord] = React.useState({});

  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Organizations',
      url: '/organizations',
    },
    {
      name: `${record.companyName}`,
      url: `/organizations/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/organizations/${record.id}`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<OrganizationTitle />} {...props}>
        <SimpleForm>
          <RecordFetcher setRecord={setRecord} />
          <TextInput disabled source="id" />
          <TextInput source="companyName" />
          {/* <TextInput source="firstName" /> */}
          {/* <TextInput source="lastName" /> */}
          <TextInput source="domainName" />
          <DateInput source="contractStartDate" />
          <DateInput source="contractEndDate" />
          <TextInput source="registrationNo" />
          <TextInput source="vatNo" />
          {/* <ReferenceInput source="taxId" reference="taxes"><SelectInput optionText="id" /></ReferenceInput> */}
          <BooleanInput source="termAndConditions" />
          <BooleanInput source="active" />
          <BooleanInput source="blocked" />
          <BooleanInput source="allowInvoicing" />
          <NumberInput source="discountPercentage" />
          <TextInput type="email" source="email" />
          <TextInput source="mobileNo" />
          <TextInput source="gstnNo" />
          <TextInput source="country" />
          <TextInput source="currency" />
          <TextInput disabled source="createdAt" />
          <TextInput disabled source="updatedAt" />
        </SimpleForm>
      </Edit>
    </>
  )

}

export default OrganizationEdit;
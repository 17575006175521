import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  SimpleForm,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import Modal from "../Components/Common/Modal/Modal";
import keyBy from "lodash/keyBy";
import {
  useQuery,
  Datagrid,
  TextField as ReactTextField,
  Pagination,
  Loading,
  ListContextProvider,
  FunctionField,
  BooleanField,
  DateField,
} from "react-admin";
import { TransferTypes } from "Constants/Transfers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OfferSchema = Yup.object().shape({
  offerType: Yup.string().trim(),
  vendorId: Yup.string().label("Vendor Email"),
  vehicleId: Yup.string().label("Vehicle"),
  totalAmount: Yup.string().label("Total Amount"),
  hourlyRate: Yup.string().label("Total Amount"),
});

const VendorReassignModal = ({
  showModal,
  closeModal,
  refreshList,
  bookingRecord,
}) => {
  const transferType =
    bookingRecord &&
    bookingRecord.transfers &&
    bookingRecord.transfers[0]?.transferType;
  const returnDateTime =
    bookingRecord &&
    bookingRecord.transfers &&
    bookingRecord.transfers[0]?.returnDateTime;
  const [selectedOffer, setSelectedOffer] = useState(null);
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const [vendorList, setVendorList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [ancillaryList, setAncillaryList] = useState([]);

  useEffect(() => {
    fetchAncillaries();
  }, []);

  const fetchVendors = (emailInput) => {
    dataProvider
      .getList(`vendors`, {
        filter: { removeLimit: true, q: emailInput },
        sort: { field: "createdAt", order: "ASC" },
      })
      .then((res) => {
        setVendorList([
          ...res.data
            .map((vendorObj) => {
              return {
                id: vendorObj.id,
                currencyId: vendorObj.currencyId,
                email: vendorObj.user?.email,
                currencySymbol: vendorObj?.currency?.symbol,
              };
            })
            .filter((obj) => obj.email != undefined),
        ]);
      })
      .catch((error) => {
        notify("Something went wrong!");
      });
  };

  const handleVendorInputEmail = (event) => {
    if (event.target.value && event.target.value.length > 4)
      fetchVendors(event.target.value);
  };

  const fetchAncillaries = () => {
    dataProvider
      .getList(`ancillary`, {
        sort: { field: "createdAt", order: "ASC" },
        offset: 0,
      })
      .then((res) => {
        setAncillaryList(res.data);
      })
      .catch((error) => {
        notify("Something went wrong!");
      });
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: OfferSchema,
    initialValues: {
      offerType: "existing-offer",
      vendorId: "",
      currencyId: "",
      vehicleId: "",
      ancillary: [],
      limitedOfferCheck: false,
      limitedOfferDate: new Date(),
      totalAmount: 0,
      amountToCollect: 0,
      supplierAmount: 0,
      hourlyRate: "",
      comments: "",
    },
    onSubmit: (data) => {
      let reassignData;
      if (data.offerType === "new-offer") {
        if (data.vendorId == "") {
          notify("Vendor Email is compulsory for new offer !");
          return;
        }

        if (!data.totalAmount) {
          notify("Total Amount is required for new offer !");
          return;
        }

        if (
          bookingRecord.transfers[0]?.reservationAmountCents &&
          !data.amountToCollect
        ) {
          notify("Collection Amount is required for new offer !");
          return;
        }

        if (transferType === TransferTypes.HOURLY && data.hourlyRate === "") {
          notify("Hourly rate is required for hourly type transfer !");
          return;
        }

        const commissionAmount =
          Number(data.totalAmount) - Number(data.supplierAmount);

        const reservationAmount =
          Number(data.totalAmount) - Number(data.amountToCollect);

        const newCommissionPercentage =
          (commissionAmount / Number(data.totalAmount)) * 100;

        reassignData = {
          ancillary: ancillaryList
            .filter((anc) => data.ancillary.includes(anc.name))
            .map((anc) => anc.id),
          comment: data.comments,
          commissionAmount,
          commissionPercentage: newCommissionPercentage.toFixed(2),
          oneWayPrice: data.totalAmount,
          supplierAmount: data.supplierAmount,
          totalAmount: data.totalAmount,
          reservationAmount,
          transferOfferId: bookingRecord?.transfers[0]?.transferOffers[0]?.id,
          vendorId: data.vendorId,
          currencyId: data.currencyId,
          offerType: data.offerType,
          transferId: bookingRecord?.transfers[0]?.id,
          vehicleId: data.vehicleId === "" ? vehicleList[0].id : data.vehicleId,
        };

        if (returnDateTime) {
          reassignData.oneWayPrice = data.totalAmount / 2;
          reassignData.returnPrice = data.totalAmount / 2;
        }

        if (data.limitedOfferCheck) {
          reassignData.limitedOffer = data.limitedOfferCheck;
          reassignData.limitedOfferDueDate = new Date(data.limitedOfferDate);
        }

        if (transferType === TransferTypes.HOURLY)
          reassignData.amountCentsPerHour = data.hourlyRate;
      } else {
        if (!selectedOffer) {
          notify("Please select an offer to reassign !");
          return;
        }

        reassignData = {
          transferOfferId: bookingRecord?.transfers[0]?.transferOffers[0]?.id,
          vendorId: selectedOffer?.vendorId,
          currencyId: selectedOffer?.currency?.id,
          offerType: data.offerType,
          existingOfferId: selectedOffer?.id,
          transferId: bookingRecord?.transfers[0]?.id,
        };
      }

      dataProvider
        .create(`vendorReassign`, { data: removeNullVals(reassignData) })
        .then((res) => {
          notify("Vendor reassigned successfully! ");
          redirectTo(`/bookings`);
        })
        .catch((error) => {
          console.log(error);
          notify("Something went wrong!");
        });
    },
  });

  useEffect(() => {
    if (values && values.vendorId !== "") {
      fetchVehicles();
    }
  }, [values.vendorId]);

  const fetchVehicles = () => {
    dataProvider
      .getList(`vehicles`, {
        filter: { vendorId: values.vendorId },
        sort: { field: "createdAt", order: "ASC" },
      })
      .then((res) => {
        console.log(res.data);
        setVehicleList(res.data);
      })
      .catch((error) => {
        notify("Something went wrong!");
      });
  };

  const removeNullVals = (obj) => {
    return Object.keys(obj)
      .filter((k) => obj[k] != "")
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  };

  //   event.preventDefault();

  const handleOfferClick = (id, transferOffers) => {
    setSelectedOffer(transferOffers.filter((obj)=> obj.id === id)?.[0] ?? {});
  };

  const acceptedOfferStyle = (record, index) => ({
    background:
      record.id == selectedOffer?.id
        ? "linear-gradient(to right, #afa, white 5%)"
        : "white",
  });

  const commissionPercentage =
    ((Number(values.totalAmount) - Number(values.supplierAmount)) /
      Number(values.totalAmount)) *
    100;

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        <Box width={800} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
          <Typography className={classes.heading} variant="h4">
            Reassign Vendor
          </Typography>
          <SimpleForm toolbar={null}>
            <div className={classes.formContainer}>
              <Box display="flex" justifyContent="space-between">
                <div className={classes.radioContainer}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      className={classes.radioGroup}
                      row
                      aria-label="gender"
                      name="offerType"
                      value={values.offerType}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="existing-offer"
                        control={<Radio />}
                        label="Existing Offer"
                      />
                      <FormControlLabel
                        value="new-offer"
                        control={<Radio />}
                        label="New Offer"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Box>
              {values.offerType === "existing-offer" ? (
                <>
                  <Typography className={classes.subHeading} variant="h6">
                    Select Transfer Offer
                  </Typography>
                  <TransferOfferList
                    previousOfferId={
                      bookingRecord?.transfers &&
                      bookingRecord?.transfers[0]?.transferOffers &&
                      bookingRecord?.transfers[0]?.transferOffers[0]?.id
                    }
                    acceptedOfferStyle={acceptedOfferStyle.bind(this)}
                    handleOfferClick={handleOfferClick.bind(this)}
                    transferId={
                      bookingRecord?.transfers &&
                      bookingRecord?.transfers[0]?.id
                    }
                  />
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {/* <Typography className={classes.subHeading} variant="h6">Select Vendor</Typography> */}
                    <div className={classes.fieldContainer}>
                      <Autocomplete
                        id="vendorId"
                        onInputChange={handleVendorInputEmail}
                        onChange={(e, value) => {
                          setFieldValue("vendorId", value?.id);
                          setFieldValue("currencyId", value?.currencyId);
                          setFieldValue(
                            "currencySymbol",
                            value?.currencySymbol
                          );
                        }}
                        onBlur={handleBlur}
                        options={vendorList}
                        getOptionLabel={(option) => option?.email}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Vendor Email"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors.vendorId && touched.vendorId ? (
                        <div className={classes.errorMessage}>
                          {errors.vendorId}
                        </div>
                      ) : null}
                    </div>
                    {vehicleList && vehicleList.length > 0 && (
                      <div className={classes.fieldContainer}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="select-vehicle-id">
                            Vehicles
                          </InputLabel>
                          <Select
                            native
                            value={values.vehicleId}
                            onChange={handleChange}
                            label="Vehicles"
                            inputProps={{
                              name: "vehicleId",
                              id: "select-vehicle-id",
                            }}
                          >
                            {vehicleList.map((vehicle) => (
                              <option key={vehicle.id} value={vehicle.id}>
                                {vehicle.refModel
                                  ? `${vehicle.name} ${vehicle.refModel}`
                                  : `${vehicle.brand} ${vehicle.model} , ${vehicle.yearOfManufacture}`}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    marginTop="0.5rem"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <div className={classes.fieldContainer}>
                      <FormControl
                        className={
                          values.ancillary.length > 0
                            ? classes.ancillaryContainerActive
                            : classes.ancillaryContainer
                        }
                      >
                        <InputLabel id="select-ancillary-label">
                          Ancillaries
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="select-ancillary-label"
                          id="select-ancillary"
                          multiple
                          value={values.ancillary}
                          onChange={(event) =>
                            setFieldValue("ancillary", event.target.value)
                          }
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {ancillaryList.map((anci) => (
                            <MenuItem key={anci.name} value={anci.name}>
                              {anci.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div
                        className={
                          values.limitedOfferCheck
                            ? classes.offerContainerActive
                            : classes.offerContainer
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="limitedOfferCheck"
                              checked={values.limitedOfferCheck}
                              onChange={handleChange}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                          label="Limited Offer"
                        />

                        {values.limitedOfferCheck && (
                          <TextField
                            id="limited-date"
                            type="date"
                            name="limitedOfferDate"
                            onChange={handleChange}
                            defaultValue={new Date()}
                            value={values.limitedOfferDate}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    marginTop="0.5rem"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <div className={classes.priceContainer}>
                      <TextField
                        name="totalAmount"
                        value={values.totalAmount}
                        onChange={handleChange}
                        label="Total Offer Price"
                        variant="outlined"
                        className={classes.totalInput}
                        style={{ width: "60%" }}
                      />
                      <TextField
                        name="supplierAmount"
                        value={values.supplierAmount}
                        onChange={handleChange}
                        label="Supplier Cost"
                        variant="outlined"
                        className={classes.totalInput}
                        style={{ width: "60%", marginTop: 15 }}
                      />
                      {transferType &&
                        transferType === TransferTypes.HOURLY && (
                          <TextField
                            name="hourlyRate"
                            value={values.hourlyRate}
                            onChange={handleChange}
                            label="Hourly Rate"
                            variant="outlined"
                            className={classes.totalInput}
                            style={{ width: "60%", marginTop: 15 }}
                          />
                        )}
                      {bookingRecord?.transfers[0]?.reservationAmountCents >
                        0 && (
                        <TextField
                          name="amountToCollect"
                          value={values.amountToCollect}
                          onChange={handleChange}
                          label="Amount To Collect"
                          variant="outlined"
                          className={classes.totalInput}
                          style={{ width: "60%", marginTop: 15 }}
                        />
                      )}
                    </div>

                    <div className={classes.fieldContainer}>
                      <div className={classes.infoContainer}>
                        <div className={classes.infoHead}>Customer Pays</div>
                        <div className={classes.infoTotalValue}>
                          {values.currencySymbol} {values.totalAmount}
                        </div>
                      </div>
                      <div className={classes.infoContainer}>
                        <div className={classes.infoHead}>Vendor Get</div>
                        <div className={classes.infoValue}>
                          {values.currencySymbol}
                          {values.supplierAmount}
                        </div>
                      </div>
                      <div className={classes.infoContainer}>
                        <div className={classes.infoHead}>
                          Rydeu Commission{" "}
                          {commissionPercentage > 0
                            ? commissionPercentage.toFixed(2)
                            : 0}
                          %
                        </div>
                        <div className={classes.infoValue}>
                          {values.currencySymbol}
                          {Number(values.totalAmount) -
                            Number(values.supplierAmount)}
                        </div>
                      </div>
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    mt={2}
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <TextField
                      name="comments"
                      value={values.comments}
                      onChange={handleChange}
                      label="Comments"
                      variant="outlined"
                      className={classes.totalInput}
                      style={{ width: "100%" }}
                      multiline
                      rows={3}
                    />
                  </Box>
                </>
              )}

              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  {"Close"}
                </Button>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {"Reassign"}
                </Button>
              </Box>
            </div>
          </SimpleForm>
        </Box>
      </Fragment>
    </Modal>
  );
};

export const TransferOfferList = (props) => {
  const [page, setPage] = useState(1);
  const perPage = 50;

  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "transferOffers",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: {
        transferId: props.transferId,
        showOffers: "all",
      },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR: {error}</p>;
  }
  return (
    <ListContextProvider
      value={{
        data: keyBy(data, "id"),
        ids: data
          .map(({ id }) => id)
          .filter((id) => id !== props.previousOfferId),
        total,
        page,
        perPage,
        setPage,
        currentSort: { field: "id", order: "ASC" },
        basePath: "/transferOffers",
        resource: "transferOffers",
        selectedIds: [],
      }}
    >
      <Datagrid
        rowClick={(offerId) => props.handleOfferClick(offerId, data)}
        rowStyle={props.acceptedOfferStyle}
      >
        <ReactTextField source="vendorId" />
        <FunctionField
          label="Offered Vehicle"
          render={(record) =>
            record.vehicleId
              ? `${record.vehicle.brand} ${record.vehicle.model} , ${record.vehicle.yearOfManufacture}`
              : `${record.vehicleType.name} ${record.vehicleType.refModel}`
          }
        />
        <ReactTextField label="Total Amount" source="totalAmount" />
        <ReactTextField
          label="Amount Cents Per Hour"
          source="amountCentsPerHour"
        />
        <BooleanField
          label="Limited Offer"
          source="limitedOffer"
        ></BooleanField>
        <DateField
          label="Limited Offer Due Date"
          source="limitedOfferDueDate"
        />
        <DateField label="Offer Creation Date" source="createdAt" />
        {/* <ReactTextField label="Comment" source="comment" /> */}
      </Datagrid>
      <Pagination />
    </ListContextProvider>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    heading: {
      textAlign: "center",
      letterSpacing: "1.5px",
      fontWeight: "600",
    },
    radioGroup: {
      justifyContent: "space-around",
    },
    errorMessage: {
      marginTop: 10,
      fontSize: 14,
      color: "#8B0000",
    },
    radioContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: "1.5rem",
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "column",
      width: "45%",
      marginTop: "1.5rem",
      justifyContent: "center",
    },
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width: "100%",
    },
    inputContainer: {
      // width: "45%",
      width: "95%",
      margin: spacing(2, 0),
    },
    checkIcon: {
      color: "green",
      fontSize: 32,
    },
    closeIcon: {
      color: "#8b0000",
      fontSize: 32,
    },
    suggestionsContainer: {
      zIndex: 2100,
    },
    autoInput: {
      width: "47%",
    },
    subHeading: {
      letterSpacing: "1.5px",
      marginTop: "2rem",
      fontWeight: "500",
      marginBottom: "0.5rem",
      // color: '#949494'
    },
    fieldColumn: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginTop: "1.5rem",
    },
    offerContainer: {
      display: "flex",
      flexDirection: "column",
    },
    offerContainerActive: {
      display: "flex",
      flexDirection: "column",
      marginTop: "-1.4rem",
    },
    ancillaryContainerActive: {
      marginTop: "-1.2rem",
    },
    ancillaryContainer: {
      marginTop: "0.1rem",
    },
    infoContainer: {
      background: "#E8F2FC",
      padding: "1rem",
      display: "flex",
    },
    infoHead: {
      width: "60%",
      fontWeight: "600",
      fontSize: "14px",
      textAlign: "left",
      letterSpacing: "1.5px",
    },
    infoValue: {
      width: "40%",
      textAlign: "center",
      color: "#004A85",
      fontWeight: "600",
    },
    infoTotalValue: {
      width: "40%",
      textAlign: "center",
      color: "#33B30B",
      fontWeight: "600",
    },
    priceContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginTop: "1.5rem",
      justifyContent: "center",
      borderRight: "1px solid #d4d4d5",
    },
  };
});

export default VendorReassignModal;

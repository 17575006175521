import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import PricingModal from "./PricingModal";

const CreateEditPricing = ({
  refreshList,
  isPricingCreated,
  vehicleTypes,
  pricings,
  intercityRouteDetails
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant="contained"
        color="primary"
      >
        {`${isPricingCreated ? "Edit" : "Create"} Pricings`}
      </Button>
      <PricingModal
        vehicleTypes={vehicleTypes}
        intercityRouteDetails={intercityRouteDetails}
        pricings={pricings}
        isPricingCreated={isPricingCreated}
        refreshList={refreshList}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

export default CreateEditPricing;

import React from 'react'
import { BooleanField, NumberField, Show, SimpleShowLayout, TextField, TabbedShowLayout, Tab, ReferenceField, ReferenceManyField, Datagrid, } from 'react-admin'
import LongDateField from '../../Components/Common/Fields/LongDateField'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const VehicleBrandTitle = ({ record }) => {
  return (
    <span>
      VehicleBrand: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const VehicleBrandShow = props => {
  const [record, setRecord] = React.useState({});
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vehicle Brands',
      url: '/vehicleBrands',
    },
    {
      name: `${record.id}`,
      url: `/vehicleBrands/${record.id}/show`,
    },
  ]
  
  const handleRowClick = (id, basePath, record) => {
    // console.log(id, basePath, record);
    props.history.push(`${basePath}/${id}/show`)
  }

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <RecordFetcher setRecord={setRecord} />        
            <TextField source="id" />
            <TextField source="brand" />
            <BooleanField source="major" />
            <BooleanField source="verified" />
            <LongDateField source="createdAt" />
            <LongDateField source="updatedAt" />
            <ReferenceField link="show" source="vendorId" reference="vendors"><TextField source="user.email" /></ReferenceField>
          </Tab>
          
          <Tab label="VehicleModels" path="vehicleModels">
            <RecordFetcher setRecord={setRecord} />
            {/* <CreateBookingStateButton /> */}
            <ReferenceManyField reference="vehicleModels" target="vehicleBrandId" addLabel={false} sort={{ field: 'createdAt', order: 'DESC' }}>
              <Datagrid rowClick={handleRowClick}>
                <TextField source="id" />
                <TextField source="model" />
                <TextField source="brandName" />
                <TextField source="modelType" />
                <BooleanField source="verified" />
                {/* <LongDateField source="createdAt" /> */}
                <LongDateField source="updatedAt" />
                <ReferenceField source="vehicleBrandId" reference="vehicleBrands"><TextField source="brand" /></ReferenceField>
                <ReferenceField source="vehicleTypeId" reference="vehicleTypes"><TextField source="name" /></ReferenceField>
                <ReferenceField source="vendorId" reference="vendors"><TextField source="user.email" /></ReferenceField>
              </Datagrid>
            </ReferenceManyField>
          </Tab>

        </TabbedShowLayout>
      </Show>
    </>
  )
}

export default VehicleBrandShow;
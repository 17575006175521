export default {
  cityDetails: {
    about: {
      web: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">The captivating sights and hustle of cosmopolitan make Geneva the best of both worlds. The city gifts her visitors the exquisite beauty of the Alps and a nostalgic sting with the medieval layout of the streets and squares. Having the perfect mix of all the realms, the city offers you canoeing in Marais de la Versoix as well as hot air balloon adventures over breath-taking landscapes. Being tucked between mountain ranges you see mesmerizing views of the Alps and Jura throughout the year. This makes Geneva a true paradise for your touristy souls.</span></span></p>

      <div style="float:right; padding-left:20px">
      <div style="text-align:justify"><img alt="St. Peter’s Basilica" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail1.jpg" style="width:100%" /></div>
      
      <div style="text-align:justify"><span style="font-size:12px"><span style="color:#999999"><span style="font-family:Arial">Lake Geneva&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></span><em><span style="font-size:10px"><span style="color:#999999">Image by</span> </span></em><span style="font-size:10px"><a href="https://pixabay.com/users/gavilla-1862695/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1766003" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial"><u>guillermo gavilla</u></span></span></a></span></div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">From picturesque sights, ancient museums, and popular cafes, unwind your soul and relax in the wholesome setting of the city. The city is the perfect amalgamation of ancient touches and modern swirl in its setting. Indulge in the enriching, warm, and diverse culture of Geneva present in every nook. Geneva is the city of music. Visit Victoria Hall if you have a flair for classical music or attend the </span><a href="https://www.ville-ge.ch/fetedelamusique/" style="text-decoration:none"><span style="font-family:Arial">F&ecirc;te de la Musique</span></a><span style="font-family:Arial"> festival to enjoy the work of local artists presenting different genres. The city floods with different art forms so unravel your mind in the musings of opera, museums, and theatre present in the city.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">&nbsp;</span></span></p>
      
      <div style="float:left; padding-right:20px">
      <div style="text-align:justify"><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail2.jpg" style="width:100%" /></span></div>
      
      <div style="text-align:justify"><span style="font-size:12px"><span style="color:#999999"><span style="font-family:Arial">United Nations, Geneva&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></span><em><span style="font-size:10px"><span style="color:#999999">Image by</span> </span></em><span style="font-size:10px"><a href="https://pixabay.com/users/konferenzadhs-8651590/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3362464" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial"><u>konferenzadhs</u></span></span></a></span></div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">The authenticity of restaurants and local street food influenced by French, German, and Northern Italian cuisine is completely gratifying. Fall in love with the wholesome fondue, cheese, and Swiss chocolate. The extensive variety of bread rolls is also very famous.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Contrary to the homely aura, Geneva is an active hotspot for global meetings. It is the home to the United Nations headquarters and the Red Cross. This is also a reason why it stands as a symbol of tolerance. It is the home to over 200 worldwide organizations and hosts the highest number of international meetings.</span></span></p>
      `,
      mobile: `<p style="text-align:justify"><span style="font-size:16px">The captivating sights and hustle of cosmopolitan make Geneva the best of both worlds. The city gifts her visitors the exquisite beauty of the Alps and a nostalgic sting with the medieval layout of the streets and squares. Having the perfect mix of all the realms, the city offers you canoeing in Marais de la Versoix as well as hot air balloon adventures over breath-taking landscapes. Being tucked between mountain ranges you see mesmerizing views of the Alps and Jura throughout the year. This makes Geneva a true paradise for your touristy souls.</span></p>

      <div>
      <div style="text-align:justify"><span style="font-size:16px"><img alt="Geneva Lake" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail1.jpg" style="width:100%" /></span></div>
      
      <div>
      <div><span style="color:#999999"><span style="font-size:12px">Lake Geneva</span></span>&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://pixabay.com/users/gavilla-1862695/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1766003"><span style="color:#3498db"><u>guillermo gavilla</u></span></a></span></div>
      </div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px">From picturesque sights, ancient museums, and popular cafes, unwind your soul and relax in the wholesome setting of the city. The city is the perfect amalgamation of ancient touches and modern swirl in its setting. Indulge in the enriching, warm, and diverse culture of Geneva present in every nook. Geneva is the city of music. Visit Victoria Hall if you have a flair for classical music or attend the <a href="https://www.ville-ge.ch/fetedelamusique/">F&ecirc;te de la Musique</a> festival to enjoy the work of local artists presenting different genres. The city floods with different art forms so unravel your mind in the musings of opera, museums, and theatre present in the city.</span></p>
      
      <div>
      <div style="text-align:justify"><span style="font-size:16px"><img alt="United Nations" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail2.jpg" style="width:100%" /></span></div>
      
      <div>
      <div><span style="color:#999999"><span style="font-size:12px">United Nations, Geneva&nbsp;</span></span>&nbsp; &nbsp; &nbsp; &nbsp;|&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://pixabay.com/users/konferenzadhs-8651590/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3362464"><span style="color:#3498db"><u>konferenzadhs</u></span></a></span></div>
      
      <p style="text-align:justify"><span style="font-size:16px">The authenticity of restaurants and local street food influenced by French, German, and Northern Italian cuisine is completely gratifying. Fall in love with the wholesome fondue, cheese, and Swiss chocolate. The extensive variety of bread rolls is also very famous.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px">Contrary to the homely aura, Geneva is an active hotspot for global meetings. It is the home to the United Nations headquarters and the Red Cross. This is also a reason why it stands as a symbol of tolerance. It is the home to over 200 worldwide organizations and hosts the highest number of international meetings.</span></p>
      </div>
      </div>
      `,
    },
    transportation: {
      web: `<p><span style="font-size:16px"><span style="font-family:Arial"><strong>Buses</strong></span></span></p>

      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Looking for a quick, cheap, and comfortable ride? Then buses are your go-to option. If you have less luggage and are swift on sight-seeing then buses give a satisfying experience.&nbsp; Make your <strong>transfer from Geneva Airport</strong> by paying under 4 Swiss Franc. The picturesque sights of the city are also well connected by a chain of buses that run day and night. &nbsp; Therefore, commutation is easy because of the affordability and nice quality.</span></span></p>
      
      <div style="float:right; padding-left:20px">
      <div style="text-align:justify"><img alt="Geneva transportation in tram" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_transportationTram.jpg" style="width:100%" /></div>
      
      <div style="text-align:justify"><span style="color:#999999"><span style="font-size:11pt"><span style="font-family:Arial"><span style="font-size:12px">Tram, Geneva&nbsp;</span> &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></span><em><span style="font-size:10px"><span style="color:#999999">Image by</span> </span></em><span style="font-size:10px"><a href="https://www.flickr.com/photos/sharonhahndarlin/" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial">Sharon Hahn Darlin</span></span></a></span></div>
      </div>
      
      <p><span style="font-size:16px"><span style="font-family:Arial"><strong>Trains</strong></span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Another efficient and easy way of transport is travelling by train. Make a trip to the city under 23SFr from Geneva Airport. Travelling from Sion Airport to the city would cost around 93SFr. This mode of transportation is less time consuming and even more comfortable. The hurdle-free ride is worth the money for <strong>transfer in Geneva.</strong></span></span></p>
      
      <p style="text-align:justify">&nbsp;</p>
      
      <div style="float:left; padding-right:20px">
      <div style="text-align:justify"><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_transportationTrolleyBus.jpg" style="height:250px; width:375px" /></span></div>
      
      <div style="text-align:justify"><span style="font-size:12px"><span style="font-family:Arial"><span style="color:#999999">Trolley Bus, Geneva&nbsp;</span></span></span><span style="font-size:16px"><span style="font-family:Arial"> &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span><span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/wimox/" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial">Wimox</span></span></a></span></div>
      </div>
      
      <p><span style="font-size:16px"><span style="font-family:Arial"><strong>Taxi</strong></span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">If you are someone with a lot of luggage tagged along the taxis could be the saviour of your day. The city is well flooded with a trail of taxies making <strong>transfers in Geneva</strong> as well as <strong>intercity transfer from Geneva </strong>easy. One of the greatest choices among <strong>Geneva taxi transfers </strong>is provided by Rydeu, offering budget as well as premium trips across the city.</span></span></p>
      `,
      mobile: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial"><strong>Buses</strong></span></span></p>

      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Looking for a quick, cheap, and comfortable ride? Then buses are your go-to option. If you have less luggage and are swift on sight-seeing then buses give a satisfying experience.&nbsp; Make your <strong>transfer from Geneva Airport</strong> by paying under 4 Swiss Franc. The picturesque sights of the city are also well connected by a chain of buses that run day and night. &nbsp; Therefore, commutation is easy because of the affordability and nice quality.</span></span></p>
      
      <div>
      <div style="text-align:justify"><span style="font-size:16px"><img alt="Geneva Transportation in Tram" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_transportationTram.jpg" style="width:100%" /></span></div>
      
      <div>
      <p style="text-align:justify"><span style="font-size:12px"><span style="color:#999999">Tram, Geneva&nbsp;</span></span> &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/sharonhahndarlin/"><span style="color:#3498db">Sharon Hahn Darlin</span></a></span></p>
      </div>
      </div>
      
      <p><span style="font-size:16px"><span style="font-family:Arial"><strong>Trains</strong></span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Another efficient and easy way of transport is travelling by train. Make a trip to the city under 23SFr from Geneva Airport. Travelling from Sion Airport to the city would cost around 93SFr. This mode of transportation is less time consuming and even more comfortable. The hurdle-free ride is worth the money for <strong>transfer in Geneva.</strong></span></span></p>
      
      <div>
      <div style="text-align:justify"><span style="font-size:16px"><img alt="Trolley Bus" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_transportationTrolleyBus.jpg" style="width:100%" /></span></div>
      
      <div>
      <div style="text-align:justify"><span style="color:#999999"><span style="font-size:12px">Trolley Bus, Geneva</span></span>&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/wimox/"><span style="color:#3498db">Wimox</span></a></span></div>
      
      <p><span style="font-size:16px"><span style="font-family:Arial"><strong>Taxi</strong></span></span></p>
      </div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">If you are someone with a lot of luggage tagged along the taxis could be the saviour of your day. The city is well flooded with a trail of taxies making <strong>transfers in Geneva</strong> as well as <strong>intercity transfer from Geneva </strong>easy. One of the greatest choices among <strong>Geneva taxi transfers </strong>is provided by Rydeu, offering budget as well as premium trips across the city.</span></span></p>
      `,
    },
    aboutAirportTransfers: {
      web: `<p style="text-align:justify"><span style="font-size:16px">A successful trip is one that allows easy navigation through the city. With the availability of various conveyance options from airports, your journey takes a hurdle- free turn. Geneva proves to be the perfect destination because of its easy accessibility. It has one major international airport near it. The proximity from the city to airport is less which allows you to save more time and indulge in other recreational activities.</span></p>

      <div style="float:right; padding-left:20px">
      <div><img alt="Battery Bus" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAirportTransfer.jpg" style="width:100%" /></div>
      
      <div><span style="color:#999999"><span style="font-size:12px">Battery Bus,&nbsp;Le Grand-Saconnex</span></span> &nbsp; &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp; &nbsp;<em><span style="color:#999999"><span style="font-size:10px">Image by</span></span> </em><span style="font-size:10px"><a href="https://commons.wikimedia.org/wiki/User:MB-one"><span style="color:#3498db">Matti Blume</span></a></span></div>
      </div>
      
      <div>
      <p style="text-align:justify"><span style="font-size:16px">A momentous trip requires hassle-free transportation. Travelling from a public transport can be very difficult if you are carrying a lot of luggage, taking a private taxi to reach your accommodation or for sightseeing is the best option to consider if you are looking forward to a relaxed trip.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>Geneva Airport </strong>is the second most popular airport in <strong>Switzerland</strong>. <strong>Airport to Geneva city</strong> is situated 4kms northwest to the city centre making it very easy to access. The northern limit of the airport runs along the Swiss-French border making it accessible for both countries. Therefore,<strong> transport from Geneva Airport </strong>is quick and untroubled. Check out Rydeu for <strong>Geneva Airport private transfers</strong> for a variety of options for a hassle-free journey.</span></p>
      </div>
      `,
      mobile: `<p style="text-align:justify"><span style="font-size:16px">A successful trip is one that allows easy navigation through the city. With the availability of various conveyance options from airports, your journey takes a hurdle- free turn. Geneva proves to be the perfect destination because of its easy accessibility. It has one major international airport near it. The proximity from the city to airport is less which allows you to save more time and indulge in other recreational activities.</span></p>

      <p><img alt="Battery Bus" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAirportTransfer.jpg" style="width:100%" /></p>
      
      <div><span style="color:#999999"><span style="font-size:12px">Battery Bus,&nbsp;Le Grand-Saconnex</span></span> &nbsp; &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp; &nbsp;<span style="color:#999999"><span style="font-size:10px"><em>Image by </em></span></span><span style="font-size:10px"><a href="https://commons.wikimedia.org/wiki/User:MB-one"><span style="color:#3498db">Matti Blume</span></a></span></div>
      
      <p style="text-align:justify"><span style="font-size:16px">A momentous trip requires hassle-free transportation. Travelling from a public transport can be very difficult if you are carrying a lot of luggage, taking a private taxi to reach your accommodation or for sightseeing is the best option to consider if you are looking forward to a relaxed trip.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><strong>Geneva Airport </strong>is the second most popular airport in <strong>Switzerland</strong>. <strong>Airport to Geneva city</strong> is situated 4kms northwest to the city centre making it very easy to access. The northern limit of the airport runs along the Swiss-French border making it accessible for both countries. Therefore,<strong> transport from Geneva Airport </strong>is quick and untroubled. Check out Rydeu for <strong>Geneva Airport private transfers</strong> for a variety of options for a hassle-free journey.</span></p>
      
      <p style="text-align:justify">&nbsp;</p>`,
    },
    aboutSightseeingTransfers: {
      web: `<div style="float:right; padding-left:20px">
      <div><img alt="Mont Saleve" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_sightseeingMontSaleve.jpg" style="width:100%" /></div>
      
      <div><span style="color:#999999"><span style="font-size:12px">Mont Saleve</span></span><span style="font-size:14px">&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><em><span style="font-size:10px"><span style="color:#999999">Image by </span></span></em><span style="font-size:10px"><a href="https://www.flickr.com/photos/7737054@N07" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial">Nouhailler</span></span></a></span></div>
      </div>
      
      <div>
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">1. <strong>Mont Sal&egrave;ve</strong>- Hike your way up to the top or make a day trip by cable cars to the Balcony of Geneva. It is present 900 meters above the Jet D&#39;Eau. It is known for the scenic view the mountain top has to offer. One can engage in several activities like ranging from mountain biking and hiking to paragliding and skiing in the winter. The jaw-dropping view of the city would make you want to stay longer<strong>.&nbsp;</strong></span></span></p>
      
      <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
      </div>
      
      <p><br />
      &nbsp;</p>
      
      <div style="float:left; padding-right:20px">
      <div><span style="font-size:16px"><img alt="Place du Bourg-de-Four" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_sightseeingPlaceDuBourgDeFour.jpg" style="width:100%" /></span></div>
      
      <div><span style="color:#999999"><span style="font-size:12px"><span style="font-family:Arial">Place du Bourg-de-Four&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></span><span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://web.archive.org/web/20161016183229/http://www.panoramio.com/user/1286122?with_photo_id=44756898" style="text-decoration:none"><span style="color:#3498db">Patrick Nouhailler&#39;s</span></a></span></div>
      
      <p>&nbsp;</p>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">2. <strong>Place du Bourg-de-Four</strong>- is the true essence of Geneva&rsquo;s Old Town. Indulge in the warm and hearty ambience which transports you a medieval yet preppy city life. Gift yourself a day at this lively place to explore a trail of laid-back caf&eacute;s, bistros, restaurants with open seating, fountains, and high-end as well as souvenir shops. The place looks magnificent on Christmas while being beautifully lit by a row of lamps and fairy lights across the square. Take a quick trip to St. Pierrie Cathedral, Geneva&rsquo;s largest church, lying a few meters away. Make sure you satisfy your taste buds at Caf&eacute; de Bourg-de-Four, La Cl&eacute;mence, and Restaurant au Carnivore.</span></span></p>`,
      mobile: `<p><span style="font-size:16px"><span style="font-family:Arial"><strong>Mont Sal&egrave;ve</strong></span></span></p>

      <div>
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_sightseeingMontSaleve.jpg" style="width:100%" /></span></div>
      
      <div><span style="color:#999999"><span style="font-size:12px">Mont Saleve</span></span>&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span style="font-size:10px"><em><span style="color:#999999">Image by</span> </em><a href="https://www.flickr.com/photos/7737054@N07">Nouhailler</a></span></div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Hike your way up to the top or make a day trip by cable cars to the Balcony of Geneva. It is present 900 meters above the Jet D&#39;Eau. It is known for the scenic view the mountain top has to offer. One can engage in several activities like ranging from mountain biking and hiking to paragliding and skiing in the winter. The jaw-dropping view of the city would make you want to stay longer<strong>.&nbsp;</strong></span></span></p>
      
      <p>&nbsp;</p>
      
      <p><span style="font-size:16px"><strong>Place du Bourg-de-Four</strong></span></p>
      
      <div>
      <div><span style="font-size:16px"><img alt="Place du Bourg-de-Four" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_sightseeingPlaceDuBourgDeFour.jpg" style="width:100%" /></span></div>
      
      <div><span style="color:#999999"><span style="font-size:12px">Place du Bourg-de-Four&nbsp;</span></span> &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://web.archive.org/web/20161016183229/http://www.panoramio.com/user/1286122?with_photo_id=44756898"><span style="color:#3498db">Patrick Nouhailler&#39;s</span></a></span></div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px">Is the true essence of Geneva&rsquo;s Old Town. Indulge in the warm and hearty ambience which transports you a medieval yet preppy city life. Gift yourself a day at this lively place to explore a trail of laid-back caf&eacute;s, bistros, restaurants with open seating, fountains, and high-end as well as souvenir shops. The place looks magnificent on Christmas while being beautifully lit by a row of lamps and fairy lights across the square. Take a quick trip to St. Pierrie Cathedral, Geneva&rsquo;s largest church, lying a few meters away. Make sure you satisfy your taste buds at Caf&eacute; de Bourg-de-Four, La Cl&eacute;mence, and Restaurant au Carnivore.</span></p>`,
    },
  },
  airportDetails: {
    about: {
      web: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Destinations which are easily accessible are one of the most tourist friendly places. The best trips are the ones which reduce travel time and stress. The beautiful city of Geneva lies at a close proximity to Geneva Airport. <strong>Transport from Geneva Airport </strong>is swift and smooth. Due to the extensive chain of trains, buses and taxi services running, commute is one of the biggest convenient resources that the city has to offer.</span></span></p>

      <div style="float:right; padding-left:20px">
      <div><img alt="Geneva airport bus" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_airportBus.jpg" style="width:100%" /></div>
      
      <div><span style="color:#999999"><span style="font-size:12px">Geneva Airport buses to town&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span><span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/eguidetravel/" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial">eGuide Travel</span></span></a><span style="color:#999999"><em>&nbsp;</em></span></span></div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Geneva Airport lies at a distance of just 4 km from the heart of the city. Therefore, the time taken while travelling is easy and you indulge in more fun activities. <strong>Geneva airport transfers</strong> are completely hassle-free.&nbsp; The efficient services of buses make your <strong>transfer from Geneva Airport </strong>by paying about 4 Swiss Franc. The picturesque sights of the city are also well connected by a chain of buses that run day and night. Another efficient and easy way of transport is travelling by train. Make a trip to the city under 23SFr from Geneva Airport.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Rydeu provides some of the best options for transfers around the city. From connecting hotels and hostels to the airports, making your Geneva airport transfers unproblematic and effortless.</span></span></p>
      
      <div style="float:left; padding-right:20px">
      <p>&nbsp;</p>
      </div>`,
      mobile: `<p><span style="font-size:16px">Destinations which are easily accessible are one of the most tourist friendly places. The best trips are the ones which reduce travel time and stress. The beautiful city of Geneva lies at a close proximity to Geneva Airport. <strong>Transport from Geneva Airport </strong>is swift and smooth. Due to the extensive chain of trains, buses and taxi services running, commute is one of the biggest convenient resources that the city has to offer.</span></p>

      <div>
      <div><span style="font-size:16px"><img alt="Geneva airport bus" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_airportBus.jpg" style="width:100%" /></span></div>
      
      <div><span style="color:#999999"><span style="font-size:12px">Geneva Airport Bus to town&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span><span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/eguidetravel/"><span style="color:#3498db">eGuide Travel</span></a></span></div>
      </div>
      
      <p><span style="font-size:16px">Geneva Airport lies at a distance of just 4 km from the heart of the city. Therefore, the time taken while travelling is easy and you indulge in more fun activities. <strong>Geneva airport transfers</strong> are completely hassle-free.&nbsp; The efficient services of buses make your <strong>transfer from Geneva Airport </strong>by paying about 4 Swiss Franc. The picturesque sights of the city are also well connected by a chain of buses that run day and night. Another efficient and easy way of transport is travelling by train. Make a trip to the city under 23SFr from Geneva Airport.</span></p>
      
      <div><span style="font-size:16px"><img alt="Geneva airport taxi" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_airportTaxi.jpg" style="width:100%" /></span></div>
      
      <div><span style="color:#999999"><span style="font-size:12px">Geneva Airport taxi&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span><span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://www.flickr.com/photos/31190896@N08" rel="noopener" target="_blank"><span style="color:#3498db">eGuide Travel</span></a></span></div>
      
      <p><span style="font-size:16px">Rydeu provides some of the best options for transfers around the city. From connecting hotels and hostels to the airports, making your Geneva airport transfers unproblematic and effortless.</span></p>`,
    },
    whyRydeu: {
      web: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Forget the everyday hustle of your mundane and traverse the enchanting streets of Geneva. Rydeu provides an array of options for its customers depending on their mood, style, and pocket. From exquisite premium rides to affordable ones within resources, Rydeu has got you covered! Be it high-end luxurious taxis or compact yet comfortable ones- you will find all these options. Our dynamic range of options suits every person with every taste. We understand that your quirks, preferences and even lifestyle reflect in your choice of the ride so we never restrict our pool of options. Making your scope for choice bigger and better. We understand your concerns and strive to provide you with our services accordingly. Another spectacular quality of Rydeu is our 24/7 customer service. Our employees are only a call away from fixing your problem or answering any query. So, don&rsquo;t hesitate by considering the hour of the day. We are always available to you. Whenever you need us!</span></span></p>

      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Rydeu serves as one of the most feasible options for booking a ride because of its comprehensive and easy cancellation policy. We understand that plans can change any moment so our forum allows the customer to cancel a ride within the city until 3 hours before the trip. If it is an intercity ride, it can be cancelled 24 hours before the trip. The feasible framework of our cancellation policy is one of our best features.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">We&rsquo;re eagerly waiting to make your dream vacation come true. Be it a happy summer with your family or a romantic getaway with your beau, we realize that safe and comfortable travel tops our customer&rsquo;s priorities. And so does ours. We offer rides that are not only cosy but are also secure in every way possible. There are no extra or hidden charges to any ride. So, back your bags and get on board. Since the woeful city of Geneva awaits.</span></span></p>
      
      <p>&nbsp;</p>`,
      mobile: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Forget the everyday hustle of your mundane and traverse the enchanting streets of Geneva. Rydeu provides an array of options for its customers depending on their mood, style, and pocket. From exquisite premium rides to affordable ones within resources, Rydeu has got you covered! Be it high-end luxurious taxis or compact yet comfortable ones- you will find all these options. Our dynamic range of options suits every person with every taste. We understand that your quirks, preferences and even lifestyle reflect in your choice of the ride so we never restrict our pool of options. Making your scope for choice bigger and better. We understand your concerns and strive to provide you with our services accordingly. Another spectacular quality of Rydeu is our 24/7 customer service. Our employees are only a call away from fixing your problem or answering any query. So, don&rsquo;t hesitate by considering the hour of the day. We are always available to you. Whenever you need us!</span></span></p>

      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Rydeu serves as one of the most feasible options for booking a ride because of its comprehensive and easy cancellation policy. We understand that plans can change any moment so our forum allows the customer to cancel a ride within the city until 3 hours before the trip. If it is an intercity ride, it can be cancelled 24 hours before the trip. The feasible framework of our cancellation policy is one of our best features.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">We&rsquo;re eagerly waiting to make your dream vacation come true. Be it a happy summer with your family or a romantic getaway with your beau, we realize that safe and comfortable travel tops our customer&rsquo;s priorities. And so does ours. We offer rides that are not only cosy but are also secure in every way possible. There are no extra or hidden charges to any ride. So, back your bags and get on board. Since the woeful city of Geneva awaits.</span></span></p>`,
    },
  },
  sightseeingDetails: {
    about: {
      web: `<p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">The captivating sights and hustle of cosmopolitan make Geneva the best of both worlds. The city gifts her visitors the exquisite beauty of the Alps and a nostalgic sting with the medieval layout of the streets and squares. Having the perfect mix of all the realms, the city offers you canoeing in Marais de la Versoix as well as hot air balloon adventures over breath-taking landscapes. Being tucked between mountain ranges you see mesmerizing views of the Alps and Jura throughout the year. This makes Geneva a true paradise for your touristy souls.</span></span></p>

      <div style="float:right; padding-left:20px">
      <div style="text-align:justify"><img alt="St. Peter’s Basilica" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail1.jpg" style="width:100%" /></div>
      
      <div style="text-align:justify"><span style="font-size:12px"><span style="color:#999999"><span style="font-family:Arial">Lake Geneva&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></span><em><span style="font-size:10px"><span style="color:#999999">Image by</span> </span></em><span style="font-size:10px"><a href="https://pixabay.com/users/gavilla-1862695/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1766003" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial"><u>guillermo gavilla</u></span></span></a></span></div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">From picturesque sights, ancient museums, and popular cafes, unwind your soul and relax in the wholesome setting of the city. The city is the perfect amalgamation of ancient touches and modern swirl in its setting. Indulge in the enriching, warm, and diverse culture of Geneva present in every nook. Geneva is the city of music. Visit Victoria Hall if you have a flair for classical music or attend the </span><a href="https://www.ville-ge.ch/fetedelamusique/" style="text-decoration:none"><span style="font-family:Arial">F&ecirc;te de la Musique</span></a><span style="font-family:Arial"> festival to enjoy the work of local artists presenting different genres. The city floods with different art forms so unravel your mind in the musings of opera, museums, and theatre present in the city.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">&nbsp;</span></span></p>
      
      <div style="float:left; padding-right:20px">
      <div style="text-align:justify"><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail2.jpg" style="width:100%" /></span></div>
      
      <div style="text-align:justify"><span style="font-size:12px"><span style="color:#999999"><span style="font-family:Arial">United Nations, Geneva&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></span><em><span style="font-size:10px"><span style="color:#999999">Image by</span> </span></em><span style="font-size:10px"><a href="https://pixabay.com/users/konferenzadhs-8651590/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3362464" style="text-decoration:none"><span style="color:#3498db"><span style="font-family:Arial"><u>konferenzadhs</u></span></span></a></span></div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">The authenticity of restaurants and local street food influenced by French, German, and Northern Italian cuisine is completely gratifying. Fall in love with the wholesome fondue, cheese, and Swiss chocolate. The extensive variety of bread rolls is also very famous.</span></span></p>
      
      <p style="text-align:justify"><span style="font-size:16px"><span style="font-family:Arial">Contrary to the homely aura, Geneva is an active hotspot for global meetings. It is the home to the United Nations headquarters and the Red Cross. This is also a reason why it stands as a symbol of tolerance. It is the home to over 200 worldwide organizations and hosts the highest number of international meetings.</span></span></p>`,
      mobile: `<p style="text-align:justify"><span style="font-size:16px">The captivating sights and hustle of cosmopolitan make Geneva the best of both worlds. The city gifts her visitors the exquisite beauty of the Alps and a nostalgic sting with the medieval layout of the streets and squares. Having the perfect mix of all the realms, the city offers you canoeing in Marais de la Versoix as well as hot air balloon adventures over breath-taking landscapes. Being tucked between mountain ranges you see mesmerizing views of the Alps and Jura throughout the year. This makes Geneva a true paradise for your touristy souls.</span></p>

      <div>
      <div style="text-align:justify"><span style="font-size:16px"><img alt="Geneva Lake" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail1.jpg" style="width:100%" /></span></div>
      
      <div>
      <div><span style="color:#999999"><span style="font-size:12px">Lake Geneva</span></span>&nbsp; &nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://pixabay.com/users/gavilla-1862695/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1766003"><span style="color:#3498db"><u>guillermo gavilla</u></span></a></span></div>
      </div>
      </div>
      
      <p style="text-align:justify"><span style="font-size:16px">From picturesque sights, ancient museums, and popular cafes, unwind your soul and relax in the wholesome setting of the city. The city is the perfect amalgamation of ancient touches and modern swirl in its setting. Indulge in the enriching, warm, and diverse culture of Geneva present in every nook. Geneva is the city of music. Visit Victoria Hall if you have a flair for classical music or attend the <a href="https://www.ville-ge.ch/fetedelamusique/">F&ecirc;te de la Musique</a> festival to enjoy the work of local artists presenting different genres. The city floods with different art forms so unravel your mind in the musings of opera, museums, and theatre present in the city.</span></p>
      
      <div>
      <div style="text-align:justify"><span style="font-size:16px"><img alt="United Nations" src="https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/geneva/geneva_cityAboutThumbnail2.jpg" style="width:100%" /></span></div>
      
      <div>
      <div><span style="color:#999999"><span style="font-size:12px">United Nations, Geneva&nbsp;</span></span>&nbsp; &nbsp; &nbsp; &nbsp;|&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<span style="font-size:10px"><span style="color:#999999"><em>Image by </em></span><a href="https://pixabay.com/users/konferenzadhs-8651590/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3362464"><span style="color:#3498db"><u>konferenzadhs</u></span></a></span></div>
      
      <p style="text-align:justify"><span style="font-size:16px">The authenticity of restaurants and local street food influenced by French, German, and Northern Italian cuisine is completely gratifying. Fall in love with the wholesome fondue, cheese, and Swiss chocolate. The extensive variety of bread rolls is also very famous.</span></p>
      
      <p style="text-align:justify"><span style="font-size:16px">Contrary to the homely aura, Geneva is an active hotspot for global meetings. It is the home to the United Nations headquarters and the Red Cross. This is also a reason why it stands as a symbol of tolerance. It is the home to over 200 worldwide organizations and hosts the highest number of international meetings.</span></p>
      </div>
      </div>`,
    },
    whyVisit: {
      web: `<p><span style="font-size:16px">Nestled in the heart of Italian Peninsula, Rome is the capital of the country and one of the most populated cities in Italy. From its rich architecture to rare artefacts, this city is known to possess a history like none other. The pic</span><span style="font-size:16px">turesque atmosphere, the cold wind blowing and the serene atmosphere is what makes Rome a must visit place for all t</span><span style="font-size:16px">he travel fanatics out there.</span></p>

      <div style="float:right; padding-left:20px">
      <div><img alt="" src="https://cdn.rydeu.com/rome-civitavecchia-italy_1800x1000+1.png" style="height:225px; width:391px" /></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <div><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city adsasd wholeheartedly welcomes travellers from a wide array of interests, from awe inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></div>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city wholeheartedly welcomes travellers from a wide arra</span><span style="font-size:16px">y of interests, from awe</span>&nbsp;&nbsp;<span style="font-size:16px">inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">The Roman Empire has been an icon when it comes to historical importance. The Roman Empire had cut the ribbons when Augustus Caesar announced to be the first emperor of Rome in 31BC and ended with the fall of Constantinople in 1453CE. Known to be the centre of the Roman Empire, Rome attracts the majority of the crowd due to its rich history and politics.</span><br />
      &nbsp;</p>
      
      <div style="float:left; padding-right:20px">
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/Hotel-Eden-La-Terrazza-scaled+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><em><span style="font-size:10px">Image by VIA DEI MONTI PARIOLI</span></em></div>
      
      <p>&nbsp;</p>
      </div>
      
      <p><span style="font-size:16px">Referred to as &lsquo;An Eternal City&rsquo; in the beginning of the history, Rome is seen to be a dream for music lovers due to the Parco Della Musica which is known to be one of the largest music venues in the world. Not only music, Rome has also set itself in the most wanted places to visit in the world and is the third m</span><span style="font-size:16px">ost visited places in the entire Europe. This place has experienced arts from different periods and became a home for some of the most renowned artists such as Borromini, Bernini, Carracci and Cortona.</span></p>
      
      <p><span style="font-size:16px">Embracing the ancient art, Rome has now decided to use its innovation to give birth to contemporary art to keep enhancing their love for art and architecture, National Museum of the 21st Century Arts is a live example of that. The Italian cuisine has somehow managed to be a king of all cuisines, from the Carciofi alla romana to the Spaghetti alla carbonara- Delicious spaghetti topped with bacon, smokey eggs and pecorino is what makes it mouth watering.&nbsp;</span></p>
      
      <p><span style="font-size:16px">Rome has a series of reasons to attract you and slip you into its warm arms to rejuvenate your soul with its authentic art, awe-inspiring architecture and mouth-watering food.</span></p>
      
      
      `,
      mobile: `<p><span style="font-size:16px">Nestled in the heart of Italian Peninsula, Rome is the capital of the country and one of the most populated cities in Italy. From its rich architecture to rare artefacts, this city is known to possess a history like none other. The pic</span><span style="font-size:16px">turesque atmosphere, the cold wind blowing and the serene atmosphere is what makes Rome a must visit place for all t</span><span style="font-size:16px">he travel fanatics out there.</span></p>

      <div>
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/rome-civitavecchia-italy_1800x1000+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city adsasd wholeheartedly welcomes travellers from a wide array of interests, from awe inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city wholeheartedly welcomes travellers from a wide arra</span><span style="font-size:16px">y of interests, from awe</span>&nbsp;&nbsp;<span style="font-size:16px">inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">The Roman Empire has been an icon when it comes to historical importance. The Roman Empire had cut the ribbons when Augustus Caesar announced to be the first emperor of Rome in 31BC and ended with the fall of Constantinople in 1453CE. Known to be the centre of the Roman Empire, Rome attracts the majority of the crowd due to its rich history and politics.</span><br />
      &nbsp;</p>
      
      <div>
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/Hotel-Eden-La-Terrazza-scaled+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <p><span style="font-size:16px">Referred to as &lsquo;An Eternal City&rsquo; in the beginning of the history, Rome is seen to be a dream for music lovers due to the Parco Della Musica which is known to be one of the largest music venues in the world. Not only music, Rome has also set itself in the most wanted places to visit in the world and is the third m</span><span style="font-size:16px">ost visited places in the entire Europe. This place has experienced arts from different periods and became a home for some of the most renowned artists such as Borromini, Bernini, Carracci and Cortona.</span></p>
      
      <p><span style="font-size:16px">Embracing the ancient art, Rome has now decided to use its innovation to give birth to contemporary art to keep enhancing their love for art and architecture, National Museum of the 21st Century Arts is a live example of that. The Italian cuisine has somehow managed to be a king of all cuisines, from the Carciofi alla romana to the Spaghetti alla carbonara- Delicious spaghetti topped with bacon, smokey eggs and pecorino is what makes it mouth watering.&nbsp;</span></p>
      
      <p><span style="font-size:16px">Rome has a series of reasons to attract you and slip you into its warm arms to rejuvenate your soul with its authentic art, awe-inspiring architecture and mouth-watering food.</span></p>
      
      
      `,
    },
    placesToVisit: {
      web: `<div style="float:right; padding-left:20px">
      <div>&nbsp;</div>
      </div>`,
      mobile: `<p><span style="font-size:16px">Nestled in the heart of Italian Peninsula, Rome is the capital of the country and one of the most populated cities in Italy. From its rich architecture to rare artefacts, this city is known to possess a history like none other. The pic</span><span style="font-size:16px">turesque atmosphere, the cold wind blowing and the serene atmosphere is what makes Rome a must visit place for all t</span><span style="font-size:16px">he travel fanatics out there.</span></p>

      <div>
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/rome-civitavecchia-italy_1800x1000+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city adsasd wholeheartedly welcomes travellers from a wide array of interests, from awe inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city wholeheartedly welcomes travellers from a wide arra</span><span style="font-size:16px">y of interests, from awe</span>&nbsp;&nbsp;<span style="font-size:16px">inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">The Roman Empire has been an icon when it comes to historical importance. The Roman Empire had cut the ribbons when Augustus Caesar announced to be the first emperor of Rome in 31BC and ended with the fall of Constantinople in 1453CE. Known to be the centre of the Roman Empire, Rome attracts the majority of the crowd due to its rich history and politics.</span><br />
      &nbsp;</p>
      
      <div>
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/Hotel-Eden-La-Terrazza-scaled+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <p><span style="font-size:16px">Referred to as &lsquo;An Eternal City&rsquo; in the beginning of the history, Rome is seen to be a dream for music lovers due to the Parco Della Musica which is known to be one of the largest music venues in the world. Not only music, Rome has also set itself in the most wanted places to visit in the world and is the third m</span><span style="font-size:16px">ost visited places in the entire Europe. This place has experienced arts from different periods and became a home for some of the most renowned artists such as Borromini, Bernini, Carracci and Cortona.</span></p>
      
      <p><span style="font-size:16px">Embracing the ancient art, Rome has now decided to use its innovation to give birth to contemporary art to keep enhancing their love for art and architecture, National Museum of the 21st Century Arts is a live example of that. The Italian cuisine has somehow managed to be a king of all cuisines, from the Carciofi alla romana to the Spaghetti alla carbonara- Delicious spaghetti topped with bacon, smokey eggs and pecorino is what makes it mouth watering.&nbsp;</span></p>
      
      <p><span style="font-size:16px">Rome has a series of reasons to attract you and slip you into its warm arms to rejuvenate your soul with its authentic art, awe-inspiring architecture and mouth-watering food.</span></p>
      
      
      `,
    },
    nearByPlaces: {
      web: `<p><span style="font-size:16px">Nestled in the heart of Italian Peninsula, Rome is the capital of the country and one of the most populated cities in Italy. From its rich architecture to rare artefacts, this city is known to possess a history like none other. The pic</span><span style="font-size:16px">turesque atmosphere, the cold wind blowing and the serene atmosphere is what makes Rome a must visit place for all t</span><span style="font-size:16px">he travel fanatics out there.</span></p>

      <div style="float:right; padding-left:20px">
      <div><img alt="" src="https://cdn.rydeu.com/rome-civitavecchia-italy_1800x1000+1.png" style="height:225px; width:391px" /></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <div><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city adsasd wholeheartedly welcomes travellers from a wide array of interests, from awe inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></div>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city wholeheartedly welcomes travellers from a wide arra</span><span style="font-size:16px">y of interests, from awe</span>&nbsp;&nbsp;<span style="font-size:16px">inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">The Roman Empire has been an icon when it comes to historical importance. The Roman Empire had cut the ribbons when Augustus Caesar announced to be the first emperor of Rome in 31BC and ended with the fall of Constantinople in 1453CE. Known to be the centre of the Roman Empire, Rome attracts the majority of the crowd due to its rich history and politics.</span><br />
      &nbsp;</p>
      
      <div style="float:left; padding-right:20px">
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/Hotel-Eden-La-Terrazza-scaled+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><em><span style="font-size:10px">Image by VIA DEI MONTI PARIOLI</span></em></div>
      
      <p>&nbsp;</p>
      </div>
      
      <p><span style="font-size:16px">Referred to as &lsquo;An Eternal City&rsquo; in the beginning of the history, Rome is seen to be a dream for music lovers due to the Parco Della Musica which is known to be one of the largest music venues in the world. Not only music, Rome has also set itself in the most wanted places to visit in the world and is the third m</span><span style="font-size:16px">ost visited places in the entire Europe. This place has experienced arts from different periods and became a home for some of the most renowned artists such as Borromini, Bernini, Carracci and Cortona.</span></p>
      
      <p><span style="font-size:16px">Embracing the ancient art, Rome has now decided to use its innovation to give birth to contemporary art to keep enhancing their love for art and architecture, National Museum of the 21st Century Arts is a live example of that. The Italian cuisine has somehow managed to be a king of all cuisines, from the Carciofi alla romana to the Spaghetti alla carbonara- Delicious spaghetti topped with bacon, smokey eggs and pecorino is what makes it mouth watering.&nbsp;</span></p>
      
      <p><span style="font-size:16px">Rome has a series of reasons to attract you and slip you into its warm arms to rejuvenate your soul with its authentic art, awe-inspiring architecture and mouth-watering food.</span></p>
      
      
      `,
      mobile: `<p><span style="font-size:16px">Nestled in the heart of Italian Peninsula, Rome is the capital of the country and one of the most populated cities in Italy. From its rich architecture to rare artefacts, this city is known to possess a history like none other. The pic</span><span style="font-size:16px">turesque atmosphere, the cold wind blowing and the serene atmosphere is what makes Rome a must visit place for all t</span><span style="font-size:16px">he travel fanatics out there.</span></p>

      <div>
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/rome-civitavecchia-italy_1800x1000+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city adsasd wholeheartedly welcomes travellers from a wide array of interests, from awe inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">A perfect blend of gothic ruins, jaw dropping art and lively nights, Rome sets an exemplary example for a place about how it should entertain its travellers. The city wholeheartedly welcomes travellers from a wide arra</span><span style="font-size:16px">y of interests, from awe</span>&nbsp;&nbsp;<span style="font-size:16px">inspiring monuments to living life at its best, Rome will never fail to surprise you with a new place to explore.</span></p>
      
      <p><span style="font-size:16px">The Roman Empire has been an icon when it comes to historical importance. The Roman Empire had cut the ribbons when Augustus Caesar announced to be the first emperor of Rome in 31BC and ended with the fall of Constantinople in 1453CE. Known to be the centre of the Roman Empire, Rome attracts the majority of the crowd due to its rich history and politics.</span><br />
      &nbsp;</p>
      
      <div>
      <div><span style="font-size:16px"><img alt="" src="https://cdn.rydeu.com/Hotel-Eden-La-Terrazza-scaled+1.png" style="height:225px; width:391px" /></span></div>
      
      <div><span style="font-size:14px">Rome Civitavecchia, Italy</span></div>
      
      <div><em><span style="font-size:10px">Image by xyz via Flickr</span></em></div>
      </div>
      
      <p><span style="font-size:16px">Referred to as &lsquo;An Eternal City&rsquo; in the beginning of the history, Rome is seen to be a dream for music lovers due to the Parco Della Musica which is known to be one of the largest music venues in the world. Not only music, Rome has also set itself in the most wanted places to visit in the world and is the third m</span><span style="font-size:16px">ost visited places in the entire Europe. This place has experienced arts from different periods and became a home for some of the most renowned artists such as Borromini, Bernini, Carracci and Cortona.</span></p>
      
      <p><span style="font-size:16px">Embracing the ancient art, Rome has now decided to use its innovation to give birth to contemporary art to keep enhancing their love for art and architecture, National Museum of the 21st Century Arts is a live example of that. The Italian cuisine has somehow managed to be a king of all cuisines, from the Carciofi alla romana to the Spaghetti alla carbonara- Delicious spaghetti topped with bacon, smokey eggs and pecorino is what makes it mouth watering.&nbsp;</span></p>
      
      <p><span style="font-size:16px">Rome has a series of reasons to attract you and slip you into its warm arms to rejuvenate your soul with its authentic art, awe-inspiring architecture and mouth-watering food.</span></p>
      
      
      `,
    },
  },
};

import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../../../../Components/Common/Modal/Modal";
import Editor from "../../../../../Components/Common/Editor/Editor";

const AnswerFormSchema = Yup.object().shape({
  answer: Yup.string().trim().required()
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto"
    },
    inputContainer: {
      width: "100%",
      margin: spacing(2, 0)
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2)
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start"
    }
  };
});

const AnswerModal = ({
  answerDetails,
  showModal,
  closeModal,
  refreshList,
  cityDetails
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [imageCreditType, setImageCreditType] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue
  } = useFormik({
    validationSchema: AnswerFormSchema,
    initialValues: {
      answer: "",
      ...answerDetails
    },
    onSubmit: (data) => {
      console.log(data);
      if (answerDetails.id) {
        editAnswer(data);
      } else {
        addAnswer(data);
      }
    }
  });

  const addAnswer = (data) => {
    dataProvider
      .create(`answers`, {
        data
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Answer created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editAnswer = (data) => {
    dataProvider
      .update(`answers`, {
        id: answerDetails.id,
        data: {
          answer: data.answer,
          question: answerDetails.question
        }
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Answer Details updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">{`${
          answerDetails ? "Edit" : "Create"
        } Answer`}</Typography>
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="space-between">
            <Editor
              onChange={(e) => setFieldValue("answer", e.editor.getData())}
              style={{ borderWidth: 0 }}
              config={{
                height: "200px",
                width: "100%",
                resize_enabled: false
              }}
              data={values.answer}
            />
          </Box>
          <Box display="flex" mt={2} justifyContent="space-between">
            <Button onClick={closeModal} variant="text" color="primary">
              {answerDetails.id ? "Discard" : "cancel"}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {answerDetails.id ? "Save" : "Submit"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AnswerModal;

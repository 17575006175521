import React, { useEffect, useState } from "react";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import {
  BooleanField,
  SelectField,
  FunctionField,
  DateField,
  SingleFieldList,
  ChipField,
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  ReferenceField,
  Tab,
  TabbedShowLayout,
  EditButton,
  ReferenceManyField,
  ShowButton,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import {
  Box,
  TextField as MaterialTextField,
  Button as MaterialButton,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import Clear from "@material-ui/icons/Clear";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import moment from "moment-timezone";
import LongDateField from "Components/Common/Fields/LongDateField";
import { BookingStatusChoices } from "Constants/Bookings";
import { CrmTypeChoices } from "Constants/Crm";
import { TransferOfferStatus } from "Constants/TransferOffers";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CurrencyField from "Components/Common/Fields/CurrencyField";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import RecordFetcher from "Components/Common/RecordFetcher";
import Button from "Components/Common/RaOverrides/Button";
import { withRouter } from "react-router";
import TopToolbar from "Components/Common/RaOverrides/TopToolbar";
import BookingConfirmModal from "./BookingConfirmModal";
import CrmModal from "Components/CrmModal";
import AlertDialog from "Components/Common/AlertDialog/AlertDialog";
import { CommunicationStatusTypeChoices } from "Constants/CommunicatedToCustomer";
import { capitalize, lowerCase, upperCase } from "lodash";
import { SUPPLIER_APP_HOSTNAME } from "Constants";
import CopyIcon from "@material-ui/icons/FileCopy";
import axios from "axios";
import BookingCancelModal from "./BookingCancelModal";

const useStyles = makeStyles((theme) => ({
  addressText: {
    display: "flex",
    flexWrap: "wrap",
    width: "17rem",
  },
  transferIdLink: {
    color: "#0157B5",
    cursor: "pointer",
  },
  columnGap: {
    columnGap: "3rem",
  },
  communicationRadioContainer: {
    width: "100%",
    paddingLeft: "6em",
  },
  customerCommunicationLabel: {
    fontSize: "1.5rem",
    fontWeight: "500",
    color: "#18191A",
    marginBottom: "0.4em",
    "&$focused": {
      color: "pink !important",
    },
  },
  createOfferButton: {
    width: "8rem",
    float: "right",
  },
  communicationButton: {
    width: "5rem",
    marginBottom: "1rem",
    marginTop: "0.4em",
  },
  trashButton: {
    position: "absolute",
    right: 10,
    cursor: "pointer",
    color: "#D50104",
  },
  templateList: {
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "scroll",
    maxHeight: "60vh",
  },
  templateContainer: {
    textAlign: "left",
    minWidth: "5%",
    maxWidth: "400px",
    width: "max-content",
    height: "auto",
    display: "inline-block",
    lineHeight: "1.5rem",
    overflowWrap: "break-word",
    background: "#BDC3C7",
    padding: "0.5rem 1rem",
    borderRadius: "0.3rem",
    marginBottom: "0.5em",
    fontSize: 14,
  },
  templateHeading: {
    marginTop: "10px",
    letterSpacing: "1px",
    fontSize: "1.2rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  commentListContainer: {
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    maxHeight: "40vh",
  },
  commentBox: {
    position: "relative",
    textAlign: "left",
    minWidth: "5%",
    maxWidth: "400px",
    width: "max-content",
    height: "auto",
    display: "inline-block",
    lineHeight: "1.5rem",
    overflowWrap: "break-word",
    background: "#BDC3C7",
    padding: "0.5rem 1rem",
    borderRadius: "0.3rem",
    marginBottom: "0.5em",
    fontSize: 14,
  },
  dateText: {
    fontSize: 10,
    textAlign: "right",
    fontWeight: "700",
  },
  emptyComments: {
    fontSize: "1.2rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh",
  },
  clipboardButton: {
    width: "25%",
    alignSelf: "flex-end",
    fontSize: "14px",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  button: {
    width: "25%",
    alignSelf: "flex-end",
    fontSize: "14px",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  crmSelect: {
    marginTop: "1rem",
    width: "40%",
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "1rem",
    height: "100%",
  },
  addButton: {
    marginTop: "0.6rem",
    fontSize: 14,
    background: "#01579B",
    paddingLeft: 15,
    paddingTop: 12,
    paddingRight: 15,
    paddingBottom: 12,
    color: "#ffff",
    borderRadius: 4,
    fontWeight: "500",
  },
  bookButton: {
    fontSize: 13,
    background: "#01579B",
    paddingLeft: 15,
    paddingTop: 7,
    paddingRight: 15,
    paddingBottom: 7,
    color: "#ffff",
    borderRadius: 4,
    fontWeight: "500",
  },
}));

const BookingTitle = ({ record }) => {
  return <span>Booking ID: {record ? `${record.bookingNumber}` : ""}</span>;
};

const CreateBookingStateButton = ({ record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/bookingStates/create?bookingId=${record.id}&bookingNumber=${record.bookingNumber}`}
      label="Add New Booking State"
      title="Add New Booking State"
    >
      <AddIcon />
    </Button>
  );
};

const CreatePaymentButton = ({ record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/payments/create?bookingId=${record.id}&bookingNumber=${record.bookingNumber}&organizationId=${record.organizationId}`}
      label="Add New Payment"
      title="Add New Payment"
    >
      <AddIcon />
    </Button>
  );
};

// const AddVoucherForBookingButton = ({ record }) => (
//   <Button
//     variant="contained"
//     component={Link}
//     to={`/vouchers/create?bookingId=${record.id}`}
//     label="Add new voucher"
//     title="Add new voucher"
//   >
//     <AddIcon />
//   </Button>
// );

const PrintVoucherButton = ({ classes, record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  // console.log(record);
  return (
    <Button
      component={Link}
      onClick={() => {
        // console.log('print', dataProvider, record);
        dataProvider
          .getCustomWithQueryParams(`vouchers/${record.id}/printVouchers`)
          .then((res) => {
            notify("Vouchers Sent Successfully!");
            // console.log("Res From Print Voucher", res)
          })
          .catch((error) => {
            notify("Something went wrong!");
          });
      }}
      // label="E-mail Vouchers"
      title="Email Vouchers"
    >
      <SendIcon />
    </Button>
  );
};

const RequestApproveButton = ({ classes, record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirectTo = useRedirect();
  return (
    <Button
      component={Link}
      variant="contained"
      onClick={() => {
        console.log(record.id);
        dataProvider
          .update(`booking-request-approve`, { id: record.id, data: {} })
          .then((res) => {
            notify("Booking Approved Successfully!");
            redirectTo(`/bookingRequests`);
          })
          .catch((error) => {
            notify("Something went wrong!");
          });
      }}
      label="Approve Request"
      title="Approve Request"
    ></Button>
  );
};

const CreateVoucherForBookingButton = ({ classes, record, ...rest }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  return (
    <Button
      component={Link}
      variant="contained"
      onClick={() => {
        // console.log('generate vouchers', dataProvider, record);
        dataProvider
          .create("vouchers", { data: { bookingId: record.id } })
          .then((res) => {
            notify("Generated Vouchers Successfully!");
            // console.log(rest)
            let currRoute = rest.location.pathname;
            let matchUrl = rest.match.url;
            if (!matchUrl.endsWith("/")) matchUrl += "/";
            matchUrl += "vouchers";
            rest.history.push("/temp");
            rest.history.replace(matchUrl);
          })
          .catch((error) => {
            notify("Something went wrong!");
          });
      }}
      label="Generate Vouchers"
      title="Generate Vouchers"
    >
      <PrintIcon />
    </Button>
  );
};

const VoucherPanel = ({ id, record, resource, basePath }) => (
  <SimpleShowLayout
    record={record}
    resource={resource}
    id={id}
    basePath={basePath}
  >
    {/* <RecordFetcher setRecord={setRecord} /> */}
    {/* <ReferenceField link="show" source="bookingId" reference="bookings">
      <TextField source="bookingNumber" />
    </ReferenceField> */}
    {/* <TextField source="id" /> */}
    <TextField source="driverName" />
    <TextField source="driverContactNumber" />
    <TextField source="receiverName" />
    <TextField source="receiverEmail" />
    <TextField source="receiverPhoneNumber" />
    <TextField source="supportName" />
    <TextField source="supportPhoneNumber" />
    {/* <TextField source="createdBy" /> */}
    {/* <CustomUrlField displayName="Pdf Link" source="voucherUrl" /> */}
    {/* <TextField source="voucherNumber" /> */}
    {/* <LongDateField source="createdAt" /> */}
    {/* <DateField source="updatedAt" /> */}
    {/* <TextField source="deletedAt" /> */}
    {/* <ReferenceField source="bookingId" reference="bookings">
      <TextField source="id" />
    </ReferenceField> */}
    {/* <ReferenceField source="dmcBookingId" reference="dmcBookings">
      <TextField source="id" />
    </ReferenceField> */}

    <ArrayField source="voucherDetails">
      <Datagrid>
        <TextField source="orderNumber" />
        {/* <ReferenceField source="bookingId" reference="bookings">
          <TextField source="id" />
        </ReferenceField> */}
        <TextField source="supportName" />
        {/* <TextField source="supportPhoneNumber" /> */}
        <TextField source="pickupAddress" />
        <TextField source="dropAddress" />
        <TextField source="vehicleInfo" />
        {/* <NumberField source="noOfBaggage" /> */}
        {/* <NumberField source="noOfPax" /> */}
        {/* <EmailField source="email" /> */}
        {/* <TextField source="flightNo" /> */}
        <TextField source="customerName" />
        <LongDateField source="travelDateTime" />
        {/* <TextField source="distance" /> */}
        {/* <NumberField source="day" /> */}
      </Datagrid>
    </ArrayField>
    <Button
      variant={"contained"}
      addNegativeMargin
      customComponent={ShowButton}
      label="View Complete Details"
    />
  </SimpleShowLayout>
);

const voucherRowStyle = (record, index) => ({
  // borderLeft: record.voucherUrl ? '5px solid green' : 'white',
  background: record.voucherUrl
    ? "linear-gradient(to right, #afa, white 5%)"
    : "white",
  // backgroundColor: record.voucherUrl ? '#afa' : 'white',
});

const Aside = () => null;

const CrmUpdateButton = ({ classes, record, showModalFunc }) => {
  return (
    <Button
      component={Link}
      variant="contained"
      onClick={() => showModalFunc()}
      label="Update Crm"
      title="Update Crm"
    ></Button>
  );
};

const CancelBookingRequestButton = ({ classes, record, showModalFunc }) => {
  // console.log(record);
  // console.log(typeof record.cancelledAt);
  return (
    <Button
      variant="contained"
      onClick={() => showModalFunc()}
      label="Cancel Booking Request"
      title="Cancel Booking Request"
      disabled={record?.cancelledAt}
    >
      <CancelIcon />
    </Button>
  );
};

const BookingHistory = ({ record}) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/bookingHistory/create?bookingId=${record.id}&bookingNumber=${record.bookingNumber}&referer=bookingrequests`}
      label="Booking History"
      title="Booking History"
    >
    </Button>
  );
};


const ShowButtons = (props) => {
  // console.log(props.data);
  switch (props.slug) {
    case "show":
      return (
        <>
          {props?.data && !props.data.isApproved && (
            <RequestApproveButton record={props.data} />
          )}
          <CancelBookingRequestButton
            showModalFunc={props.rest.setShowCancelBookingRequestModal}
            record={props.data}
          />

          <BookingHistory record={props.data}/>

          <Button
            variant="contained"
            customComponent={EditButton}
            basePath={props.basePath}
            record={props.data}
          />
        </>
      );
    case "crm":
      return (
        <CrmUpdateButton
          showModalFunc={props.rest.showModalFunc}
          record={props.data}
        />
      );
    default:
      return <></>;
  }
};

const BookingShowActions = withRouter(
  ({ basePath, data, resource, ...rest }) => {
    const slug = rest.location.pathname.split("/").pop();
    return (
      <TopToolbar>
        <ShowButtons
          slug={slug}
          data={data}
          rest={rest}
          resource={resource}
          basePath={basePath}
        />
        {/* <CreatePaymentButton record={data} />      */}
        {/* <CreateVouchersButton record={data} /> */}
        {/* <CreateVoucherForBookingButton record={data} {...rest}/> */}
      </TopToolbar>
    );
  }
);

const AddStateButton = ({ state, classes }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <div className={classes.addButtonContainer}>
      <a
        className={classes.addButton}
        onClick={(e) => {
          e.stopPropagation();
          // dataProvider
          //   .create("crm", { data: { state: state } })
          //   .then((res) => {
          //     notify("Crm State Created !");
          //   })
          //   .catch((error) => {
          //     notify("Something went wrong!");
          //   });
        }}
      >
        Add State
      </a>
    </div>
  );
};

const BookButton = ({
  record,
  showModalFunc,
  setTransferOfferRecord,
  buttonClass,
}) => {
  return (
    <a
      className={buttonClass}
      onClick={(e) => {
        e.stopPropagation();
        setTransferOfferRecord(record);
        showModalFunc();
      }}
    >
      Book
    </a>
  );
};

export const BookingShow = ({ history, ...props }) => {
  const classes = useStyles();
  const [record, setRecord] = React.useState({});
  const userId = record?.user?.id;
  const [transferOfferRecord, setTransferOfferRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCancelBookingRequestModal, setShowCancelBookingRequestModal] =
    useState(false);
  const [offerId, setOfferId] = useState(null);
  const [showCrmModal, setShowCrmModal] = useState(false);
  const [comment, setComment] = useState(null);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirectTo = useRedirect();
  const notify = useNotify();

  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Booking Requests",
      url: "/bookingRequests",
    },
    {
      name: `${record.bookingNumber}`,
      url: `/bookingRequests/${record.id}/show`,
    },
  ];

  const handleRemoveComment = (commentObj) => {
    dataProvider
      .delete(`/crm/${record?.crm?.id}/comments`, {
        id: commentObj.commentId,
        previousData: commentObj,
      })
      .then(() => {
        notify("comment deleted successfully");
        refresh();
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const cancelOfferHandler = () => {
    setOfferId(null);
    dataProvider
      .create(`transferOffers/${offerId}`, {
        data: {
          cancelReason: "Offer cancelled by admin",
        },
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddComment = () => {
    if (comment == null || comment == "") {
      return notify("Please add comment first !");
    }

    const data = {
      comments: {
        createdBy: "Admin",
        comment: comment,
      },
    };

    dataProvider
      .create(`/crm/${record?.crm?.id}/comments`, { data })
      .then(() => {
        notify("comment added successfully");
        refresh();
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const handleRowClick = (id, basePath, record) => {
    history.push({
      pathname: `${basePath}/${id}/show`,
      search: "",
      state: { prevResource: "bookingRequest" },
    });
  };

  const handleOffers = (record, type) => {
    if (record && record.transfers) {
      return {
        transferId: record.transfers[0].id,
        offerType: type,
        resource: "bookingRequests",
      };
    }
  };

  const handleCopyToClipboard = (templateId) => {
    var r = document.createRange();
    r.selectNode(document.getElementById(templateId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    var textarea = document.getElementById("hidden-area");
    textarea.style.display = "block";
    textarea.value = r
      .toString()
      .split("\n")
      .map((text) => text.trim(" "))
      .join("\n")
      .trim();
    textarea.select();
    document.execCommand("copy");
    textarea.style.display = "none";
  };

  const handleCreateOfferRedirect = () => {
    window.open(
      `${SUPPLIER_APP_HOSTNAME}/dashboard/requests/${record.id}`,
      "_blank"
    );
  };

  const ChatMessages = ({ commentList, handleRemoveComment }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [hoverCommentId, setHoverCommentId] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);

    const handleClickOpen = (commentObj) => {
      setSelectedComment(commentObj);
      setIsDeleteModalOpen(true);
    };

    const handleClose = () => {
      setIsDeleteModalOpen(false);
    };

    const parseMsg = (comment) => {
      const obj = JSON.parse(comment);
      const keys = Object.keys(obj.before);

      const showObj = {
        pickupLocation: "Pickup Addr",
        dropLocation: "Drop Addr",
        startDateTime: "Start time",
        returnDateTime: "Return time",
        totalAdultSeats: "No. of Adults",
        totalChildSeats: "No. of Children",
        numberOfHours: "Duration",
        passengerName: "Passenger Name",
        passengerContactNumber: "Passenger Contact No.",
        baggageCount: "No. of Baggage",
        flightNumber: "Flight No.",
        customerComment: "Customer Comment",
      };

      return (
        <>
          {keys.map((key) => {
            return (
              <>
                <>
                  <b>{showObj[key]}</b> changed from {obj.before[key] ?? "null"}{" "}
                  to {obj.after[key]}
                </>
                <br></br>
              </>
            );
          })}
        </>
      );
    };

    return (
      <>
        <AlertDialog
          buttonComponent={null}
          isOpen={isDeleteModalOpen}
          handleClose={handleClose}
          handleOk={() => handleRemoveComment(selectedComment)}
          handleCancel={handleClose}
        />
        {commentList && commentList.length ? (
          <div className={classes.commentListContainer}>
            {commentList.map((commentObj) => {
              return (
                <div
                  key={commentObj.commentId}
                  className={classes.commentBox}
                  onMouseEnter={(e) => {
                    setHoverCommentId(commentObj.commentId);
                  }}
                  onMouseLeave={(e) => {
                    setHoverCommentId(null);
                  }}
                >
                  <a
                    onClick={() => handleClickOpen(commentObj)}
                    className={classes.trashButton}
                    style={
                      commentObj.commentId === hoverCommentId
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <DeleteIcon style={{ fontSize: 24 }} />
                  </a>
                  <div className={classes.comment}>
                    {commentObj.isObject
                      ? parseMsg(commentObj.comment)
                      : commentObj.comment}
                  </div>
                  <div className={classes.dateText}>
                    {moment
                      .tz(commentObj.createdAt, moment.tz.guess())
                      .format("DD/MM/YYYY, hh:mm A")}
                  </div>
                  <div className={classes.dateText}>{commentObj.createdBy}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.emptyComments}>No comments added yet !</div>
        )}
      </>
    );
  };

  const CustomerCommunication = ({ communicationValue, crmId }) => {
    const [communicatedToCustomer, setCommunicatedToCustomer] = useState(null);

    useEffect(() => {
      if (communicationValue) {
        setCommunicatedToCustomer([...communicationValue]);
        return;
      }
      setCommunicatedToCustomer([]);
    }, []);

    const handleCommunicationChange = (event) => {
      if (communicatedToCustomer.includes(event.target.value)) {
        setCommunicatedToCustomer(
          communicatedToCustomer.filter(
            (communication) => communication !== event.target.value
          )
        );
        return;
      }
      setCommunicatedToCustomer([
        ...communicatedToCustomer,
        event.target.value,
      ]);
    };

    const handleCommunicationUpdate = () => {
      if (crmId)
        dataProvider
          .update(`crm`, { id: crmId, data: { communicatedToCustomer } })
          .then((res) => {
            notify("Crm updated successfully! ");
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
            notify("Something went wrong!");
          });
    };

    return (
      <FormControl
        component="fieldset"
        className={classes.communicationRadioContainer}
      >
        <FormLabel
          component="label"
          className={classes.customerCommunicationLabel}
        >
          Customer Communication
        </FormLabel>
        <FormGroup row>
          {CommunicationStatusTypeChoices &&
            CommunicationStatusTypeChoices.map((communicationType, index) => (
              <FormControlLabel
                key={communicationType.id}
                value={communicationType.id}
                control={
                  <Checkbox
                    style={index > 2 ? { color: "#ff5d59" } : {}}
                    checked={
                      communicatedToCustomer &&
                      communicatedToCustomer.includes(communicationType.id)
                    }
                    onChange={handleCommunicationChange}
                    name={communicationType.name}
                  />
                }
                label={capitalize(communicationType.name)}
              />
            ))}
        </FormGroup>
        <MaterialButton
          variant="contained"
          color="primary"
          className={classes.communicationButton}
          onClick={handleCommunicationUpdate}
        >
          Update
        </MaterialButton>
      </FormControl>
    );
  };

  const handleAddressCopy = (e, id, type) => {
    e.stopPropagation();
    let address;
    if (type == "pickup") {
      address = document.getElementById("pickup-" + id);
    } else if (type == "drop") {
      address = document.getElementById("drop-" + id);
    } else if (type == "user contact") {
      address = document.getElementById("user contact-" + id);
    } else if (type == "user email") {
      address = document.getElementById("user email-" + id);
    }
    console.log(address);
    const textArea = document.createElement("textarea");
    textArea.value = address.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  const offerStyle = (record, index) => {
    const style = {
      background: "white",
    };

    if (
      record.currentState == TransferOfferStatus.CANCELLED ||
      record.currentState == TransferOfferStatus.EXPIRED
    ) {
      style.background = "linear-gradient(to right, #FF0000, white 5%)";
    }

    return style;
  };

  return (
    <>
      <CrmModal
        bookingRecord={record}
        showModal={showCrmModal}
        closeModal={() => setShowCrmModal(false)}
      />
      <BookingConfirmModal
        userId={userId}
        transferOfferRecord={transferOfferRecord}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
      <BookingCancelModal
        bookingRecord={record}
        showModal={showCancelBookingRequestModal}
        closeModal={() => setShowCancelBookingRequestModal(false)}
        refresh={refresh}
      />

      <Breadcrumbs links={breadcrumbLinks} />
      <Show
        title={<BookingTitle />}
        actions={
          <BookingShowActions
            showModalFunc={() => setShowCrmModal(true)}
            setShowCancelBookingRequestModal={() =>
              setShowCancelBookingRequestModal(true)
            }
          />
        }
        aside={<Aside />}
        {...props}
      >
        <SimpleShowLayout>
          <BoxedShowLayout>
            <h3>Transfer Details</h3>
            <RaBox
              flexWrap="wrap"
              flexGrow={4}
              display="flex"
              justifyContent="space-between"
            >
              <FunctionField
                label="Transfer ID"
                render={(record) => (
                  <div
                    className={classes.transferIdLink}
                    onClick={() =>
                      handleRowClick(
                        record?.transfers[0]?.id,
                        "/transfers",
                        record
                      )
                    }
                  >
                    {record?.transfers[0]?.id}
                  </div>
                )}
              />
              <FunctionField
                label="Start Date Time"
                render={(record) =>
                  moment
                    .tz(
                      record?.transfers[0]?.startDateTime,
                      record?.transfers[0]?.timezone || moment.tz.guess()
                    )
                    .format("DD/MM/YYYY, HH:mm")
                }
              />
              <FunctionField
                label="Return Date Time"
                render={(record) => {
                  if (record?.transfers[0]?.returnDateTime) {
                    return moment
                      .tz(
                        record?.transfers[0]?.returnDateTime,
                        record?.transfers[0]?.timezone || moment.tz.guess()
                      )
                      .format("DD/MM/YYYY, HH:mm");
                  } else return "N/A";
                }}
              />
              <FunctionField
                label="Pickup Location"
                render={(record) => (
                  <>
                    <div className={classes.pickuplocation}>
                      <CopyIcon
                        style={{ fontSize: 14 }}
                        onClick={(e) =>
                          handleAddressCopy(e, record.id, "pickup")
                        }
                      />
                      <div style={{ display: "inline-block" }}>
                        <span id={`pickup-${record.id}`}>
                          {(() => {
                            if (
                              record?.transfers[0]?.pickupLocation
                                ?.formattedAddress
                            ) {
                              return (
                                <div className={classes.addressText}>
                                  {
                                    record.transfers[0].pickupLocation
                                      .formattedAddress
                                  }
                                </div>
                              );
                            } else return "N/A";
                          })()}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              />
              <FunctionField
                label="Drop Location"
                render={(record) => (
                  <>
                    <div className={classes.pickuplocation}>
                      <CopyIcon
                        style={{ fontSize: 14 }}
                        onClick={(e) => handleAddressCopy(e, record.id, "drop")}
                      />
                      <div style={{ display: "inline-block" }}>
                        <span id={`drop-${record.id}`}>
                          {(() => {
                            if (
                              record?.transfers[0]?.dropLocation
                                ?.formattedAddress
                            ) {
                              return (
                                <div className={classes.addressText}>
                                  {
                                    record.transfers[0].dropLocation
                                      .formattedAddress
                                  }
                                </div>
                              );
                            } else return "N/A";
                          })()}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              />
              <TextField label="Distance" source="transfers[0].numberOfKms" />
              <TextField label="Duration" source="transfers[0].numberOfHours" />
            </RaBox>
            <RaBox
              flexWrap="wrap"
              marginTop={1}
              flexGrow={4}
              className={classes.columnGap}
              display="flex"
              justifyContent="flex-start"
            >
              <FunctionField
                label="Passengers / BaggageCount"
                render={(record) =>
                  `${record?.transfers[0]?.totalAdultSeats},${record?.transfers[0]?.totalChildSeats} / ${record?.transfers[0]?.baggageCount}`
                }
              />
              <TextField
                label="Transfer Type"
                source="transfers[0].transferType"
              />
              <ArrayField
                label="vehicleTypes"
                source="transfers[0].vehicleTypes"
              >
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              </ArrayField>
              <TextField
                label="Customer Comments"
                source="transfers[0].customerComment"
              />
            </RaBox>
          </BoxedShowLayout>
          <TabbedShowLayout>
            <Tab label="Request Details">
              <RecordFetcher setRecord={setRecord} />
              <BoxedShowLayout>
                <h3>Request Details</h3>
                <RaBox
                  flexWrap="wrap"
                  flexGrow={4}
                  display="flex"
                  justifyContent="space-between"
                >
                  <TextField
                    className={classes.rowField}
                    label="Booking ID"
                    source="id"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Booking Number"
                    source="bookingNumber"
                  />
                  <CurrencyField
                    label="Total Amount"
                    source="totalAmountCents"
                  />
                  <SelectField
                    source="currentState"
                    choices={BookingStatusChoices}
                  />
                  <TextField source="paymentMethod" />
                  {/* <TextField 
                    className={classes.rowField}
                    label="User Contact"
                    source="user.account.mobileNo"                                      
                  />                   */}
                  <FunctionField
                    label="User Contact"
                    render={(record) => (
                      <>
                        <div className={classes.pickuplocation}>
                          <CopyIcon
                            style={{ fontSize: 14 }}
                            onClick={(e) =>
                              handleAddressCopy(e, record.id, "user contact")
                            }
                          />
                          <div style={{ display: "inline-block" }}>
                            <span id={`user contact-${record.id}`}>
                              {record ? record.user.mobileNo : "N/A"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  />
                  {/* <ReferenceField
                    label="User"
                    source="user.id"
                    reference="users"
                  > */}
                  <FunctionField
                    label="User Email"
                    render={(record) => (
                      <>
                        <div>
                          <CopyIcon
                            style={{ fontSize: 14 }}
                            onClick={(e) =>
                              handleAddressCopy(e, record.id, "user email")
                            }
                          />
                          <div style={{ display: "inline-block" }}>
                            <span id={`user email-${record.id}`}>
                              {record ? record.user.email : "N/A"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  />
                  {/* </ReferenceField>             */}
                </RaBox>
                <RaBox
                  flexWrap="wrap"
                  marginTop={2}
                  flexGrow={4}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <TextField
                    label="Cancellation Reason"
                    source="cancelReason"
                  />
                </RaBox>

                {/* <h3>Transfers Details</h3>
                <RaBox>
                  <RecordFetcher setRecord={setRecord} />
                  <ReferenceManyField
                    reference="transfers"
                    target="bookingId"
                    addLabel={false}
                    sort={{ field: "createdAt", order: "DESC" }}
                  >
                    <Datagrid rowClick={handleRowClick}>
                      <TextField label="Transfer ID" source="id" />
                      <FunctionField
                        label="Start Date Time"
                        render={(record) =>
                          moment
                            .tz(
                              record.startDateTime,
                              record.timezone || moment.tz.guess()
                            )
                            .format("DD/MM/YYYY, HH:mm")
                        }
                      />
                      <FunctionField
                        label="Return Date Time"
                        render={(record) => {
                          console.log(record);
                          if (record.returnDateTime) {
                            return moment
                              .tz(
                                record.returnDateTime,
                                record.timezone || moment.tz.guess()
                              )
                              .format("DD/MM/YYYY, HH:mm");
                          } else return "N/A";
                        }}
                      />
                      <TextField
                        label="Pickup Location"
                        source="pickupLocation.formattedAddress"
                      />
                      <TextField
                        label="Drop Location"
                        source="dropLocation.formattedAddress"
                      />
                      <TextField label="Distance" source="numberOfKms" />
                      <TextField label="Duration" source="numberOfHours" />
                      <FunctionField
                        label="Passengers / BaggageCount"
                        render={(record) =>
                          `${record.totalAdultSeats},${record.totalChildSeats} / ${record.baggageCount}`
                        }
                      />
                      <TextField label="Transfer Type" source="transferType" />
                      <ArrayField label="vehicleTypes" source="vehicleTypes">
                        <SingleFieldList>
                          <ChipField source="name" />
                        </SingleFieldList>
                      </ArrayField>
                      <TextField
                        label="Customer Comments"
                        source="customerComment"
                      />
                    </Datagrid>
                  </ReferenceManyField>
                </RaBox> */}
              </BoxedShowLayout>
            </Tab>

            <Tab label="Offers" path="transferOffers">
              <RecordFetcher setRecord={setRecord} />
              <MaterialButton
                variant="contained"
                color="primary"
                type="button"
                className={classes.createOfferButton}
                onClick={handleCreateOfferRedirect}
              >
                Create Offer
              </MaterialButton>
              <h3>Rydeu Offers</h3>
              <ReferenceManyField
                reference="transferOffers"
                target={handleOffers(record, "rydeuOffers")}
                addLabel={false}
                sort={{ field: "createdAt", order: "DESC" }}
              >
                <Datagrid rowClick={handleRowClick} rowStyle={offerStyle}>
                  <ReferenceField
                    source="vendorId"
                    linkType="show"
                    reference="vendors"
                    label="Vendor Id"
                  >
                    <TextField source="id" />
                  </ReferenceField>
                  <FunctionField
                    label="Vendor Details"
                    render={(record) => (
                      <>
                        <div>{record?.vendor?.companyName || "---"}</div>
                        <div style={{ marginTop: "3%" }}>
                          {record?.vendor?.user?.email || "---"}
                        </div>
                      </>
                    )}
                  />
                  <FunctionField
                    label="Offered Vehicle"
                    render={(record) =>
                      `${record.vehicleType.name} ${record.vehicleType.refModel}`
                    }
                  />
                  <FunctionField
                    sortBy="totalAmount"
                    label="Total Amount"
                    render={(record) =>
                      `${record?.transfer?.currency?.symbol || ""} ${
                        record.totalAmount
                      } / ${record?.transfer?.currency?.symbol || ""} ${
                        record.totalAmount +
                        (record.charges?.normal?.serviceCharges || 0)
                      }
                    `
                    }
                  />
                  <FunctionField
                    sortBy="reservationAmount"
                    label="Reservation Amount"
                    render={(record) =>
                      `
                    ${record?.transfer?.currency?.symbol || ""} ${
                        record.reservationAmount
                      }
                    /
                    ${record?.transfer?.currency?.symbol || ""} ${
                        record.reservationAmount +
                        (record.charges?.reservation?.serviceCharges || 0)
                      }`
                    }
                  />
                  <FunctionField
                    label="Amount Cents Per Hour"
                    render={(record) =>
                      `${record?.transfer?.currency?.symbol || ""} ${
                        record.amountCentsPerHour || 0
                      }`
                    }
                  />
                  <FunctionField
                    label="Booking Preference"
                    render={(record) => {
                      console.log({ record });
                      if (record?.bookingPreference)
                        return record?.bookingPreference === "BOOK_NOW"
                          ? "PRE_PAYMENT"
                          : record?.bookingPreference;
                      else return "N/A";
                    }}
                  />
                  <DateField
                    label="Limited Offer Due Date"
                    source="limitedOfferDueDate"
                  />
                  <FunctionField
                    label="Offer Creation Date"
                    render={(record) => {
                      const d = new Date(record.createdAt);
                      return `
                   ${
                     d.getUTCDate() < 10 ? "0" + d.getUTCDate() : d.getUTCDate()
                   }/${
                        d.getUTCMonth() + 1 < 10
                          ? "0" + (d.getUTCMonth() + 1)
                          : d.getUTCMonth() + 1
                      }/${d.getUTCFullYear()},
                    ${
                      d.getUTCHours() < 10
                        ? "0" + d.getUTCHours()
                        : d.getUTCHours()
                    }:${
                        d.getUTCMinutes() < 10
                          ? "0" + d.getUTCMinutes()
                          : d.getUTCMinutes()
                      }
                    `;
                    }}
                  />
                  <TextField label="Comment" source="comment" />

                  <FunctionField
                    render={(offerRecord) =>
                      offerRecord.currentState ==
                        TransferOfferStatus.PENDING && (
                        <BookButton
                          buttonClass={classes.bookButton}
                          record={offerRecord}
                          setTransferOfferRecord={(value) =>
                            setTransferOfferRecord(value)
                          }
                          showModalFunc={() => setShowModal(true)}
                        />
                      )
                    }
                  />

                  <FunctionField
                    label="icon"
                    render={(record) =>
                      record.currentState == TransferOfferStatus.PENDING && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setOfferId(record.id);
                          }}
                        >
                          <Clear
                            style={{ color: "red", height: 30, width: 30 }}
                          />
                        </IconButton>
                      )
                    }
                  />
                </Datagrid>
              </ReferenceManyField>
              <h3>Supplier Offers</h3>
              <ReferenceManyField
                reference="transferOffers"
                target={handleOffers(record, "supplierOffers")}
                source="transfers[0].id"
                addLabel={false}
                sort={{ field: "createdAt", order: "DESC" }}
              >
                <Datagrid rowClick={handleRowClick} rowStyle={offerStyle}>
                  <ReferenceField
                    source="vendorId"
                    linkType="show"
                    reference="vendors"
                    label="Vendor Id"
                  >
                    <TextField source="id" />
                  </ReferenceField>
                  <FunctionField
                    label="Vendor Details"
                    render={(record) => (
                      <>
                        <div>{record?.vendor?.companyName || "---"}</div>
                        <div style={{ marginTop: "3%" }}>
                          {record?.vendor?.user?.email || "---"}
                        </div>
                      </>
                    )}
                  />
                  <FunctionField
                    component="pre"
                    label="Offered Vehicle"
                    render={(record) =>
                      record.vehicle
                        ? `${record.vehicle.brand} ${record.vehicle.model} , ${record.vehicle.yearOfManufacture}`
                        : "No Vehicle"
                    }
                  />
                  <FunctionField
                    sortBy="totalAmount"
                    label="Total Amount"
                    render={(record) =>
                      `${record?.transfer?.currency?.symbol || ""} ${
                        record.totalAmount
                      } / ${record?.transfer?.currency?.symbol || ""} ${
                        record.totalAmount +
                        (record.charges?.normal?.serviceCharges || 0)
                      }
                    `
                    }
                  />
                  <FunctionField
                    sortBy="reservationAmount"
                    label="Reservation Amount"
                    render={(record) =>
                      `
                    ${record?.transfer?.currency?.symbol || ""} ${
                        record.reservationAmount
                      }
                    /
                    ${record?.transfer?.currency?.symbol || ""} ${
                        record.reservationAmount +
                        (record.charges?.reservation?.serviceCharges || 0)
                      }`
                    }
                  />
                  <FunctionField
                    label="Amount Cents Per Hour"
                    render={(record) =>
                      `${record?.transfer?.currency?.symbol || ""} ${
                        record.amountCentsPerHour || 0
                      }`
                    }
                  />
                  <FunctionField
                    label="Booking Preference"
                    render={(record) => {
                      console.log({ record });
                      if (record?.bookingPreference)
                        return record?.bookingPreference === "BOOK_NOW"
                          ? "PRE_PAYMENT"
                          : record?.bookingPreference;
                      else return "N/A";
                    }}
                  />
                  <DateField
                    label="Limited Offer Due Date"
                    source="limitedOfferDueDate"
                  />
                  <FunctionField
                    label="Offer Creation Date"
                    render={(record) => {
                      const d = new Date(record.createdAt);
                      return `
                   ${
                     d.getUTCDate() < 10 ? "0" + d.getUTCDate() : d.getUTCDate()
                   }/${
                        d.getUTCMonth() + 1 < 10
                          ? "0" + (d.getUTCMonth() + 1)
                          : d.getUTCMonth() + 1
                      }/${d.getUTCFullYear()},
                    ${
                      d.getUTCHours() < 10
                        ? "0" + d.getUTCHours()
                        : d.getUTCHours()
                    }:${
                        d.getUTCMinutes() < 10
                          ? "0" + d.getUTCMinutes()
                          : d.getUTCMinutes()
                      }
                    `;
                    }}
                  />
                  <TextField label="Comment" source="comment" />

                  <FunctionField
                    render={(offerRecord) =>
                      offerRecord.currentState ==
                        TransferOfferStatus.PENDING && (
                        <BookButton
                          buttonClass={classes.bookButton}
                          record={offerRecord}
                          setTransferOfferRecord={(value) =>
                            setTransferOfferRecord(value)
                          }
                          showModalFunc={() => setShowModal(true)}
                        />
                      )
                    }
                  />

                  <FunctionField
                    label="icon"
                    render={(record) =>
                      record.currentState == TransferOfferStatus.PENDING && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setOfferId(record.id);
                          }}
                        >
                          <Clear
                            style={{ color: "red", height: 30, width: 30 }}
                          />
                        </IconButton>
                      )
                    }
                  />
                </Datagrid>
              </ReferenceManyField>
            </Tab>

            {/* <Tab label="Booking States" path="bookingStates">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceManyField
              reference="bookingStates"
              target="bookingId"
              addLabel={false}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <Datagrid>
                <SelectField source="state" choices={BookingStatusChoices} />
                <LongDateField source="createdAt" />
                <TextField source="bookingId" />
              </Datagrid>
            </ReferenceManyField>
          </Tab> */}

            <Tab label="Crm" path="crm">
              <RecordFetcher setRecord={setRecord} />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                style={{ marginTop: "-8px" }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width="100%"
                  style={{ padding: "1rem", borderRight: "1px solid #D0D0D0" }}
                >
                  <ChatMessages
                    handleRemoveComment={handleRemoveComment.bind(this)}
                    commentList={record && record.crm && record.crm.comments}
                  />
                  <MaterialTextField
                    name="comments"
                    label="Comments"
                    variant="outlined"
                    className={classes.totalInput}
                    style={{ width: "100%", marginBottom: "1rem" }}
                    multiline
                    rows={3}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <MaterialButton
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<SendIcon />}
                    onClick={handleAddComment}
                  >
                    Send
                  </MaterialButton>
                </Box>
                <Box
                  display="flex"
                  mt={2.2}
                  className={classes.templateList}
                  flexDirection="column"
                  justifyContent="space-between"
                  width="100%"
                >
                  <CustomerCommunication
                    crmId={record?.crm?.id}
                    communicationValue={record?.crm?.communicatedToCustomer}
                  />
                  {record && record.crm && record.crm.whatsAppMessage && (
                    <div className={classes.templateContainer}>
                      <div className={classes.templateHeading}>
                        WhatsApp Template
                      </div>
                      <div
                        id="whatsapp-template"
                        className={classes.templateBody}
                        dangerouslySetInnerHTML={{
                          __html: record.crm.whatsAppMessage,
                        }}
                      />
                      <MaterialButton
                        variant="contained"
                        color="primary"
                        className={classes.clipboardButton}
                        endIcon={<ContentCopyIcon />}
                        onClick={handleCopyToClipboard.bind(
                          this,
                          "whatsapp-template"
                        )}
                      >
                        Copy
                      </MaterialButton>
                    </div>
                  )}
                  {record && record.crm && record.crm.offerEmailTemplate && (
                    <div className={classes.templateContainer}>
                      <div className={classes.templateHeading}>
                        Offer Email Template
                      </div>
                      <div
                        id="email-template"
                        className={classes.templateBody}
                        dangerouslySetInnerHTML={{
                          __html: record.crm.offerEmailTemplate,
                        }}
                      />
                      <MaterialButton
                        variant="contained"
                        color="primary"
                        className={classes.clipboardButton}
                        endIcon={<ContentCopyIcon />}
                        onClick={handleCopyToClipboard.bind(
                          this,
                          "email-template"
                        )}
                      >
                        Copy
                      </MaterialButton>
                    </div>
                  )}
                  {record && record.crm && record.crm.quoteCheckTemplate && (
                    <div className={classes.templateContainer}>
                      <div className={classes.templateHeading}>
                        Quote Template
                      </div>
                      <div
                        id="quote-template"
                        className={classes.templateBody}
                        dangerouslySetInnerHTML={{
                          __html: record.crm.quoteCheckTemplate,
                        }}
                      />
                      <MaterialButton
                        variant="contained"
                        color="primary"
                        className={classes.clipboardButton}
                        endIcon={<ContentCopyIcon />}
                        onClick={handleCopyToClipboard.bind(
                          this,
                          "quote-template"
                        )}
                      >
                        Copy
                      </MaterialButton>
                    </div>
                  )}
                  <textarea
                    id="hidden-area"
                    style={{ display: "none" }}
                  ></textarea>
                </Box>
              </Box>
            </Tab>
            <Tab label="Marketing Details">
              <BoxedShowLayout>
                <h3>Marketing Details</h3>
                <RaBox
                  flexWrap="wrap"
                  flexGrow={4}
                  display="flex"
                  justifyContent="space-between"
                >
                  <TextField
                    className={classes.rowField}
                    label="Campaign"
                    source="marketingAttribute.campaign"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Ad Group"
                    source="marketingAttribute.adGroup"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Click Location"
                    source="marketingAttribute.clickLocation"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Source"
                    source="marketingAttribute.source"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Keyword"
                    source="marketingAttribute.keyword"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Ad Id"
                    source="marketingAttribute.adId"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Device"
                    source="marketingAttribute.device"
                  />
                  <TextField
                    className={classes.rowField}
                    label="Ad Position"
                    source="marketingAttribute.adPosition"
                  />
                </RaBox>
                <RaBox
                  flexWrap="wrap"
                  marginTop={2}
                  flexGrow={4}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <TextField
                    className={classes.rowField}
                    label="Click Location"
                    source="marketingAttribute.clickLocation"
                  />
                </RaBox>
              </BoxedShowLayout>
            </Tab>
          </TabbedShowLayout>
        </SimpleShowLayout>
      </Show>

      <Dialog
        open={!!offerId}
        onClose={() => setOfferId(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to cancel this offer?"}
        </DialogTitle>
        <DialogActions>
          <MaterialButton onClick={() => setOfferId(null)} variant="text">
            {"No"}
          </MaterialButton>
          <MaterialButton
            onClick={() => cancelOfferHandler()}
            variant="text"
            autoFocus
          >
            {"Yes"}
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BookingShow;

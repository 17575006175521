import React from 'react'
import { 
  BooleanInput, DateInput, Edit, Toolbar, SaveButton,
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator
} from 'react-admin'

import { BookingTypeChoices } from 'Constants/Bookings';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const BookingTitle = ({ record }) => {
  return (
    <span>
      Booking: {record ? `${record.bookingNumber}` : ''}
    </span>
  )
}
const PostEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
)

export const BookingEdit = props => {
  const [record, setRecord] = React.useState({});
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Bookings',
      url: '/bookings',
    },
    {
      name: `${record.bookingNumber}`,
      url: `/bookings/${record.id}/show`,
    },
    {
      name: 'Edit',
      url: `/bookings/${record.id}`,
    }
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<BookingTitle />} {...props}>
        <SimpleForm toolbar={<PostEditToolbar />}>
          <RecordFetcher setRecord={setRecord} />
          {/* <TextInput source="id" /> */}
          <TextInput source="bookingNumber" />
          <SelectInput source="bookingType" choices={BookingTypeChoices}/>
          <NumberInput label="Total Amount" source="totalAmountCents" format={v => v/100} parse={v => v*100} />
          <TextInput source="currency" />
          <TextInput source="paymentMethod" />
          <TextInput source="additionalRequest" />
          <TextInput source="cancelReason" />
          <ReferenceInput source="organizationId" reference="organizations">
            <SelectInput optionText="companyName" />
          </ReferenceInput>
          <ReferenceInput source="userId" reference="users">
            <SelectInput optionText="email" />
          </ReferenceInput>

          {/* <TextInput source="invoice.id" /> */}

        </SimpleForm>
      </Edit>
    </>
  )
}

export default BookingEdit;
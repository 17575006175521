import React from 'react'
import Create from './Create';
import List from './List';
import Show from './Show';
import Edit from './Edit';

export default {
  create: Create,
  list: List,
  show: Show,
  edit: Edit,
}
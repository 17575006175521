import { objToNameIdArray } from 'Util/Constants';

export const TransferTypes = {
  AIRPORT: 'airport_transfer',
  CITY: 'city_transfer',
  HOURLY: 'hourly_transfer'
};

export const TransferTypeChoices = objToNameIdArray(TransferTypes);

export default {
  TransferTypes,
  TransferTypeChoices,
}
import React from 'react'
import { 
  BooleanInput, Edit, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator, FormDataConsumer, ReferenceField,
} from 'react-admin';
import DateFnsUtils from '@date-io/date-fns';

import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
// import { VehicleStates } from 'Constants/VehicleStates'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const VehicleTitle = ({ record }) => {
  return (
    <span>
      Vehicle: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const VehicleEdit = props => {
  
  const [record, setRecord] = React.useState({});
  const [vendorRecord, setVendorRecord] = React.useState({});

  const { vendorId } = props.record || {};
  const redirect = record.vendorId ? `/vendors/${record.vendorId}/show/vehicles` : false;
  // console.log("Vehicle Edit Props", props)
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
    {
      name: `${vendorRecord.id}`,
      url: `/vendors/${vendorRecord.id}/show`,
    },
    {
      name: 'Vehicles',
      url: `/vendors/${vendorRecord.id}/show/vehicles`,
    },
    {
      name: `${record.id}`,
      url: `/vehicles/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/vehicles/${record.id}`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<VehicleTitle />} {...props}>
        <SimpleForm
          redirect={redirect}
        >
          <RecordFetcher setRecord={setRecord} />
          <ReferenceField addLabel={false} link="show" source="vendorId" reference="vendors">
            <RecordFetcher setRecord={setVendorRecord} />  
          </ReferenceField>
          {/* <TextInput source="id" /> */}
          {/* <TextInput source="brand" /> */}
          {/* <TextInput source="vehicleModel" /> */}
          <ReferenceInput source="vehicleModelId" reference="vehicleModels"><SelectInput optionText="model" /></ReferenceInput>
          <NumberInput source="yearOfManufacture" />
          <NumberInput source="maxPax" />
          <NumberInput source="maxBaggage" />
          <TextInput source="color" />
          <TextInput source="licensePlate" />
          <TextInput source="category" />
          <TextInput source="type" />
          {/* <TextInput source="uploadPics" /> */}
          <ReferenceInput disabled source="vendorId" reference="vendors"><SelectInput optionText="id" /></ReferenceInput>
          <ReferenceInput source="vehicleTypeId" reference="vehicleTypes"><SelectInput optionText="name" /></ReferenceInput>
          {/* <FormDataConsumer>
            {({ formData, ...rest }) => formData.currentState === VehicleStates.PENDING &&
                  <NumberInput label="Total Amount" source="totalAmountCents" format={v => v/100} parse={v => v*100} {...rest} />
            }
          </FormDataConsumer> */}
        </SimpleForm>
      </Edit>
    </>
  )
}

export default VehicleEdit;
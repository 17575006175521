import React from 'react'
import { 
  BooleanInput, Edit, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator, FormDataConsumer, ReferenceField,
} from 'react-admin';
import DateFnsUtils from '@date-io/date-fns';

import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
// import { VendorLocationStates } from 'Constants/VendorLocationStates'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const VendorLocationTitle = ({ record }) => {
  return (
    <span>
      VendorLocation: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const VendorLocationEdit = props => {
  
  const [record, setRecord] = React.useState({});
  const [vendorRecord, setVendorRecord] = React.useState({});

  const { vendorId } = props.record || {};
  const redirect = record.vendorId ? `/vendors/${record.vendorId}/show/vendorLocations` : false;
  // console.log("VendorLocation Edit Props", props)
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
    {
      name: `${vendorRecord.id}`,
      url: `/vendors/${vendorRecord.id}/show`,
    },
    {
      name: 'VendorLocations',
      url: `/vendors/${vendorRecord.id}/show/vendorLocations`,
    },
    {
      name: `${record.id}`,
      url: `/vendorLocations/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/vendorLocations/${record.id}`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<VendorLocationTitle />} {...props}>
        <SimpleForm
          redirect={redirect}
        >
          <RecordFetcher setRecord={setRecord} />
          {/* <ReferenceField addLabel={false} link="show" source="vendorId" reference="vendors">
            <RecordFetcher setRecord={setVendorRecord} />  
          </ReferenceField> */}
          <TextInput source="id" />
          <TextInput source="city" />
          <TextInput source="country" />
          <DateInput source="createdAt" />
          <DateInput source="updatedAt" />
          <ReferenceInput source="vendorId" reference="vendors"><SelectInput optionText="id" /></ReferenceInput>
          {/* <FormDataConsumer>
            {({ formData, ...rest }) => formData.currentState === VendorLocationStates.PENDING &&
                  <NumberInput label="Total Amount" source="totalAmountCents" format={v => v/100} parse={v => v*100} {...rest} />
            }
          </FormDataConsumer> */}
        </SimpleForm>
      </Edit>
    </>
  )
}

export default VendorLocationEdit;
import { objToNameIdArray } from 'Util/Constants';

export const CrmStatuses = {
  DUPLICATE: '0',
  PROPOSALRECEIVED: '1',
  PROSPECTS: '2',
  BOOKINGCONFIRMED: '3',
  TRIAGECOMPLETED: '4',
  TRIPPLANNING: '5',
  CLOSEDLOST: '6',
  BADREQUEST: '7',
  QUOTEREQUESTED: '8',
  LEAD: '9',
  B2BCUSTOMER: '10',
  FOLLOWUP: '12',
  RESPONSENEEDED: '13',
  WAITINGPAYMENTCONFIRMATION: '14',
  WAITINGBETTEROFFERS: '15'
};

export const CrmStatusTypes = {
  'Duplicate': 0, 
  'Proposal Recieved': 1,
  'Prospects': 2,
  'Booking Confirmed': 3,
  'Triage Completed': 4,
  'Trip Planning': 5,
  'Closed Lost': 6,
  'Bad Request': 7,
  'Quote Requested': 8,
  'Lead': 9,
  'B2B Customer': 10,
  'Follow Up': 12,
  'Response Needed': 13,
  'Waiting Payment Confirmation': 14,
  'Waiting Better Offers': 15
};

export const TicketStatuses = {
  All: 'all',
  OPEN: 'open',
  Closed: 'closed'
};

export const CrmStatusChoices = objToNameIdArray(CrmStatuses)
export const CrmTypeChoices = objToNameIdArray(CrmStatusTypes);
export const TicketStatusChoices = objToNameIdArray(TicketStatuses)

export default {
  CrmStatuses,
  CrmStatusChoices,
  CrmStatusTypes,
  CrmTypeChoices,
  TicketStatuses,
  TicketStatusChoices
}
import React from 'react'
import { ArrayInput, BooleanInput, Edit, DateInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin'

export const DealOfferEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="expiryDate" />
      <BooleanInput source="approved" />
      <TextInput source="currency" />
      <TextInput source="vatAmountCents" />
      <TextInput source="totalAmountCentsPerPax" />
      <NumberInput source="actualAmountCents" />
      <NumberInput source="targetAmountCents" />
      <NumberInput source="offeredAmountCents" />
      <DateInput disabled source="createdAt" />
      <DateInput disabled source="updatedAt" />
      <ReferenceInput source="dealId" reference="deals">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
export default DealOfferEdit;
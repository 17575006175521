import React from 'react'
import { 
  BooleanField, NumberField, SelectField,
  DateField, SingleFieldList, ChipField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout, Button, 
  // TopToolbar, 
  EditButton, ReferenceManyField, ShowButton,
  useGetOne, EmailField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
// import { VehicleStateStatusChoices } from 'Constants/VehicleStates';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import ChipSelectField from 'Components/Common/Fields/ChipSelectField';
import LongDateField from 'Components/Common/Fields/LongDateField';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';
import SimpleArrayField from 'Components/Common/Fields/SimpleArrayField';
import DocumentOrImageFileField from 'Components/Common/Fields/DocumentOrImageFileField';
import ImagesArrayField from 'Components/Common/Fields/ImagesArrayField';

const VehicleTitle = ({ record }) => {
  return (
    <span>
      Vehicle ID: {record ? `${record.vehicleNumber}` : ''}
    </span>
  )
}

const CreateVehicleStateButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/vehicleStates/create?vehicleId=${record.id}`}
      // to={`/vehicleStates/create?vehicleId=${record.id}&vehicleNumber=${record.vehicleNumber}&vendorId=${record.vendorId}`}
      label="Add New Vehicle State"
      title="Add New Vehicle State"
    >
      <AddIcon />
    </Button>
  );
};


const Aside = () => null;
const VehicleShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        {/* <CreateVehicleStateButton record={data} /> */}
    </TopToolbar>
);

export const VehicleShow = props => {

  const [record, setRecord] = React.useState({});
  const [vendorRecord, setVendorRecord] = React.useState({});

  // const vehicleData = useGetOne('vehicles', props.id);
  // const vendorData = useGetOne('vendors', record.id);

  // React.useEffect(() => {
  //   const { data } = vehicleData || {};
  //   const { vendor } = data || {};

  //   console.log("React Use Effect", data, vendor);
    
  //   data && setRecord(data);
  //   vendor && setVendorRecord(vendor);

  // }, [vehicleData.data])

  // React.useEffect(() => {
  //   console.log("Vendor Data Change")
  //   setVendorRecord(vendorData.data || {})
  // }, [vendorData.data])

  // React.useEffect(() => {

  //   const breadcrumbLinks = 

  // }, [vehicleData.data])

  // const { vendor = {} } = record || {};


  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
    {
      name: `${vendorRecord.id}`,
      url: `/vendors/${vendorRecord.id}/show`,
    },
    {
      name: 'Vehicles',
      url: `/vendors/${vendorRecord.id}/show/vehicles`,
    },
    {
      name: `${record.id}`,
      url: `/vehicles/${record.id}/show`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show title={<VehicleTitle/>} aside={<Aside />} actions={<VehicleShowActions/>} {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceField link="show" source="vendorId" reference="vendors">
              <RecordFetcher setRecord={setVendorRecord}>
                <TextField source="id" />
              </RecordFetcher>
            </ReferenceField>
            <TextField source="id" />
            <TextField source="brand" />
            <TextField source="vehicleModel" />
            <DateField source="yearOfManufacture" />
            <NumberField source="maxPax" />
            <NumberField source="maxBaggage" />
            <TextField source="color" />
            <TextField source="licensePlate" />
            <TextField source="category" />
            <TextField source="type" />
            {/* <SimpleArrayField source="uploadPics" max={4} FieldComponent={DocumentOrImageFileField} fieldComponentProps={{ height: 50, width: 50 }}/> */}
            <ImagesArrayField source="uploadPics" max={4} />

            {/* <DateField source="createdAt" /> */}
            {/* <DateField source="updatedAt" /> */}
            {/* <ReferenceField source="vendorId" reference="vendors"><TextField source="id" /></ReferenceField> */}
            <ReferenceField source="vehicleTypeId" reference="vehicleTypes"><TextField source="name" /></ReferenceField>
          </Tab>

          {/* <Tab label="Vehicle States" path="vehicleStates">
            <RecordFetcher setRecord={setRecord} />
          </Tab> */}

        </TabbedShowLayout>
      </Show>
    </>
  )
}

export default VehicleShow;
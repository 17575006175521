import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Title, useDataProvider } from 'react-admin';

export default (props) => {
    // console.log(props);
    return (
      <Card>
        <Title title="Rydeu Admin Dashboard" />
        <CardHeader title="Welcome to Rydeu Admin Dashboard" />
        <CardContent></CardContent>
      </Card>
    )
};
import React from 'react'
import { 
  BooleanInput, Edit, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator, FormDataConsumer, ReferenceField,
} from 'react-admin';
import DateFnsUtils from '@date-io/date-fns';

import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
// import { DriverStates } from 'Constants/DriverStates'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const DriverTitle = ({ record }) => {
  return (
    <span>
      Driver: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const DriverEdit = props => {
  
  const [record, setRecord] = React.useState({});
  const [vendorRecord, setVendorRecord] = React.useState({});

  const { vendorId } = props.record || {};
  const redirect = record.vendorId ? `/vendors/${record.vendorId}/show/drivers` : false;
  // console.log("Driver Edit Props", props)
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
    {
      name: `${vendorRecord.id}`,
      url: `/vendors/${vendorRecord.id}/show`,
    },
    {
      name: 'Drivers',
      url: `/vendors/${vendorRecord.id}/show/drivers`,
    },
    {
      name: `${record.id}`,
      url: `/drivers/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/drivers/${record.id}`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<DriverTitle />} {...props}>
        <SimpleForm
          redirect={redirect}
        >
          <RecordFetcher setRecord={setRecord} />
          {/* <ReferenceField addLabel={false} link="show" source="vendorId" reference="vendors">
            <RecordFetcher setRecord={setVendorRecord} />  
          </ReferenceField> */}
          <TextInput disabled source="id" />
          <TextInput source="firstName" />
          <TextInput source="lastName" />
          <TextInput source="phone" />
          <TextInput source="email" />
          <NumberInput source="drivingLicense" />
          {/* <TextInput source="languageProficiency" /> */}
          {/* <TextInput source="drivingLicenseDocuments" /> */}
          <BooleanInput source="documentVerified" />
          {/* <DateInput source="createdAt" /> */}
          {/* <DateInput source="updatedAt" /> */}
          <ReferenceInput disabled source="vendorId" reference="vendors"><SelectInput optionText="id" /></ReferenceInput>
          {/* <FormDataConsumer>
            {({ formData, ...rest }) => formData.currentState === DriverStates.PENDING &&
                  <NumberInput label="Total Amount" source="totalAmountCents" format={v => v/100} parse={v => v*100} {...rest} />
            }
          </FormDataConsumer> */}
        </SimpleForm>
      </Edit>
    </>
  )
}

export default DriverEdit;
import React from "react";
import { 
  makeStyles,
  FormControl,
  InputLabel,
  Select
} from "@material-ui/core";
import {
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  SelectInput,
  Datagrid,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  TextField,
  ExportButton,
  useDataProvider,
  useNotify,
  useRefresh
} from "react-admin";
import moment from "moment-timezone";
import { CrmTypeChoices, TicketStatusChoices } from "Constants/Crm";
import Button from "Components/Common/RaOverrides/Button";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import ResetFiltersActionButton from "Components/Common/ResetFiltersActionButton";
import List from "Components/Common/RaOverrides/List";
import { useState } from "react";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CustomPagination from "Components/Common/CustomPagination";

const CrmFilter = (props) => { 
  return (
    <div>
      <Filter {...props}>
        <ReferenceInput
          label="Booking Number"
          source="bookingId"
          reference="tickets"
          alwaysOn
          filter={{ currentState : props?.filterValues?.currentState, }}
        >
          <AutocompleteInput optionText="bookingNumber" />
        </ReferenceInput>
        <SelectInput
          label="Ticket Status"
          source="ticketStatus"
          choices={TicketStatusChoices}
          alwaysOn
          initialValue={"open"}
        />
      </Filter>
    </div>
  );
};

const CrmActions = (props) => {
  const { className, ...rest } = props || {};
  return (
    <div style={{ display: "flex", alignSelf: "center" }}>
      <ResetFiltersActionButton
        {...rest}
        defaultObj={{
          bookingId: null,
          organizationId: null,
          bookingState: null,
          paymentState: null,
        }}
      />
      <Button customComponent={ExportButton} label="Export" {...rest} />
    </div>
  );
};

export const Tickets = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Tickets",
      url: "/tickets",
    },
  ];

  const CrmSelect = ({ record }) => {
    
    const [ crmState, setCrmState ] = useState(record?.crm?.currentState || null)

    const handleCrmChange = (event) => {
      setCrmState(event.target.value);
      if( record?.crm?.id )
      dataProvider
        .update(`crm`, { id: record.crm.id, data: { currentState: event.target.value } })
        .then((res) => {            
            notify("Crm updated successfully! ");
            refresh();
        })
        .catch((error) => {
            console.log(error);
            notify("Something went wrong!");
        });
    }

    return (
      <FormControl label="Crm State" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="select-state-id">Crm State</InputLabel>
          <Select
              native
              value={crmState}
              onChange={handleCrmChange}
              onClick={ e => e.stopPropagation()}
              label="Crm State"
              inputProps={{
                  name: 'crmState',
                  id: 'select-crm-state-id',
              }}
          >
          { CrmTypeChoices.map( ( choice, index ) => (
              <option key={index} value={choice.id}>
              {`${choice.name}`}
              </option>
          ))}
          </Select>
      </FormControl>
    )
  }

  const handleRowClick = ( id ) => {
    props.history.push(`bookings/${id}/show/crm`)
  }

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <List
        {...props}
        title="Tickets List"
        filters={<CrmFilter />}
        actions={<CrmActions />}
        pagination={<CustomPagination />}
      >
        <Datagrid rowClick={handleRowClick}>
          <FunctionField
            label="Booking Number"
            render={(record) => {
              const d = new Date(record.createdAt);
              return (
                <>
                  <div>{record.bookingNumber}</div>
                  <div>{`${
                    d.getUTCDate() < 10 ? "0" + d.getUTCDate() : d.getUTCDate()
                    }/${
                      d.getUTCMonth() + 1 < 10
                        ? "0" + (d.getUTCMonth() + 1)
                        : d.getUTCMonth() + 1
                    }/${d.getUTCFullYear()},
                    ${d.getUTCHours() < 10 ? "0" + d.getUTCHours() : d.getUTCHours()}:${d.getUTCMinutes() < 10 ? "0" + d.getUTCMinutes() : d.getUTCMinutes()}
                  `}
                  </div>
                </>
              )
            }}
          />
          <FunctionField
            label="Travel Date/Time"
            render={(record) => (
              <>
                <div className={classes.startDateTime}>
                  <WatchLaterIcon style={{ fontSize: 18, marginRight: "2px" }} />
                  {moment
                    .tz(
                      record.transfers[0].startDateTime,
                      record.transfers[0].timezone || moment.tz.guess()
                    )
                    .format("DD/MM/YYYY,HH:mm")}
                </div>
                {record.transfers[0].returnDateTime && (
                  <div className={classes.returnDateTime}>
                    <WatchLaterIcon style={{ fontSize: 18, marginRight: "2px" }}/>
                      {moment
                      .tz(
                        record.transfers[0].returnDateTime,
                        record.transfers[0].timezone || moment.tz.guess()
                      )
                      .format("DD/MM/YYYY,HH:mm")}
                  </div>
                  )}
              </>
            )}
          />
          <FunctionField
            label="Travel Pickup/Drop"
            render={(record) => (
              <>
                <div className={classes.pickupLocation}>
                  <LocationOnIcon style={{ fontSize: 18 }} />
                  {record.transfers[0]?.pickupLocation?.formattedAddress || ""} 
                </div>
                {record.transfers[0].dropLocation && (
                  <div className={classes.dropLocation}>
                    <LocationOnIcon style={{ fontSize: 18 }} />
                    {(record.transfers[0].dropLocation.formattedAddress)}
                  </div>
                  )}
              </>
            )}
          />
          <FunctionField
            label="Passengers / BaggageCount"
            render={(record) =>
              record && record.transfers && record.transfers[0]
                ? `${record.transfers[0].totalAdultSeats},${record.transfers[0].totalChildSeats} / ${record.transfers[0].baggageCount}`
                : null
            }
          />
          <ArrayField label="vehicleTypes" source="transfers[0].vehicleTypes">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField label="Booking Type" source="bookingType" />
          <TextField label="Offered By" source="offeredBy" />
          <TextField label="Booking By" source="user.email" />
          <FunctionField
            label="Ticket Status"
            render={(record) =>
              record && record.crm && record.crm.currentState === '11'
                ? 'Open'
                : 'Closed'
            }
          />
        </Datagrid>
      </List>
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    formControl: {
      width: '12rem'
    },
    returnDateTime: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: "5%"
    },
    startDateTime: {
      display: "flex",
      alignItems: "flex-start"
    },
    pickupLocation: {
      display: "flex",
      alignItems: "flex-start"
    },
    dropLocation: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: "5%"
    }
  };
});

export default Tickets;

import React from "react";
import {
  BooleanInput,
  DateInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField
} from "react-admin";
import { parse } from 'query-string';
import { PaymentModeChoices, PaymentStateChoices } from 'Constants/Payments';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const today = new Date();

const PaymentCreate = props => {
  // console.log(props)
  const { bookingId, bookingNumber, organizationId } = parse(props.location.search);

  const redirect = bookingId ? `/bookings/${bookingId}/show/payments` : false;

  // console.log(bookingId, bookingNumber, redirect);

  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Bookings',
      url: '/bookings',
    },
    {
      name: `${bookingNumber}`,
      url: `/bookings/${bookingId}/show`,
    },
    {
      name: 'Payments',
      url: `/bookings/${bookingId}/show/payments`,
    },
    {
      name: `Create`,
      url: `${props.location.pathname}${props.location.search}`,
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Create {...props} title={`Add New Payment for Booking ID: ${bookingNumber}`}>
        <SimpleForm
          defaultValue={{ bookingId, organizationId }}
          redirect={redirect}
        >
          {/* <ReferenceInput source="chargeId" reference="charges"><SelectInput optionText="id" /></ReferenceInput> */}
          <NumberInput label="Amount" source="amountCents" format={v => v/100} parse={v => v*100} />
          {/* <TextInput source="currency" /> */}
          {/* <TextInput source="receiptNumber" /> */}
          {/* <TextInput source="receiptEmail" /> */}
          {/* <TextInput source="receiptUrl" /> */}
          <TextInput source="description" />
          <SelectInput source="paymentMode" choices={PaymentModeChoices}/>
          {/* <SelectInput source="currentState" choices={PaymentStateChoices}/> */}

          <ReferenceInput disabled source="bookingId" reference="bookings"><SelectInput optionText="bookingNumber" /></ReferenceInput>
          <ReferenceInput disabled source="organizationId" reference="organizations"><SelectInput optionText="companyName" /></ReferenceInput>
          {/* <ReferenceInput source="userId" reference="users"><SelectInput optionText="email" /></ReferenceInput> */}

      </SimpleForm>
      </Create>
    </>  
  );
}

export default PaymentCreate;

import { objToNameIdArray } from 'Util/Constants';

export const CommunicationStatusTypes = {
    WHATSAPP: 'whatsapp',
    EMAIL: 'email',
    CALL: 'call',
    VOICEMAIL: 'voicemail',
    NOWHATSAPP: 'nowhatsapp',
    WRONGNUMBER: 'wrongnumber'  
};

export const CommunicationStatusTypeChoices = objToNameIdArray(CommunicationStatusTypes);

export default {
   CommunicationStatusTypes,
   CommunicationStatusTypeChoices
}
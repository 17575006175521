import React, { Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../Components/Common/Modal/Modal";
import axios from "axios";

const CancelBookingRequestFormSchema = Yup.object().shape({
  cancelReason: Yup.string(),
});

const BookingCancelModal = ({
  bookingRecord,
  showModal,
  closeModal,
  refresh,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: CancelBookingRequestFormSchema,
    initialValues: {
      cancelReason: "",
    },
    onSubmit: (data) => {
      if (values.cancelReason === "") {
        notify("Cancellation reason cannot be empty");
        return;
      }
      cancelBookingRequest(data);
    },
  });

  const cancelBookingRequest = (data) => {
    const payload = { cancelReason: data.cancelReason };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
      url: `${process.env.REACT_APP_API_HOSTNAME}/admin/bookings/${bookingRecord.bookingNumber}`,
    };
    axios(requestOptions)
      .then((res) => {
        closeModal();
        refresh();
        notify("Booking cancelled successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
          <Typography variant="h5">Cancel Booking Request</Typography>
          <form className={classes.formContainer} onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between">
              <TextField
                error={values.cancelReason.length === 0}
                type="text"
                label="Cancellation Reason"
                variant="outlined"
                onChange={handleChange("cancelReason")}
                onBlur={handleBlur("cancelReason")}
                value={values.cancelReason}
                className={classes.inputContainer}
                helperText={
                  values.cancelReason.length === 0
                    ? "Reason cannot be empty"
                    : ""
                }
              />
            </Box>
            <Box display="flex" mt={2} justifyContent="space-between">
              <Button onClick={closeModal} variant="text" color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Fragment>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width: "100%",
    },
    inputContainer: {
      width: "95%",
      margin: spacing(2, 0),
      fontSize: "48",
    },
  };
});

export default BookingCancelModal;

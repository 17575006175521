import React from 'react'
import { 
  Datagrid, ReferenceField, 
  ArrayField, BooleanField, NumberField, 
  Show, SimpleShowLayout, TextField, 
} from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import { Button } from "react-admin";

const DealStateTitle = ({ record }) => {
  return <span>Deal State: {record ? `${record.name}` : ''}</span>
}


export const DealStateShow = props => (
  <Show title={<DealStateTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
        <NumberField source="state" />
        <TextField source="message" />
        <BooleanField source="silent" />
        <LongDateField source="createdAt" />
        <LongDateField source="updatedAt" />
        <ReferenceField link="show" source="dealId" reference="deals">
          <TextField source="name" />
        </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export default DealStateShow;
import React, { Fragment, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "./Common/Modal/Modal";
import { TransferTypes } from "Constants/Transfers";
import { CurrenciesChoices } from "Constants/Payments";

const GenerateVendorAssignmentUrlSchema = Yup.object().shape({
  userEmail: Yup.string().email().required(),
});

const GenerateVendorAssignmentUrl = ({
  bookingRecord,
  showModal,
  closeModal,
  refresh,
}) => {

  const [isDisable, toggleSubmit ] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const transferType =
    bookingRecord &&
    bookingRecord.transfers &&
    bookingRecord.transfers.length > 0 &&
    bookingRecord.transfers[0]?.transferType;

  const returnDateTime =
    bookingRecord &&
    bookingRecord.transfers &&
    bookingRecord.transfers[0]?.returnDateTime;

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    validationSchema: GenerateVendorAssignmentUrlSchema,
    initialValues: {
      userEmail: "",
      currency: CurrenciesChoices[0].id,
      totalAmount: 0,
      amountToCollect: 0,
      supplierAmount: 0,
      hourlyRate: 0,
      comments: "",
      isNewOffer: false,
      sendEmail: false,
    },
    onSubmit: (data) => {
      if (data.isNewOffer) {
        if (!data.totalAmount) {
          notify("Total Amount is required for new offer !");
          return;
        }

        if (
          bookingRecord.transfers[0]?.reservationAmountCents &&
          !data.amountToCollect
        ) {
          notify("Collection Amount is required for new offer !");
          return;
        }

        if (transferType === TransferTypes.HOURLY && data.hourlyRate === "") {
          notify("Hourly rate is required for hourly type transfer !");
          return;
        }
      }

      const commissionAmount =
        Number(data.totalAmount) - Number(data.supplierAmount);

      const reservationAmount =
        Number(data.totalAmount) - Number(data.amountToCollect);

      const newCommissionPercentage =
        (commissionAmount / Number(data.totalAmount)) * 100;

      const payload = {
        comment: data.comments ? data.comments.trim() : data.comments,
        commissionAmount,
        commissionPercentage: newCommissionPercentage.toFixed(2),
        oneWayPrice: data.totalAmount,
        supplierAmount: data.supplierAmount,
        totalAmount: data.totalAmount,
        reservationAmount,
        currency: data.currency,
        transferId: bookingRecord?.transfers[0]?.id,
        email: data.userEmail,
        isNewOffer: data.isNewOffer,
        sendEmail: data.sendEmail,
      };

      if (returnDateTime) {
        payload.oneWayPrice = data.totalAmount / 2;
        payload.returnPrice = data.totalAmount / 2;
      }

      if (transferType === TransferTypes.HOURLY) {
        payload.amountCentsPerHour = data.hourlyRate;
      }

      toggleSubmit(true);

      generateVendorAssignmentUrlHandler(payload);
    },
  });

  const generateVendorAssignmentUrlHandler = (data) => {
    dataProvider
      .create("generate-vendor-assignment-url", {
        data,
      })
      .then((res) => {
        toggleSubmit(false);
        resetForm();
        closeModal();
        window.location.reload();
        notify("Vendor assignment url generated successfully! ");
      })
      .catch((error) => {
        notify(`Error: ${error.message}`);
        toggleSubmit(false);
        console.log(error);
      });
  };

  const commissionPercentage =
    ((Number(values.totalAmount) - Number(values.supplierAmount)) /
      Number(values.totalAmount)) *
    100;

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
          <Typography variant="h5">Generate Vendor Assignment Url</Typography>
          <form
            className={classes.formContainer}
            onSubmit={handleSubmit}
            noValidate
          >
            <Box display="flex" justifyContent="space-between">
              <TextField
                error={touched.userEmail && errors.userEmail}
                type="email"
                label="Vendor Email"
                variant="outlined"
                onChange={handleChange("userEmail")}
                onBlur={handleBlur("userEmail")}
                value={values.userEmail}
                className={classes.inputContainer}
                helperText={
                  touched.userEmail && errors.userEmail && "Invalid Email"
                }
              />
            </Box>

            <Box display="flex" justifyContent="space-between" width="70%">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isNewOffer}
                    onChange={handleChange}
                    name="isNewOffer"
                    color="primary"
                  />
                }
                label="Create new offer"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.sendEmail}
                    onChange={handleChange}
                    name="sendEmail"
                    color="primary"
                  />
                }
                label="Send link to vendor"
              />
            </Box>

            {bookingRecord?.id && values.isNewOffer && (
              <>
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Currency
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.currency}
                    onChange={handleChange("currency")}
                    onBlur={handleBlur("currency")}
                    label="currency"
                    inputProps={{
                      name: "currency",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {CurrenciesChoices.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Box
                  display="flex"
                  marginTop="0.5rem"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <div className={classes.priceContainer}>
                    <TextField
                      name="totalAmount"
                      value={values.totalAmount}
                      onChange={handleChange}
                      label="Total Offer Price"
                      variant="outlined"
                      className={classes.totalInput}
                      style={{ width: "60%" }}
                    />
                    <TextField
                      name="supplierAmount"
                      value={values.supplierAmount}
                      onChange={handleChange}
                      label="Supplier Cost"
                      variant="outlined"
                      className={classes.totalInput}
                      style={{ width: "60%", marginTop: 15 }}
                    />
                    {transferType && transferType === TransferTypes.HOURLY && (
                      <TextField
                        name="hourlyRate"
                        value={values.hourlyRate}
                        onChange={handleChange}
                        label="Hourly Rate"
                        variant="outlined"
                        className={classes.totalInput}
                        style={{ width: "60%", marginTop: 15 }}
                      />
                    )}
                    {bookingRecord?.transfers[0]?.reservationAmountCents >
                      0 && (
                      <TextField
                        name="amountToCollect"
                        value={values.amountToCollect}
                        onChange={handleChange}
                        label="Amount To Collect"
                        variant="outlined"
                        className={classes.totalInput}
                        style={{ width: "60%", marginTop: 15 }}
                      />
                    )}
                  </div>

                  <div className={classes.fieldContainer}>
                    <div className={classes.infoContainer}>
                      <div className={classes.infoHead}>Customer Pays</div>
                      <div className={classes.infoTotalValue}>
                        {values.currencySymbol} {values.totalAmount}
                      </div>
                    </div>
                    <div className={classes.infoContainer}>
                      <div className={classes.infoHead}>Vendor Get</div>
                      <div className={classes.infoValue}>
                        {values.currencySymbol}
                        {values.supplierAmount}
                      </div>
                    </div>
                    <div className={classes.infoContainer}>
                      <div className={classes.infoHead}>
                        Rydeu Commission{" "}
                        {commissionPercentage > 0
                          ? commissionPercentage.toFixed(2)
                          : 0}
                        %
                      </div>
                      <div className={classes.infoValue}>
                        {values.currencySymbol}
                        {Number(values.totalAmount) -
                          Number(values.supplierAmount)}
                      </div>
                    </div>
                  </div>
                </Box>

                <Box
                  display="flex"
                  mt={2}
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <TextField
                    name="comments"
                    value={values.comments}
                    onChange={handleChange}
                    label="Comments"
                    variant="outlined"
                    className={classes.totalInput}
                    style={{ width: "100%" }}
                    multiline
                    rows={3}
                  />
                </Box>
              </>
            )}

            <Box display="flex" mt={2} justifyContent="space-between">
              <Button onClick={closeModal} variant="text" color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isDisable}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Fragment>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width: "100%",
    },
    inputContainer: {
      width: "95%",
      margin: spacing(2, 0, 0, 0),
      fontSize: "48",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    heading: {
      textAlign: "center",
      letterSpacing: "1.5px",
      fontWeight: "600",
    },
    radioGroup: {
      justifyContent: "space-around",
    },
    errorMessage: {
      marginTop: 10,
      fontSize: 14,
      color: "#8B0000",
    },
    radioContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: "1.5rem",
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "column",
      width: "45%",
      marginTop: "1.5rem",
      justifyContent: "center",
    },
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width: "100%",
    },
    inputContainer: {
      // width: "45%",
      width: "95%",
      margin: spacing(2, 0),
    },
    checkIcon: {
      color: "green",
      fontSize: 32,
    },
    closeIcon: {
      color: "#8b0000",
      fontSize: 32,
    },
    suggestionsContainer: {
      zIndex: 2100,
    },
    autoInput: {
      width: "47%",
    },
    subHeading: {
      letterSpacing: "1.5px",
      marginTop: "2rem",
      fontWeight: "500",
      marginBottom: "0.5rem",
      // color: '#949494'
    },
    fieldColumn: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginTop: "1.5rem",
    },
    offerContainer: {
      display: "flex",
      flexDirection: "column",
    },
    offerContainerActive: {
      display: "flex",
      flexDirection: "column",
      marginTop: "-1.4rem",
    },
    ancillaryContainerActive: {
      marginTop: "-1.2rem",
    },
    ancillaryContainer: {
      marginTop: "0.1rem",
    },
    infoContainer: {
      background: "#E8F2FC",
      padding: "1rem",
      display: "flex",
    },
    infoHead: {
      width: "60%",
      fontWeight: "600",
      fontSize: "14px",
      textAlign: "left",
      letterSpacing: "1.5px",
    },
    infoValue: {
      width: "40%",
      textAlign: "center",
      color: "#004A85",
      fontWeight: "600",
    },
    infoTotalValue: {
      width: "40%",
      textAlign: "center",
      color: "#33B30B",
      fontWeight: "600",
    },
    priceContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginTop: "1.5rem",
      justifyContent: "center",
      borderRight: "1px solid #d4d4d5",
    },
  };
});

export default GenerateVendorAssignmentUrl;

import React from 'react'
import { 
  BooleanInput, Edit, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator, FormDataConsumer, ReferenceField,
} from 'react-admin';
import DateFnsUtils from '@date-io/date-fns';

import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
// import { VehicleModelStates } from 'Constants/VehicleModelStates'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const VehicleModelTitle = ({ record }) => {
  return (
    <span>
      VehicleModel: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

export const VehicleModelEdit = props => {
  
  const [record, setRecord] = React.useState({});
  const [vehicleBrandRecord, setVehicleBrandRecord] = React.useState({});

  const { vehicleBrandId } = props.record || {};
  const redirect = record.vehicleBrandId ? `/vehicleBrands/${record.vehicleBrandId}/show/vehicleModels` : false;
  // console.log("VehicleModel Edit Props", props)
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'VehicleBrands',
      url: '/vehicleBrands',
    },
    {
      name: `${vehicleBrandRecord.id}`,
      url: `/vehicleBrands/${vehicleBrandRecord.id}/show`,
    },
    {
      name: 'VehicleModels',
      url: `/vehicleBrands/${vehicleBrandRecord.id}/show/vehicleModels`,
    },
    {
      name: `${record.id}`,
      url: `/vehicleModels/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/vehicleModels/${record.id}`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<VehicleModelTitle />} {...props}>
        <SimpleForm
          redirect={redirect}
        >
          <RecordFetcher setRecord={setRecord} />
          <ReferenceField addLabel={false} link="show" source="vehicleBrandId" reference="vehicleBrands">
            <RecordFetcher setRecord={setVehicleBrandRecord} />  
          </ReferenceField>
          <TextInput disabled source="id" />
          <TextInput source="model" />
          <TextInput source="brandName" />
          <TextInput source="modelType" />
          <BooleanInput source="verified" />
          {/* <DateInput source="createdAt" /> */}
          {/* <DateInput source="updatedAt" /> */}
          <ReferenceInput disabled source="vehicleBrandId" reference="vehicleBrands"><SelectInput optionText="brand" /></ReferenceInput>
          <ReferenceInput source="vehicleTypeId" reference="vehicleTypes"><SelectInput optionText="name" /></ReferenceInput>
          <ReferenceInput disabled source="vendorId" reference="vendors"><SelectInput optionText="user.email" /></ReferenceInput>          
        </SimpleForm>
      </Edit>
    </>
  )
}

export default VehicleModelEdit;
import React from 'react'
import { 
  BooleanField, NumberField, SelectField,
  DateField, SingleFieldList, ChipField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout, Button, EmailField,
  // TopToolbar, 
  EditButton, 
  ReferenceManyField, Filter, TextInput,
 } from 'react-admin'

import LongDateField from 'Components/Common/Fields/LongDateField';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import SimpleArrayField from 'Components/Common/Fields/SimpleArrayField';

import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';
import List from 'Components/Common/RaOverrides/List';

const VehicleTitle = ({ record }) => {
  return (
    <span>
      Vehicle: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

const VehicleFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Name" source="q" alwaysOn />
  </Filter>
)

export const VehicleList = props => (
  <List {...props} title="Vehicle List" pagination={false} filters={<VehicleFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField label="Brand (deprecated)" source="brand" />
      <TextField label="Model (deprecated)" source="vehicleModel" />
      <ReferenceField source="vehicleModelId" reference="vehicleModels"><TextField source="model" /></ReferenceField>
      <DateField source="yearOfManufacture" />
      <NumberField source="maxPax" />
      <NumberField source="maxBaggage" />
      <TextField source="color" />
      <TextField source="licensePlate" />
      <TextField source="category" />
      <TextField source="type" />
      <TextField source="uploadPics" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceField source="vendorId" reference="vendors"><TextField source="id" /></ReferenceField>
      <ReferenceField source="vehicleTypeId" reference="vehicleTypes"><TextField source="id" /></ReferenceField>
    </Datagrid>
  </List>
)
export default VehicleList;


import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import AirportModal from "./AirportModal";

const CreateEditOperatingCity = ({
  refreshList,
  airportDetails,
  cityDetails,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant={airportDetails ? "text" : "contained"}
        color="primary"
      >
        {airportDetails ? "Edit" : "Create Airport"}
      </Button>
      <AirportModal
        cityDetails={cityDetails}
        airportDetails={airportDetails}
        refreshList={refreshList}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

export default CreateEditOperatingCity;

import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import AnswerModal from "./AnswerModal";

const CreateEditAnswer = ({ refreshList, answerDetails, cityDetails }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant={answerDetails.id ? "text" : "contained"}
        color="primary"
      >
        {answerDetails.id ? "Edit Answer" : "Create Answer"}
      </Button>
      <AnswerModal
        cityDetails={cityDetails}
        answerDetails={answerDetails}
        refreshList={refreshList}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

export default CreateEditAnswer;

import React from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField
} from "react-admin";
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const AccountEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="companyName" />
      <TextInput source="businessEmail" />
      <TextInput source="mobileNo" />
      <TextInput source="officePhone" />
      <TextInput source="termsAndCondition" />
      <TextInput source="drivingLicence" />
      <TextInput source="age" />
      <TextInput source="occupation" />
      <TextInput source="businessType" />
      <ReferenceInput source="organizationId" reference="organizations">
        <SelectInput optionText="companyName" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default AccountEdit;

import React from 'react'
import { 
  BooleanInput, DateInput, Edit, 
  NumberInput, SimpleForm, TextInput, 
  ReferenceInput, SelectInput, ArrayInput, 
  SimpleFormIterator
} from 'react-admin'
import { VehicleTypeDescriptionItemChoices } from 'Constants/VehicleTypes';

const VehicleTypeTitle = ({ record }) => {
  return (
    <span>
      VehicleType: {record ? `${record.name}` : ''}
    </span>
  )
}

export const VehicleTypeEdit = props => (
  <Edit title={<VehicleTypeTitle />} {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="type" />
      <TextInput source="category" />
      <TextInput source="refModel" />
      <ArrayInput source="description">
        <SimpleFormIterator>
          <SelectInput source="key" choices={VehicleTypeDescriptionItemChoices} optionText="name" optionValue="value"/>
          <TextInput source="value" />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="commercial" />
      <NumberInput source="maxPax" />
      <NumberInput source="maxBaggage" />
      <TextInput source="imageUrl" />
      <DateInput disabled source="createdAt" />
      <DateInput disabled source="updatedAt" />
    </SimpleForm>
  </Edit>
)

export default VehicleTypeEdit;
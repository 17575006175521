import React, { useState, useEffect } from "react";
import { useLogout, useNotify } from "react-admin";
import httpClient from "../../Providers/Data/HttpClient";
import {
  Box,
  Button,
  Grid,
  TextareaAutosize,
  Tabs,
  Tab,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";

const transformTemplate = (str, params) => {
  const parseStr = eval("`" + str + "`");
  return parseStr;
};

const processData = (str) => {
  return str.replace(/(<\/?.*?>)/g, "$1\n");
};

const EmailTemplates = (props) => {
  const logout = useLogout();
  const notify = useNotify();
  const [apiResponse, setApiResponse] = useState();
  const [templateHTML, setTemplateHTML] = useState("");
  const [templateJSON, setTemplateJSON] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");

  const [template, parseTemplate] = useState("");
  const [subject, parseSubject] = useState("");

  const [tabValue, setTabValue] = useState("BODY");
  const [emailOperation, setEmailOperation] = useState("welcomeCustomer");

  const getEmailTemplates = async (name) => {
    try {
      const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/emailTemplate/${name}`;
      const response = await httpClient(url).then((res) =>
        JSON.parse(res.body)
      );
      const { status, data } = response;
      if (status == 200) {
        setApiResponse(data);
        setTemplateHTML(processData(data.body));
        setTemplateSubject(data.subject);
        setTemplateJSON(JSON.stringify(JSON.parse(data.sampleJSON), null, 4));
        parseTemplate(
          transformTemplate(data.body, JSON.parse(data.sampleJSON))
        );
        parseSubject(
          transformTemplate(data.subject, JSON.parse(data.sampleJSON))
        );
      }
    } catch (err) {
      if (err.message === "Invalid token") {
        logout();
      }
      notify("something went wrong");
    }
  };

  const saveEmailTemplate = async () => {
    try {
      const payload = {
        subject: templateSubject,
        body: templateHTML.replace(/\n/g, ""),
        sampleJSON: templateJSON.replace(/\n/g, ""),
        baseTemplate: apiResponse.baseTemplate,
      };
      const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/emailTemplate/${emailOperation}`;
      const response = await httpClient(url, {
        method: "PUT",
        body: JSON.stringify(payload),
      }).then((res) => JSON.parse(res.body));

      const { status, data } = response;
      if (status == 200) {
        notify("email template updated successfully");
        setApiResponse(data[1][0]);
        setTemplateHTML(processData(data[1][0].body));
        setTemplateSubject(data[1][0].subject);
        setTemplateJSON(
          JSON.stringify(JSON.parse(data[1][0].sampleJSON), null, 4)
        );
        parseTemplate(
          transformTemplate(data[1][0].body, JSON.parse(data[1][0].sampleJSON))
        );
        parseSubject(
          transformTemplate(
            data[1][0].subject,
            JSON.parse(data[1][0].sampleJSON)
          )
        );
      } else {
        notify("something went wrong");
      }
    } catch (err) {
      console.log(err);
      notify("something went wrong");
    }
  };

  const triggerEmail = async (e) => {
    const payload = {
      json: templateJSON.replace(/\n/g, ""),
    };
    const url = `${process.env.REACT_APP_API_HOSTNAME}/admin/triggerEmail/${emailOperation}`;
    const response = await httpClient(url, {
      method: "POST",
      body: JSON.stringify(payload),
    }).then((res) => JSON.parse(res.body));

    const { status } = response;
    if (status == 200) {
      notify("email triggered successfully");
    } else {
      notify("something went wrong while triggering email");
    }
  };

  const changeEmailTemplateOperation = (e) => {
    getEmailTemplates(e.target.value);
    setEmailOperation(e.target.value);
  };

  const resetTemplate = (e) => {
    setTemplateHTML(processData(apiResponse.body));
    setTemplateSubject(apiResponse.subject);
    setTemplateJSON(
      JSON.stringify(JSON.parse(apiResponse.sampleJSON), null, 4)
    );
    parseTemplate(
      transformTemplate(apiResponse.body, JSON.parse(apiResponse.sampleJSON))
    );
    parseSubject(
      transformTemplate(apiResponse.subject, JSON.parse(apiResponse.sampleJSON))
    );
  };

  const rerenderTemplate = () => {
    parseTemplate(transformTemplate(templateHTML, JSON.parse(templateJSON)));
    parseSubject(transformTemplate(templateSubject, JSON.parse(templateJSON)));
  };

  useEffect(() => {
    getEmailTemplates("welcomeCustomer");
  }, []);

  return (
    <Box>
      <br></br>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => {
              setTabValue(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="BODY" value="BODY" />
            <Tab label="SUBJECT" value="SUBJECT" />
            <Tab label="JSON" value="JSON" />
          </Tabs>

          {tabValue === "BODY" && (
            <Box>
              <TextareaAutosize
                placeholder="Email Template HTML"
                style={{
                  width: "100%",
                  height: "500px",
                  maxHeight: "500px",
                  overflow: "scroll",
                }}
                rows={30}
                maxRows={30}
                value={templateHTML}
                onChange={(e) => setTemplateHTML(e.target.value)}
              />
            </Box>
          )}
          {tabValue === "SUBJECT" && (
            <Box>
              <TextareaAutosize
                placeholder="Email Template Subject"
                style={{
                  width: "100%",
                  height: "500px",
                  maxHeight: "500px",
                  overflow: "scroll",
                }}
                rows={30}
                maxRows={30}
                value={templateSubject}
                onChange={(e) => setTemplateSubject(e.target.value)}
              />
            </Box>
          )}
          {tabValue === "JSON" && (
            <Box>
              <TextareaAutosize
                placeholder="Email Template JSON"
                style={{
                  width: "100%",
                  height: "500px",
                  maxHeight: "500px",
                  overflow: "scroll",
                }}
                rows={30}
                maxRows={30}
                value={templateJSON}
                onChange={(e) => setTemplateJSON(e.target.value)}
              />
            </Box>
          )}

          <br></br>

          <Box style={{ float: "right" }}>
            <Grid container spacing={1}>
              <Grid item>
                <InputLabel htmlFor={"emailOperation"}>TEMPLATES</InputLabel>
                <NativeSelect
                  inputProps={{
                    id: "emailOperation",
                    name: "emailOperation",
                  }}
                  value={emailOperation}
                  onChange={changeEmailTemplateOperation}
                >
                  <option value={"newRequest"}>New Request</option>
                  <option value={"vendorOffer"}>Vendor Offer</option>
                  <option value={"rydeuOffer"}>Rydeu Offer</option>
                  <option value={"bookingInformation"}>
                    Booking Information
                  </option>
                  <option value={"offerInformation"}>Offer Information</option>
                  <option value={"driverAssignment"}>Driver Assignment</option>
                  <option value={"welcomeCustomer"}>Welcome Customer</option>
                  <option value={"welcomeSupplier"}>Welcome Supplier</option>
                  <option value={"forgotPassword"}>Forgot Password</option>
                  <option value={"customerFeedback"}>Customer Feedback</option>
                  <option value={"temporaryPassword"}>Temporary Password</option>
                  <option value={"requestCallback"}>Request Callback</option>
                  <option value={"supplierPayout"}>Supplier Payout</option>
                </NativeSelect>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  style={{ margin: "3px" }}
                  color="primary"
                  onClick={resetTemplate}
                >
                  RESET
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  style={{ margin: "3px" }}
                  color="primary"
                  onClick={rerenderTemplate}
                >
                  TEST
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  style={{ margin: "3px" }}
                  color="primary"
                  onClick={triggerEmail}
                >
                  TRIGGER EMAIL
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  style={{ margin: "3px" }}
                  color="primary"
                  onClick={saveEmailTemplate}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <div
              style={{
                textAlign: "center",
                fontFamily: "Verdana",
                fontSize: "16px",
              }}
            >
              <span>
                <b>Subject</b>:{" "}
              </span>
              <span dangerouslySetInnerHTML={{ __html: subject }}></span>
            </div>
            <br></br>
            <div
              dangerouslySetInnerHTML={{ __html: template }}
              style={{
                maxWidth: "580px",
                height: "500px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                overflow: "scroll",
                backgroundColor: "white",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailTemplates;

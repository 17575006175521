import React from "react";
import _ from "lodash";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { useDataProvider, useNotify } from "react-admin";
import { Form, Formik } from "formik";
import Modal from "../../../../../Components/Common/Modal/Modal";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    inputContainer: {
      width: "100%",
      margin: spacing(2, 0),
    },
  };
});

const NearByPlaceModal = ({
  sightseeings,
  showModal,
  closeModal,
  refreshList,
  places,
  nearByPlaces,
  citySlug,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const addNearByPlace = (values) => {
    dataProvider
      .create(`operating-city/${citySlug}/nearby-places`, { data: values })
      .then(() => {
        closeModal();
        refreshList();
        notify("NearBy Places added successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">Add NearBy Places</Typography>
        <Formik
          initialValues={{
            sightseeingIds: [],
          }}
          onSubmit={(values) => {
            if (values.sightseeingIds.length > 0) {
              addNearByPlace({
                sightseeingIds: values.sightseeingIds.map((value) => value.id),
              });
            }
          }}
        >
          {({ setFieldValue, handleSubmit }) => (
            <Form className={classes.formContainer} onSubmit={handleSubmit}>
              <Box pt={1.25}>
                <Autocomplete
                  multiple
                  onChange={(_e, values) =>
                    setFieldValue("sightseeingIds", values)
                  }
                  id="checkboxes-tags-demo"
                  options={_.differenceBy(
                    sightseeings,
                    [...places, ...nearByPlaces],
                    "id"
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select NearBy Places"
                      placeholder="NearBy Places"
                    />
                  )}
                />
              </Box>
              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default NearByPlaceModal;

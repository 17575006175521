import React from 'react'
import { 
  BooleanInput, DateInput, Create, 
  NumberInput, SimpleForm, TextInput,
  ReferenceInput, SelectInput, 
  ReferenceField, TextField,
  useGetOne,
} from 'react-admin';
import { OrderStateStatusChoices } from 'Constants/OrderStates';
import { parse } from 'query-string';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';


const OrderStateCreate = props => {
    const [record, setRecord] = React.useState({});
    const [orderRecord, setOrderRecord] = React.useState({});
    const { orderId, orderNumber, bookingId } = parse(props.location.search);
  
    const redirect = orderId ? `/orders/${orderId}/show/orderStates` : false;

    // console.log(orderId, redirect);

    const { data:orderData } = useGetOne('orders', orderId);

    React.useEffect(() => {
      // console.log("Order Data", orderData);
      orderData && setOrderRecord(orderData);
    }, [orderData])

    const breadcrumbLinks = [
      {
        name: 'Home',
        url: '/',
      },
      // {
      //   name: 'Bookings',
      //   url: '/bookings',
      // },
      // {
      //   name: `${bookingNumber}`,
      //   url: `/bookings/${bookingId}/show`,
      // },
      {
        name: '...',
        url: `/bookings/${orderRecord.bookingId}/show`,
      },
      {
        name: 'Orders',
        url: `/bookings/${orderRecord.bookingId}/show/orders`,
      },
      {
        name: `${orderNumber}`,
        url: `/orders/${orderId}/show`,
      },
      {
        name: `Create`,
        url: `${props.location.pathname}${props.location.search}`,
      },
    ]
    return (
      <>
        <Breadcrumbs links={breadcrumbLinks} />
        <Create {...props} title={`Add New Order State for Order: #${orderNumber}`}>
          <SimpleForm
            defaultValue={{ orderId, bookingId }}
            redirect={redirect}
          >
          <SelectInput source="state" choices={OrderStateStatusChoices}/>
          <ReferenceInput disabled source="orderId" reference="orders">
            <SelectInput optionText="id" />
          </ReferenceInput>
        </SimpleForm>
        </Create>
      </>
    );
}


export default OrderStateCreate;
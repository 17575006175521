import { objToNameIdArray } from 'Util/Constants';

export const OrderStates = {
  PENDING: "1",
  INPROGRESS: "2",
  COMPLETED: "3",
  CANCELLED: "4",
  EXPIRED: "5"
}
export const OrderStateStatusChoices = objToNameIdArray(OrderStates);

export default {
  OrderStates,
  OrderStateStatusChoices,
}
import React from 'react'
import { 
  Datagrid, ReferenceField, ArrayField, 
  BooleanField, NumberField, Show, SimpleShowLayout, 
  TextField,
} from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import { Button } from "react-admin";
import CurrencyField from 'Components/Common/Fields/CurrencyField';

const DealOfferTitle = ({ record }) => {
  return <span>Deal Offer: {record ? `${record.name}` : ''}</span>
}


export const DealOfferShow = props => (
  <Show title={<DealOfferTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <LongDateField source="expiryDate" />
      <BooleanField source="approved" />
      {/* <TextField source="currency" /> */}
      <CurrencyField label="Vat Amount" source="vatAmountCents" />
      <CurrencyField label="Total Amount Per Pax" source="totalAmountCentsPerPax" />
      <CurrencyField label="Actual Amount" source="actualAmountCents" />
      <CurrencyField label="Target Amount" source="targetAmountCents" />
      <CurrencyField label="Offered Amount" source="offeredAmountCents" />      
      <LongDateField source="createdAt" />
      <LongDateField source="updatedAt" />
      <ReferenceField link="show" source="dealId" reference="deals">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export default DealOfferShow;
// console.log(process.env.REACT_APP_API_HOSTNAME)
/**
 * uncomment below line before pushing on production
 */
export const API_HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
// 'https://cors-anywhere.herokuapp.com/' + process.env.REACT_APP_API_HOSTNAME
// "https://new-api-staging.rydeu.com";
// export const API_HOSTNAME = "https://new-api-staging.rydeu.com";
// export const API_HOSTNAME = "https://api.rydeu.com";
// export const API_HOSTNAME = "http://localhost:3000/api";
export const SUPPLIER_APP_HOSTNAME = process.env.REACT_APP_SUPPLIER_APP_HOSTNAME;
export const LONG_DATE_FORMAT = {
  hour12: true,
  // weekday: 'short',
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
};

export const BooleanInputChoices = [
  // {
  //   name: 'All',
  //   id: null,
  // },
  {
    name: "Yes",
    id: true,
  },
  {
    name: "No",
    id: false,
  },
];




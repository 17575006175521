import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/core/Avatar';
import { SimpleShowLayout, List, TextField, NumberField, ReferenceField, EditButton } from "react-admin";
import LongDateField from 'Components/Common/Fields/LongDateField';

const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
    cardContent: {
      padding: 0,
    }
};

const ServiceCard = ({id, basePath, ...commonProps}) => (
  <Card style={cardStyle}>
    <CardHeader
        title={<TextField { ...commonProps } source="name" />}
        subheader={<LongDateField { ...commonProps } source="created_at" />}
        avatar={<Avatar icon={<PersonIcon />} />}
    />
    <CardContent style={cardStyle.cardContent}>
    <SimpleShowLayout {...commonProps}>
        
    <LongDateField { ...commonProps } source="startDateTime" />
  {/* <TextField source="endDateTime" /> */}
  <TextField { ...commonProps } source="additionalRequest" />
  {/* <TextField source="city" /> */}
  
    <TextField { ...commonProps } source="country" />
  {/* <TextField source="currency" /> */}
  {/* <TextField source="title" /> */}
  <NumberField { ...commonProps } source="childSeats" />
  <NumberField { ...commonProps } source="adultSeats" />
  <NumberField { ...commonProps } source="sequenceNo" />
  {/* <TextField source="travellerName" /> */}
  {/* <TextField source="travellerPhoneNumber" /> */}
  {/* <TextField source="travellerEmail" /> */}
  <TextField { ...commonProps } source="serviceType" />
  <TextField { ...commonProps } source="vehicleTypes" />
  <TextField { ...commonProps } source="totalhours" />
  <TextField { ...commonProps } source="pickupLocation.formattedAddress" />
  <TextField { ...commonProps } source="dropLocation.formattedAddress" />
  {/* <LongDateField source="createdAt" /> */}
  {/* <LongDateField source="updatedAt" /> */}
  
  {/* <ReferenceField { ...commonProps } source="pickupLocationId" reference="locations">
    <TextField source="formattedAddress" />
  </ReferenceField>
  <ReferenceField { ...commonProps } source="dropLocationId" reference="locations">
    <TextField source="formattedAddress" />
  </ReferenceField> */}
  {/* <TextField { ...commonProps } source="pickupLocation.id" /> */}
  {/* <TextField { ...commonProps } source="dropLocation.id" /> */}
  <TextField { ...commonProps } source="type" />
  </SimpleShowLayout>
  </CardContent>
    <CardActions style={{ textAlign: 'right' }}>
        <EditButton resource="transfers" basePath={'/transfers'} { ...commonProps }  />
    </CardActions>
  </Card>
)

const ServiceCardList = ({ ids, data, basePath }) => (
    <div style={{ margin: '1em' }}>
    {ids.map(id => <ServiceCard key={id} id={id} basePath={basePath} record={data[id]} />)}
    </div>
);

ServiceCardList.defaultProps = {
    data: {},
    ids: [],
};

export default ServiceCardList;
import React, { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

import Editor from "../../../../../Components/Common/Editor/Editor";
import DefaultTemplates from "./DefaultTemplates";

const TemplatesOptions = [
  {
    id: 1,
    label: "Airport Details - About Airport",
    value: "about",
  },
  {
    id: 2,
    label: "Airport Details - Book Airport Transfers",
    value: "bookAirportTransfer",
  },
];

const PlatformOptions = [
  {
    id: 1,
    label: "Web",
    value: "web",
  },
  {
    id: 2,
    label: "Mobile",
    value: "mobile",
  },
];

const AirportDetails = (props) => {
  const notify = useNotify();
  const [template, setTemplate] = useState(JSON.stringify(TemplatesOptions[0]));
  const [templateData, setTemplateData] = useState("");
  const [platform, setPlatform] = useState(PlatformOptions[0].value);
  const dataProvider = useDataProvider();
  const [
    { airportDetails, airportDetailsLoading, airportDetailsError },
    setAirportDetails,
  ] = useState({
    airportDetails: null,
    airportDetailsLoading: true,
    airportDetailsError: "",
  });

  const updateTemplate = (
    currentTemplateType = template,
    platformType = platform
  ) => {
    let selectedTemplate = JSON.parse(currentTemplateType);
    if (
      airportDetails &&
      airportDetails[selectedTemplate.value] &&
      airportDetails[selectedTemplate.value][platformType]
    ) {
      setTemplateData(airportDetails[selectedTemplate.value][platformType]);
    } else {
      setTemplateData(
        DefaultTemplates.airportDetails[selectedTemplate.value][platformType]
      );
    }
  };

  useEffect(() => {
    const clearTimer = setTimeout(() => {
      updateTemplate();
    }, 3000);
    return () => {
      clearTimeout(clearTimer);
    };
  }, [airportDetails]);

  const getAirportDetails = () => {
    dataProvider
      .getOne(`operating-city/${props.match.params.citySlug}/airports`, {
        id: props.match.params.airportSlug,
      })
      .then((res) => {
        setAirportDetails({
          airportDetails: res.data,
          airportDetailsLoading: false,
          airportDetailsError: "",
        });
      })
      .catch((err) => {
        setAirportDetails({
          airportDetails: null,
          airportDetailsLoading: false,
          airportDetailsError: err.message,
        });
      });
  };

  const saveAirportTemplate = () => {
    let selectedTemplate = JSON.parse(template),
      data = {
        [selectedTemplate.value]: {
          [platform]: templateData,
        },
        name: airportDetails.name,
        city: airportDetails.city.id,
      };

    if (typeof airportDetails[selectedTemplate.value] !== "string") {
      data[selectedTemplate.value] = {
        ...airportDetails[selectedTemplate.value],
        ...data[selectedTemplate.value],
      };
    }

    dataProvider
      .update(`operating-city/${props.match.params.citySlug}/airports`, {
        id: props.match.params.airportSlug,
        data,
      })
      .then((res) => {
        notify("Template Saved Successfully");
        getAirportDetails();
      })
      .catch((err) => {
        notify(`Error: ${err.message}`);
      });
  };

  useEffect(() => {
    getAirportDetails();
  }, []);

  const handleTemplateType = (e) => {
    setTemplate(e.target.value);
    updateTemplate(e.target.value, platform);
  };

  const handlePlatformType = (e) => {
    setPlatform(e.target.value);
    updateTemplate(template, e.target.value);
  };

  const handleResetTemplate = () => {
    updateTemplate(template, platform);
  };

  if (airportDetailsLoading) {
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  if (airportDetailsError && !airportDetailsLoading && !airportDetails) {
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6">{airportDetailsError}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box
        mt={3}
        mb={3}
        display="flex"
        alignItems="flex-start"
        width="100%"
        justifyContent="flex-start"
      >
        <Typography variant="h4">
          {airportDetails.name.toUpperCase()}
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        width="100%"
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" width="50%" justifyContent="space-between">
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Template Type
            </InputLabel>
            <Select
              native={true}
              value={template}
              onChange={handleTemplateType}
              label="Template Type"
              inputProps={{
                name: "template",
                id: "outlined-age-native-simple",
              }}
            >
              {TemplatesOptions.map((item) => (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Platform Type
            </InputLabel>
            <Select
              native={true}
              value={platform}
              onChange={handlePlatformType}
              label="Platform Type"
              inputProps={{
                name: "platform",
                id: "outlined-age-native-simple",
              }}
            >
              {PlatformOptions.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width="30%" display="flex" justifyContent="space-between">
          <Button
            onClick={() => handleResetTemplate()}
            variant="contained"
            color="default"
          >
            Reset Template
          </Button>
          <Button
            onClick={() => saveAirportTemplate()}
            variant="contained"
            color="primary"
          >
            Save Template
          </Button>
        </Box>
      </Box>
      <Box width={platform === "mobile" ? 500 : 1024}>
        <Editor
          config={{
            height: "70vh",
            width: "100%",
          }}
          onChange={(e) => setTemplateData(e.editor.getData())}
          data={templateData}
        />
      </Box>
    </Box>
  );
};

export default AirportDetails;

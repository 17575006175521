import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import CommentRounded from "@material-ui/icons/CommentRounded";
import { useLogout } from "react-admin";
import moment from "moment-timezone";
import httpClient from "../../Providers/Data/HttpClient";
import { debounce } from "lodash";
import {
  Box,
  Button,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";

const UpcomingBookings = (props) => {
  const activity = {
    PROSPECTION: "prospection",
    PROCUREMENT: "procurement",
    TRIPCOORDINATOR: "tripCoordinator",
  };

  const activityState = {
    PENDING: "pending",
    INPROGRESS: "inProgress",
    COMPLETED: "completed",
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [adminUsers, setAdminUsers] = useState({});
  const [filters, setFilters] = useState({});

  const logout = useLogout();
  const [{ data, loading, error }, setResponse] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const formatTimeElapsed = (travelTime) => {
    const today = new Date();
    const endDate = new Date(travelTime);

    const days = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60 * 24));
    const hours = parseInt((Math.abs(endDate - today) / (1000 * 60 * 60)) % 24);
    const minutes = parseInt(
      (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60
    );

    let display = "";
    if (days > 0) display += `${days} days, `;
    if (hours > 0) display += `${hours} hrs, `;
    if (minutes > 0) display += `${minutes} mins`;

    if (endDate - today < 0) {
      return <div style={{ color: "red" }}>{display}</div>;
    }

    return <div>{display}</div>;
  };

  const customRowStyle = (driverId) => {
    if (!driverId) {
      return {
        background: "linear-gradient(to right, #FFBF00, white 5%)",
      };
    }
  };

  const getAdminUsers = async () => {
    try {
      const url = `${process.env.REACT_APP_API_HOSTNAME
        }/admin/users?filter={"userType":"6"}&limit=${25}&offset=${0}`;
      const response = await httpClient(url).then((res) =>
        JSON.parse(res.body)
      );
      const { rows } = response.data;
      console.log(rows);
      const resObj = {};
      if (rows.length > 0) {
        rows.forEach((element) => {
          resObj[element.id] = element;
        });
      }
      setAdminUsers(resObj);
    } catch (err) {
      setResponse({ data: null, loading: false, error: err.message });
    }
  }

  const getList = async (limit = 10, offset = 0, filter = {}) => {
    try {
      const url = `${process.env.REACT_APP_API_HOSTNAME
        }/admin/upcomingBookings?limit=${limit}&offset=${offset}&filter=${JSON.stringify(
          filter
        )}`;
      const response = await httpClient(url).then((res) =>
        JSON.parse(res.body)
      );
      setResponse({
        data: response.data,
        loading: false,
        error: null,
      });
    } catch (err) {
      if (err.message === "Invalid token") {
        logout();
      } else {
        setResponse({ data: null, loading: false, error: err.message });
      }
    }
  }


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      getList(rowsPerPage, newPage * rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
      try {
        const val = +event.target.value;
        setRowsPerPage(val);
        setPage(0);
        getList(val, 0);
      } catch (error) {
        console.log(error);
      }
    };

  /*Filters*/

    const fetchData = () => {
      try {
        getList(rowsPerPage, 0, filters);
      } catch (error) {
        console.log(error);
      }
    };

    const reset = () => {
      try {
        setFilters({});
        getList(rowsPerPage, 0, {});
      } catch (error) {
        console.log(error);
      }
    };

    const handleBookingActivityChange = (event) => {
      try {
        const filterObj = JSON.parse(JSON.stringify(filters));
        filterObj[event.target.name] = {
          ...(filterObj[event.target.name] ?? {}),
          [event.target.id]:
            event.target.value === "State" || event.target.value === "Owner"
              ? null
              : event.target.value,
        };
        setFilters(filterObj);
      } catch (error) {
        console.log(error);
      }
    };

    const handleSearch = (event) => {
      try {
        const filterObj = JSON.parse(JSON.stringify(filters));
        filterObj["q"] = event.target.value;
        setFilters(filterObj);
        getList(rowsPerPage, 0, filterObj);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getList(rowsPerPage, 0);
      getAdminUsers();
      console.log(data);
    }, []);

    return (
      <Box>
        <Box mt={2} mb={2} display="flex" justifyContent="space-between">
          <Typography variant="h4">Upcoming Bookings</Typography>

          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "3px" }}
              onClick={reset}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "3px" }}
              onClick={fetchData}
            >
              Filter
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              id="standard-basic"
              label="Search Booking, Email ..."
              variant="standard"
              onChange={handleSearch}
              value={filters?.q ?? ""}
            />
          </Grid>

          <Grid item xs={2}>
            <>
              <InputLabel htmlFor={activity.PROSPECTION}>Prospection</InputLabel>
              <NativeSelect
                // defaultValue={null}
                inputProps={{
                  id: "userId",
                  name: activity.PROSPECTION,
                }}
                value={filters[activity.PROSPECTION]?.userId ?? ""}
                onChange={handleBookingActivityChange}
              >
                <option value={""}>Owner</option>
                <option value={"none"}>No Owner</option>
                {Object.keys(adminUsers).map((key) => {
                  return (
                    <option value={key}>{`${adminUsers[key]?.account?.firstName ?? ""
                      } ${adminUsers[key]?.account?.lastName ?? ""} - ${adminUsers[key]?.email
                      }`}</option>
                  );
                })}
              </NativeSelect>
            </>
            <br />
            <br />
            <>
              <NativeSelect
                // defaultValue={null}
                inputProps={{
                  id: "state",
                  name: activity.PROSPECTION,
                }}
                value={filters[activity.PROSPECTION]?.state ?? ""}
                onChange={handleBookingActivityChange}
              >
                <option value={""}>State</option>
                {Object.keys(activityState).map((key) => {
                  return <option value={activityState[key]}>{key}</option>;
                })}
              </NativeSelect>
            </>
          </Grid>

          <Grid item xs={2}>
            <>
              <InputLabel htmlFor={activity.PROCUREMENT}>Procurement</InputLabel>
              <NativeSelect
                // defaultValue={null}
                inputProps={{
                  id: "userId",
                  name: activity.PROCUREMENT,
                }}
                value={filters[activity.PROCUREMENT]?.userId ?? ""}
                onChange={handleBookingActivityChange}
              >
                <option value={""}>Owner</option>
                <option value={"none"}>No Owner</option>
                {Object.keys(adminUsers).map((key) => {
                  return (
                    <option value={key}>{`${adminUsers[key]?.account?.firstName ?? ""
                      } ${adminUsers[key]?.account?.lastName ?? ""} - ${adminUsers[key]?.email
                      }`}</option>
                  );
                })}
              </NativeSelect>
            </>
            <br />
            <br />
            <>
              <NativeSelect
                // defaultValue={null}
                inputProps={{
                  id: "state",
                  name: activity.PROCUREMENT,
                }}
                value={filters[activity.PROCUREMENT]?.state ?? ""}
                onChange={handleBookingActivityChange}
              >
                <option value={""}>State</option>
                {Object.keys(activityState).map((key) => {
                  return <option value={activityState[key]}>{key}</option>;
                })}
              </NativeSelect>
            </>
          </Grid>

          <Grid item xs={2}>
            <>
              <InputLabel htmlFor={activity.TRIPCOORDINATOR}>
                Trip Coordination
              </InputLabel>
              <NativeSelect
                // defaultValue={null}
                inputProps={{
                  id: "userId",
                  name: activity.TRIPCOORDINATOR,
                }}
                onChange={handleBookingActivityChange}
                value={filters[activity.TRIPCOORDINATOR]?.userId ?? ""}
              >
                <option value={""}>Owner</option>
                <option value={"none"}>No Owner</option>
                {Object.keys(adminUsers).map((key) => {
                  return (
                    <option value={key}>{`${adminUsers[key]?.account?.firstName ?? ""
                      } ${adminUsers[key]?.account?.lastName ?? ""} - ${adminUsers[key]?.email
                      }`}</option>
                  );
                })}
              </NativeSelect>
            </>
            <br />
            <br />
            <>
              <NativeSelect
                // defaultValue={null}
                inputProps={{
                  id: "state",
                  name: activity.TRIPCOORDINATOR,
                }}
                onChange={handleBookingActivityChange}
                value={filters[activity.TRIPCOORDINATOR]?.state ?? ""}
              >
                <option value={""}>State</option>
                {Object.keys(activityState).map((key) => {
                  return <option value={activityState[key]}>{key}</option>;
                })}
              </NativeSelect>
            </>
          </Grid>
        </Grid>

        <br></br>

        {loading && (
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" size={50} />
          </Box>
        )}
        {error && (
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6">{error}</Typography>
          </Box>
        )}
        {data?.length == 0 && (
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6">No records found</Typography>
          </Box>
        )}

        {data?.length > 0 && !error && !loading && (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Booking Number</TableCell>
                    <TableCell>Travel Pickup / Drop</TableCell>
                    <TableCell>
                      <div>Time To Travel</div>
                      <div>Start / Return Date Time</div>
                    </TableCell>
                    <TableCell>
                      <div>Passengers</div>
                      <div>BaggageCount</div>
                    </TableCell>
                    <TableCell>
                      <div>Passenger Details</div>
                      <div>Flight No.</div>
                    </TableCell>
                    <TableCell>
                      <div>Vendor</div>
                      <div>Driver Details</div>
                    </TableCell>
                    <TableCell>
                      <div>Procurement </div>
                      <div>Owner / State</div>
                    </TableCell>
                    <TableCell>
                      <div>Trip Coordination </div>
                      <div>Owner</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row["bookingId"]}
                      style={customRowStyle(row["driverId"])}
                    >
                      <TableCell>
                        <div>
                          <a href={`#/bookings/${row["bookingId"]}/show`}>
                            {row["bookingNumber"]}
                          </a>
                        </div>
                        <br />
                        <div>
                          {moment
                            .tz(
                              row["bookingConfirmedDate"],
                              row["timezone"] || moment.tz.guess()
                            )
                            .format("DD/MM/YYYY, HH:mm")}
                        </div>
                        <br />
                        <div>
                          {row["numberOfKms"] && `${row["numberOfKms"]} kms`}
                          {row["numberOfHours"] && ` ${row["numberOfHours"]} hrs`}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>{row["pickupLocationAddr"]}</div>
                        <br />
                        <div>{row["dropLocationAddr"] ?? "N/A"}</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <b>
                            {formatTimeElapsed(
                              row["timeToTravel"],
                              row["timezone"]
                            )}
                          </b>
                        </div>
                        <br />
                        <div>
                          {moment
                            .tz(
                              row["startDateTime"],
                              row["timezone"] || moment.tz.guess()
                            )
                            .format("DD/MM/YYYY, HH:mm")}
                        </div>
                        <br />
                        <div>
                          {row["returnDateTime"] &&
                            moment
                              .tz(
                                row["returnDateTime"],
                                row["timezone"] || moment.tz.guess()
                              )
                              .format("DD/MM/YYYY, HH:mm")}
                          {!row["returnDateTime"] && "N/A"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {`${row["totalAdultSeats"]}, ${row["totalChildSeats"]} / ${row["baggageCount"]}`}
                        </div>
                        <br />
                        <div>{row["customerComments"] && <CommentRounded />}</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <a href={`#/users/${row["userId"]}/show`}>
                            {row["email"]}
                          </a>
                        </div>
                        <br />
                        <div>{row["passengerName"] ?? "--"}</div>
                        <br />
                        <div>{row["passengerContactNumber"] ?? "--"}</div>
                        <br />
                        <div>{row["flightNumber"] ?? "--"}</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {row["vendorId"] && (
                            <a href={`#/vendors/${row["vendorId"]}/show`}>
                              {row["vendorEmail"]}
                            </a>
                          )}
                          {!row["vendorId"] && "N/A"}
                        </div>
                        <br />
                        <div>
                          {row["driverId"] && (
                            <div>
                              {`${row["driverName"]} / ${row["driverContact"]}`}
                            </div>
                          )}
                          {!row["driverId"] && "N/A"}
                        </div>
                        <br />
                        <div>
                          {`${row["vehicleName"] === ","
                              ? " --"
                              : row["vehicleName"]
                            } / ${row["licensePlate"] ?? "--"}`.trim()}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {row["procurement"]
                            ? `${adminUsers[row["procurement"]]?.account
                              ?.firstName ?? "-"
                            } ${adminUsers[row["procurement"]]?.account
                              ?.lastName ?? "-"
                            }`
                            : "N/A"}
                        </div>
                        <br />
                        <div>
                          {row["procurementState"]
                            ? row["procurementState"].toUpperCase()
                            : "N/A"}
                        </div>
                        <br />
                        <div>
                          {row["procurementLastUpdated"]
                            ? moment
                              .tz(
                                row["procurementLastUpdated"],
                                moment.tz.guess()
                              )
                              .format("DD/MM/YYYY, HH:mm")
                            : "N/A"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {row["tripCoordination"]
                            ? `${adminUsers[row["tripCoordination"]]?.account
                              ?.firstName ?? "-"
                            } ${adminUsers[row["tripCoordination"]]?.account
                              ?.lastName ?? "-"
                            }`
                            : "N/A"}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={+data[0]["full_count"] ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    );
  };

  export default UpcomingBookings;

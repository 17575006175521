import React from "react";
import {
  SimpleShowLayout, SelectField, Datagrid,
  Filter, List, TextField, TextInput,
  NumberField, DateField, Show, EmailField,
  ReferenceField, BooleanField, ArrayField,
  SingleFieldList, ChipField, ReferenceManyField,
  // TopToolbar,
} from "react-admin";
import FullNameField from 'Components/Common/Fields/FullNameField';
import { BookingStatusChoices } from 'Constants/Bookings';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import { PaymentModeChoices, PaymentStateChoices } from 'Constants/Payments';
import ChipSelectField from 'Components/Common/Fields/ChipSelectField';
import LongDateField from 'Components/Common/Fields/LongDateField';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import Button from 'Components/Common/RaOverrides/Button';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';


const CreatePaymentStateButton = ({ record }) => {

  return (
    <Button
      variant="contained"
      component={Link}
      to={`/paymentStates/create?paymentId=${record.id}`}
      label="Add New Payment State"
      title="Add New Payment State"
    >
      <AddIcon />
    </Button>
  );
};

const PaymentShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <Button variant="contained" customComponent={EditButton} basePath={basePath} record={data} /> */}
        <CreatePaymentStateButton record={data} />
    </TopToolbar>
);

const PaymentShow = props => {
  const [record, setRecord] = React.useState({});
  const [bookingRecord, setBookingRecord] = React.useState({});

  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Bookings',
      url: '/bookings',
    },
    {
      name: `${bookingRecord.bookingNumber}`,
      url: `/bookings/${bookingRecord.id}/show`,
    },
    {
      name: 'Payments',
      url: `/bookings/${bookingRecord.id}/show/payments`,
    },
    {
      name: `${record.id}`,
      url: `/payments/${record.id}/show`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show actions={<PaymentShowActions />} {...props}>
        <SimpleShowLayout>
          <RecordFetcher setRecord={setRecord} />
          <ReferenceField link="show" source="bookingId" reference="bookings">
            {/* <TextField source="bookingNumber" /> */}
            <RecordFetcher setRecord={setBookingRecord}>
              <TextField source="bookingNumber" />
            </RecordFetcher>
          </ReferenceField>
          {/* <ReferenceField link='show' source="bookingId" reference="bookings">
            <TextField source="bookingNumber" />
          </ReferenceField> */}
          <CurrencyField label="Amount" source="amountCents" />
          {/* <TextField source="currency" /> */}

          <TextField source="description" />
          <SelectField source="paymentMode" choices={PaymentModeChoices}/>
          <SelectField source="currentState" choices={PaymentStateChoices}/>
          <TextField source="receiptNumber" />
          <TextField source="receiptEmail" />
          <TextField source="receiptUrl" />
          {/* <DateField source="createdAt" /> */}
          {/* <DateField source="updatedAt" /> */}
          <ReferenceManyField reference="paymentStates" target="paymentId" label={'Payment States'} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
              <SelectField source="state" choices={PaymentStateChoices}/>
              <LongDateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
          {/* <ArrayField source="paymentStates"> */}

          {/* </ArrayField> */}
      </SimpleShowLayout>
      </Show>
    </>
  );
}

export default PaymentShow;

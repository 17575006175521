import React, { useState, useEffect } from "react";
import {
  ReferenceField,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  BooleanInput,
  Create,
  DateInput,
  NumberInput,
  SimpleForm,
  NumberInputTextInput,
  TextField,
  ReferenceInput,
  SelectInput,
  useGetList,
  FormDataConsumer,
  minValue,
  required,
  minLength,
  maxLength,
  useNotify,
  AutocompleteInput,
  SelectField,
} from "react-admin";
import { PricingGroupChoices } from "Constants/Pricing";
import { BookingTypes, BookingTypeChoices } from "Constants/Bookings";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import RecordFetcher from "Components/Common/RecordFetcher";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { startCase, toLower } from "lodash";
import classnames from "classnames";
import { getItem } from "Util/storage";
import { API_HOSTNAME as apiUrl } from "Constants";

const MIN_PRICE = 1;

// const pricingMinValue(pricingType) =>

const validateCity = [required()];
const validateCountry = [required(), minLength(2), maxLength(2)];
// const validatePrice = [required()];
// const validatePriceOptional = [minValue(1)];
// const validateDistance = [required(), minValue(1)];
const validatePercentage = [required(), minValue(1)];
const validatePricingGroup = [required()];

const validateDistance = (vehicleTypeId) => {
  return vehicleTypeId !== process.env.REACT_APP_BUDGET_MINI_BUS &&
    vehicleTypeId !== process.env.REACT_APP_PREMIUM_MINI_BUS
    ? [required(), minValue(1)]
    : [];
};

const validatePrice = (vehicleTypeId) => {
  return vehicleTypeId !== process.env.REACT_APP_BUDGET_MINI_BUS &&
    vehicleTypeId !== process.env.REACT_APP_PREMIUM_MINI_BUS
    ? [required()]
    : [];
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // background: 'lightgreen',
    // width: 'min-content',
    "& section": {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "100vw",
      // background: 'rgba(255, 50, 50, 0.5)',
    },
  },
  input: {
    marginRight: theme.spacing(2),
    maxWidth: theme.spacing(20),
  },
  numberInput: {
    maxWidth: theme.spacing(20),
  },
  disabled: {
    color: theme.palette.text.primary,
  },
  row: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&>*": {
      marginRight: theme.spacing(2),
    },
  },
  rowHeader: {
    ...theme.typography.caption,
    fontSize: "0.9rem",
    fontWeight: theme.typography.fontWeightMedium,
  },
  rowSubHeader: {
    ...theme.typography.caption,
    fontSize: "0.75rem",
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
  },
  inline: {
    display: "inline",
  },
}));

export const PricingCreate = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const [vehicleTypesData, setVehicleTypesData] = React.useState({});
  const [cities, setCities] = useState([]);

  const initialValues = { values: [] };

  useEffect(() => {
    getServiceLocations()
      .then(async (response) => {
        if (response.data.rows) {
          setCities(response.data.rows);
        }
      })
      .catch((error) => {
        notify(error);
      });
  }, []);

  const getServiceLocations = async () => {
    const defaultOptions = {
      headers: {
        authorization: `Bearer ${getItem("token")}`,
      },
    };
    return await fetch(`${apiUrl}/admin/cities`, defaultOptions)
      .then((response) => response.json())
      .then((data) => data);
  };

  try {
    (!props.location.state || !props.location.state.data) &&
      props.history.replace("/pricings");
    Object.keys(vehicleTypesData).length == 0 &&
      setVehicleTypesData(props.location.state.data);
    let vehicleTypeIds = Object.keys(props.location.state.data); //Object.keys(data)

    vehicleTypeIds
      // .filter(
      //   (vehicleTypeId) =>
      //     vehicleTypeId !== process.env.REACT_APP_BUDGET_MINI_BUS &&
      //     vehicleTypeId !== process.env.REACT_APP_PREMIUM_MINI_BUS
      // )
      .forEach((vehicleTypeId) => {
        let value = {
          vehicleTypeId: vehicleTypeId,
        };

        initialValues.values.push(value);
      });
  } catch (e) {
    // console.log(e);
    return null;
  }
  // console.log(initialValues)
  const commonInputProps = {
    InputLabelProps: {
      shrink: true,
    },
    variant: "outlined",
    className: `${classes.input} ${classes.numberInput}`,
  };

  const pricingInputFormat = (v) => v && v / 100;
  const pricingInputParse = (v) => v && v * 100;

  const pricingInputsCommonProps = {
    format: pricingInputFormat,
    parse: pricingInputParse,
  };

  const breadcrumbLinks = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Pricings",
      url: "/pricings",
    },
    {
      name: `Create`,
      url: `/pricings/create`,
    },
  ];
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Create {...props}>
        <SimpleForm redirect={"/pricings"} initialValues={initialValues}>
          {/* <SelectInput label="Pricing Group" source="pricingGroup" choices={PricingGroupChoices} variant={'outlined'} validate={validatePricingGroup} /> */}
          {/* <TextInput
            source="country"
            variant={"outlined"}
            validate={validateCountry}
          />
          <TextInput
            source="city"
            variant={"outlined"}
            validate={validateCity}
          /> */}

          <AutocompleteInput
            validate={validateCity}
            variant={"outlined"}
            label="City"
            source="CityId"
            choices={cities
              .filter(
                (city) =>
                  city.id === city.baseCityId || city.baseCityId === null
              )
              .map((city) => ({
                id: city.id,
                name: `${city.city} - ${city.country}`,
              }))}
            alwaysOn
            emptyText="All"
          />

          {/* <TextInput source="currency" variant={'outlined'} /> */}
          {/* <BooleanInput source="selfDrive" />
          <BooleanInput source="fixedPricing" /> */}

          <ArrayInput
            source="values"
            className={classes.root}
            variant={"outlined"}
          >
            <SimpleFormIterator disableRemove disableAdd>
              {/* <SelectInput disabled label="Pricing Group" source="pricingGroup" choices={PricingGroupChoices}/> */}

              {/* <SelectInput disabled label="Pricing Type" source="pricingType" choices={BookingTypeChoices} className = {`${classes.input} ${classes.disabled}`} /> */}
              {/* <ReferenceInput disabled label="Vehicle Type" source="vehicleTypeId" reference="vehicleTypes" className = {`${classes.input} ${classes.disabled}`} >
                <SelectInput optionText="name" />
              </ReferenceInput> */}

              <FormDataConsumer>
                {({ scopedFormData, formData, getSource, ...rest }) => {
                  return (
                    <>
                      <Box className={`${classes.row}`}>
                        {/* <SelectField
                          record={scopedFormData}
                          label="Pricing Type"
                          source="pricingType"
                          choices={BookingTypeChoices}
                          className={classnames(
                            classes.field,
                            classes.rowHeader
                          )}
                          optionText={(choice) =>
                            startCase(toLower(choice.name))
                          }
                        />

                        <Typography
                          className={classnames(
                            classes.inline,
                            classes.field,
                            classes.rowHeader
                          )}
                        >
                          {"-"}
                        </Typography> */}

                        <TextField
                          record={{
                            ...(vehicleTypesData[
                              scopedFormData.vehicleTypeId
                            ] || {}),
                          }}
                          source="name"
                          className={classnames(
                            classes.field,
                            classes.rowHeader
                          )}
                        />
                      </Box>

                      {/* <ReferenceField link={false} resource="vehicleTypes" basePath="/vehicleTypes" record={scopedFormData} label="Vehicle Type" source="vehicleTypeId" reference="vehicleTypes" > */}
                      {/* </ReferenceField> */}

                      <NumberInput
                        label="Airport Transfer Fees"
                        source={getSource("amountCentsAirportFees")}
                        validate={validatePrice(scopedFormData.vehicleTypeId)}
                        {...pricingInputsCommonProps}
                        {...commonInputProps}
                      />

                      <NumberInput
                        label="Amount Per Hour"
                        // source="amountCentsPerHour"
                        source={getSource("amountCentsPerHour")}
                        validate={validatePrice(scopedFormData.vehicleTypeId)}
                        {...pricingInputsCommonProps}
                        {...commonInputProps}
                      />

                      <NumberInput
                        label="Amount Per KM"
                        // source="amountCentsPerKm"
                        source={getSource("amountCentsPerKm")}
                        validate={validatePrice(scopedFormData.vehicleTypeId)}
                        {...pricingInputsCommonProps}
                        {...commonInputProps}
                      />

                      <NumberInput
                        label="Base Amount"
                        // source="baseAmountCents"
                        source={getSource("baseAmountCents")}
                        validate={validatePrice(scopedFormData.vehicleTypeId)}
                        {...pricingInputsCommonProps}
                        {...commonInputProps}
                      />

                      <NumberInput
                        label="Base Kms"
                        // source="baseKms"
                        source={getSource("baseKms")}
                        validate={validateDistance(
                          scopedFormData.vehicleTypeId
                        )}
                        {...commonInputProps}
                      />

                      <NumberInput
                        label="Late Night Charges %"
                        source={getSource("lateNightChargesPercentage")}
                        {...commonInputProps}
                      />

                      <NumberInput
                        label="Min. Late Night Charges"
                        source={getSource("minimumLateNightCharges")}
                        {...commonInputProps}
                      />

                      <NumberInput
                        label="Max. Late Night Charges"
                        source={getSource("maximumLateNightCharges")}
                        {...commonInputProps}
                      />

                      {/* <NumberInput
                        label="Holiday Surcharges"
                        // source="holidaySurcharges"
                        source={getSource("holidaySurcharges")}
                        {...pricingInputsCommonProps}
                        // validate={validatePrice}
                        {...commonInputProps}
                      />
                      <NumberInput
                        label="Night Charges"
                        // source="lateNightCharges"
                        source={getSource("lateNightCharges")}
                        {...pricingInputsCommonProps}
                        // validate={validatePrice}
                        {...commonInputProps}
                      />
                      <NumberInput
                        label="Vat %"
                        // source="vatPercentage"
                        source={getSource("vatPercentage")}
                        {...pricingInputsCommonProps}
                        {...commonInputProps}
                      /> */}
                    </>
                    /* <SelectInput
                        source="city"
                        choices={getCitiesFor(formData.country)}
                        {...rest}
                     /> */
                  );
                }}
              </FormDataConsumer>

              {/* <DateInput source="createdAt" /> */}
              {/* <DateInput source="updatedAt" /> */}
              {/* <TextInput source="deletedAt" /> */}
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>

        {/* <SimpleForm>
          <TextInput source="city" />
          <TextInput source="country" />
          <SelectInput source="pricingType" choices={BookingTypeChoices}/>
          <SelectInput source="pricingGroup" choices={PricingGroupChoices}/>
          <BooleanInput source="selfDrive" />
          <BooleanInput source="fixedPricing" />
          
          <NumberInput source="baseKms" />

          <TextInput source="currency" />
          <NumberInput label="Per Hour Amount" source="amountCentsPerHour" format={v => v/100} parse={v => v*100} />
          <NumberInput label="Airport Transfer Amount" source="amountCentsAirportTransfer" format={v => v/100} parse={v => v*100} />
          <NumberInput label="Per KM Amount" source="amountCentsPerKm" format={v => v/100} parse={v => v*100} />
          <NumberInput label="Holiday Surcharges" source="holidaySurcharges" format={v => v/100} parse={v => v*100} />
          <NumberInput label="Night Charges" source="lateNightCharges" format={v => v/100} parse={v => v*100} />
          <NumberInput label="Vat Percentage" source="vatPercentage" />
          <NumberInput label="Base Amount" source="baseAmountCents" format={v => v/100} parse={v => v*100} />
          
          <ReferenceInput source="vehicleTypeId" reference="vehicleTypes">
            <SelectInput optionText="name" />
          </ReferenceInput>
      </SimpleForm> */}
      </Create>
    </>
  );
};

export default PricingCreate;

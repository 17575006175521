import { fetchUtils } from "react-admin";

export default (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options).then((res) => {
    // console.log("Response for", url)
    // console.log(res);
    return res;
  });
};

import React from 'react'
import { 
  BooleanField, NumberField, 
  DateField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout,
 } from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import { LONG_DATE_FORMAT } from 'Constants';

const VehicleTypeTitle = ({ record }) => {
  return (
    <span>
      VehicleType: {record ? `${record.title}` : ''}
    </span>
  )
}

export const VehicleTypeShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="category" />
        <TextField source="refModel" />
        <BooleanField source="commercial" />
        <NumberField source="maxPax" />
        <NumberField source="maxBaggage" />
        <TextField source="imageUrl" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
      <Tab label="Description">
        <ArrayField source="description">
          <Datagrid>
            <TextField source="key" />
            <TextField source="value" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default VehicleTypeShow;
//WELCOME TO THE GREAT CREATE AUCTION MODAL
import React, { Fragment, useEffect } from "react";
import { getIds, SimpleForm, useRedirect } from "react-admin";
import { useLocation } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDataProvider, useNotify, Loading } from "react-admin";
import Modal from "../Components/Common/Modal/Modal";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  InputLabel,
  makeStyles,
  Button,
} from "@material-ui/core";
import { TransferTypes } from "Constants/Transfers";
import { useState } from "react";

const GenerateAuctionSchema = Yup.object().shape({
  comment: Yup.string().trim().min(5, "Too Short!"),
  bookingId: Yup.string(),
  vehicleTypeId: Yup.string(),
  vehicleCategory: Yup.string().required(),
  currency: Yup.string().required(),
  totalAmount: Yup.number().min(1).required(),
  amountToCollect: Yup.number(),
  supplierAmount: Yup.number().min(1).required(),
  hourlyRate: Yup.number(),
});

const CreateAuctionModal = ({
  showModal,
  closeModal,
  bookingRecord,
  auctions,
}) => {
  const [vehicleTypes, setVehicleTypes] = React.useState([]);
  const [vehicleIdList, setVehicleIdList] = React.useState([]);
  const [currentRecord, setCurrentRecord] = React.useState(null);
  const [currentRecordData, setCurrentRecordData] = React.useState();
  const [loading, setLoading] = useState(false);
  const [supCost, setSupCost] = useState();
  const location = useLocation();
  useEffect(() => {
    getVehicleTypeList();
    getBookingId();
  }, []);

  const getBookingId = () => {
    setLoading(true);
    const text = location.pathname.toString().split("/")[2];
    setCurrentRecord(text);
    const url = `bookings/${text}`;
    dataProvider
      .onlyList(url)
      .then((res) => {
        setCurrentRecordData(res.data);
        setSupCost(currentRecord?.transfers[0]?.transferOffers[0]?.totalAmount);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getVehicleTypeList = () => {
    const url = "vehicleTypes";
    dataProvider.onlyList(url).then((res) => {
      const { rows } = res.data;
      const vehicleList = [
        {
          name: "Select a Vehicle",
          id: undefined,
        },
      ].concat(rows);

      setVehicleTypes(vehicleList);
    });
    const vehicleIds = [];
    for (let i = 0; i < vehicleTypes.length; i++) {
      vehicleIds.push(vehicleTypes[i].id);
    }
    setVehicleIdList(vehicleIds);
  };
  const transferType =
    bookingRecord &&
    bookingRecord.transfers &&
    bookingRecord.transfers[0]?.transferType;
  const returnDateTime =
    bookingRecord &&
    bookingRecord.transfers &&
    bookingRecord.transfers[0]?.returnDateTime;
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: GenerateAuctionSchema,
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      comment: "",
      price: 0,
      vehicleCategory: "budget",
      currency: "USD",
      // below this I am hydrating the data to totalAmount because the Chinmay Wants best UX for OPS team 😎
      totalAmount: currentRecordData?.transfers[0]?.transferOffers[0]
        ?.totalAmount
        ? currentRecordData?.transfers[0]?.transferOffers[0]?.totalAmount
        : 0,
      amountToCollect: currentRecordData?.transfers[0]?.transferOffers[0]
        ?.commissionAmount
        ? currentRecordData?.transfers[0]?.transferOffers[0]?.commissionAmount
        : 0,
      //same here
      
      // for chinmay  OPS team 😎 > dev team👨🏽‍💻 : partiality 😫
      supplierAmount:
        currentRecordData?.transfers[0]?.transferOffers[0]?.totalAmount &&
        currentRecordData?.transfers[0]?.transferOffers[0]?.commissionAmount
          ? currentRecordData?.transfers[0]?.transferOffers[0]?.totalAmount -
            currentRecordData?.transfers[0]?.transferOffers[0]?.commissionAmount
          : 0,
      hourlyRate: 0,
      commissionAmount: 0,
    },
    onSubmit: (data) => {
      if (data.vehicleTypeId == undefined) {
        notify("Select Vehicle Type");
      } else {
        addAuction(data);
      }
    },
  });

  const addAuction = (createAuctionObj) => {
    const data = {};
    // console.log(bookingRecord.id);
    data.bookingId = bookingRecord.id;
    data.vehicleTypeId = createAuctionObj.vehicleTypeId;
    data.comment = createAuctionObj.comment;
    data.price = Number(createAuctionObj.supplierAmount);
    data.vehicleCategory = createAuctionObj.vehicleCategory;
    data.currency = bookingRecord.currency;
    data.isReserved = bookingRecord.isReserved;
    const commissionAmount =
      Number(createAuctionObj.totalAmount) -
      Number(createAuctionObj.supplierAmount);

    const reservationAmount =
      Number(createAuctionObj.totalAmount) -
      Number(createAuctionObj.amountToCollect);

    const newCommissionPercentage =
      (commissionAmount / Number(createAuctionObj.totalAmount)) * 100;

    data.commissionAmount = commissionAmount;
    data.commissionPercentage = newCommissionPercentage.toFixed(2);
    data.oneWayPrice = createAuctionObj.totalAmount;
    data.supplierAmount = createAuctionObj.supplierAmount;
    data.totalAmount = createAuctionObj.totalAmount;

    if (returnDateTime) {
      data.oneWayPrice = createAuctionObj.totalAmount / 2;
      data.returnPrice = createAuctionObj.totalAmount / 2;
    }

    if (
      bookingRecord.transfers[0]?.reservationAmountCents &&
      bookingRecord.transfers[0]?.reservationAmountCents > 0
    ) {
      data.reservationAmount = reservationAmount;
    }

    if (transferType === TransferTypes.HOURLY)
      data.amountCentsPerHour = createAuctionObj.hourlyRate;

    // console.log({ data });
    dataProvider
      .create("auction", { data })
      .then(() => {
        console.log("success");
        closeModal();
        notify("Auction has been Added");
        redirectTo(`/bookings`);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  if (!bookingRecord.id) {
    return null;
  }

  const commissionPercentage =
    ((Number(values.totalAmount) - Number(values.supplierAmount)) /
      Number(values.totalAmount)) *
    100;
  // console.log(location.pathname.split("/")[2]);
  // console.log(currentRecordData?.transfers[0]?.transferOffers[0]?.totalAmount);
  console.log(supCost);
  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
        <Typography variant="h5">Create Auction</Typography>
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="space-between">
            <FormControl variant="outlined" className={classes.inputContainer}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Currency
              </InputLabel>
              <Select
                native={true}
                value={values.currency}
                label="currency"
                disabled
              >
                <option value={bookingRecord.currency}>
                  {bookingRecord.currency}
                </option>
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.inputContainer}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Vehicle Preference
              </InputLabel>
              <Select
                native={true}
                value={values.vehicleCategory}
                onChange={handleChange("vehicleCategory")}
                onBlur={handleBlur("vehicleCategory")}
                label="vehicleCategory"
                inputProps={{
                  name: "Booking Preference",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value="budget">Budget</option>
                <option value="premium">Premium</option>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TextField
              label="comment"
              error={"comment" in errors && "comment" in touched}
              helperText={
                "comment" in errors && "comment" in touched && errors.comment
              }
              variant="outlined"
              onChange={handleChange("comment")}
              onBlur={handleBlur("comment")}
              value={values.comment}
              className={classes.inputContainer}
              style={{ width: "100%" }}
            />
          </Box>
          <Box display={"flex"} justifyContent="space-between">
            <FormControl variant="outlined" className={classes.inputContainer}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Vehicle Type
              </InputLabel>
              <Select
                native={true}
                value={values.vehicleTypeId}
                onChange={handleChange("vehicleTypeId")}
                onBlur={handleBlur("vehicleTypeId")}
                label="vehicleTypeId"
                inputProps={{
                  name: "Vehicle Type",
                }}
              >
                {vehicleTypes.map((type) => {
                  return (
                    <option value={type.id} key={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" width="45%">
              <TextField
                name="totalAmount"
                // defaultValue={
                //   bookingRecord?.transfers[0]?.transferOffers[0]?.totalAmount
                //     ? bookingRecord?.transfers[0]?.transferOffers[0]
                //         ?.totalAmount
                //     : values?.totalAmount
                // }
                value={values.totalAmount}
                onChange={handleChange}
                label="Total Offer Price"
                variant="outlined"
                type="number"
                className={classes.inputContainer}
                style={{ width: "100%" }}
              />
              <TextField
                name="supplierAmount"
                value={values.supplierAmount}
                // defaultValue={
                //   bookingRecord?.transfers[0]?.transferOffers[0]?.totalAmount
                //     ? Math.round(
                //         bookingRecord?.transfers[0]?.transferOffers[0]
                //           ?.totalAmount
                //       ) -
                //       Math.round(
                //         bookingRecord?.transfers[0]?.transferOffers[0]
                //           ?.commissionAmount
                //       )
                //     : values.commissionAmount
                // }
                onChange={handleChange}
                label="Supplier Cost"
                variant="outlined"
                type="number"
                className={classes.inputContainer}
                style={{ width: "100%" }}
              />
              {transferType && transferType === TransferTypes.HOURLY && (
                <TextField
                  name="hourlyRate"
                  value={values.hourlyRate}
                  // defaultValue={
                  //   auctions[0]?.transferOffer?.amountCentsPerHour
                  //     ? auctions[0]?.transferOffer?.amountCentsPerHour
                  //     : values?.totalAmount
                  // }
                  onChange={handleChange}
                  label="Hourly Rate"
                  variant="outlined"
                  type="number"
                  className={classes.inputContainer}
                  style={{ width: "100%" }}
                />
              )}

              {bookingRecord?.transfers[0]?.reservationAmountCents > 0 && (
                <TextField
                  name="amountToCollect"
                  // value={values.amountToCollect}
                  defaultValue={
                    bookingRecord?.transfers[0]?.transferOffers[0]?.totalAmount
                      ? bookingRecord?.transfers[0]?.transferOffers[0]
                          ?.commissionAmount
                      : values?.totalAmount
                  }
                  onChange={handleChange}
                  label="Amount To Collect"
                  variant="outlined"
                  type="number"
                  className={classes.inputContainer}
                  style={{ width: "100%" }}
                />
              )}
            </Box>
            <div className={classes.fieldContainer}>
              <div className={classes.infoContainer}>
                <div className={classes.infoHead}>Customer Pays</div>
                <div className={classes.infoTotalValue}>
                  {values.currencySymbol} {values.totalAmount}
                </div>
              </div>
              <div className={classes.infoContainer}>
                <div className={classes.infoHead}>Vendor Get</div>
                <div className={classes.infoValue}>
                  {values.currencySymbol}
                  {values.supplierAmount}
                </div>
              </div>
              <div className={classes.infoContainer}>
                <div className={classes.infoHead}>
                  Rydeu Commission{" "}
                  {commissionPercentage > 0
                    ? commissionPercentage.toFixed(2)
                    : 0}
                  %
                </div>
                <div className={classes.infoValue}>
                  {values.currencySymbol}
                  {Number(values.totalAmount) - Number(values.supplierAmount)}
                </div>
              </div>
            </div>
          </Box>

          <Box display="flex" mt={2} justifyContent="space-between">
            <Button onClick={closeModal} variant="text" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateAuctionModal;
const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,

      overflowY: "auto",
    },
    inputContainer: {
      width: "45%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "column",
      width: "45%",
      marginTop: "1rem",
      justifyContent: "center",
    },
    infoContainer: {
      background: "#E8F2FC",
      padding: "1rem",
      display: "flex",
    },
    infoHead: {
      width: "60%",
      fontWeight: "600",
      fontSize: "14px",
      textAlign: "left",
      letterSpacing: "1.5px",
    },
    infoValue: {
      width: "40%",
      textAlign: "center",
      color: "#004A85",
      fontWeight: "600",
    },
    infoTotalValue: {
      width: "40%",
      textAlign: "center",
      color: "#33B30B",
      fontWeight: "600",
    },
  };
});

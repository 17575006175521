import React from "react";
import { Backdrop, Modal as MuiModal, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

const Modal = ({ showModal, children, closeModal }) => {
  const classes = useStyles();

  return (
    <MuiModal
      disableBackdropClick={true}
      className={classes.modal}
      open={showModal}
      onClose={closeModal}
      BackdropComponent={Backdrop}
    >
      {children}
    </MuiModal>
  );
};

export default Modal;

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const AlertDialog = ({ isOpen, buttonComponent, handleClose, handleOk, ...props}) => {

    return (
      <div>
        {buttonComponent}
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title ? `${props.title}` : "Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {props.description ? `${props.description}` : "Are you sure you want to delete it ?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleOk} color="primary" variant="contained" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
}

export default AlertDialog;

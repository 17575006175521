import React from "react";
import {
  SimpleShowLayout,
  SelectField,
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  NumberField,
  DateField,
  Show,
  EmailField,
  ReferenceField,
  BooleanField,
} from "react-admin";
import FullNameField from 'Components/Common/Fields/FullNameField';
import { UserStatusChoicesIntegers } from 'Constants/Users';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const UserShow = props => {
  const [record, setRecord] = React.useState({});

  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Users',
      url: '/users',
    },
    {
      name: `${record.email}`,
      url: `/users/${record.id}/show`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show {...props}>
        <SimpleShowLayout>
          <RecordFetcher setRecord={setRecord} />
          <TextField source="id" />
          <EmailField source="email" />
          <SelectField source="userType" choices={UserStatusChoicesIntegers}/>
          <TextField source="mobileNo" />
          <TextField source="verified" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ReferenceField link="show" source="accountId" reference="accounts">
            <FullNameField />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
    </>  
  )
};

export default UserShow;

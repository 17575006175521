
import { objToNameIdArray } from 'Util/Constants';

export const DealStates = {
  DRAFT: '1',
  PENDING_FOR_PRICING: '2',
  APPROVAL_PENDING: '3',
  APPROVED: '4',
  REJECTED: '5',
  COMPLETE: '6'
};

export const DealStateChoices = objToNameIdArray(DealStates);


export default {
  DealStates,
  DealStateChoices,
}
import React from 'react'
import { 
  BooleanField, NumberField, SelectField,
  DateField, SingleFieldList, ChipField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout, Button, 
  // TopToolbar, 
  EditButton,
  ReferenceManyField, Filter, TextInput,
 } from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';
import ResetFiltersActionButton from 'Components/Common/ResetFiltersActionButton';
import List from 'Components/Common/RaOverrides/List';
import CustomPagination from 'Components/Common/CustomPagination';

const VehicleModelTitle = ({ record }) => {
  return (
    <span>
      VehicleModel: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

const VehicleModelFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Name" source="q" alwaysOn />
  </Filter>
)

export const VehicleModelList = props => (
  <List {...props} title="VehicleModel List" pagination={<CustomPagination />} filters={<VehicleModelFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="model" />
      <TextField source="brandName" />
      <TextField source="modelType" />
      <BooleanField source="verified" />
      {/* <LongDateField source="createdAt" /> */}
      <LongDateField source="updatedAt" />
      <ReferenceField source="vehicleBrandId" reference="vehicleBrands"><TextField source="brand" /></ReferenceField>
      <ReferenceField source="vehicleTypeId" reference="vehicleTypes"><TextField source="name" /></ReferenceField>
      <ReferenceField source="vendorId" reference="vendors"><TextField source="user.email" /></ReferenceField>
    </Datagrid>
  </List>
)
export default VehicleModelList;


import React from "react";
import {
  NumberField,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  ReferenceField,
  CardActions,
  EditButton,
  FunctionField,
  ShowController
} from "react-admin";
import moment from "moment-timezone";
import CurrencyField from "Components/Common/Fields/CurrencyField";
import Breadcrumbs from "Components/Common/Breadcrumbs";
import RecordFetcher from "Components/Common/RecordFetcher";
import { withRouter } from "react-router";
import TopToolbar from "Components/Common/RaOverrides/TopToolbar";
import Button from "Components/Common/RaOverrides/Button";
import { BookingStatuses } from "Constants/Bookings";
import { TransferTypes } from 'Constants/Transfers';

const TransferTitle = ({ record }) => {
  return <span>Transfer: {record ? `${record.title}` : ""}</span>;
};

// const NoneActions = (props) => <CardActions />;

const TransferShowActions = withRouter(
  ({ basePath, data, resource, ...rest }) => {
    if( data && data.booking && ( data.booking.currentState === BookingStatuses.PENDING || data.booking.currentState === BookingStatuses.CONFIRMED ) )
    return (
      <TopToolbar>
        <Button
          variant="contained"
          customComponent={EditButton}
          basePath={basePath}
          record={data}
        />
      </TopToolbar>
    );
    else 
    return <></>
  }
);

export const TransferShow = ({ history, ...props }) => {
  const [record, setRecord] = React.useState({});
  // const [ prevResource, setPrevResource ] = React.useState('');
  let breadcrumbLinks;
  // if( history.location.state && prevResource == '') {
  //   setPrevResource('bookingRequest');
  //   console.log(history.location.state.prevResource)
  // }
  // console.log(prevResource)
  if (
    history.location.state &&
    history.location.state.prevResource == "bookingRequest"
  ) {
    breadcrumbLinks = [
      {
        name: "Home",
        url: "/",
      },
      {
        name: "Booking Requests",
        url: "/bookingRequests",
      },
      {
        name: `${record.bookingId}`,
        url: `/bookingRequests/${record.bookingId}/show`,
      },
      {
        name: `Transfers`,
        url: `/bookingRequests/${record.bookingId}/show`,
      },
      {
        name: `${record.id}`,
        url: `/transfers/${record.id}/show`,
      },
    ];
  } else {
    breadcrumbLinks = [
      {
        name: "Home",
        url: "/",
      },
      {
        name: "Bookings",
        url: "/bookings",
      },
      {
        name: `${record.bookingId}`,
        url: `/bookings/${record.bookingId}/show`,
      },
      {
        name: `Transfers`,
        url: `/bookings/${record.bookingId}/show`,
      },
      {
        name: `${record.id}`,
        url: `/transfers/${record.id}/show`,
      },
    ];
  }

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <ShowController {...props}>
        {controllerProps => 
        <Show {...props} actions={<TransferShowActions />}>
          <SimpleShowLayout>
            <RecordFetcher setRecord={setRecord} />
            <TextField source="id" />
            {/* <TextField source="title" /> */}
            {/* <TextField source="termAndConditions" /> */}
            <FunctionField
              label="Start Date Time"
              render={(record) =>
                moment
                  .tz(record.startDateTime, record.timezone || moment.tz.guess())
                  .format("MMM D, YYYY, hh:mm A")
              }
            />
            <FunctionField
              label="Return Date Time"
              render={(record) =>
                record.returnDateTime
                  ? moment
                      .tz(
                        record.returnDateTime,
                        record.timezone || moment.tz.guess()
                      )
                      .format("MMM D, YYYY, hh:mm A")
                  : "N/A"
              }
            />
            {/* <LongDateField source="endDateTime" /> */}
            {/* <TextField source="additionalRequest" /> */}
            <TextField source="city" />
            <TextField label="Duration" source="numberOfHours" />
            <TextField source="country" />
            {/* <TextField source="currency" /> */}
            <NumberField label="Child Seats" source="totalChildSeats" />
            <NumberField label="Adult Seats" source="totalAdultSeats" />
            <NumberField label="Baggage Count" source="baggageCount" />
            <TextField label="Passenger Name" source="passengerName" />
            <TextField
              label="Passenger Contact Number"
              source="passengerContactNumber"
            />
            {controllerProps.record && controllerProps.record.transferType === TransferTypes.AIRPORT && 
              <TextField label="Flight Number" source="flightNumber" />
            }
            {/* <ArrayField label="Flight Details" source="flightDetails">
              <Datagrid>
                <TextField label="Flight Number" source="flightNumber" />
                <TextField label="Airline Name" source="airlineName" />
                <TextField label="Type" source="type" />
                <DateField label="Travel DateTime" source="travelDateTime" />
              </Datagrid>
            </ArrayField> */}
            {/* <TextField source="travellerEmail" /> */}
            {/* <TextField source="serviceType" /> */}
            <ArrayField source="vehicleTypes">
              <Datagrid>
                <TextField label="Name" source="name" />
                <NumberField label="Count" source="transferVehicleType.count" />
              </Datagrid>
            </ArrayField>
            {/* <ArrayField
            label="vehicleTypes"
            source="vehicleTypes">
                <SingleFieldList>
                    <ChipField link="false" source="name" />
                </SingleFieldList>
            </ArrayField> */}
            <CurrencyField
              label="Total Supplier Cost"
              source="totalSupplierCost"
            />
            <CurrencyField label="Minimum Amount" source="minAmountCents" />
            <CurrencyField label="Offered Amount" source="offeredAmountCents" />
            <NumberField source="supplierOfferCount" />
            <NumberField source="rydeuOfferCount" />
            <ReferenceField
              source="pickupLocationId"
              reference="locations"
              link="false"
            >
              <TextField source="formattedAddress" />
            </ReferenceField>
            <ReferenceField
              source="dropLocationId"
              reference="locations"
              link="false"
            >
              <TextField source="formattedAddress" />
            </ReferenceField>
            <TextField label="Customer Comment" source="customerComment" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </SimpleShowLayout>
        </Show>
        }
      </ShowController>
    </>
  );
};

export default TransferShow;

import React from 'react'
import { 
  BooleanField, NumberField, DateField, 
  AutocompleteInput, ArrayField, SingleFieldList,
  ReferenceInput, SelectInput, Datagrid, 
  Filter, TextField, TextInput, 
  ReferenceField, ChipField, SelectField, 
  ExportButton, EmailField, NullableBooleanInput,
} from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import { UserStatusChoices, UserTypeChoices } from 'Constants/Users';
import { PaymentStateChoices } from 'Constants/Payments';
import Button from 'Components/Common/RaOverrides/Button';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import ResetFiltersActionButton from 'Components/Common/ResetFiltersActionButton';
import List from 'Components/Common/RaOverrides/List';
import FullNameField from 'Components/Common/Fields/FullNameField';
import { UserStatusChoicesIntegers } from 'Constants/Users';
import { BooleanInputChoices } from 'Constants';
import CustomPagination from 'Components/Common/CustomPagination';

const UserTitle = ({ record }) => {
  return (
    <span>
      User: {record ? `${record.name}` : ''}
    </span>
  )
}

const UserFilter = props => {
  // console.log("User Filter Props", props);
  
  return (
    <div>
      <Filter {...props}>
        {/* <TextInput label="Search Name" source="q" alwaysOn /> */}
        <TextInput label="Search" source="q" alwaysOn />
        
        <ReferenceInput label="Organization" source="organizationId" reference="organizations" alwaysOn>
          <AutocompleteInput optionText="companyName" />
        </ReferenceInput>

        <SelectInput label="User Type" source="userType" choices={UserStatusChoices} alwaysOn emptyText="All"/>
        
        <SelectInput label="Is Verified?" source="verified" choices={BooleanInputChoices} alwaysOn emptyText="All"/>
      </Filter>
    </div>
  )
}

const UserActions = props => {
  // console.log("User Actions Props", props);
  const { className, ...rest } = props || {};
  return (
    <div style={{ display: 'flex', alignSelf: 'center', }}>
      <ResetFiltersActionButton 
        {...rest}
        defaultObj={{
          q: null,
          organizationId: null,
          userType: null,
        }} 
      />
      <Button customComponent={ExportButton} label="Export" {...rest} />
    </div>
  )
}
const UserList = props => {
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Users',
      url: '/users',
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <List {...props} actions={<UserActions />} pagination={<CustomPagination/>} filters={<UserFilter />}>
        <Datagrid rowClick="show">
          {/* <TextField source="id" /> */}
          <EmailField source="email" />
          <SelectField source="userType" choices={UserStatusChoicesIntegers}/>
          <TextField source="mobileNo" />
          <BooleanField source="verified" />
          {/* <DateField source="createdAt" /> */}
          {/* <DateField source="updatedAt" /> */}
          <ReferenceField link="show" source="accountId" reference="accounts">
            <FullNameField />
          </ReferenceField>
        </Datagrid>
      </List>
    </>
)};

export default UserList;
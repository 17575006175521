import React from 'react';
import PropTypes from 'prop-types';
import { Button as RaButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `${theme.spacing(5)}px !important`,
    margin: (props) => props.addNegativeMargin ? `-${theme.spacing(2)}px` : theme.spacing(1),
    padding: `${theme.spacing(1)}px !important`,
    whiteSpace: 'nowrap',
    '&>*': {
      whiteSpace: 'nowrap',
    }
  }
}))

const Button = ({customComponent:Component, addNegativeMargin, className, ...props}) => {
  const classes = useStyles({ addNegativeMargin, label: props.label });
  return <Component className={classnames(className, classes.root)} {...props} />
}

Button.propTypes = {
  customComponent: PropTypes.elementType,
  variant: PropTypes.string,
}
Button.defaultProps = {
  customComponent: RaButton,
  // variant: 'contained',
}
export default Button;
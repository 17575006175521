import React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import CustomPagination from 'Components/Common/CustomPagination';

const OrganizationTitle = ({ record }) => {
  return (
    <span>
      Organization: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

const OrganizationFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Name" source="q" alwaysOn />
  </Filter>
)

export const OrganizationList = props => {
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Organizations',
      url: '/organizations',
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <List {...props} title="Organization List" pagination={<CustomPagination />} filters={<OrganizationFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick="show">
          {/* <TextField source="id" /> */}
          <TextField source="companyName" />
          {/* <TextField source="firstName" /> */}
          {/* <TextField source="lastName" /> */}
          <TextField source="domainName" />
          {/* <LongDateField source="contractStartDate" /> */}
          {/* <LongDateField source="contractEndDate" /> */}
          {/* <LongDateField source="registrationNo" /> */}
          {/* <TextField source="vatNo" /> */}
          {/* <ReferenceField source="taxId" reference="taxes"><TextField source="id" /></ReferenceField> */}
          {/* <TextField source="termAndConditions" /> */}
          {/* <TextField source="active" /> */}
          {/* <TextField source="blocked" /> */}
          {/* <BooleanField source="allowInvoicing" /> */}
          {/* <NumberField source="discountPercentage" /> */}
          <TextField source="email" />
          <TextField source="mobileNo" />
          {/* <TextField source="gstnNo" /> */}
          <TextField source="country" />
          {/* <TextField source="currency" /> */}
          <LongDateField source="createdAt" />
          {/* <DateField source="updatedAt" /> */}
        </Datagrid>
      </List>
    </>
  )
}
export default OrganizationList;


//Welcome to the great Auction the only place where u find find C O M M E N T S
import React, { useCallback, useState } from "react";
import { API_HOSTNAME as apiUrl } from "Constants";
import moment from "moment-timezone";
import { useEffect } from "react";
import httpClient from "Providers/Data/HttpClient";
import { SelectInput, TabbedShowLayout } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { useDataProvider, useNotify } from "react-admin";
import usePagination from "../../Util/customHooks/pagination";
import AlertDialog from "Components/Common/AlertDialog/AlertDialog";
import Pagination from "@material-ui/lab/Pagination";
import {
  Paper,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  Box,
  TableHead,
  Typography,
} from "@material-ui/core";
import { Tab } from "react-admin";
import { Select } from "@material-ui/core";
import { Button, Loading } from "react-admin";

const Auction = (props) => {
  const [openAuction, setOpenAuction] = useState(true);
  const [fetchUpdate, setFetchUpdate] = useState(true); // the sole purose of this fetch update is to refetch the approval
  // status of this auction without reloading.
  const [alert, setAlert] = useState(false); //women ☕ 😶‍🌫️ hope she doesn't read this
  const [deleteAuctionID, setDeleteAuctionID] = useState(null); // SELF explanatory 😏
  const [loading, setLoading] = useState(false); // no idea why added this 😅 // yeah remembered to set the loading state
  const [page, setPage] = React.useState(1); //state for pagination could have done this with hook but am a hipster 🤖
  const [recordPerPage, setRecordPerPage] = useState(10); // this state has  no use but am gonna leave it here cause i want to (●'◡'●)
  const [totalCount, setTotalCount] = useState(0); // this state is there to store the total count account according to which pagination tabs are counted
  const [offset, setOffset] = useState(0); // this is the offset that will be updated as the pagination is moved frwd or backwrd
  const [limit, setLimits] = useState(10); // for setting the limits , meeting with  the needs 🥲😤
  // const token = localStorage.getItem("token");

  const dataProvider = useDataProvider();
  // const { page, MuiPagination } = usePagination(totalCount, 10);
  const notify = useNotify();
  const [result, setResult] = React.useState([]);

  useEffect(() => {
    getAuctionList();
  }, [openAuction, fetchUpdate, offset, limit]);

  function OnOpsApprove(idx) {
    const bookingID = result[idx]?.id;
    const data = {
      isOpsVerified: true,
    };
    dataProvider
      .create(`auction/${bookingID}`, { data })
      .then(() => {
        notify("Auction has been Approved");
        setFetchUpdate(!fetchUpdate); // to fetch the date when ever their is
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  function handleOpenAlertModal(idx) {
    setDeleteAuctionID(idx);
    setAlert(true);
  }
  function handleCloseAlertModal() {
    setAlert(false);
  }

  // this function will be executed to delete the auction of open
  function onDelete(idx) {
    const bookingID = result[idx]?.id;
    dataProvider
      .deleteAuction(`auction/${bookingID}`)
      .then(() => {
        notify("Auction has been deleted");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  const getAuctionList = () => {
    const url = `auction?state=${
      openAuction ? 1 : 2
    }&order=desc&limit=${limit}&offset=${offset}`;
    dataProvider.onlyList(url).then((res) => {
      const { rows } = res.data;
      const { count } = res.data;
      setResult(rows);
      setTotalCount(count);
      setLoading(false);
    });
  };
  const handleChange = (event, newpage) => {
    setOffset((newpage - 1) * limit);
    // console.log((newpage - 1) * 25);
    setPage(newpage);
  };
  const handleRowChange = (event) => {
    setLimits(event.target.value);
  };
  // const currentPage = page;
  // const indexOfLastPost = currentPage * recordPerPage;
  // const indexOfFirstPost = indexOfLastPost - recordPerPage;
  // const currentItems = result.slice(indexOfFirstPost, indexOfLastPost);
  const classes = useStyles();
  return (
    <>
      <Box>
        <AlertDialog
          buttonComponent={null}
          isOpen={alert}
          handleClose={handleCloseAlertModal}
          handleOk={() => onDelete(deleteAuctionID)}
          handleCancel={handleCloseAlertModal}
        />
        <Box mt={2} mb={2} display="flex" justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Auction</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box mr={2}>
              <Typography variant="p">Rows Per Page</Typography>
            </Box>
            <Box mx={2}>
              <FormControl className={classes.inputContainer}>
                <Select value={limit} onChange={handleRowChange}>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Typography variant="p">
              {limit * page - limit + 1} - {limit * page} of {totalCount}
            </Typography>
            <Box>
              <Pagination
                count={Math.round(totalCount / limit) + 1}
                page={page}
                shape="rounded"
                onChange={handleChange}
                color="secondary"
              />
            </Box>
          </Box>
        </Box>
        <TabbedShowLayout syncWithLocation={false}>
          <Tab
            label="Open"
            onClick={() => {
              setOpenAuction(true);
              setLoading(true);
            }}
          >
            {loading ? (
              <Loading />
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Booking Number</TableCell>
                      <TableCell>Travel Pickup / Drop</TableCell>
                      <TableCell>
                        <div>Distance</div>
                        <div>Start / Return Date Time</div>
                      </TableCell>
                      <TableCell>
                        <div>Passengers</div>
                        <div>BaggageCount</div>
                      </TableCell>
                      <TableCell>
                        <div>Passenger Details</div>
                        <div>Flight No.</div>
                      </TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Vehicle Category</TableCell>
                      <TableCell>Comment</TableCell>
                      {/* <TableCell>Status</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.map((item, idx) => (
                      <TableRow key={item.bookingId}>
                        <TableCell>
                          <div>
                            <a href={`#/bookings/${item.bookingId}/show`}>
                              {item.booking?.bookingNumber
                                ? item.booking?.bookingNumber
                                : "N/A"}
                            </a>
                          </div>
                          <br />
                          <div>
                            {moment
                              .tz(
                                item.createdAt,
                                item.booking.transfers[0].timezone
                              )
                              .format("DD/MM/YYYY, HH:mm")}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {item.booking.transfers[0].pickupLocation
                              .formattedAddress
                              ? item.booking.transfers[0].pickupLocation
                                  .formattedAddress
                              : "N/A"}
                          </div>
                          <br />
                          <div>
                            {item.booking.transfers[0]?.dropLocation
                              ?.formattedAddress
                              ? item.booking.transfers[0]?.dropLocation
                                  ?.formattedAddress
                              : "N/A"}
                          </div>
                          <br />
                        </TableCell>
                        <TableCell>
                          <div>
                            <div>
                              {item.booking.transfers[0].numberOfKms
                                ? item.booking.transfers[0].numberOfKms
                                : "N.A"}
                            </div>
                            <br />
                            <div>
                              {moment
                                .tz(
                                  item.booking.transfers[0].startDateTime,
                                  item.booking.transfers[0].timezone
                                )
                                .format("DD/MM/YYYY, HH:mm")}
                            </div>
                            <br />
                            <div>
                              {item.booking.transfers[0].returnDateTime
                                ? moment
                                    .tz(
                                      item.booking.transfers[0].returnDateTime,
                                      item.booking.transfers[0].timezone ||
                                        moment.tz.guess()
                                    )
                                    .format("DD/MM/YYYY, HH:mm")
                                : "N/A"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {item.booking.transfers[0]?.baggageCount ? (
                            <>
                              {item.booking.transfers[0]?.totalAdultSeats},
                              {item.booking.transfers[0]?.totalChildSeats}/
                              {item.booking.transfers[0]?.baggageCount}
                            </>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            <div>
                              {item.booking.transfers[0]?.passengerName}
                            </div>
                            <br />
                            <div>{item.booking.user.email}</div>
                            <br />
                            <div>{item.booking.user.mobileNo}</div>
                            <br />
                            <div>{item.booking.transfers[0]?.flightNumber}</div>
                            <br />
                          </div>
                        </TableCell>
                        <TableCell>
                          {item.currency.symbol + " " + item.price}
                        </TableCell>
                        <TableCell>
                          <div>
                            <div>{item.vehicleCategory}</div>
                            <br />
                            <div>
                              {item?.booking?.transfers[0]?.vehicleTypes[0]
                                ?.name
                                ? item?.booking?.transfers[0]?.vehicleTypes[0]
                                    ?.name
                                : "N/A"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <p>{item.comment}</p>
                          {/* {console.log(item.comment)} */}
                        </TableCell>
                        <TableCell>
                          <Button
                            label="Delete"
                            variant="contained"
                            onClick={() => handleOpenAlertModal(idx)}
                          />
                        </TableCell>
                        {/* <TableCell>
                        {item.isOpsVerified == false ||
                        item.isOpsVerified == null ? (
                          <Button
                            label="Approve"
                            variant="contained"
                            onClick={() => OnOpsApprove(idx)}
                          />
                        ) : (
                          <p>Approved</p>
                        )}
                      </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Tab>
          <Tab
            label="Accepted"
            onClick={() => {
              setOpenAuction(false);
              setLoading(true);
            }}
          >
            {loading ? (
              <Loading />
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Booking Number</TableCell>
                      <TableCell>Travel Pickup / Drop</TableCell>
                      <TableCell>
                        <div>Distance</div>
                        <div>Start / Return Date Time</div>
                      </TableCell>
                      <TableCell>
                        <div>Passengers</div>
                        <div>BaggageCount</div>
                      </TableCell>
                      <TableCell>
                        <div>Passenger Details</div>
                        <div>Flight No.</div>
                      </TableCell>
                      <TableCell>Price</TableCell>
                      {/* <TableCell>Confirmation Date</TableCell> */}
                      <TableCell>Vendor Details</TableCell>
                      <TableCell>Vehicle Type</TableCell>
                      <TableCell>Comment</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.map((item, idx) => (
                      <TableRow key={item.bookingId}>
                        {/* {console.log(item)} */}
                        <TableCell>
                          <div>
                            <a href={`#/bookings/${item.bookingId}/show`}>
                              {item.booking.bookingNumber}
                            </a>
                          </div>
                          <br />
                          <div>
                            {moment
                              .tz(
                                item.createdAt,
                                item.booking.transfers[0].timezone
                              )
                              .format("DD/MM/YYYY, HH:mm")}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {item.booking.transfers[0].pickupLocation
                              .formattedAddress
                              ? item.booking.transfers[0].pickupLocation
                                  .formattedAddress
                              : "N/A"}
                          </div>
                          <br />
                          <div>
                            {item.booking.transfers[0]?.dropLocation
                              ?.formattedAddress
                              ? item.booking.transfers[0]?.dropLocation
                                  ?.formattedAddress
                              : "N/A"}
                          </div>
                          <br />
                        </TableCell>
                        <TableCell>
                          <div>
                            <div>
                              {item.booking.transfers[0].numberOfKms
                                ? item.booking.transfers[0].numberOfKms
                                : "N.A"}
                            </div>
                            <br />
                            <div>
                              {moment
                                .tz(
                                  item.booking.transfers[0].startDateTime,
                                  item.booking.transfers[0].timezone
                                )
                                .format("DD/MM/YYYY, HH:mm")}
                            </div>
                            <br />
                            <div>
                              {item.booking.transfers[0].returnDateTime
                                ? moment
                                    .tz(
                                      item.booking.transfers[0].returnDateTime,
                                      item.booking.transfers[0].timezone ||
                                        moment.tz.guess()
                                    )
                                    .format("DD/MM/YYYY, HH:mm")
                                : "N/A"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {item.booking.transfers[0]?.baggageCount ? (
                            <>
                              {item.booking.transfers[0]?.totalAdultSeats},
                              {item.booking.transfers[0]?.totalChildSeats}/
                              {item.booking.transfers[0]?.baggageCount}
                            </>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            <div>
                              {item.booking.transfers[0]?.passengerName
                                ? item.booking.transfers[0]?.passengerName
                                : "N/A"}
                            </div>
                            <br />
                            <div>
                              {item.booking.user.email
                                ? item.booking.user.email
                                : "N/A"}
                            </div>
                            <br />
                            <div>
                              {item.booking.user.mobileNo
                                ? item.booking.user.mobileNo
                                : "N/A"}
                            </div>
                            <br />
                            <div>
                              {item.booking.transfers[0]?.flightNumber
                                ? item.booking.transfers[0]?.flightNumber
                                : "N/A"}
                            </div>
                            <br />
                          </div>
                        </TableCell>
                        <TableCell>
                          {item.currency.symbol + " " + item.price}
                        </TableCell>

                        <TableCell>
                          <div>
                            {item.booking.transfers[0]?.transferOffers[0]
                              ?.vendor.firstName
                              ? item.booking.transfers[0]?.transferOffers[0]
                                  ?.vendor.firstName
                              : "N/A"}

                            {item.booking.transfers[0]?.transferOffers[0]
                              ?.vendor.lastName
                              ? item.booking.transfers[0]?.transferOffers[0]
                                  ?.vendor.lastName
                              : "N/A"}
                          </div>

                          <br />
                          <div>
                            <a
                              href={`#/vendors/${item.booking.transfers[0]?.transferOffers[0]?.vendor.id}/show`}
                            >
                              {item.booking.transfers[0]?.transferOffers[0]
                                ?.vendor.user.email
                                ? item.booking.transfers[0]?.transferOffers[0]
                                    ?.vendor.user.email
                                : "N/A"}
                            </a>
                          </div>
                          <br />
                          <div>
                            {item.booking.transfers[0]?.transferOffers[0]
                              ?.vehicle?.brand
                              ? item.booking.transfers[0]?.transferOffers[0]
                                  ?.vehicle?.brand
                              : "N?A"}
                          </div>
                          <div>
                            {item.booking.transfers[0]?.transferOffers[0]
                              ?.vehicle?.licensePlate
                              ? item.booking.transfers[0]?.transferOffers[0]
                                  ?.vehicle?.licensePlate
                              : "N/A"}
                          </div>
                          {/* <div>
                          {item.booking.transfers[0]?.transferOffers[0]?.vendor
                            .mobileNo
                            ? item.booking.transfers[0]?.transferOffers[0]
                                ?.vendor.mobileNo
                            : "N/A"}
                        </div> */}
                        </TableCell>
                        <TableCell>
                          <div>
                            <div>
                              <div>{item.vehicleCategory}</div>
                              <br />
                              <div>
                                {item?.booking?.transfers[0]?.vehicleTypes[0]
                                  ?.name
                                  ? item?.booking?.transfers[0]?.vehicleTypes[0]
                                      ?.name
                                  : "N/A"}
                              </div>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell>
                          <p>{item.comment}</p>
                          {/* {console.log(item.comment)} */}
                        </TableCell>
                        {/* <TableCell></TableCell> */}
                        <TableCell>
                          {item.isOpsVerified == false ||
                          item.isOpsVerified == null ? (
                            <Button
                              label="Approve"
                              variant="contained"
                              onClick={() => OnOpsApprove(idx)}
                            />
                          ) : (
                            <p>Approved</p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Tab>
        </TabbedShowLayout>
      </Box>
    </>
  );
};

export default Auction;

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    inputContainer: {
      // width: "10%",
      border: "2px solid #999",

      // margin: spacing(2, 0),
    },
  };
});

import React from 'react'
import { BooleanInput, DateInput, Edit, NumberInput, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';

const VehicleBrandTitle = ({ record }) => {
  return (
    <span>
      VehicleBrand: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}
export const VehicleBrandEdit = props => {
  const [record, setRecord] = React.useState({});
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vehicle Brands',
      url: '/vehicleBrands',
    },
    {
      name: `${record.id}`,
      url: `/vehicleBrands/${record.id}/show`,
    },
    {
      name: `Edit`,
      url: `/vehicleBrands/${record.id}`,
    },
  ]
  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Edit title={<VehicleBrandTitle />} {...props}>
        <SimpleForm>
          <RecordFetcher setRecord={setRecord} />
          <TextInput disabled source="id" />
          <TextInput source="brand" />
          <BooleanInput source="major" />
          <BooleanInput source="verified" />
          {/* <DateInput source="createdAt" /> */}
          {/* <DateInput source="updatedAt" /> */}
          <ReferenceInput source="vendorId" reference="vendors"><SelectInput optionText="user.email" /></ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  )
}

export default VehicleBrandEdit;
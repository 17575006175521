import { API_HOSTNAME } from "Constants";
import Storage from "../../Util/storage";

// export default {
//   login: ({ email, password }) => {
//     const request = new Request(`${API_HOSTNAME}/admin/login/v1`, {
//       method: "POST",
//       body: JSON.stringify({ email, password }),
//       headers: new Headers({ "Content-Type": "application/json" }),
//     });

//     let access_token;
//     let refresh_token;
//     let user;
//     let access_token_expires_in;
//     let access_token_expiry;
//     let refresh_token_expires_in;
//     let refresh_token_expiry;

//     return fetch(request)
//       .then((response) => {
//         if (response.status < 200 || response.status >= 300) {
//           throw new Error(response.statusText);
//         }
//         return response.json();
//       })
//       .then((res) => {
//         // console.log(res.data);
//         if (res.status >= 200 && res.status < 300) {
//           return res.data;
//         }
//         throw new Error(res.message);
//       })
//       .then((data) => {
//         let now = new Date();

//         access_token = data.access_token;
//         access_token_expires_in = data.expires_in;
//         now.setSeconds(now.getSeconds() + access_token_expires_in);
//         access_token_expiry = now.toISOString();

//         refresh_token = data.refresh_token;
//         refresh_token_expires_in = data.refresh_expires_in;
//         now = new Date();
//         now.setSeconds(now.getSeconds() + refresh_token_expires_in);
//         refresh_token_expiry = now.toISOString();

//         const request = new Request(`${API_HOSTNAME}/admin/me`, {
//           method: "GET",
//           headers: new Headers({
//             Authorization: "Bearer " + access_token,
//           }),
//         });
//         return fetch(request);
//       })
//       .then((response) => {
//         if (response.status < 200 || response.status >= 300) {
//           throw new Error(response.statusText);
//         }
//         return response.json();
//       })
//       .then((res) => {
//         if (res.status >= 200 && res.status < 300) {
//           // console.log(res.data.roles);
//           user = res.data;
//           if (
//             res.data.roles.includes("rydeu-admin") ||
//             res.data.roles.includes("rydeu-super-admin")
//           ) {
//             Storage.setItem("access_token", access_token);
//             Storage.setItem("refresh_token", refresh_token);
//             Storage.setItem("user", user);
//             Storage.setItem("access_token_expires_in", access_token_expires_in);
//             Storage.setItem("access_token_expiry", access_token_expiry);
//             Storage.setItem(
//               "refresh_token_expires_in",
//               refresh_token_expires_in
//             );
//             Storage.setItem("refresh_token_expiry", refresh_token_expiry);

//             return Promise.resolve();
//           }
//           return Promise.reject();
//         }
//         throw new Error(res.message);
//       })
//       .catch((err) => {
//         // console.log('In AuthProvider Login', err);
//         return Promise.reject(err.message);
//       });
//   },
//   logout: (params) => {
//     const access_token = Storage.getItem("access_token");
//     const refresh_token = Storage.getItem("refresh_token");
//     const user = Storage.getItem("user");
//     clearLocalStorage();

//     console.log({ refresh_token });
//     if (access_token && refresh_token) {
//       const request = new Request(`${API_HOSTNAME}/admin/logout`, {
//         method: "POST",
//         body: JSON.stringify({ refresh_token }),
//         headers: new Headers({
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + access_token,
//         }),
//       });
//       return fetch(request)
//         .then((response) => {
//           if (response.status < 200 || response.status >= 300) {
//             throw new Error(response.statusText);
//           }
//           return response.json();
//         })
//         .then((res) => {
//           if (res.status >= 200 && res.status < 300) {
//             return Promise.resolve();
//           }
//           throw new Error(res.message);
//         })
//         .catch((err) => {
//           // console.log('In AuthProvider Login', err);
//           return Promise.reject(err.message);
//         });
//     }
//     return Promise.resolve();
//   },
//   checkAuth: (params) => {
//     console.log("CHECKAUTH");
//     const now = new Date();
//     const access_token_expiry = new Date(
//       Storage.getItem("access_token_expiry")
//     );
//     const access_token_expires_in = new Date(
//       Storage.getItem("access_token_expires_in")
//     );
//     const refresh_token_expiry = new Date(
//       Storage.getItem("refresh_token_expiry")
//     );
//     const refresh_token_expires_in = new Date(
//       Storage.getItem("refresh_token_expires_in")
//     );
//     const access_token = Storage.getItem("access_token");
//     const refresh_token = Storage.getItem("refresh_token");

//     if (
//       !access_token ||
//       !access_token_expiry ||
//       !access_token_expires_in ||
//       !refresh_token_expiry ||
//       !refresh_token_expires_in ||
//       !refresh_token
//     ) {
//       clearLocalStorage();
//       return Promise.reject();
//     }

//     if (getDifferenceInSeconds(access_token_expiry, now) <= 0) {
//       if (getDifferenceInSeconds(refresh_token_expiry, now) <= 0)
//         return Promise.reject();
//       else {
//         renewAccessToken()
//           .then((status) => {
//             console.log("Refreshed the token");
//             if (status) return Promise.resolve();
//             return Promise.reject();
//           })
//           .catch((err) => {
//             console.log(err);
//             return Promise.reject();
//           });
//       }
//     } else if (
//       getDifferenceInSeconds(access_token_expiry, now) >=
//       Number(access_token_expires_in) / 2
//     ) {
//       renewAccessToken()
//         .then((status) => {
//           console.log("Refreshed the token");
//           if (status) return Promise.resolve();
//           return Promise.reject();
//         })
//         .catch((err) => {
//           console.log(err);
//           return Promise.reject();
//         });
//     }

//     return Promise.resolve();
//     // try {
//     //   // console.log('checkAuth');
//     //   let isAllowed = false;

//     //   // Storage.getItem('token')
//     //   // console.log(Storage.getItem('user'))
//     //   const userLocal = Storage.getItem("user") || {};
//     //   // console.log("userLocal", userLocal.userType);
//     //   // const userCheck =
//     //   // const userRemote = await
//     //   isAllowed = userLocal.userType == "6";
//     //   // console.log('isAllowed', isAllowed)
//     //   return isAllowed ? Promise.resolve() : Promise.reject();
//     // } catch (e) {
//     //   console.log(e);
//     //   return Promise.reject();
//     // }
//   },
//   checkError: (error) => {
//     const status = error.status;
//     if (status === 401 || status === 403) {
//       renewAccessToken()
//         .then((status) => {
//           console.log("Refreshed the token");
//           if (status) return Promise.resolve();
//           return Promise.reject();
//         })
//         .catch((err) => {
//           console.log(err);
//           return Promise.reject();
//         });
//       return Promise.reject();
//     }
//     return Promise.reject();
//   },
//   getPermissions: (params) => {
//     console.log("PERMISSIONS");
//     return Promise.resolve();
//   },
// };

// const renewAccessToken = () => {
//   const refresh_token = Storage.getItem("refresh_token");
//   if (refresh_token) {
//     const request = new Request(`${API_HOSTNAME}/admin/renewAccessToken`, {
//       method: "POST",
//       body: JSON.stringify({ refresh_token }),
//       headers: new Headers({
//         "Content-Type": "application/json",
//       }),
//     });
//     return fetch(request)
//       .then((response) => {
//         if (response.status < 200 || response.status >= 300) {
//           throw new Error(response.statusText);
//         }
//         return response.json();
//       })
//       .then((res) => {
//         if (res.status >= 200 && res.status < 300) {
//           Storage.setItem("access_token", res.data.access_token);
//           Storage.setItem("refresh_token", res.data.refresh_token);
//           return true;
//         }
//         throw new Error(res.message);
//       })
//       .catch((err) => {
//         // console.log('In AuthProvider Login', err);
//         return false;
//       });
//   } else {
//     return false;
//   }
// };

// function getDifferenceInSeconds(date1, date2) {
//   const diffInMs = date1 - date2;
//   return diffInMs / 1000;
// }

// const clearLocalStorage = () => {
//   Storage.removeItem("access_token");
//   Storage.removeItem("refresh_token");
//   Storage.removeItem("user");
//   Storage.removeItem("access_token_expires_in");
//   Storage.removeItem("access_token_expiry");
//   Storage.removeItem("refresh_token_expires_in");
//   Storage.removeItem("refresh_token_expiry");
// };

export default {
  login: ({ email, password }) => {
    const request = new Request(`${API_HOSTNAME}/v1/admin/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        // console.log(res);
        if (res.status >= 200 && res.status < 300) {
          const { data } = res;
          const { access_token, user } = data;

          Storage.setItem("token", access_token);
          Storage.setItem("user", user);
          return Promise.resolve(access_token);
        }
        throw new Error(res.message);
      })
      .catch((err) => {
        // console.log('In AuthProvider Login', err);
        return Promise.reject(err.message);
      });
  },
  logout: (params) => {
    Storage.removeItem("token");
    Storage.removeItem("user");
    return Promise.resolve();
  },
  checkAuth: async (params) => {
    try {
      // console.log('checkAuth');
      let isAllowed = false;

      // Storage.getItem('token')
      // console.log(Storage.getItem('user'))
      const userLocal = Storage.getItem("user") || {};
      // console.log("userLocal", userLocal.userType);
      // const userCheck =
      // const userRemote = await
      isAllowed = userLocal.userType == "6";
      // console.log('isAllowed', isAllowed)
      return isAllowed ? Promise.resolve() : Promise.reject();
    } catch (e) {
      console.log(e);
      return Promise.reject();
    }
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      Storage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params) => Promise.resolve(),
};

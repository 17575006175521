import React from 'react'
import { 
  BooleanField, NumberField, SelectField,
  DateField, SingleFieldList, ChipField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout, Button, 
  // TopToolbar, 
  EditButton, ReferenceManyField, ShowButton,
  useGetOne,
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
// import { VehicleModelStateStatusChoices } from 'Constants/VehicleModelStates';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import ChipSelectField from 'Components/Common/Fields/ChipSelectField';
import LongDateField from 'Components/Common/Fields/LongDateField';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';

const VehicleModelTitle = ({ record }) => {
  return (
    <span>
      VehicleModel ID: {record ? `${record.vehicleModelNumber}` : ''}
    </span>
  )
}

const CreateVehicleModelStateButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/vehicleModelStates/create?vehicleModelId=${record.id}&vehicleModelNumber=${record.vehicleModelNumber}&vehicleBrandId=${record.vehicleBrandId}`}
      label="Add New VehicleModel State"
      title="Add New VehicleModel State"
    >
      <AddIcon />
    </Button>
  );
};


const Aside = () => null;
const VehicleModelShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <CreateVehicleModelStateButton record={data} />
    </TopToolbar>
);

export const VehicleModelShow = props => {

  const [record, setRecord] = React.useState({});
  const [vehicleBrandRecord, setVehicleBrandRecord] = React.useState({});

  // const vehicleModelData = useGetOne('vehicleModels', props.id);
  // const vehicleBrandData = useGetOne('vehicleBrands', record.id);

  // React.useEffect(() => {
  //   const { data } = vehicleModelData || {};
  //   const { vehicleBrand } = data || {};

  //   console.log("React Use Effect", data, vehicleBrand);
    
  //   data && setRecord(data);
  //   vehicleBrand && setVehicleBrandRecord(vehicleBrand);

  // }, [vehicleModelData.data])

  // React.useEffect(() => {
  //   console.log("VehicleBrand Data Change")
  //   setVehicleBrandRecord(vehicleBrandData.data || {})
  // }, [vehicleBrandData.data])

  // React.useEffect(() => {

  //   const breadcrumbLinks = 

  // }, [vehicleModelData.data])

  // const { vehicleBrand = {} } = record || {};


  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'VehicleBrands',
      url: '/vehicleBrands',
    },
    {
      name: `${vehicleBrandRecord.id}`,
      url: `/vehicleBrands/${vehicleBrandRecord.id}/show`,
    },
    {
      name: 'VehicleModels',
      url: `/vehicleBrands/${vehicleBrandRecord.id}/show/vehicleModels`,
    },
    {
      name: `${record.id}`,
      url: `/vehicleModels/${record.id}/show`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show title={<VehicleModelTitle/>} aside={<Aside />} actions={<VehicleModelShowActions/>} {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceField link="show" source="vehicleBrandId" reference="vehicleBrands">
              <RecordFetcher setRecord={setVehicleBrandRecord}>
                <TextField source="brand" />
              </RecordFetcher>
            </ReferenceField>
            <TextField source="id" />
            <TextField source="model" />
            <TextField source="brandName" />
            <TextField source="modelType" />
            <BooleanField source="verified" />
            <LongDateField source="createdAt" />
            <LongDateField source="updatedAt" />
            {/* <ReferenceField source="vehicleBrandId" reference="vehicleBrands"><TextField source="brand" /></ReferenceField> */}
            <ReferenceField source="vehicleTypeId" reference="vehicleTypes"><TextField source="name" /></ReferenceField>
            <ReferenceField source="vendorId" reference="vendors"><TextField source="user.email" /></ReferenceField>
          </Tab>

          {/* <Tab label="VehicleModel States" path="vehicleModelStates">
            <RecordFetcher setRecord={setRecord} />
          </Tab> */}

        </TabbedShowLayout>
      </Show>
    </>
  )
}

export default VehicleModelShow;
import React, { useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../Components/Common/Modal/Modal";

const UserReassignFormSchema = Yup.object().shape({
  email: Yup.string().email(),
});

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const UserReassignModal = ({
  bookingRecord,
  showModal,
  closeModal,
  refresh,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: UserReassignFormSchema,
    initialValues: {
      userEmail: "",
    },
    onSubmit: (data) => {
      if (!isEmailValid) {
        notify("Invalid Email");
        return;
      }
      reassignUser(data);
    },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsEmailValid(validateEmail(values.userEmail));
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [values.userEmail]);

  const reassignUser = (data) => {
    dataProvider
      .create("userReassign", {
        data: { bookingId: bookingRecord.id, userEmail: data.userEmail },
      })
      .then((res) => {
        closeModal();
        refresh();
        notify("User reassigned successfully! ");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
          <Typography variant="h5">Reassign User</Typography>
          <form className={classes.formContainer} onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between">
              <TextField
                error={!isEmailValid}
                type="email"
                label="User Email"
                variant="outlined"
                onChange={handleChange("userEmail")}
                onBlur={handleBlur("userEmail")}
                value={values.userEmail}
                className={classes.inputContainer}
                helperText={!isEmailValid ? "Invalid Email" : "Looks Good!"}
              />
            </Box>
            <Box display="flex" mt={2} justifyContent="space-between">
              <Button onClick={closeModal} variant="text" color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Fragment>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
      width: "100%",
    },
    inputContainer: {
      width: "95%",
      margin: spacing(2, 0),
      fontSize: "48",
    },
  };
});

export default UserReassignModal;

import React from 'react'
import { Datagrid, Filter, TextField, TextInput, ReferenceField, BooleanField } from 'react-admin'
import LongDateField from 'Components/Common/Fields/LongDateField'
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import ResetFiltersActionButton from 'Components/Common/ResetFiltersActionButton';
import List from 'Components/Common/RaOverrides/List';
import CustomPagination from 'Components/Common/CustomPagination';

const VehicleBrandTitle = ({ record }) => {
  return (
    <span>
      VehicleBrand: {record ? `${record.name || record.domainName}` : ''}
    </span>
  )
}

const VehicleBrandFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Name" source="q" alwaysOn />
  </Filter>
)

export const VehicleBrandList = props => {
  
  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vehicle Brands',
      url: '/vehicleBrands',
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <List {...props} pagination={<CustomPagination />} title="VehicleBrand List" filters={<VehicleBrandFilter />}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="brand" />
          <BooleanField source="major" />
          <BooleanField source="verified" />
          {/* <DateField source="createdAt" /> */}
          {/* <DateField source="updatedAt" /> */}
          <ReferenceField source="vendorId" reference="vendors"><TextField source="user.email" /></ReferenceField>
        </Datagrid>
      </List>
    </>
  )
}
export default VehicleBrandList;


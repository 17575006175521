import React from 'react'
import { 
  BooleanField, NumberField, SelectField,
  DateField, SingleFieldList, ChipField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout, Button, 
  // TopToolbar, 
  EditButton, ReferenceManyField, ShowButton,
  useGetOne, EmailField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
// import { DriverStateStatusChoices } from 'Constants/DriverStates';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import ChipSelectField from 'Components/Common/Fields/ChipSelectField';
import LongDateField from 'Components/Common/Fields/LongDateField';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';
import DocumentOrImageFileField from 'Components/Common/Fields/DocumentOrImageFileField';
import SimpleArrayField from 'Components/Common/Fields/SimpleArrayField';

const DriverTitle = ({ record }) => {
  return (
    <span>
      Driver ID: {record ? `${record.orderNumber}` : ''}
    </span>
  )
}

const CreateDriverStateButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/orderStates/create?driverId=${record.id}`}
      // to={`/orderStates/create?orderId=${record.id}&orderNumber=${record.orderNumber}&vendorId=${record.vendorId}`}
      label="Add New Driver State"
      title="Add New Driver State"
    >
      <AddIcon />
    </Button>
  );
};


const Aside = () => null;
const DriverShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        {/* <CreateDriverStateButton record={data} /> */}
    </TopToolbar>
);

export const DriverShow = props => {

  const [record, setRecord] = React.useState({});
  const [vendorRecord, setVendorRecord] = React.useState({});

  // const orderData = useGetOne('orders', props.id);
  // const vendorData = useGetOne('vendors', record.id);

  // React.useEffect(() => {
  //   const { data } = orderData || {};
  //   const { vendor } = data || {};

  //   console.log("React Use Effect", data, vendor);
    
  //   data && setRecord(data);
  //   vendor && setVendorRecord(vendor);

  // }, [orderData.data])

  // React.useEffect(() => {
  //   console.log("Vendor Data Change")
  //   setVendorRecord(vendorData.data || {})
  // }, [vendorData.data])

  // React.useEffect(() => {

  //   const breadcrumbLinks = 

  // }, [orderData.data])

  // const { vendor = {} } = record || {};


  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Vendors',
      url: '/vendors',
    },
    {
      name: `${vendorRecord.id}`,
      url: `/vendors/${vendorRecord.id}/show`,
    },
    {
      name: 'Drivers',
      url: `/vendors/${vendorRecord.id}/show/drivers`,
    },
    {
      name: `${record.id}`,
      url: `/drivers/${record.id}/show`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show title={<DriverTitle/>} aside={<Aside />} actions={<DriverShowActions/>} {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceField link="show" source="vendorId" reference="vendors">
              <RecordFetcher setRecord={setVendorRecord}>
                <TextField source="id" />
              </RecordFetcher>
            </ReferenceField>
            <TextField source="id" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="phone" />
            <EmailField source="email" />
            <TextField source="drivingLicense" />
            <SimpleArrayField source="languageProficiency" max={Number.MAX_SAFE_INTEGER}/>
            <DocumentOrImageFileField source="drivingLicenseDocuments" />
            <BooleanField source="documentVerified" />
            <DateField source="createdAt" />
            {/* <DateField source="updatedAt" /> */}
            {/* <ReferenceField link="show" source="vendorId" reference="vendors"><TextField source="id" /></ReferenceField> */}
          </Tab>

          {/* <Tab label="Driver States" path="orderStates">
            <RecordFetcher setRecord={setRecord} />
          </Tab> */}

        </TabbedShowLayout>
      </Show>
    </>
  )
}

export default DriverShow;
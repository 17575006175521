import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import moment from "moment-timezone";
import { useDataProvider, useNotify } from "react-admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "../../Components/Common/Modal/Modal";
import Editor from "../../Components/Common/Editor/Editor";

const SurgeFormSchema = Yup.object().shape({
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
  surgePercentage: Yup.number().required().min(-1000).max(1000),
  city: Yup.string().required(),
});

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    formContainer: {
      marginTop: spacing(2),
      maxHeight: "70vh",
      paddingRight: 15,
      overflowY: "auto",
    },
    inputContainer: {
      width: "45%",
      margin: spacing(2, 0),
    },
    creditButtonContainer: {
      width: "45%",
      margin: spacing(2, 0),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: spacing(0, 2),
    },
    creditButtonLabel: {
      display: "flex",
      justifyContent: "flex-start",
    },
  };
});

const OpertaingCityModal = ({
  surgeDetails,
  showModal,
  closeModal,
  refreshList,
  countries,
  cities,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [imageCreditType, setImageCreditType] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    validationSchema: SurgeFormSchema,
    initialValues: {
      city: "",
      surgePercentage: "",
      startDate: "",
      endDate: "",
      ...surgeDetails,
      city:
        surgeDetails &&
        JSON.stringify({
          id: surgeDetails.city,
          name: surgeDetails.city,
          location: surgeDetails.location,
          country: surgeDetails.country,
        }),
      startDate:
        surgeDetails &&
        surgeDetails.startDate &&
        moment(surgeDetails.startDate).utc().format("YYYY-MM-DD"),
      endDate:
        surgeDetails &&
        surgeDetails.endDate &&
        moment(surgeDetails.endDate).utc().format("YYYY-MM-DD"),
    },
    onSubmit: (data) => {
      if (surgeDetails) {
        editSurge(data);
      } else {
        addSurge(data);
      }
    },
  });

  const addSurge = (data) => {
    let city = {
      id: "global",
      country: "global",
      location: null,
    };

    if (data.city !== "global") {
      city = JSON.parse(data.city);
    }

    dataProvider
      .create("surges", {
        data: {
          city: city.id,
          country: city.country,
          location: city.location,
          surgePercentage: data.surgePercentage,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
        },
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Surge created successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  const editSurge = (data) => {
    const city = JSON.parse(data.city);

    const details = {
      city: city.id,
      country: city.country,
      location: city.location,
      surgePercentage: data.surgePercentage,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
    };

    dataProvider
      .update("surges", {
        id: surgeDetails.id,
        data: details,
      })
      .then(() => {
        closeModal();
        refreshList();
        notify("Surge updated successfully");
      })
      .catch((error) => {
        closeModal();
        notify(`Error: ${error.message}`);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!surgeDetails && countries && countries.length > 0) {
      setFieldValue("country", countries[0].id);
    }
  }, [countries, surgeDetails]);

  useEffect(() => {
    if (!surgeDetails && cities && cities.length > 0) {
      setFieldValue("city", cities[0].id);
    }
  }, [cities, surgeDetails]);

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Fragment>
        {!imageCreditType ? (
          <Box width={600} pl={5} pr={5} pt={3} pb={3} bgcolor="white">
            <Typography variant="h5">{`${
              surgeDetails ? "Edit" : "Create"
            } Surge`}</Typography>
            <form className={classes.formContainer} onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="space-between">
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                  style={{ width: "100%" }}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    City Name
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.city}
                    onChange={handleChange("city")}
                    onBlur={handleBlur("city")}
                    label="City Name"
                    inputProps={{
                      name: "city",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {cities.map((city, index) => (
                      <option
                        key={city.id + index}
                        value={JSON.stringify(city)}
                      >
                        {`${city.name.toUpperCase()} - ${
                          city.country || "GLOBAL"
                        }`}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  value={values.startDate}
                  onChange={handleChange("startDate")}
                  onBlur={handleBlur("startDate")}
                  className={classes.inputContainer}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  value={values.endDate}
                  onChange={handleChange("endDate")}
                  onBlur={handleBlur("endDate")}
                  className={classes.inputContainer}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "surgePercentage" in errors && "surgePercentage" in touched
                  }
                  type="number"
                  label="Surge Percentage"
                  variant="outlined"
                  onChange={handleChange("surgePercentage")}
                  onBlur={handleBlur("surgePercentage")}
                  value={values.surgePercentage}
                  className={classes.inputContainer}
                />
              </Box>
              {/*
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailSmall" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailSmall" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Small Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailSmall")}
                  onBlur={handleBlur("images.web.thumbnailSmall")}
                  value={values.images.web.thumbnailSmall}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("thumbnailSmall")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailSmall ||
                        "<p>Thumbnail Small Image Credits</p>"
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  error={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web
                  }
                  helperText={
                    "images" in errors &&
                    "web" in errors.images &&
                    "thumbnailLarge" in errors.images.web &&
                    "images" in touched &&
                    "web" in touched.images &&
                    "thumbnailLarge" in touched.images.web &&
                    "Invalid url"
                  }
                  label="Web Thumbnail Large Image URL"
                  variant="outlined"
                  onChange={handleChange("images.web.thumbnailLarge")}
                  onBlur={handleBlur("images.web.thumbnailLarge")}
                  value={values.images.web.thumbnailLarge}
                  className={classes.inputContainer}
                />
                <Button
                  onClick={() => setImageCreditType("thumbnailLarge")}
                  classes={{ label: classes.creditButtonLabel }}
                  className={classes.creditButtonContainer}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        values.imagesCredits.thumbnailLarge ||
                        "<p>Thumbnail Large Image Credits</p>"
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  label="Latitude"
                  variant="outlined"
                  onChange={handleChange("latitude")}
                  onBlur={handleBlur("latitude")}
                  value={values.latitude}
                  className={classes.inputContainer}
                />
                <TextField
                  label="Longitude"
                  variant="outlined"
                  onChange={handleChange("longitude")}
                  onBlur={handleBlur("longitude")}
                  value={values.longitude}
                  className={classes.inputContainer}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <FormControl
                  variant="outlined"
                  className={classes.inputContainer}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Visibility
                  </InputLabel>
                  <Select
                    native={true}
                    value={values.visibility}
                    onChange={handleChange("visibility")}
                    onBlur={handleBlur("visibility")}
                    label="Visibility"
                    inputProps={{
                      name: "visibility",
                      id: "outlined-age-native-simple"
                    }}
                  >
                    <option value={true}>On</option>
                    <option value={false}>Off</option>
                  </Select>
                </FormControl>
              </Box> */}
              <Box display="flex" mt={2} justifyContent="space-between">
                <Button onClick={closeModal} variant="text" color="primary">
                  {surgeDetails ? "Discard" : "Cancel"}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {surgeDetails ? "Save" : "Submit"}
                </Button>
              </Box>
            </form>
          </Box>
        ) : (
          <Box>
            <Box display="flex" bgcolor="white" justifyContent="space-between">
              <Button
                onClick={() => {
                  setTemplateData(values.imagesCredits[imageCreditType] || "");
                  setImageCreditType(null);
                }}
                variant="text"
                color="primary"
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  setFieldValue(
                    `imagesCredits.${imageCreditType}`,
                    templateData
                  );
                  setImageCreditType(null);
                }}
                style={{ borderRadius: 0 }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
            <Editor
              onChange={(e) => setTemplateData(e.editor.getData())}
              style={{ borderWidth: 0 }}
              config={{
                height: "400px",
                width: "100%",
                resize_enabled: false,
              }}
              data={templateData || values.imagesCredits[imageCreditType]}
            />
          </Box>
        )}
      </Fragment>
    </Modal>
  );
};

export default OpertaingCityModal;

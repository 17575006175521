import React from 'react';
import { isNumber, isFinite } from 'lodash';
import Typography from '@material-ui/core/Typography';
import CurrencyField from 'Components/Common/Fields/CurrencyField';

const DefaultWarningMessage = "One or more Amount Values are missing.";

const getTotal = (arr, key, initValue = 0) => arr.reduce((sum, item)=> isFinite(item[key]) && sum + item[key], initValue);

const getCurrencyForTransferService = ({services = []}) => ((services.length && services.filter(item => item.type === "transfers")[0].currency) || '');

const CustomField = ({ title, content, warning }) => (
  <div>
    <Typography variant="h6" >{title}</Typography>
    <Typography variant="body2" >{content || warning || DefaultWarningMessage}</Typography>
  </div>
)

export const ActualAmountField = ({ record = {}, ...props }) => {
  const totalAmountValue = getTotal(record.services, 'actualAmountCents')// record.services.reduce((sum, service)=> isFinite(service.actualAmountCents) && sum + service.actualAmountCents, 0); 
  let currency = getCurrencyForTransferService(record);
  return <CustomField title={'Total Actual Amount:'} content={`${currency.toString().toUpperCase()} ${totalAmountValue/100}`} />
}
ActualAmountField.defaultProps = {
    // addLabel: true,
};

export const TargetAmountField = ({ record = {}, ...props }) => {
  const totalAmountValue = getTotal(record.services, 'targetAmountCents')// record.services.reduce((sum, service)=> isFinite(service.actualAmountCents) && sum + service.actualAmountCents, 0); 
  let currency = getCurrencyForTransferService(record);
  return <CustomField title={'Total Target Amount:'} content={`${currency.toString().toUpperCase()} ${totalAmountValue/100}`} />
}
TargetAmountField.defaultProps = {
    // addLabel: true,
};


export const OfferedAmountField = ({ record = {}, ...props }) => {
  const totalAmountValue = getTotal(record.services, 'offeredAmountCents')// record.services.reduce((sum, service)=> isFinite(service.actualAmountCents) && sum + service.actualAmountCents, 0); 
  let currency = getCurrencyForTransferService(record);
  return <CustomField title={'Total Offered Amount'} content={`${currency.toString().toUpperCase()} ${totalAmountValue/100}`} />
}

OfferedAmountField.defaultProps = {
    // addLabel: true,
};

export default {
  ActualAmountField,
  TargetAmountField,
  OfferedAmountField
}
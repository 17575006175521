import React from 'react'
import { 
  BooleanField, NumberField, SelectField,
  DateField, SingleFieldList, ChipField,
  Show, SimpleShowLayout, TextField, 
  ArrayField, Datagrid, ReferenceField,
  Tab, TabbedShowLayout, Button, 
  // TopToolbar, 
  EditButton, ReferenceManyField, ShowButton,
  useGetOne,
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { OrderStateStatusChoices } from 'Constants/OrderStates';
import CurrencyField from 'Components/Common/Fields/CurrencyField';
import ChipSelectField from 'Components/Common/Fields/ChipSelectField';
import LongDateField from 'Components/Common/Fields/LongDateField';
import Breadcrumbs from 'Components/Common/Breadcrumbs';
import RecordFetcher from 'Components/Common/RecordFetcher';
import TopToolbar from 'Components/Common/RaOverrides/TopToolbar';

const OrderTitle = ({ record }) => {
  return (
    <span>
      Order ID: {record ? `${record.orderNumber}` : ''}
    </span>
  )
}

const CreateOrderStateButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/orderStates/create?orderId=${record.id}&orderNumber=${record.orderNumber}&bookingId=${record.bookingId}`}
      label="Add New Order State"
      title="Add New Order State"
    >
      <AddIcon />
    </Button>
  );
};


const Aside = () => null;
const OrderShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <CreateOrderStateButton record={data} />
    </TopToolbar>
);

export const OrderShow = props => {

  const [record, setRecord] = React.useState({});
  const [bookingRecord, setBookingRecord] = React.useState({});

  // const orderData = useGetOne('orders', props.id);
  // const bookingData = useGetOne('bookings', record.id);

  // React.useEffect(() => {
  //   const { data } = orderData || {};
  //   const { booking } = data || {};

  //   console.log("React Use Effect", data, booking);
    
  //   data && setRecord(data);
  //   booking && setBookingRecord(booking);

  // }, [orderData.data])

  // React.useEffect(() => {
  //   console.log("Booking Data Change")
  //   setBookingRecord(bookingData.data || {})
  // }, [bookingData.data])

  // React.useEffect(() => {

  //   const breadcrumbLinks = 

  // }, [orderData.data])

  // const { booking = {} } = record || {};


  const breadcrumbLinks = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Bookings',
      url: '/bookings',
    },
    {
      name: `${bookingRecord.bookingNumber}`,
      url: `/bookings/${bookingRecord.id}/show`,
    },
    {
      name: 'Orders',
      url: `/bookings/${bookingRecord.id}/show/orders`,
    },
    {
      name: `${record.orderNumber}`,
      url: `/orders/${record.id}/show`,
    },
  ]

  return (
    <>
      <Breadcrumbs links={breadcrumbLinks} />
      <Show title={<OrderTitle/>} aside={<Aside />} actions={<OrderShowActions/>} {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceField link="show" source="bookingId" reference="bookings">
              {/* <TextField source="bookingNumber" /> */}
              <RecordFetcher setRecord={setBookingRecord}>
                <TextField source="bookingNumber" />
              </RecordFetcher>
            </ReferenceField>

            <TextField source="orderNumber" />
            <ReferenceField link={false} source="pickupLocationId" reference="locations">
              <TextField source="formattedAddress" />
            </ReferenceField>
            <ReferenceField link={false} source="dropLocationId" reference="locations">
              <TextField source="formattedAddress" />
            </ReferenceField>
            <LongDateField source="startDateTime" />
            <LongDateField source="endDateTime" />
            <ReferenceField link="show" source="vehicleTypeId" reference="vehicleTypes">
              <TextField source="name" />
            </ReferenceField>

            <TextField source="additionalRequest" />
            <NumberField source="childSeats" />
            <CurrencyField label="Total Amount" source="totalAmountCents" />
            <TextField source="travellerName" />
            <TextField source="travellerPhoneNumber" />
            {/* <TextField source="currency" /> */}
            <TextField source="travellerEmail" />
            <SelectField source="currentState" choices={OrderStateStatusChoices}/>
            <LongDateField source="createdAt" />
            <LongDateField source="updatedAt" />
            <TextField source="termAndConditions" />
            
            <ReferenceField link="show" source="billingAddressId" reference="addresses">
              <TextField source="id" />
            </ReferenceField>
            
            
          </Tab>

          <Tab label="Order States" path="orderStates">
            <RecordFetcher setRecord={setRecord} />
            <ReferenceField addLabel={false} link="show" source="bookingId" reference="bookings">
              {/* <TextField source="bookingNumber" /> */}
              {/* <RecordFetcher setRecord={setBookingRecord} content={<TextField source="bookingNumber" />}/> */}
              <RecordFetcher setRecord={setBookingRecord} />  
            </ReferenceField>
            <ReferenceManyField reference="orderStates" target="orderId" addLabel={false} sort={{ field: 'createdAt', order: 'DESC' }}>
              <Datagrid>
                {/* <TextField source="id" /> */}
                <SelectField source="state" choices={OrderStateStatusChoices}/>
                <LongDateField source="createdAt" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>

        </TabbedShowLayout>
      </Show>
    </>
  )
}

export default OrderShow;
import { objToNameIdArray } from 'Util/Constants';

export const VehicleTypeDescriptionItemChoices = objToNameIdArray({
  Heading: 'h',
  Paragraph: 'p',
})

export const VehicleCategories = {
  BUDGET: "budget",
  PREMIUM: "premium"
}

export const VehicleCategoryChoices = objToNameIdArray(VehicleCategories);

export default {
  VehicleTypeDescriptionItemChoices,
  VehicleCategories,
  VehicleCategoryChoices,
}
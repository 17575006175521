import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import OperatingCityModal from "./SurgeModal";

const CreateEditOperatingCity = ({
  refreshList,
  surgeDetails,
  countries,
  cities
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant={surgeDetails ? "text" : "contained"}
        color="primary"
      >
        {surgeDetails ? "Edit" : "Create Surge"}
      </Button>
      <OperatingCityModal
        countries={countries}
        cities={cities}
        surgeDetails={surgeDetails}
        refreshList={refreshList}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

export default CreateEditOperatingCity;
